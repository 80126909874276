import React, { Component } from 'react';
import '../../../employeeScreens/continuousFeedbackManagement/CFMHome.css';
import { connect } from 'react-redux';
import * as Config from "../../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../../constants/Service";
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import default_profile from '../../../../../assets/drishti_images/profile/default_profile.svg';
import SummaryScreen from '../../../managerScreens/teams/directReportees/SummaryScreen';
import DetailsScreen from '../../../managerScreens/teams/directReportees/DetailsScreen';
class FeedbackCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            todoSelect: 'Goal',
            isGoalPopup: true,
            isLeadershipPopup: false,
            empIdOfReportee: this.props.cfm.directReporteeDetails.empId ? this.props.cfm.directReporteeDetails.empId : '',
            emailIdOfReportee: this.props.cfm.directReporteeDetails.username ? this.props.cfm.directReporteeDetails.username : '',
            PSubarea: this.props.loginData.userData.PSubarea ? this.props.loginData.userData.PSubarea : '',
            PersArea: this.props.loginData.userData.PersArea ? this.props.loginData.userData.PersArea : '',
            directReporteesData: [],
            employeedata: [],
            employeeImage: []
        }
    }

    componentDidMount() {
        this.getLatestuserDetailsFromEdpCet(this.state.empIdOfReportee, this.state.emailIdOfReportee);
        this.getBulkProfileImage(this.state.empIdOfReportee)
    }

    getLatestuserDetailsFromEdpCet(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId,
        }
        fetchApiCall(Config.getLatestuserDetailsFromEdpCet, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        employeedata: res.data.user_details[0]
                    })
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    getBulkProfileImage(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id_list: [employeeId],
        }
        console.log("getBulkProfileImage body:", body)
        fetchApiCall(Config.get_bulk_profile_image, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        employeeImage: res.data.profile_photos[0].profile_img,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        employeeImage: ''
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    render() {
        const name = this.state.employeedata?.CompName
        const emp_id = this.state.employeedata?.Perno
        const email_id = this.state.employeedata?.Imailid
        const org_unit = this.state.employeedata?.Orgtx
        const reporting_name = this.state.employeedata?.Nameofreporting
        const function_text = this.state.employeedata?.FunctionText
        const doj = this.state.employeedata?.Doj
        console.log('doj---------->', doj);
        return (
            <div className="cfm-card p-3" >
                <LoaderIndicator loading={this.state.loading} />
                <div className="cfm-subcard justify-content-start align-item-center">
                    <div className="row align-item-center py-3 team-overview-style" >
                        {
                            this.state.employeeImage === '' ?
                                <div className='mx-3'>
                                    <img alt="avatar"
                                        src={default_profile} className="header-profile-icon"
                                        onClick={this.toggleProfile}
                                        data-bs-toggle="modal">
                                    </img>
                                </div>
                                :
                                <div className='mx-3'>
                                    <img alt="avatar"
                                        src={this.state.employeeImage} className="header-profile-icon"
                                        onClick={this.toggleProfile}
                                        data-bs-toggle="modal">
                                    </img>
                                </div>
                        }
                    </div>
                    <div className='mt-0 w-100'>
                        <div className="flex-direction-column">
                            <div className='card-content-title common-text-color'>{name}</div>
                            <div className='profile-list-item-text pointer'>{org_unit}</div>
                        </div>
                        <div className='row mt-4'>
                            <div className='profile-card-text-bold w-50'>
                                Employee ID - <span className='profile-card-text-light'>{emp_id}</span>
                            </div>
                            <div className='profile-card-text-bold w-50'>
                                Function -<span className='profile-card-text-light'>{function_text}</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='profile-card-text-bold w-50'>
                                Reporting Manager - <span className='profile-card-text-light'>{reporting_name}</span>
                            </div>
                            <div className='profile-card-text-bold w-50'>
                                Org Unit -
                                <span className='profile-card-text-light'>{org_unit}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row d-flex justify-content-center mt-3 mb-4'>
                    {this.state.todoSelect === 'Competency' ?
                        <>
                            <div className="row deactivate-button-left d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Goal' })}>Summary</div>
                            <div className="row activate-button-right d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Competency' })}>Details</div>
                        </>
                        :
                        <>
                            <div className="row activate-button-left d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Goal' })}>Summary</div>
                            <div className="row deactivate-button-right d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Competency' })}>Details</div>
                        </>
                    }
                </div>
                <div className=''>
                    {this.state.todoSelect === 'Goal' ?
                        <div>
                            <SummaryScreen
                                loginData={this.props.loginData}
                                clickid={this.state.empIdOfReportee}
                                PersArea={this.state.PersArea}
                                PSubarea={this.state.PSubarea}
                                clickEmailId={this.state.emailIdOfReportee}
                                ReporteeEmpId={this.state.employeedata.Perno}
                                ReporteeName={this.state.employeedata.CompName}
                                ReporteeEmailId={this.state.employeedata.Imailid}
                                reporteeDoj={doj ? doj : ''}
                                reporteeName={name ? name : ''}
                            />
                        </div> : null
                    }
                    {this.state.todoSelect === 'Competency' ?
                        <div>
                            <DetailsScreen
                                empdata={this.state.employeedata}
                                clickid={this.state.empIdOfReportee}
                                clickEmailId={this.state.emailIdOfReportee}
                            />
                        </div> : null
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfm: state.cfmState
    }
}

export default (connect(mapStatesToProps)(FeedbackCard));