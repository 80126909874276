import React, { Component } from 'react';
import './ProfileScreen.css';
import personal from '../../../../assets/drishti_images/profile/personal_info_icon.svg';
import exit_img from '../../../../assets/drishti_images/profile/exit_img.svg';
import eseparationsLogo from '../../../../assets/drishti_images/profile/eseparationsLogo.svg';
import paySpaceNew from '../../../../assets/drishti_images/profile/paySpaceNew.svg';
import transfer from '../../../../assets/drishti_images/profile/document.png';
import document from '../../../../assets/drishti_images/profile/transfer.svg';
import coi_img from '../../../../assets/drishti_images/profile/coi_img.svg';
import * as AdminLoginAction from '../../adminScreens/login/LoginAction';
import logout from '../../../../assets/drishti_images/profile/logout.svg';
import { configStore } from "../../../../redux/ConfigStore";
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import OutsideClickHandler from 'react-outside-click-handler';
import default_profile from '../../../../assets/drishti_images/profile/default_profile.svg';
import { connect } from 'react-redux';
import * as userActions from '../../employeeScreens/login/LoginAction';
import { bindActionCreators } from 'redux';
import whitecross from '../../../../assets/drishti_images/white-cross.svg';

const preProfile = 'data:image/png;base64,'
const label = [
  { title: 'Personal Information', image: personal, routeTo: 'profile' },
  // { title: 'Professional Information', image: professional, routeTo: 'professionalInformation' },
  { title: 'Document Center', image: document, routeTo: 'document' },
  // { title: 'Conflict Of Interest', image: coi_img, routeTo: 'coi' },
  // { title: 'E-Separation', image: exit_img, routeTo: 'esepRequest' }
]
class ProfileCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profileArray: label,
      clickImage: ''
    }
  }


  routeProfile = (route) => {
    if (route === 'logout') {
      this.logoutApi()
    }
    else {
      window.location = (BASE_WEB_URL_DEV + route);
    }
  }

  logoutApi = () => {
    const refreshToken = configStore.getState().keycloakState.keycloakData.access_token ?
      configStore.getState().keycloakState.keycloakData.access_token.refresh_token : '';
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = {
      empId: configStore.getState().loginState.userData.Perno,
      refresh_token: refreshToken
    }
    fetchApiCall(Config.logout, body, headers, true, '')
      .then(res => {
        sessionOut(this.props, res);
        window.localStorage.clear();
        this.props.actions.emptyLoginState()
        if (configStore.getState().adminLoginState.isAdminLoggedIn) {
          window.location = (BASE_WEB_URL_DEV + 'admin');
        }
        else {
          window.location = (BASE_WEB_URL_DEV + 'login');
        }
      }).catch(err => {
        console.log("logout err");
        window.localStorage.clear();
        if (configStore.getState().adminLoginState.isAdminLoggedIn) {
          window.location = (BASE_WEB_URL_DEV + 'admin');
        }
        else {
          window.location = (BASE_WEB_URL_DEV + 'login');
        }
      })
  }


  viewImage = (img) => {
    this.setState({
      clickImage: img
    })
  }

  render() {
    const companyName = configStore.getState().loginState.company.company_sht_txt
    const isExitFormApplicable = this.props.loginData.isExitFormApplicable
    const name = configStore.getState().adminLoginState.isAdminLoggedIn ? configStore.getState().adminLoginState.userData.CompName : configStore.getState().loginState.userData.CompName
    const org_unit = configStore.getState().adminLoginState.isAdminLoggedIn ? configStore.getState().adminLoginState.userData.Orgtx : configStore.getState().loginState.userData.Orgtx
    const reporting_name = configStore.getState().adminLoginState.isAdminLoggedIn ? configStore.getState().adminLoginState.userData.Nameofreporting : configStore.getState().loginState.userData.Nameofreporting
    const function_text = configStore.getState().adminLoginState.isAdminLoggedIn ? configStore.getState().adminLoginState.userData.FunctionText : configStore.getState().loginState.userData.FunctionText
    const isBHR = configStore.getState().loginState.isBHR
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.props.onDismiss()}>
        <div>
          <div className={configStore.getState().adminLoginState.isAdminLoggedIn ? 'admin-profile-container' : 'profile-container'} ref={this.ref}>
            <div className='profile-container-first' >
              <div className="row align-item-center py-3">
                {configStore.getState().adminLoginState.isAdminLoggedIn ?
                  <>
                    {configStore.getState().adminLoginState.profileImage != '' ?
                      <div className='mx-3'>
                        <img alt="avatar" src={preProfile + configStore.getState().adminLoginState.profileImage} className="header-right-icon" onClick={() => this.viewImage(preProfile + configStore.getState().loginState.profileImage)} data-bs-target="#exampleModalCenter" data-bs-toggle="modal"></img>
                      </div> :
                      <div className='mx-3'>
                        <img alt="avatar" src={default_profile} className="profile-sm-default" onClick={() => this.viewImage(preProfile + configStore.getState().loginState.profileImage)} data-bs-toggle="modal" data-bs-target="#exampleModalCenter"></img>
                      </div>
                    }
                  </>
                  :
                  <>
                    {configStore.getState().loginState.profileImage != '' ?
                      <div className='mx-3'>
                        <img alt="avatar"
                          src={preProfile + configStore.getState().loginState.profileImage}
                          className="header-right-icon"
                          onClick={() => this.viewImage(preProfile + configStore.getState().loginState.profileImage)}
                          data-bs-target="#exampleModalCenter"
                          data-bs-toggle="modal"></img>
                      </div> :
                      <div className='mx-3'>
                        <img alt="avatar" src={default_profile} className="profile-sm-default" onClick={this.toggleProfile} data-bs-toggle="modal"></img>
                      </div>
                    }
                  </>
                }
                <div className="flex-direction-column">
                  <div className='card-content-title common-text-color'>{name}</div>
                  <div className='profile-list-item-text pointer'>{org_unit}</div>
                </div>
              </div>

              <div className='px-3' >
                <div className='hr-line'></div>
                <div className='detail-lable-head card-content-title common-text-color text-left d-flex pt-3'>
                  Reporting Manager - <span className='detail-lable-desc ml-1'>{reporting_name}</span>
                </div>
                <div className='detail-lable-head common-text-color text-left d-flex pt-1'>Function -
                  <span className='detail-lable-desc ml-1'>{function_text}</span>
                </div>
                <div className='detail-lable-head common-text-color text-left d-flex pt-1'>Org Unit -
                  <span className='detail-lable-desc ml-1'>{org_unit}</span></div>
              </div>
            </div>

            <div className={configStore.getState().adminLoginState.isAdminLoggedIn ? '' : 'profileCardScroller'}>
              <div>
                {!configStore.getState().adminLoginState.isAdminLoggedIn &&
                  <>
                    {this.state.profileArray.map((list, i) => (
                      <div key={i} className='profile-list-item px-20px' onClick={() => this.routeProfile(list.routeTo)}>
                        <div className='d-flex align-item-center' >
                          <div>
                            <img alt={list.image} src={list.image} className="profile-list-image"
                            ></img>
                          </div>
                          <div className='profile-list-item-text pointer'>{list.title}</div>
                        </div>
                        <div className='hr-line'></div>
                      </div>
                    ))
                    }
                  </>
                }
                {!configStore.getState().adminLoginState.isAdminLoggedIn &&
                  <>
                    {this.props.loginData.isApplicableForChoicePay.is_employee_applicable &&
                      <div className='profile-list-item px-20px' onClick={() => this.routeProfile('paySpace')}>
                        <div className='d-flex align-item-center' >
                          <div>
                            <img alt={paySpaceNew} src={paySpaceNew} className="profile-list-image"
                            ></img>
                          </div>
                          <div className='profile-list-item-text pointer'>{'Pay Space'}</div>
                        </div>
                        <div className='hr-line'></div>
                      </div>}
                  </>
                }
                {companyName !== 'TMLBSL' && !configStore.getState().adminLoginState.isAdminLoggedIn &&
                  <>
                    {isBHR &&
                      <div className='profile-list-item px-20px' onClick={() => this.routeProfile('transfer')}>
                        <div className='d-flex align-item-center' >
                          <div>
                            <img alt={transfer} src={transfer} className="profile-list-image"
                            ></img>
                          </div>
                          <div className='profile-list-item-text pointer'>{'Transfer'}</div>
                        </div>
                        <div className='hr-line'></div>
                      </div>
                    }
                  </>
                }
                {!configStore.getState().adminLoginState.isAdminLoggedIn &&
                  <>
                    <div className='profile-list-item px-20px' onClick={() => this.routeProfile('esepRequest')}>
                      <div className='d-flex align-item-center' >
                        <div>
                          <img alt={eseparationsLogo} src={eseparationsLogo} className="profile-list-image"
                          ></img>
                        </div>
                        <div className='profile-list-item-text pointer'>{'E-Separation'}</div>
                      </div>
                      <div className='hr-line'></div>
                    </div>
                  </>
                }
                {!configStore.getState().adminLoginState.isAdminLoggedIn &&
                  <>
                    <div className='profile-list-item px-20px' onClick={() => this.routeProfile('coi')}>
                      <div className='d-flex align-item-center' >
                        <div>
                          <img alt={coi_img} src={coi_img} className="profile-list-image"
                          ></img>
                        </div>
                        <div className='profile-list-item-text pointer'>{'Conflict Of Interest'}</div>
                      </div>
                      <div className='hr-line'></div>
                    </div>
                  </>
                }

                {isExitFormApplicable && configStore.getState().loginState.isExitApplicable.is_exit_applicable ?
                  <div className='profile-list-item px-20px' onClick={() => this.routeProfile('exitform')}>
                    <div className='d-flex align-item-center' >
                      <div>
                        <img alt={exit_img} src={exit_img} className="profile-list-image"
                        ></img>
                      </div>
                      <div className='profile-list-item-text pointer'>{'Exit Form'}</div>
                    </div>
                    <div className='hr-line'></div>
                  </div> : null
                }

                <div className='profile-list-item px-20px' onClick={() => this.routeProfile('logout')}>
                  <div className='d-flex align-item-center' >
                    <div>
                      <img alt={logout} src={logout} className="profile-list-image"
                      ></img>
                    </div>
                    <div className='profile-list-item-text pointer'>{'Logout'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModalCenter" tabIndex={"-1"} role="" >
            <div className="modal-dialog modal-dialog-centered" role="">
              <div className="modal-content">
                <div className="modal-body">
                  <div className='close-btn-styles'>
                    <img src={whitecross} alt="" className="" data-bs-dismiss="modal" aria-label="Close" />
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <img src={this.state.clickImage} alt="" className="model-img-gems" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    adminLoginData: state.adminLoginState,
    serviceData: state.activePopupState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
    adminAction: bindActionCreators(AdminLoginAction, dispatch),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ProfileCard);