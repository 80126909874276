import React, { Component } from 'react';
import LARightScreen from './LARightScreen';
import * as Config from "../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import './learningArchitectureStyle.css'
import '../../employeeScreens/continuousFeedbackManagement/CFMHome.css';
import { connect } from 'react-redux';
import infoicon from '../../../../assets/drishti_images/infoicon.svg'
import default_profile from '../../../../assets/drishti_images/profile/default_profile.svg';
import rightarrowbg from '../../../../assets/drishti_images/right-arrow-bg.svg'
import LASearchPopup from './LASearchPopup'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import Welcomepopup from './Welcomepopup';

const surveyText = "We would like to get feedback on your experience of 'Learning Landscape' on Drishti. Please share your inputs by responding to this short four question survey (Rating Scale: 1 being lowest and 4 being highest)."
class Learningarchitecture extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            employeeImage: '',
            aspirationalRole: false,
            currentRole: true,
            tabType: 'Functional-Development',
            typeTitle: 'Functional Programs',
            openPopUp: false,
            viewData: [],
            datalist: [],
            getGuidedSearchData: [],
            headerTitle: [],
            company_list: [],
            function_list: [],
            level_list: [],
            sub_job_family_1_list: [],
            sub_job_family_2_list: [],
            masterData: [],
            temp1: [],
            level: '',
            company: '',
            ujrText: '',
            ujrText_list: '',
            customSearch: false,
            welcomepopUp: false
        }
    }
    componentDidMount() {
        this.setState({
            welcomepopUp: true
        })
        this.getBulkProfileImage()
        this.getcurrentRole()
    }
    getBulkProfileImage() {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id_list: [this.props.loginData.userData.Perno],
        }
        fetchApiCall(Config.get_bulk_profile_image, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        employeeImage: res.data.profile_photos[0].profile_img,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        employeeImage: ''
                    })
                    setTimeout(() => {
                        toast.warn(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastWarn'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                setTimeout(() => {
                    toast.error("something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    getcurrentRole() {
        this.setState({
            loading: true,
            customSearch: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.getcurrent_role, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        headerTitle: res.data,
                        viewData: res.data,
                        level: res.data.level,
                        company: res.data.company,
                        ujrText: res.data.ujr_text,
                        loading: false,
                        datalist: res.data.functional_program_list,
                        tabType: 'Functional-Development'
                    })
                } else {
                    console.log("getcurrent_role else running");
                    this.setState({
                        loading: false,
                        viewData: [],
                        headerTitle: [],
                        datalist: []
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }

    getGuided_search(companyValue, functionValue, jf1Value, jf2Value, levelValue, ujr) {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            company: companyValue,
            level: levelValue,
            ujr_text: ujr,
            function: functionValue,
            sub_job_family_1: jf1Value,
            sub_job_family_2: jf2Value,
            emp_id: this.props.loginData.userData.Perno,
        }
        console.log("getGuided_search body:", body)
        fetchApiCall(Config.guided_search, body, headers, true, '')
            .then(res => {
                console.log("getGuided_search res:", res)
                // sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        viewData: res.data,
                        loading: false,
                        company: companyValue,
                        level: levelValue,
                        ujrText: ujr,
                        tabType: 'Functional-Development',
                        typeTitle: 'Functional Programs',
                        datalist: res.data.length != 0 ? res.data.functional_program_list : [],
                        customSearch: true
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        viewData: [],
                        datalist: [],
                        typeTitle: '',
                        tabType: '',
                        customSearch: true,
                        company: companyValue,
                        level: levelValue,
                        ujrText: ujr
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    customSearch: true
                })
                setTimeout(() => {
                    toast.error('something went wrong', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    getUJR_search(companyValue, levelValue, ujr) {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            company: companyValue,
            level: levelValue,
            ujr_text: ujr,
            emp_id: this.props.loginData.userData.Perno,
        }
        console.log("getUJR_search body:", body)
        fetchApiCall(Config.ujr_search, body, headers, true, '')
            .then(res => {
                console.log("getUJR_search res:", res)
                // sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        viewData: res.data,
                        loading: false,
                        company: companyValue,
                        level: levelValue,
                        ujrText: ujr,
                        typeTitle: 'Functional Programs',
                        datalist: res.data.length != 0 ? res.data.functional_program_list : [],
                        tabType: 'Functional-Development',
                        customSearch: true
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        viewData: [],
                        datalist: [],
                        typeTitle: '',
                        tabType: '',
                        customSearch: true,
                        company: companyValue,
                        level: levelValue,
                        ujrText: ujr,
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    customSearch: true
                })
                setTimeout(() => {
                    toast.error("something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }

    handle = (tab) => {
        console.log("this.state.currentRole:", this.state.currentRole);
        console.log("tab:", tab);
        if (tab === "currentRole") {
            if (this.state.currentRole === true) {
                this.setState({
                    currentRole: true,
                    aspirationalRole: false,
                    // typeTitle: 'Functional Development',
                })
            }
            else {
                this.setState({
                    currentRole: true,
                    aspirationalRole: false,
                    datalist: [],
                    // typeTitle: '',
                    typeTitle: 'Functional Programs',
                    viewData: []
                },
                    () => this.getcurrentRole())
            }
        }
        else {
            this.setState({
                currentRole: false,
                aspirationalRole: true,
                datalist: [],
                // typeTitle: '',
                viewData: []
            })
        }
    }
    control_tab = (type, title) => {
        if (title === "Functional Programs") {
            this.setState({
                tabType: type,
                typeTitle: title,
                datalist: this.state.viewData.length != 0 ? this.state.viewData.functional_program_list : []
            })
        }
        else if (title === "Professional Programs") {
            this.setState({
                tabType: type,
                typeTitle: title,
                datalist: this.state.viewData.professional_program_list.length != 0 ? this.state.viewData.professional_program_list : []
            })
        }
        else {
            this.setState({
                tabType: type,
                typeTitle: title,
                datalist: this.state.viewData.leadership_program_list.length != 0 ? this.state.viewData.leadership_program_list : []
            })
        }
    }
    openPopup = (type) => {
        console.log(type)
        if (type === "open-popup") {
            this.setState({
                openPopUp: true
            },
                // () => this.get_learning_arch_master_data()
            )
        }
        else {
            this.setState({
                openPopUp: false
            })
        }
    }

    handleSearchCallFunction = (whichSearch, companyValue, functionValue, jf1Value, jf2Value, levelValue, ujr, ujrCode) => {
        console.log("language:", whichSearch, companyValue, functionValue, jf1Value, jf2Value, levelValue, ujr, ujrCode)
        if (whichSearch === "guidedSearch") {
            this.getGuided_search(companyValue, functionValue, jf1Value, jf2Value, levelValue, ujr)
        }
        else {
            this.getUJR_search(companyValue, levelValue, ujr)
        }
    }

    get_learning_arch_master_data() {
        console.log("get_learning_arch_master_data running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        console.log("getUJR_search body:", body)
        fetchApiCall(Config.get_learning_arch_master_data, body, headers, true, '')
            .then(res => {
                console.log("get_learning_arch_master_data res:", res.data.company_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        masterData: res.data,
                        company_list: res.data.company_list,
                        function_list: res.data.function_list,
                        level_list: res.data.level_list,
                        sub_job_family_1_list: res.data.sub_job_family_1_list,
                        sub_job_family_2_list: res.data.sub_job_family_2_list,
                        ujrText_list: res.data.ujr_text,
                        loading: false
                    })
                    const tempcompany_list = this.state.company_list.map(tempValue => ({ label: tempValue }))
                    const tempfunction_list = this.state.function_list.map(tempValue => ({ label: tempValue }))
                    const templevel_list = this.state.level_list.map(tempValue => ({ label: tempValue }))
                    const tempsub_job_family_1_list = this.state.sub_job_family_1_list.map(tempValue => ({ label: tempValue }))
                    const tempsub_job_family_2_list = this.state.sub_job_family_2_list.map(tempValue => ({ label: tempValue }))
                    const tempujrText_list = this.state.ujrText_list.map(tempValue => ({ label: tempValue }))

                    this.setState({
                        masterData: res.data,
                        company_list: tempcompany_list,
                        function_list: tempfunction_list,
                        level_list: templevel_list,
                        sub_job_family_1_list: tempsub_job_family_1_list,
                        sub_job_family_2_list: tempsub_job_family_2_list,
                        ujrText_list: tempujrText_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        masterData: [],
                        company_list: [],
                        function_list: [],
                        level_list: [],
                        sub_job_family_1_list: [],
                        sub_job_family_2_list: [],
                        ujrText_list: []
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }

    render() {
        return (
            <div className="la-wrapper-grid ">
                <LoaderIndicator loading={this.state.loading} />
                <div className="la-main">
                    <div className='LA-card p-10'>
                        <div className="la-left-wrapper-grid align-item-center-noflex white-card-subcard">
                            <div className="la-image text-align-center">
                                {
                                    this.state.employeeImage === '' ?
                                        <img alt="avatar"
                                            src={default_profile}
                                            className="header-profile-icon-vw"
                                            data-bs-toggle="modal">
                                        </img>
                                        :
                                        <img alt="avatar"
                                            src={this.state.employeeImage}
                                            className="header-profile-icon"
                                            onClick={this.toggleProfile}
                                            data-bs-toggle="modal">
                                        </img>
                                }
                            </div>
                            <div className="la-details">
                                <div className="flex-direction-column">
                                    <div className='card-content-title common-text-color'>{this.props.loginData.userData.CompName ? this.props.loginData.userData.CompName : ' -'}</div>
                                    <div className='profile-list-item-text-vw'>{this.props.loginData.userData.Postxt ? this.props.loginData.userData.Postxt : ' -'}</div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='profile-card-text-bold w-100'>
                                        Unique Job Role - <span className='profile-card-text-light'>{this.state.headerTitle.ujr_text ? this.state.headerTitle.ujr_text : '  -'}{' ( '}{this.state.headerTitle.ujr_code ? this.state.headerTitle.ujr_code : ' -'}{' ) '}</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='profile-card-text-bold w-50'>
                                        Function - <span className='profile-card-text-light w-40'>{this.state.headerTitle.function ? this.state.headerTitle.function : '  -'}</span>
                                    </div>
                                    <div className='profile-card-text-bold w-50'>
                                        Level - <span className='profile-card-text-light'>{this.state.headerTitle.level ? this.state.headerTitle.level : ' -'}</span>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='profile-card-text-bold w-50'>
                                        Sub Job Family 1 -<span className='profile-card-text-light'>{this.state.headerTitle.sub_job_family_1 ? this.state.headerTitle.sub_job_family_1 : '  -'}</span>
                                    </div>
                                    <div className='profile-card-text-bold w-50'>
                                        Sub Job Family 2 -
                                        <span className='profile-card-text-light w-40'>{this.state.headerTitle.sub_job_family_2 ? this.state.headerTitle.sub_job_family_2 : '  -'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='la-left-toggle-wrapper-grid'>
                                <div className='la-toggle'>
                                    <div className='row mt-10px'>
                                        <div className={this.state.currentRole === true ? "row activate-button-left d-flex justify-content-center" : "row deactivate-button-left d-flex justify-content-center"}
                                            onClick={() => this.handle("currentRole")}>
                                            Current Role
                                        </div>
                                        <div className={this.state.aspirationalRole === true ? "row activate-button-right d-flex justify-content-center" : "row deactivate-button-right d-flex justify-content-center"} 
                                        onClick={() => this.handle("aspirationalRole")}>Aspirational Role</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='la-left-item-wrapper-grid'>
                                <div className='la-item'>
                                    <div className='white-card-subcard-outer p-lrtb-10px'>
                                        {this.state.aspirationalRole === true &&
                                            <>
                                                <div className='row align-item-center-noflex '>
                                                    <div>
                                                        <img alt="info"
                                                            src={infoicon}
                                                            className="m-5px"
                                                            data-bs-toggle="modal">
                                                        </img>
                                                        <label className='card-content-title1 purple-color m-5px'>Click on search to view aspirational role</label>
                                                    </div>
                                                    <div>
                                                        <input type='button'
                                                            value='Search'
                                                            className='validateBtn ml-12'
                                                            onClick={() => this.openPopup("open-popup")}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {this.state.customSearch === true &&
                                                        <label className='profile-text-bold ml-5px'>{this.state.company}{"-"}{this.state.level}{"-"}{this.state.ujrText}</label>
                                                    }
                                                </div>
                                            </>
                                        }
                                        {this.state.viewData.length !== 0 &&
                                            // <div className={this.state.aspirationalRole === true && 'height-internaldevelopmentCard'}>
                                            <div>
                                                <div
                                                    className={this.state.tabType === 'Functional-Development' && this.state.viewData.length !== 0 ?
                                                        "row selected-card-subcard m-10px justify-content-between align-item-center p-10 pointer"
                                                        :
                                                        "row white-card-subcard m-10px justify-content-between align-item-center p-10 pointer"
                                                    }
                                                    onClick={() => this.state.viewData.length !== 0 && this.control_tab("Functional-Development", "Functional Programs")}
                                                >
                                                    <label className='announcement-inner-title common-text-color pointer'>
                                                        Functional Programs
                                                    </label>
                                                    <img
                                                        alt="on-off-button"
                                                        src={rightarrowbg}
                                                        className="m-5px pointer"
                                                        data-bs-toggle="modal"
                                                    />
                                                </div>
                                                <div
                                                    className={this.state.tabType === 'Professional-Development' && this.state.viewData.length !== 0 ? "row selected-card-subcard m-10px justify-content-between align-item-center p-10 pointer" : "row white-card-subcard m-10px justify-content-between align-item-center p-10 pointer"}
                                                    onClick={() => this.state.viewData.length !== 0 && this.control_tab("Professional-Development", "Professional Programs")}
                                                >
                                                    <label className='announcement-inner-title common-text-color pointer '>
                                                        Professional Programs
                                                    </label>
                                                    <img
                                                        alt="on-off-button"
                                                        src={rightarrowbg}
                                                        className="m-5px pointer"
                                                        data-bs-toggle="modal"
                                                    />
                                                </div>
                                                <div
                                                    className={this.state.tabType === 'Leadership-Development' && this.state.viewData.length !== 0 ? "row selected-card-subcard m-10px justify-content-between align-item-center p-10 pointer" : "row white-card-subcard m-10px justify-content-between align-item-center p-10 pointer"}
                                                    onClick={() => this.state.viewData.length !== 0 && this.control_tab("Leadership-Development", "Leadership Programs")}
                                                >
                                                    <label className='announcement-inner-title common-text-color pointer'>
                                                        Leadership Programs
                                                    </label>
                                                    <img
                                                        alt="on-off-button"
                                                        src={rightarrowbg}
                                                        className="m-5px pointer"
                                                        data-bs-toggle="modal"
                                                    />
                                                </div>
                                                <div className='welcomomepopupdescription'>
                                                    <label className='card-content-title1 purple-color m-5px'>
                                                        {surveyText}{"  "}
                                                        <u>
                                                            <a target='_Blank' className='card-content-title1' href="https://www.surveymonkey.com/r/Feedback_on_experience_of_Learning_Landscape">
                                                                Click here to launch the survey.
                                                            </a>
                                                        </u>
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="la-child">
                    <div className='LA-card-right p-10'>
                        <LARightScreen
                            title={this.state.typeTitle}
                            data={this.state.datalist}
                            customSearch={this.state.customSearch}
                        />
                    </div>
                </div>
                {
                    this.state.openPopUp &&
                    <LASearchPopup
                        onDismiss={() => this.setState({ openPopUp: false })}
                        passMasterDataValues={(whichSearch, companyValue, functionValue, jf1Value, jf2Value, levelValue, ujr) =>
                            this.handleSearchCallFunction(whichSearch, companyValue, functionValue, jf1Value, jf2Value, levelValue, ujr)}
                        masterData={this.state.masterData}
                        company_list={this.state.company_list}
                        function_list={this.state.function_list}
                        level_list={this.state.level_list}
                        sub_job_family_1_list={this.state.sub_job_family_1_list}
                        sub_job_family_2_list={this.state.sub_job_family_2_list}
                        ujrText_list={this.state.ujrText_list}
                    />
                }
                {
                    this.state.welcomepopUp &&
                    <Welcomepopup
                        onDismiss={() => this.setState({ welcomepopUp: false })} />
                }
            </div >
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfm: state.cfmState
    }
}
export default (connect(mapStatesToProps)(Learningarchitecture));

