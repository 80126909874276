import React, { useEffect, useState } from "react";
import DynamicDropdown from "../../../../shared/components/DynamicDropdown";
import { ADD, ADD_MESSAGE, ADDBUDGET, ADDGGTYPE, ADDINSTALLMENT, ADDLOANALLOWED, ADDNEXTLOANSTART, ERROR_MESSAGE, GRADE_GROUP, GRADE_GROUP_TYPE, LOAN_NO, LOAN_TYPE,SUCCESS,UNIT, UPDATE, UPDATE_MESSAGE, UPDATEBUDGET, UPDATEGGTYPE, UPDATEINSTALLMENT, UPDATELOANALLOWED, UPDATENEXTLOANSTART, WARNING } from "../constants";
import { useSelector } from "react-redux";
import { addBudget, getDropdownList, getFinancialYearList, updateBudget, updateInstallment, addInstallment, addLoanAllowed, updateLoanAllowed, addGradeGroupType, updateGradeGroupType, addNextLoan, updateNextLoan} from "../../../../../constants/api.service";
import { toaster, YYYYMMDD } from "../../../../../utils/Utils";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";

const MasterScreenPopUp = ({
  title,
  crossBtn,
  loanType = false,
  loanNo = false,
  unit = false,
  gradeGroupType = false,
  gradeGroup = false,
  financialYear = false,
  budgetAmount = false,
  loanAmount = false,
  intRate = false,
  totalMonthRecovery = false,
  startDate = false,
  noOfLoanAllowed = false,
  monthInNumber = false,
  btnValue,
  bindedData = null,
  apiRequest = null
}) => {
  const toDay = new Date().toISOString().split("T")[0];
  const [loanTypeData, setLoanTypeData] = useState([]);
  const [selectedLoanType, setSelectedLoanType] = useState([]);
  const [loanNoData, setLoanNoData] = useState([]);
  const [selectedLoanNo, setSelectedLoanNo] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [gradeGroupTypeData, setGradeGroupTypeData] = useState([]);
  const [selectedGradeGroupType, setSelectedGradeGroupType] = useState([]);
  const [gradeGroupData, setGradeGroupData] = useState([]);
  const [selectedGradeGroup, setSelectedGradeGroup] = useState([]);
  const [financialYearData, setFinancialYearData] = useState([]);
  const [selectedFinancialYear, setSelectedFinancialYear] = useState([]);
  const [budgetAmountValue, setBudgetAmountValue] = useState("");
  const [loanAmountValue, setLoanAmountValue] = useState("");
  const [interestRateValue, setInterestRateValue] = useState("");
  const [totalMonthRecoveryValue, setTotalMonthRecoveryValue] = useState("");
  const [noOfLoansAllowedValue, setNoofLoansAllowedValue] = useState("");
  const [monthInNumbersValue, setMonthInNumbersValue] = useState("");
  const [fromDate, setFormDate] = useState(toDay);
  const [loader, setLoader] = useState(false)
  const AdminLoginData = useSelector((state) => state.adminLoginState);

  const changeLoanTypeData = (data) => {
    setSelectedLoanType(data);
  };
  const changeLoanNoData = (data) => {
    setSelectedLoanNo(data);
  };
  const changeUnitData = (data) => {
    setSelectedUnit(data);
  };
  const changeGradeGroupTypeData = (data) => {
    setSelectedGradeGroupType(data);
  };
  const changeGradeGroupData = (data) => {
    setSelectedGradeGroup(data);
  };
  const changeFinancialYearData = (data) => {
    setSelectedFinancialYear(data);
  };
  const changeBudgetAmount = (data) => {
    setBudgetAmountValue(data);
  };
  const changeLoanAmount = (data) => {
    setLoanAmountValue(data);
  };
  const changeInterestRate = (data) => {
    setInterestRateValue(data);
  };
  const changeTotalMonthRecovery = (data) => {
    setTotalMonthRecoveryValue(data);
  };
  const changeNoOfLoansAllowed = (data) => {
    setNoofLoansAllowedValue(data);
  };
  const changeMonthInNumbers = (data) => {
    setMonthInNumbersValue(data);
  };
  const handleFromDate = (data) => {
    setFormDate(data);
  };
  //==============================================GET LOAN TYPE LIST====================================================
  const getLoanTypeList = async(dropDownType) =>{
    setLoader(true);
    let params={
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }
    await getDropdownList(params)
    .then((res)=>{
      if((res.status === 200 || res.status === 201)){
        const loanData = res?.data?.data?.map((data)=>({
          name: data?.loan_type_name,
          id : data?.loan_type_id
        }))
        setLoanTypeData(loanData);
      }else{
        toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
      }
    }).catch((error) => {
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    }).finally(()=>{
      setLoader(false);
    })
  }
  
        //==============================================GET LOAN NO LIST====================================================
    const getLoanNoList = async(dropDownType) =>{
      setLoader(true);
      let params={
        comp_code: AdminLoginData?.company?.company_code,
        drop_down_type: dropDownType
      }
      await getDropdownList(params)
      .then((res)=>{
        if((res.status === 200 || res.status === 201)){
          const loanDataNo = res?.data?.data?.map((data)=>({
            name: data?.loan_no_name,
            id : data?.loan_no_id
          }))
          setLoanNoData(loanDataNo);
        }else{
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false);
      })
    }
  
      //==============================================GET UNIT LIST====================================================
      const getUnitList = async(dropDownType) =>{
        setLoader(true);
        let params={
          comp_code: AdminLoginData?.company?.company_code,
          drop_down_type: dropDownType
        }
        await getDropdownList(params)
        .then((res)=>{
          if((res.status === 200 || res.status === 201)){
            const locationDataList = res?.data?.data?.map((data)=>({
              name: data?.unit_name,
              id : data?.unit_id
            }))
            setUnitData(locationDataList);
          }else{
            toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
          }
        }).catch((error) => {
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
        })
      }
  
        //==============================================GET GRADE GROUP LIST====================================================
    const getGradeGroupList = async(dropDownType) =>{
      setLoader(true);
      let params={
        comp_code: AdminLoginData?.company?.company_code,
        drop_down_type: dropDownType
      }
      await getDropdownList(params)
      .then((res)=>{
        if((res.status === 200 || res.status === 201)){
          const gradeGroupList = res?.data?.data?.map((data)=>({
            name: data?.grade_group_name,
            id : data?.grade_group_id
          }))
          setGradeGroupData(gradeGroupList);
        }else{
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false);
      })
    }

    //==============================================GET FINANCIAL YEAR LIST====================================================
        const getFinancialYear = async() =>{
          setLoader(true);      
          await getFinancialYearList()
          .then((res)=>{
            if((res.status === 200 || res.status === 201)){
              const financialYear = res?.data?.data?.map((data)=>({
                financial_year: data?.financial_year,
                to_year : data?.to_year,
                from_year: data?.from_year
              }))
              setFinancialYearData(financialYear);
            }else{
              toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
            }
          }).catch((error) => {
            toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
          }).finally(()=>{
            setLoader(false);
          })
        }
  
      //==============================================GET GRADE GROUP TYPE LIST====================================================
      const getGradeGroupTypeList = async(dropDownType) =>{
        setLoader(true);
        let params={
          comp_code: AdminLoginData?.company?.company_code,
          drop_down_type: dropDownType
        }
        await getDropdownList(params)
        .then((res)=>{
          if((res.status === 200 || res.status === 201)){
            const gradeGroupTypeList = res?.data?.data?.map((data)=>({
              name: data?.group_type_name,
              id : data?.group_type_id
            }))
            setGradeGroupTypeData(gradeGroupTypeList);
          }else{
            toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
          }
        }).catch((error) => {
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
          
        })
      }

      //==============================================ADD BUDGET DATA====================================================
      const addBudgetData = async()=>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          from_year: selectedFinancialYear?.from_year,
          to_year: selectedFinancialYear?.to_year,
          budget_year: budgetAmountValue,
          grade_group: selectedGradeGroup?.id,
          loan_type: selectedLoanType?.id,
          loan_no: selectedLoanNo?.id,
          unit: selectedUnit?.id,
          group_type: selectedGradeGroupType?.id,
        }
        await addBudget(req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? ADD_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
          
          
        })
      }
      
      //============================================ADD INSTALLMENT DATA============================================
      const addInstallmentData = async()=>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id,
          group_type: selectedGradeGroupType?.id,
          grade_group: selectedGradeGroup?.id,
          amount: loanAmountValue,
          interest_rate: interestRateValue,
          total_monthly_recovery: totalMonthRecoveryValue,
          start_date: fromDate
        }
        await addInstallment(req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? ADD_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
        })
      }



      //=============================================ADD GRADE GROUP TYPE DATA=============================================
      const addGradeGroupTypeData= async()=>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id,
          group_type: selectedGradeGroupType?.id,
          grade_group: selectedGradeGroup?.id,
          start_date: fromDate
        }
        await addGradeGroupType(req_body) 
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? ADD_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
        })
      }



      //==============================================ADD LOAN ALLOWED DATA=================================================
      const addLoanAllowedData = async() =>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id,
          group_type: selectedGradeGroupType?.id,
          start_date: fromDate,
          no_of_loan: noOfLoansAllowedValue
        }
        await addLoanAllowed(req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? ADD_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
        })
      }



      //=============================================ADD NEXT LOAN START DATA=============================================
      const addNextLoanStartData = async()=>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id,
          group_type: selectedGradeGroupType?.id,
          start_date: fromDate,
          loan_no: selectedLoanNo?.id,
          no_of_month: monthInNumbersValue
        }
        await addNextLoan(req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? ADD_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
        })
      }

      //==============================================UPDATE BUDGET DATA====================================================
      const updateBudgetData = async()=>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          from_year: selectedFinancialYear?.from_year ?? bindedData?.from_year,
          to_year: selectedFinancialYear?.to_year ?? bindedData?.to_year,
          budget_year: budgetAmountValue,
          grade_group: selectedGradeGroup?.id ?? bindedData?.grade_group,
          loan_type: selectedLoanType?.id ?? bindedData?.loan_type,
          loan_no: selectedLoanNo?.id ?? bindedData?.loan_no,
          unit: selectedUnit?.id ?? bindedData?.unit,
          group_type: selectedGradeGroupType?.id ?? bindedData?.group_type,
        }
        let budget_id = bindedData?.budget_id;
        await updateBudget(budget_id,req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? UPDATE_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
          
          
        })
      }


      //====================================UPDATE INSTALLMENT DATA======================================
      const updateInstallmentData = async() =>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id ?? bindedData?.loan_type,
          group_type: selectedGradeGroupType?.id ?? bindedData?.group_type,
          grade_group: selectedGradeGroup?.id ?? bindedData?.grade_group,
          amount: loanAmountValue,
          interest_rate: interestRateValue,
          total_monthly_recovery: totalMonthRecoveryValue,
          start_date: fromDate ? fromDate : bindedData?.start_date,
        }
        let installment_id = bindedData?.installment_id;
        await updateInstallment(installment_id,req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? UPDATE_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
        })
      }


      //================================UPDATE GRADE GROUP TYPE DATA===========================
      const updateGradeGroupTypeData = async() =>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id ?? bindedData?.loan_type,
          group_type: selectedGradeGroupType?.id ?? bindedData?.group_type,
          start_date: fromDate ? fromDate : bindedData?.start_date,
        }
        let grade_group_type_id = bindedData?.grade_group_type_id;
        await updateGradeGroupType(grade_group_type_id, req_body) 
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? UPDATE_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false);
        })
      }

      //================================UPDATE LOAN ALLOWED DATA===============================
      const updateLoanAllowedData = async() =>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id ?? bindedData?.loan_type,
          group_type: selectedGradeGroupType?.id ?? bindedData?.group_type,
          start_date: fromDate ? fromDate : bindedData?.start_date,
          no_of_loan: noOfLoansAllowedValue,
        }
        let loan_allowed_id = bindedData?.loan_allowed_id;
        await updateLoanAllowed(loan_allowed_id,req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? UPDATE_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
        })
      }

      //===========================UPDATE NEXT LOAN START DATA=========================================
      const updateNextLoanStartData = async()=>{
        setLoader(true);
        let req_body = {
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanType?.id ?? bindedData?.loan_type,
          group_type: selectedGradeGroupType?.id ?? bindedData?.group_type,
          start_date: fromDate ? fromDate : bindedData?.start_date,
          loan_no: selectedLoanNo?.id ?? bindedData?.loan_no,
          no_of_month: monthInNumbersValue ,
          // month_in_numbers: monthInNumbersValue
        }
        let next_loan_id = bindedData?.next_loan_id;
        await updateNextLoan(next_loan_id,req_body)
        .then((res)=>{
          if ((res?.status === 200 || res?.status === 201)) {
            toaster(SUCCESS,res?.data?.message ?? UPDATE_MESSAGE)
            crossBtn()
            apiRequest(false)
          } else {
            toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
        })
      }
      

  useEffect(() => {
    if (btnValue == ADD) {
      setSelectedLoanType([])
      setSelectedLoanNo([])
      setSelectedUnit([])
      setSelectedGradeGroupType([])
      setSelectedGradeGroup ([])
      setSelectedFinancialYear([])
      setBudgetAmountValue()
      setLoanAmountValue()
      setInterestRateValue()
      setTotalMonthRecoveryValue()
      setNoofLoansAllowedValue()
      setMonthInNumbersValue()
      setFormDate()
    } else {
      setSelectedLoanType({name: bindedData?.loan_type_name , id: bindedData?.loan_type})
      setSelectedLoanNo({name: bindedData?.loan_no_name,id: bindedData?.loan_no})
      setSelectedUnit({name: bindedData?.unit_name,id: bindedData?.unit})
      setSelectedGradeGroupType({name : bindedData?.group_type_name,id: bindedData?.group_type})
      setSelectedGradeGroup ({name: bindedData?.grade_group_name , id: bindedData?.grade_group})
      setSelectedFinancialYear({financial_year: bindedData?.financial_year,from_year: bindedData?.from_year,to_year:bindedData?.to_year})
      setBudgetAmountValue(bindedData?.budget_year)
      setLoanAmountValue(bindedData?.amount)
      setInterestRateValue(bindedData?.interest_rate)
      setTotalMonthRecoveryValue(bindedData?.total_monthly_recovery)
      setNoofLoansAllowedValue(bindedData?.no_of_loan)
      setMonthInNumbersValue(bindedData?.no_of_month)
      if (bindedData?.start_date) {
        setFormDate(YYYYMMDD(bindedData?.start_date))
      }
    }
    getFinancialYear()
    getLoanTypeList(LOAN_TYPE)
    getLoanNoList(LOAN_NO)
    getUnitList(UNIT)
    getGradeGroupList(GRADE_GROUP)
    getGradeGroupTypeList(GRADE_GROUP_TYPE)
    
  }, [btnValue]);


  const handleSubmit = () => {
    if(btnValue == UPDATE){
      if(title == UPDATEBUDGET){
        updateBudgetData()
      }
      if(title == UPDATEINSTALLMENT){
        updateInstallmentData()
      }
      if(title == UPDATEGGTYPE){
        updateGradeGroupTypeData()
      }
      if(title == UPDATELOANALLOWED){
        updateLoanAllowedData()
      }
      if(title == UPDATENEXTLOANSTART){
        updateNextLoanStartData()
      }
    }
    if(btnValue == ADD){
      if(title == ADDBUDGET){
        addBudgetData()
      }
      if(title == ADDINSTALLMENT){
        addInstallmentData()
      }
      if(title == ADDGGTYPE){
        addGradeGroupTypeData()
      }
      if(title == ADDLOANALLOWED){
        addLoanAllowedData()
      }
      if(title == ADDNEXTLOANSTART){
        addNextLoanStartData()
      }
    }
  }

  return (
    <div>
      <LoaderIndicator loading={loader} />
      
    <div className={"modal popup-box "}>
      <div
        className="declaration-box of-hidden p-0 w-60 height-max-min"
      >
        <div className="modal-header justify-content-center mt-2 ml-2">
          <h4 className="modal_title black">{title}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={crossBtn}
          >
            &times;
          </button>
        </div>
        <div
          className="modal-bod  y h-80 scroll-auto p-3"
        >
          <div className="p-3 mx-3">
            <div className="w-100 mb-3">
              <div className="col-12 w-100">
                <div className="d-flex flex-wrap justify-content-between gap-10 ml-2">
                  {loanType && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Loan Type<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <DynamicDropdown
                          data={loanTypeData}
                          render="name"
                          value={selectedLoanType?.name ? selectedLoanType?.name : null}
                          selectedValue={(data) => changeLoanTypeData(data)}
                          arrow={"true"}
                          height={"NotificationHeight"}
                          width={"w-20rem"}
                        />
                      </div>
                    </div>
                  )}
                  {loanNo && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Loan No.<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <DynamicDropdown
                          data={loanNoData}
                          render="name"
                          value={selectedLoanNo?.name ? selectedLoanNo?.name : null}
                          selectedValue={(data) => changeLoanNoData(data)}
                          arrow={"true"}
                          height={"NotificationHeight"}
                          width={"w-20rem"}
                        />
                      </div>
                    </div>
                  )}
                  {unit && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Unit<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <DynamicDropdown
                          data={unitData}
                          render="name"
                          value={selectedUnit?.name ? selectedUnit?.name : null}
                          selectedValue={(data) => changeUnitData(data)}
                          arrow={"true"}
                          height={"NotificationHeight"}
                          width={"w-20rem"}
                        />
                      </div>
                    </div>
                  )}
                  {gradeGroupType && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Grade Group Type<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <DynamicDropdown
                          data={gradeGroupTypeData}
                          render="name"
                          value={selectedGradeGroupType?.name ? selectedGradeGroupType?.name : null}
                          selectedValue={(data) =>
                            changeGradeGroupTypeData(data)
                          }
                          arrow={"true"}
                          height={"NotificationHeight"}
                          width={"w-20rem"}
                        />
                      </div>
                    </div>
                  )}
                  {gradeGroup && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Grade Group<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <DynamicDropdown
                          data={gradeGroupData}
                          render="name"
                          value={selectedGradeGroup?.name ? selectedGradeGroup?.name : null}
                          selectedValue={(data) => changeGradeGroupData(data)}
                          arrow={"true"}
                          height={"NotificationHeight"}
                          width={"w-20rem"}
                        />
                      </div>
                    </div>
                  )}
                  {financialYear && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Financial Year<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <DynamicDropdown
                          data={financialYearData}
                          render="financial_year"
                          value={selectedFinancialYear?.financial_year ? selectedFinancialYear?.financial_year : null}
                          selectedValue={(data) =>
                            changeFinancialYearData(data)
                          }
                          arrow={"true"}
                          height={"NotificationHeight"}
                          width={"w-20rem"}
                        />
                      </div>
                    </div>
                  )}
                  {budgetAmount && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Budget Amount<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="form-control h-43px px-3 common-dropdown-text"
                          type="text"
                          maxLength={10}
                          onChange={(e) => changeBudgetAmount(e.target.value)}
                          placeholder="Enter Loan Amount"
                          value={budgetAmountValue ? budgetAmountValue : null}
                        />
                      </div>
                    </div>
                  )}
                  {loanAmount && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Loan Amount<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="form-control h-43px px-3 common-dropdown-text"
                          type="text"
                          maxLength={10}
                          onChange={(e) => changeLoanAmount(e.target.value)}
                          placeholder="Enter Loan Amount"
                          value={loanAmountValue ? loanAmountValue : null}
                        />
                      </div>
                    </div>
                  )}
                  {intRate && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Interest Rate<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="form-control h-43px px-3 common-dropdown-text"
                          type="text"
                          maxLength={3}
                          onChange={(e) => changeInterestRate(e.target.value)}
                          placeholder="Enter Interest Rate"
                          value={interestRateValue ? interestRateValue : null}
                        />
                      </div>
                    </div>
                  )}
                  {totalMonthRecovery && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Total Month Recovery<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="form-control h-43px px-3 common-dropdown-text"
                          type="text"
                          onChange={(e) =>
                            changeTotalMonthRecovery(e.target.value)
                          }
                          placeholder="Enter Total Month Recovery"
                          value={totalMonthRecoveryValue ? totalMonthRecoveryValue : null}
                        />
                      </div>
                    </div>
                  )}
                  {noOfLoanAllowed && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        No of Loans Allowed<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="form-control h-43px px-3 common-dropdown-text"
                          type="text"
                          maxLength={10}
                          onChange={(e) =>
                            changeNoOfLoansAllowed(e.target.value)
                          }
                          placeholder="Enter No of Loans Allowed"
                          value={noOfLoansAllowedValue ? noOfLoansAllowedValue : null}
                        />
                      </div>
                    </div>
                  )}
                  {monthInNumber && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Month In Numbers<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="form-control h-43px px-3 common-dropdown-text"
                          type="text"
                          maxLength={10}
                          onChange={(e) => changeMonthInNumbers(e.target.value)}
                          placeholder="Enter Month In Numbers"
                          value={monthInNumbersValue ? monthInNumbersValue : null}
                        />
                      </div>
                    </div>
                  )}
                  {startDate && (
                    <div className="w-40">
                      <div className="appcenter-app-name1 ml-10px">
                        Start Date<span className="red">*</span>
                      </div>
                      <div className="p-placeholder-text">
                        <input
                          className="inpt input-date h-43px w-100 w-md-auto"
                          type="date"
                          min={toDay}
                          value={fromDate ? fromDate : null}
                          placeholder="Select Start Date"
                          onChange={(e) => handleFromDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                </div>

              </div>
              <div className="p-sticky-fr ">
                <button
                  type="button"
                  className="btn buttonStyle w-150px h-40px px-20px m-10px"
                  data-dismiss="modal"
                  onClick={handleSubmit}
                >{btnValue}</button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-item-center w-100 mb-3"></div>
      </div>
    </div>
    </div>
  );
};

export default MasterScreenPopUp;
