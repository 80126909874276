import React, { Component } from 'react';
import '../ProfileScreen.css';
import { connect } from 'react-redux';
import edit from '../../../../../assets/drishti_images/profile/edit.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import * as profileActions from '../ProfileAction';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import toggleon from '../../../../../assets/drishti_images/profile/toggle-on.svg';
import toggleoff from '../../../../../assets/drishti_images/profile/toggle-off.svg';
import { validateNumber, currentDateYMD } from '../../../../../utils/Utils';
import DeclarationPopup from '../../profile/DeclarationPopup';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import { maskMobile, maskEmail, maskBank,maskUAN,maskPassport } from '../../../../shared/commonMaskFunction';
import eye from '../../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../../assets/drishti_images/eye-slash.svg';

const data1 = 'Current Address is same as Permanent';
var phoneno = /^\d{10}$/;
const current_add_text = 'Current Residential Address'
const current_add_code = '3'
const perm_add_text = 'Permanent Residence'
const perm_add_code = '1'

class AddressDetails extends Component {
    constructor() {
        super();
        this.state = {
            isAddressEdit: false,
            isCurrentAddress: false,
            isToggle: false,
            isAddressSame: false,
            // permanentAddress: [{ care_of: '', country: '', state: '', city: '', postal_code: '', district: '', start_of_stay_date: '', contact_number: '', address_line_1: '', address_line_2: '' }],
            // currentAddress: [{ care_of: '', country: '', state: '', city: '', postal_code: '', district: '', start_of_stay_date: '', contact_number: '', address_line_1: '', address_line_2: '' }],
            permanentAddress: {
                address_type_code: '',
                address_type: '',
                care_of: '',
                address_line_1: '',
                address_line_2: '',
                country: '',
                country_code: '',
                state: '',
                state_code: '',
                district: '',
                city: '',
                postal_code: '',
                contact_number: '',
                start_of_stay_date: '',
                address_sequence_no: ''
            },
            currentAddress: {
                care_of: '',
                address_type_code: '',
                address_type: '',
                address_line_1: '',
                address_line_2: '',
                country: '',
                country_code: '',
                state: '',
                state_code: '',
                district: '',
                city: '',
                postal_code: '',
                contact_number: '',
                start_of_stay_date: '',
                address_sequence_no: ''
            },
            checked: false,
            empId: '',
            popupVisibility: false,
            countryData: [],
            cityData: [],
            stateData: [],
            stateCurrentData: [],
            districtData: [],
            districtCurrentData: [],
            isCurrentAddNumUnmasked: false,
            isPermanentAddNumUnmasked: false,
        };
    }
    componentDidMount() {
        this.getCountryDropdownData();
        this.getCityDropdownData();

        this.getAddressDetails(this.props.loginData.userData.Perno);
    }



    setEdit = () => {
        this.setState({ isAddressEdit: true })
    }
    cancelDetails = () => {
        this.setState({ isAddressEdit: false },
            () => this.getAddressDetails(this.props.loginData.userData.Perno)
        )
    }
    getCountryDropdownData = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCountryDropdown, {}, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    this.setState({
                        countryData: result.data ? result.data.data : [],
                    });
                    const countryArray = this.state.countryData.map((item, idx) => ({
                        ...item,
                        label: item.country_text
                    }))
                    this.setState({ countryData: countryArray })
                }

            }).catch(err => {
                console.warn('Country dropdown err', err)

            })
    }
    getCityDropdownData = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCityDropdown, {}, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    this.setState({
                        cityData: result.data.data ? result.data.data : [],
                    });
                    const cityArray = this.state.cityData.map((item, idx) => ({
                        ...item,
                        label: item.city_text
                    }))
                    this.setState({ cityData: cityArray })
                }
                else {

                }

            }).catch(err => {
                console.warn('City dropdown err', err)

            })
    }
    getStateDropdownData = (countryCode, isCurrent) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getStateDropdown, { 'country_code': countryCode }, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    isCurrent == false ?
                        this.setState({
                            stateData: result.data.data ? result.data.data : []
                        }) :
                        this.setState({
                            stateCurrentData: result.data.data ? result.data.data : []
                        })

                    // state_text
                    const stateArray = this.state.stateData ? this.state.stateData.map((item, idx) => ({
                        ...item,
                        label: item.state_text
                    })) : []
                    const stateCurrentDataArray = this.state.stateCurrentData ? this.state.stateCurrentData.map((item, idx) => ({
                        ...item,
                        label: item.state_text
                    })) : []
                    this.setState({
                        stateData: stateArray,
                        stateCurrentData: stateCurrentDataArray
                    })
                }
                else {

                }

            }).catch(err => {
                console.warn('State dropdown err', err)
            })
    }
    getDistrictDropdownData = (stateCode, isCurrent) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getDistrictDropdown, { 'state_code': stateCode }, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    isCurrent == false ?
                        this.setState({
                            districtData: result.data.data ? result.data.data : []
                        }) :
                        this.setState({
                            districtCurrentData: result.data.data ? result.data.data : []
                        })
                    // district_text=value on dropdown
                    const districtArray = this.state.districtData ? this.state.districtData.map((item, idx) => ({
                        ...item,
                        label: item.district_text
                    })) : []
                    const districtCurrentArray = this.state.districtCurrentData ? this.state.districtCurrentData.map((item, idx) => ({
                        ...item,
                        label: item.district_text
                    })) : []
                    this.setState({
                        districtData: districtArray,
                        districtCurrentData: districtCurrentArray
                    })
                }
                else {
                    this.setState({
                        loading: false,
                    })

                }

            }).catch(err => {
                console.warn('district dropdown err', err)

            })
    }


    getAddressDetails(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { 'emp_id': employeeId }
        fetchApiCall(Config.getAddressDetails, body, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200 || result.data.status_code == 300) {
                    if (result.data.status_code == 300) {
                        // setTimeout(() => { Toast.show(res.data.message, Toast.SHORT) }, 100)
                    }
                    this.props.addressActions.getCurrentAddressDetails(result.data.current_address);
                    this.props.addressActions.getPermanentAddressDetails(result.data.permanent_address);
                    this.setState({
                        countryText: result.data.permanent_address.country,
                        stateText: result.data.permanent_address.state,
                        districtText: result.data.permanent_address.district,
                        cityText: result.data.permanent_address.city,
                        countryCurrentText: result.data.current_address.country,
                        stateCurrentText: result.data.current_address.state,
                        districtCurrentText: result.data.current_address.district,
                        cityCurrentText: result.data.current_address.city,
                        permanentAddress: {
                            care_of: result.data.permanent_address.care_of,
                            address_type: result.data.permanent_address.address_type,
                            address_type_code: result.data.status_code == 300 ? result.data.permanent_address.address_type : result.data.permanent_address.address_type_code,
                            address_line_1: result.data.permanent_address.address_line_1,
                            address_line_2: result.data.permanent_address.address_line_2,
                            country: result.data.permanent_address.country,
                            country_code: result.data.permanent_address.country_code,
                            state: result.data.permanent_address.state,
                            state_code: result.data.permanent_address.state_code,
                            district: result.data.permanent_address.district,
                            city: result.data.permanent_address.city,
                            postal_code: result.data.permanent_address.postal_code,
                            contact_number: result.data.permanent_address.contact_number,
                            start_of_stay_date: result.data.permanent_address.start_of_stay_date,
                            address_sequence_no: result.data.permanent_address.address_sequence_no
                        },
                        currentAddress: {
                            care_of: result.data.current_address.care_of,
                            address_type: result.data.current_address.address_type,
                            address_type_code: result.data.status_code == 300 ? result.data.current_address.address_type : result.data.current_address.address_type_code,
                            address_line_1: result.data.current_address.address_line_1,
                            address_line_2: result.data.current_address.address_line_2,
                            country: result.data.current_address.country,
                            country_code: result.data.current_address.country_code,
                            state: result.data.current_address.state,
                            state_code: result.data.current_address.state_code,
                            district: result.data.current_address.district,
                            city: result.data.current_address.city,
                            postal_code: result.data.current_address.postal_code,
                            contact_number: result.data.current_address.contact_number,
                            start_of_stay_date: result.data.current_address.start_of_stay_date ? result.data.current_address.start_of_stay_date : '',
                            address_sequence_no: result.data.current_address.address_sequence_no
                        },
                    }, () => { this.setState({ loading: false }) });
                    this.getStateDropdownData(result.data.permanent_address.country_code, false)
                    this.getStateDropdownData(result.data.current_address.country_code, true)
                    this.getDistrictDropdownData(result.data.permanent_address.state_code, false)
                    this.getDistrictDropdownData(result.data.current_address.state_code, true)
                }
                else {
                    this.setState({
                        loading: false
                    });
                }

            }).catch(err => {
                console.log('address get err', err);
                this.setState({
                    loading: false,
                });
            })
    }
    validatePermanentCurrentDate(type) {
        let isValidate = false;
        if (type == 'permanent') {
            if (!this.state.permanentAddress.start_of_stay_date || this.state.permanentAddress.start_of_stay_date.length == 0) {
                return false;
            } else {
                isValidate = true;
            }
        }
        else {
            if (!this.state.currentAddress.start_of_stay_date || this.state.currentAddress.start_of_stay_date.length == 0) {
                return false;
            } else {
                isValidate = true;
            }
        }
        if (isValidate) {
            return true;
        }
    }

    validateData = () => {
        if (!(this.state.permanentAddress.address_line_1) || this.state.permanentAddress.address_line_1 == '') {
            toast.error('Please enter address line 1 in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.permanentAddress.country ) || this.state.permanentAddress.country == '' || this.state.countryText == '') {
            toast.error('Please select country in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.permanentAddress.state) || this.state.permanentAddress.state == '' || this.state.stateText == '') {
            toast.error('Please select state in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.permanentAddress.district) || this.state.permanentAddress.district == '' || this.state.districtText == '') {
            toast.error('Please select district in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.permanentAddress.city) || this.state.permanentAddress.city == '' || this.state.cityText == '') {
            toast.error('Please select city in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.permanentAddress.postal_code) || this.state.permanentAddress.postal_code == '' || this.state.permanentAddress.postal_code < 6) {
            toast.error('Please enter postal code in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.permanentAddress.contact_number) || this.state.permanentAddress.contact_number == '' || (!phoneno.test(this.state.permanentAddress.contact_number) || this.state.permanentAddress.contact_number.length < 10)) {
            toast.error('Please enter valid contact number in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!this.validatePermanentCurrentDate('permanent')) {
            toast.error('Please enter start of stay date in permanent address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })

        } else if (this.state.permanentAddress.start_of_stay_date ? this.state.permanentAddress.start_of_stay_date > currentDateYMD() : '') {
            toast.error('Start of stay date in permanent address cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.address_line_1) || this.state.currentAddress.address_line_1 == '') {
            toast.error('Please enter address line 1 in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.country) || this.state.currentAddress.country == '' || this.state.countryCurrentText == '') {
            toast.error('Please select country in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.state) || this.state.currentAddress.state == '' || this.state.stateCurrentText == '') {
            toast.error('Please select state in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.district) || this.state.currentAddress.district == '' || this.state.districtCurrentText == '') {
            toast.error('Please select district in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.city) || this.state.currentAddress.city == '' || this.state.cityCurrentText == '') {
            toast.error('Please select city in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.postal_code) || this.state.currentAddress.postal_code == '' || this.state.currentAddress.postal_code < 6) {
            alert("");
            toast.error('Please enter postal code in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!(this.state.currentAddress.contact_number) || this.state.currentAddress.contact_number == '' || (!phoneno.test(this.state.currentAddress.contact_number) || this.state.currentAddress.contact_number.length < 10)) {
            toast.error('Please enter valid contact number in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!this.validatePermanentCurrentDate('current')) {
            toast.error('Please enter start of stay date in current address', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.currentAddress.start_of_stay_date ? this.state.currentAddress.start_of_stay_date > currentDateYMD() : '') {
            toast.error('Start of stay date in current address cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.setState({
                popupVisibility: true
            })

        }
    }

    saveAddressDetails = () => {
        this.setState({
            loading: true,
            popupVisibility: false
        });
        const body = {
            data: [{
                emp_id: this.props.loginData.userData.Perno,
                address_type: perm_add_text,
                address_type_code: perm_add_code,
                care_of: this.state.permanentAddress.care_of ? this.state.permanentAddress.care_of : '',
                address_line_1: this.state.permanentAddress.address_line_1 ? this.state.permanentAddress.address_line_1 : '',
                address_line_2: this.state.permanentAddress.address_line_2 ? this.state.permanentAddress.address_line_2 : '',
                start_of_stay_date: this.state.permanentAddress.start_of_stay_date ? this.state.permanentAddress.start_of_stay_date : '',
                common_address: this.state.isToggle,
                country: this.state.permanentAddress.country ? this.state.permanentAddress.country : '',
                state: this.state.permanentAddress.state ? this.state.permanentAddress.state : '',
                country_code: this.state.permanentAddress.country_code ? this.state.permanentAddress.country_code : '',
                state_code: this.state.permanentAddress.state_code ? this.state.permanentAddress.state_code : '',
                district: this.state.permanentAddress.district ? this.state.permanentAddress.district : '',
                city: this.state.permanentAddress.city ? this.state.permanentAddress.city : '',
                postal_code: this.state.permanentAddress.postal_code ? this.state.permanentAddress.postal_code : '',
                contact_number: this.state.permanentAddress.contact_number ? this.state.permanentAddress.contact_number : '',
                address_sequence_no: this.state.permanentAddress.address_sequence_no ? this.state.permanentAddress.address_sequence_no : ''

            },
            {
                emp_id: this.props.loginData.userData.Perno,
                address_type: current_add_text,
                address_type_code: current_add_code,
                care_of: this.state.currentAddress.care_of ? this.state.currentAddress.care_of : '',
                address_line_1: this.state.currentAddress.address_line_1 ? this.state.currentAddress.address_line_1 : '',
                address_line_2: this.state.currentAddress.address_line_2 ? this.state.currentAddress.address_line_2 : '',
                start_of_stay_date: this.state.currentAddress.start_of_stay_date ? this.state.currentAddress.start_of_stay_date : '',
                common_address: this.state.isToggle,
                country: this.state.currentAddress.country ? this.state.currentAddress.country : '',
                state: this.state.currentAddress.state ? this.state.currentAddress.state : '',
                country_code: this.state.currentAddress.country_code ? this.state.currentAddress.country_code : '',
                state_code: this.state.currentAddress.state_code ? this.state.currentAddress.state_code : '',
                district: this.state.currentAddress.district ? this.state.currentAddress.district : '',
                city: this.state.currentAddress.city ? this.state.currentAddress.city : '',
                postal_code: this.state.currentAddress.postal_code ? this.state.currentAddress.postal_code : '',
                contact_number: this.state.currentAddress.contact_number ? this.state.currentAddress.contact_number : '',
                address_sequence_no: this.state.currentAddress.address_sequence_no ? this.state.currentAddress.address_sequence_no : ''
            }
            ],
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updateAddressDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        isAddressEdit: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    this.getAddressDetails(this.props.loginData.userData.Perno);
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    });
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                },
                    toast.error(err, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    }));
            })
    }

    setPermanetDate = (fulldate) => {
        this.setState({
            isToggle: false,
            permanentAddress: {
                ...this.state.permanentAddress,
                start_of_stay_date: moment(fulldate).format("YYYY-MM-DD")
            },
        });
    }

    setCurrentDate = (fulldate) => {
        this.setState({
            currentAddress: {
                ...this.state.currentAddress,
                start_of_stay_date: moment(fulldate).format("YYYY-MM-DD")
            },
        });
    }
    setCity = (data) => {
        this.setState({
            isToggle: false,
            permanentAddress: {
                ...this.state.permanentAddress,
                city: data.city_text ? data.city_text : ''
            }
        })
    }
    setDistrict = (data) => {
        this.setState({
            isToggle: false,
            permanentAddress: {
                ...this.state.permanentAddress,
                district: data.district_text ? data.district_text : ''
            }
        })
    }
    setCurrentDistrict = (data) => {
        this.setState({
            currentAddress: {
                ...this.state.currentAddress,
                district: data.district_text ? data.district_text : ''
            }
        })
    }
    filterStates(countryCode, isCurrent) {
        if (countryCode === '') {
            return [];
        }
        this.getStateDropdownData(countryCode, isCurrent);
        return null;
    }
    setCurrentCity = (data) => {
        this.setState({
            currentAddress: {
                ...this.state.currentAddress,
                city: data.city_text ? data.city_text : ''
            }
        })
    }
    setCountry = (data) => {
        this.setState({
            isToggle: false,
            permanentAddress: {
                ...this.state.permanentAddress,
                country: data.country_text ? data.country_text : '',
                country_code: data.country_code ? data.country_code : ''
            }
        }, () => this.filterStates(data.country_code, false)
        )
    }
    setStateData = (data) => {
        this.setState({
            isToggle: false,
            permanentAddress: {
                ...this.state.permanentAddress,
                state: data.state_text ? data.state_text : '',
                state_code: data.state_code ? data.state_code : '',
            }
        }, () => this.filterDistrict(data.state_code, false))
    }
    setCurrentState = (data) => {
        this.setState({
            currentAddress: {
                ...this.state.currentAddress,
                state: data.state_text ? data.state_text : ' ',
                state_code: data.state_code ? data.state_code : ''
            }
        }, () => this.filterDistrict(data.state_code, true))
    }
    setCurrentCountry = (data) => {
        this.setState({
            currentAddress: {
                ...this.state.currentAddress,
                country: data.country_text ? data.country_text : '',
                country_code: data.country_code ? data.country_code : ''
            }
        }, () => this.filterStates(data.country_code, true)
        )
    }
    filterDistrict(stateCode, isCurrent) {
        if (stateCode === '') {
            return [];
        }

        this.getDistrictDropdownData(stateCode, isCurrent);
        return null;
    }
    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row justify-content-between'>
                    <div className='p-heading mx-4 mt-3 mb-2'>Address Details</div>
                    {!this.state.isAddressEdit ?
                        <div className='mt-2 mr-3'>
                            <img
                                alt="edit"
                                src={edit}
                                className="plus-img"
                                onClick={() => this.setEdit()}
                            ></img>
                        </div> : null
                    }
                </div>
                <div className='p-heading mx-4 mt-3 mb-2'>Permanent Address</div>
                <div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Care of</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='care_of'
                                    value={this.state.permanentAddress.care_of ? this.state.permanentAddress.care_of : ''}
                                    readOnly={!this.state.isAddressEdit}
                                    onChange={(e) => this.setState({
                                        permanentAddress: {
                                            ...this.state.permanentAddress,
                                            care_of: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Address line 1</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='addressLine1'
                                    maxLength={'50'}
                                    value={this.state.permanentAddress.address_line_1 ? this.state.permanentAddress.address_line_1 : ''}
                                    readOnly={!this.state.isAddressEdit}
                                    onChange={(e) => this.setState({
                                        permanentAddress: {
                                            ...this.state.permanentAddress,
                                            address_line_1: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>

                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Address line 2</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='addressLine2'
                                    maxLength={'40'}
                                    value={this.state.permanentAddress.address_line_2 ? this.state.permanentAddress.address_line_2 : ''}
                                    readOnly={!this.state.isAddressEdit}
                                    onChange={(e) => this.setState({
                                        permanentAddress: {
                                            ...this.state.permanentAddress,
                                            address_line_2: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Country</div>
                            <div className='p-placeholder-text'>
                                <LargeDropdown
                                    data={this.state.countryData}
                                    value={this.state.permanentAddress.country ? this.state.permanentAddress.country : ''}
                                    selectedValue={(data) => this.setCountry(data)}
                                    disabled={!this.state.isAddressEdit}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>State</div>
                            <div className='p-placeholder-text'>

                                <LargeDropdown
                                    data={this.state.stateData}
                                    value={this.state.permanentAddress.state ? this.state.permanentAddress.state : ''}
                                    selectedValue={(data) => this.setStateData(data)}
                                    disabled={!this.state.isAddressEdit}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>City</div>
                            <div className='p-placeholder-text'>
                                <LargeDropdown
                                    data={this.state.cityData}
                                    value={this.state.permanentAddress.city ? this.state.permanentAddress.city : ''}
                                    selectedValue={(data) => this.setCity(data)}
                                    disabled={!this.state.isAddressEdit}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>District</div>
                            <LargeDropdown
                                data={this.state.districtData}
                                value={this.state.permanentAddress.district ? this.state.permanentAddress.district : ''}
                                selectedValue={(data) => this.setDistrict(data)}
                                disabled={!this.state.isAddressEdit}
                            />
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Postal Code</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="number"
                                    name='postal_code'
                                    maxLength={'6'}
                                    value={this.state.permanentAddress.postal_code ? this.state.permanentAddress.postal_code : ''}
                                    readOnly={!this.state.isAddressEdit}
                                    onChange={(e) => this.setState({
                                        permanentAddress: {
                                            ...this.state.permanentAddress,
                                            postal_code: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Contact Number</div>
                            <div className='p-placeholder-text p-placeholder contact-field'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    maxLength={'10'}
                                    name='contact_no'
                                    value={this.state.isAddressEdit ? this.state.permanentAddress.contact_number : this.state.isPermanentAddNumUnmasked ? (this.state.permanentAddress.contact_number ?? '-') :  maskMobile(this.state.permanentAddress.contact_number ?? '-') }
                                    readOnly={!this.state.isAddressEdit}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, ''); // Allow only digits
                                        this.setState({
                                            permanentAddress: {
                                                ...this.state.permanentAddress,
                                                contact_number: value ? value : ''
                                            }
                                        })
                                    }}
                                    />
                                    {
                                        !this.state.isAddressEdit &&
                                        <div 
                                            onClick={() => this.setState({ isPermanentAddNumUnmasked : !this.state.isPermanentAddNumUnmasked })} // Toggle between true/false for mask/unmask
                                            className='email-feild'>
                                            <img
                                                alt="eyeimage"
                                                src={ this.state.isPermanentAddNumUnmasked ? eye : eyeslash }
                                            />
                                        </div>
                                    }
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Start of  stay days</div>
                            <input
                                className="form-control p-placeholder px-3"
                                type="date"
                                name='contact_no'
                                value={this.state.permanentAddress.start_of_stay_date ? this.state.permanentAddress.start_of_stay_date : ''}
                                readOnly={!this.state.isAddressEdit}
                                onChange={(e) => this.setPermanetDate(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='p-heading mx-4 mt-3 mb-2'>Current Address</div>
                        <div className='mt-2 mr-3'>
                            {this.state.isAddressEdit ?
                                <>
                                    {this.state.isToggle ?
                                        <img
                                            src={toggleon}
                                            alt=''
                                            onClick={() => this.setState({
                                                isToggle: false,
                                                isAddressSame: false,
                                                currentAddress: this.props.addressData.currentAddressData,
                                                countryCurrentText: this.props.addressData.currentAddressData.country,
                                                stateCurrentText: this.props.addressData.currentAddressData.state,
                                                districtCurrentText: this.props.addressData.currentAddressData.district,
                                                cityCurrentText: this.props.addressData.currentAddressData.city
                                            })}
                                        />
                                        :
                                        <img
                                            src={toggleoff}
                                            alt=''
                                            onClick={() =>
                                                this.setState({
                                                    isToggle: true,
                                                    isAddressSame: true,
                                                    currentAddress: this.state.permanentAddress,
                                                    countryCurrentText: this.state.countryText,
                                                    stateCurrentText: this.state.stateText,
                                                    districtCurrentText: this.state.districtText,
                                                    cityCurrentText: this.state.cityText,
                                                    currentAddress: {
                                                        ...this.state.permanentAddress,
                                                        address_type: current_add_text,
                                                        address_type_code: current_add_code
                                                    }
                                                })
                                            }
                                        />
                                    }

                                </>
                                :
                                <>
                                    {this.state.isToggle ?
                                        <img
                                            src={toggleon}
                                            alt=''
                                        />
                                        :
                                        <img
                                            src={toggleoff}
                                            alt=''
                                        />
                                    }
                                </>
                            }
                        </div>
                        <div className='address-txt mt-3'>Same as permanent address</div>
                    </div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Care of</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='care_of'
                                    value={this.state.currentAddress.care_of ? this.state.currentAddress.care_of : ''}
                                    readOnly={!this.state.isAddressEdit || this.state.isToggle}
                                    onChange={(e) => this.setState({
                                        currentAddress: {
                                            ...this.state.currentAddress,
                                            care_of: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Address line 1</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='address'
                                    maxLength={'50'}
                                    value={this.state.currentAddress.address_line_1 ? this.state.currentAddress.address_line_1 : ''}
                                    readOnly={!this.state.isAddressEdit || this.state.isToggle}
                                    onChange={(e) => this.setState({
                                        currentAddress: {
                                            ...this.state.currentAddress,
                                            address_line_1: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Address line 2</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='address'
                                    maxLength={'40'}
                                    value={this.state.currentAddress.address_line_2 ? this.state.currentAddress.address_line_2 : ''}
                                    readOnly={!this.state.isAddressEdit || this.state.isToggle}
                                    onChange={(e) => this.setState({
                                        currentAddress: {
                                            ...this.state.currentAddress,
                                            address_line_2: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Country</div>
                            <div className='p-placeholder-text'>
                                <LargeDropdown
                                    data={this.state.countryData}
                                    value={this.state.currentAddress.country ? this.state.currentAddress.country : ''}
                                    selectedValue={(data) => this.setCurrentCountry(data)}
                                    disabled={!this.state.isAddressEdit || this.state.isToggle}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>State</div>
                            <div className='p-placeholder-text'>

                                <LargeDropdown
                                    data={this.state.stateCurrentData}
                                    value={this.state.currentAddress.state ? this.state.currentAddress.state : ''}
                                    selectedValue={(data) => this.setCurrentState(data)}
                                    disabled={!this.state.isAddressEdit || this.state.isToggle}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>City</div>
                            <div className='p-placeholder-text'>
                                <LargeDropdown
                                    data={this.state.cityData}
                                    value={this.state.currentAddress.city ? this.state.currentAddress.city : ''}
                                    selectedValue={(data) => this.setCurrentCity(data)}
                                    disabled={!this.state.isAddressEdit || this.state.isToggle}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>District</div>
                            <LargeDropdown
                                data={this.state.districtCurrentData}
                                value={this.state.currentAddress.district ? this.state.currentAddress.district : ''}
                                selectedValue={(data) => this.setCurrentDistrict(data)}
                                disabled={!this.state.isAddressEdit}
                            />
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Postal Code</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='postal_code'
                                    maxLength={'6'}
                                    value={this.state.currentAddress.postal_code ? this.state.currentAddress.postal_code : ''}
                                    readOnly={!this.state.isAddressEdit || this.state.isToggle}
                                    onChange={(e) => this.setState({
                                        currentAddress: {
                                            ...this.state.currentAddress,
                                            postal_code: e.target.value ? e.target.value : ''
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Contact Number</div>
                            <div className='p-placeholder-text p-placeholder contact-field'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    maxLength={'10'}
                                    name='contact_no'
                                    value={this.state.isAddressEdit ? (this.state.currentAddress.contact_number ?? '') : this.state.isCurrentAddNumUnmasked ? (this.state.currentAddress.contact_number ?? '') :  maskMobile(this.state.currentAddress.contact_number ?? '') }
                                    readOnly={!this.state.isAddressEdit || this.state.isToggle}
                                    onChange={(e) => 
                                        {
                                            const value = e.target.value.replace(/\D/g, ''); // Allow only digits
                                            this.setState({
                                                currentAddress: {
                                                    ...this.state.currentAddress,
                                                    contact_number: value ?? ''
                                                }
                                            })
                                        }
                                    }
                                    />

                                    {
                                        !this.state.isAddressEdit &&
                                        <div 
                                            onClick={() => this.setState({ isCurrentAddNumUnmasked : !this.state.isCurrentAddNumUnmasked })} // Toggle between true/false for mask/unmask
                                            className='email-feild'>
                                            <img
                                                alt="eyeimage"
                                                src={ this.state.isCurrentAddNumUnmasked ? eye : eyeslash }
                                            />
                                        </div>
                                    }
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Start of  stay days</div>
                            <input
                                className="form-control p-placeholder px-3"
                                type="date"
                                name='contact_no'
                                value={this.state.currentAddress.start_of_stay_date ? this.state.currentAddress.start_of_stay_date : ''}
                                readOnly={!this.state.isAddressEdit || this.state.isToggle}
                                onChange={(e) => this.setCurrentDate(e.target.value)}
                            />
                        </div>
                    </div>
                    {this.state.isAddressEdit === true ?
                        <div className='row justify-content-end mb-3'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() => this.cancelDetails()}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={() => this.validateData()}
                                >Save</div>
                            </div>
                        </div>
                        :
                        null}
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveAddressDetails}
                    />
                }
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        addressData: state.profileState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addressActions: bindActionCreators(profileActions, dispatch),

    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AddressDetails);