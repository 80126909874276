import React, { Component } from 'react'
import './Announcement.css';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import back from '../../../../../assets/drishti_images/back.svg';
import moment from 'moment';
import { Newemoji } from '../../../../../utils/Utils';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import nodataicon from '../../../../../assets/drishti_images/nodatafound/announcement-nodata.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import NoReaction from '../../../../../assets/drishti_images/announcement/portalEmoji/NoReaction.svg'
// import like from '../../../../../assets/drishti_images/announcement/portalEmoji/like-1.svg';
// import applaud from '../../../../../assets/drishti_images/announcement/portalEmoji/clap-1.svg';
// import love from '../../../../../assets/drishti_images/announcement/portalEmoji/love-1.svg';
// import innovative from '../../../../../assets/drishti_images/announcement/portalEmoji/innovative.svg';
// import thoughtful from '../../../../../assets/drishti_images/announcement/portalEmoji/thoughtfull.svg';
import Reactionview from '../../../employeeScreens/Announcement/annoucementEmployeeReactionView';
import { AnnouncementsSkeleton } from '../../../../shared/skeleton/Skeletons';
// const reaction_only_you = " reaction from you"
// const reaction_you_and_other = " reactions from you and others"
// const reaction_from_other = " reaction from others"
const rectionFormOther = " others"
const reactionFromYouAndOther = " others"
// const preProfile = 'data:image/png;base64,'
class ManagerAnnouncement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectitem: 'All',
            emojiData: Newemoji(),
            page_number: 1,
            announcementData: [],
            showid: '',
            responsedata: [],
            total_count: 0,
            announcement_id: '',
            employee_response: '',
            responseCount: 0,
            giveResponse: false,
            isDataPresent: false,
            screenwidth: 0,
            screenheight: 0,
            whichReaction: '',
            username: '',
            feedbackNew: [],
            passannouncement_id: '',
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.child = React.createRef();
    }

    goToAnnouncement = () => {
        window.location = (BASE_WEB_URL_DEV + 'announcement');
    }

    componentDidMount() {
        this.setState({
            username: this.props.loginData.userData.Firstname
        })

        this.getAnnouncement();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenwidth: window.innerWidth, screenheight: window.innerHeight });
    }

    getAnnouncement = () => {
        this.setState({
            loading: true,
            announcement_type: this.state.selectitem,
            page_number: this.state.page_number,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            announcement_type: this.state.selectitem,
            employee_id: this.props.loginData.userData.Perno,
            page_number: this.state.page_number
        }
        fetchApiCall(Config.get_announcement, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        announcementData: this.state.announcementData.concat(res.data.data),
                        loading: false,
                        isDataPresent: true,
                    })
                } else {
                    this.setState({
                        loading: false,
                        announcementData: [],
                        show: true,
                        isDataPresent: false,
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }
    updateAnnouncement(announcement_id, description) {
        this.setState({
            loading: true,
            announcement_id: announcement_id,
            whichReaction: description,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            announcement_id: announcement_id,
            employee_id: this.props.loginData.userData.Perno,
            employee_name: this.props.loginData.userData.CompName,
            employee_response: description
        }
        console.log("employeeresponse, announcementid:", description, announcement_id)
        console.log("body:", body)
        fetchApiCall(Config.update_announcement_user_responce, body, headers, false, '')
            .then(res => {
                console.log("updateAnnouncement:", res.data);
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        updateannouncement: res.data.data,
                        loading: false,
                    },
                        // this.getAnnouncement
                    );
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }


    handleemoji = (description, notification_id) => {
        const announcmentidData = this.state.announcementData.find(annid => annid.announcement_id === notification_id)
        console.log("announcmentidData:", announcmentidData)
        const feedbackArrayWithSameReaction = announcmentidData.feedback.find(empReaction => empReaction.employee_response === description)
        console.log("feedbackArrayWithSameReaction:", feedbackArrayWithSameReaction)
        const feedbackArrayWithSameUserEmp = announcmentidData.feedback.find(empId => empId.employee_id === this.props.loginData.userData.Perno)
        console.log("feedbackArrayWithSameUserEmp:", feedbackArrayWithSameUserEmp)

        if (announcmentidData.feedback.length === 0) {
            this.setState((prevReactions) => ({
                disable: '1',
                announcement_id: notification_id,
                employee_response: description,
                responseCount: this.state.responseCount + 1,
                whichReaction: description,
                announcementData: prevReactions.announcementData.map((data, key) =>
                    data.announcement_id === notification_id ?
                        {
                            ...data,
                            feedback: [{ employee_response: description, employee_id: this.props.loginData.userData.Perno }],
                            employee_response: description,
                            total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                        }
                        :
                        data)
                // &&
                // data.announcement_id === notification_id ?
                // {
                //     feedback: data.feedback.map(item=> item.employee_response)
                // }
                // :
                // data
            }),
                () => this.updateAnnouncement(notification_id, description)
                // console.log("feedback=====:",this.state.announcementData.feedback)
            )
        }
        else if (feedbackArrayWithSameReaction !== undefined) {
            this.setState((prevReactions) => ({
                disable: '1',
                announcement_id: notification_id,
                employee_response: description,
                responseCount: this.state.responseCount + 1,
                whichReaction: description,
                announcementData: prevReactions.announcementData.map((data, key) =>
                    data.announcement_id === notification_id ?
                        {
                            ...data,
                            feedback: data.feedback.map(el => (
                                el.employee_response === description ?
                                    { ...el, employee_response: description } :
                                    { ...el } &&
                                        el.employee_id === this.props.loginData.userData.Perno ?
                                        { ...el, employee_response: description } :
                                        { ...el }
                            ),
                            ),
                            employee_response: description,
                            total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                        }
                        :
                        data)
            }),
                () => this.updateAnnouncement(notification_id, description)
            )
        }
        else if (feedbackArrayWithSameUserEmp !== undefined) {
            this.setState((prevReactions) => ({
                disable: '1',
                announcement_id: notification_id,
                employee_response: description,
                responseCount: this.state.responseCount + 1,
                whichReaction: description,
                announcementData: prevReactions.announcementData.map((data, key) =>
                    data.announcement_id === notification_id ?
                        {
                            ...data,
                            feedback: data.feedback.map(el => (
                                el.employee_id === this.props.loginData.userData.Perno ?
                                    { ...el, employee_response: description }
                                    :
                                    { ...el }
                            )
                            ),

                            employee_response: description,
                            total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                        }
                        :
                        data)
            }),
                () => this.updateAnnouncement(notification_id, description)
            )
            console.log("ddddd:", this.state.announcementData)

        }
        else {
            this.setState((prevReactions) => ({
                disable: '1',
                announcement_id: notification_id,
                employee_response: description,
                responseCount: this.state.responseCount + 1,
                whichReaction: description,
                announcementData: prevReactions.announcementData.map((data, key) =>
                    data.announcement_id === notification_id ?
                        {
                            ...data,
                            feedback: [...data.feedback, { employee_response: description, employee_id: this.props.loginData.userData.Perno }],
                            employee_response: description,
                            total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                        }
                        :
                        data
                )
            }),
                () => this.updateAnnouncement(notification_id, description)
            )
        }
        // const uniquefeedback = this.state.announcementData.feedback
        // console.log("uniquefeedback:",this.state.announcementData.feedback)
        // this.setState((prevReactions) => ({
        //     announcementData: prevReactions.announcementData.map((data, key) =>
        //     data.announcement_id === notification_id ?
        //     {
        //        ...data,
        //        feedback: [...new Set(data.feedback.map(item => item.employee_response))]
        //     }
        //     :
        //     data
        //     )
        // }))
        // const t =  [...new Set(this.state.announcementData.feedback.map(item=>item.employee_response))]
        // console.log("t====:",t)
    }


    callChildFunctionFromParent = (announcementid) => {
        this.setState({
            passannouncement_id: announcementid
        }, () => this.child.getAnnouncementEmployeeRecord(announcementid, 'All'))
    }

    // handleclick = (id, reaction) => {
    //     const employee_id = this.props.loginData.userData.Perno
    //     this.updateAnnouncement(id, reaction)
    //     const feefTemp = this.state.announcementData.feedback.length != 0 ?
    //      this.state.announcementData.feedback.filter((item, i) =>
    //             item.employee_id === employee_id ? false : true
    //         ) : []
    //     const tempSelfResponse = { employee_response: reaction, employee_id: employee_id }
    //     feefTemp.push(tempSelfResponse)
    //     this.setState({ feedbackData: feefTemp })
    //     console.log('vvv', feefTemp);
    // }
    render() {
        // console.log("announcementData:", this.state.announcementData)
        // console.log("this.state.announcement_id:", this.state.announcement_id)
        // console.log("user name: ", this.state.username)
        const { loading } = this.state
        return (
            <div className="card-new new-height" >
                <LoaderIndicator loading={this.state.loading} />

                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Announcements</label>
                        </div>
                        <div onClick={this.goToAnnouncement}>
                            <img alt="gotoAnnouncement" src={back} className="righticon"></img>
                        </div>
                    </div>
                </div>
                {loading ?
                    <div className='m-4 p-2'>
                        <AnnouncementsSkeleton />
                        <AnnouncementsSkeleton />
                        <AnnouncementsSkeleton />
                    </div> :
                    <div className="main-contain main-contain-child pt-2" >
                        {this.state.announcementData.length != 0 ?
                            <>
                                {
                                    this.state.announcementData.slice(0, 3).map((list, id) =>
                                        <div key={id}>
                                            <ol className="announcement-inside">
                                                <div className="card-inner" >
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-4 col-sm-3 col-4 pl-0">
                                                            <a href={list.announcement_url !== '' ? list.announcement_url : null} target="_blank">
                                                                <img src={list.image_link}  alt='' className="img-thumbnail Announcementimg" />
                                                            </a>
                                                        </div>
                                                        <div className="col-lg-7 col-sm-8 col-8 px-0 announcementcontentcolumn" onMouseLeave={() => this.setState({ disable: '1' })}>
                                                            <div className='col-md-12 px-0'>
                                                                <div className="d-flex justify-content-between mt-2 ">
                                                                    <div className="announcement-title">
                                                                        {list.announcement_title}
                                                                    </div>
                                                                    <div className="announcement-date">
                                                                        {moment(list.created_datetime).format('DD MMM')}
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="content-text pl-0 pr-3 mb-3">
                                                                        {list.announcement_description}
                                                                    </div>
                                                                </div>

                                                                {/*------------------ howered emoji -------------------*/}
                                                                <div className=''>
                                                                    {this.state.showid === list.announcement_id && this.state.disable === '0' &&
                                                                        <div className="d-flex justify-content-around w-75 h-10% hover-emoji align-item-center" onMouseLeave={() => this.setState({ disable: '1' })} >
                                                                            {this.state.emojiData.map((emojilist, id) =>
                                                                                <img
                                                                                    key={id}
                                                                                    src={emojilist.src}
                                                                                    alt=''
                                                                                    className="emoji-outer  "
                                                                                    onClick={() => this.handleemoji(emojilist.description, list.announcement_id)}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    }
                                                                </div>

                                                                {/*------------------ visible emoji -------------------*/}
                                                                <div className="d-flex row align-item-center">
                                                                    <div
                                                                        className='justify-content-start align-item-center outer-emoji'
                                                                        data-bs-toggle="modal"
                                                                        data-target="#openEmployeeReaction"
                                                                        onClick={() => this.callChildFunctionFromParent(list.announcement_id)}
                                                                        onMouseEnter={() => this.setState({ showid: list.announcement_id, disable: '0' })}
                                                                    >
                                                                        {list.total_response === 0 ?
                                                                            <div>
                                                                                <img src={NoReaction}  alt='' />
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {[...new Map(list.feedback.map(item => [item["employee_response"], item])).values()].map((feedB, id) =>
                                                                                    <div key={id} style={{ marginRight: '-5px' }}>
                                                                                        {this.state.emojiData.map((emo, id) =>
                                                                                            <div key={id}>
                                                                                                {emo.description === feedB.employee_response &&
                                                                                                    <>
                                                                                                        <img src={emo.src}  alt='' />
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}

                                                                                {/* { list.feedback.map((feedB, id) =>
                                                                                    <div key={id} style={{ marginRight: '-5px' }}>
                                                                                        {this.state.emojiData.map((emo, id) =>
                                                                                            <div key={id}>
                                                                                                {emo.description === feedB.employee_response &&
                                                                                                    <>
                                                                                                        <img src={emo.src} />
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )} */}

                                                                                <div className=' reaction-text ml-2'>
                                                                                    {
                                                                                        list.total_response === 1 && list.employee_response === '' && this.state.announcement_id === list.announcement_id ?
                                                                                            (this.state.username + " and " + list.total_response + reactionFromYouAndOther)
                                                                                            :
                                                                                            list.total_response === 1 && list.employee_response === '' ?
                                                                                                (list.total_response + rectionFormOther)
                                                                                                :
                                                                                                list.total_response === 1 && list.employee_response != '' &&
                                                                                                (this.state.username)
                                                                                    }
                                                                                    {
                                                                                        list.total_response > 1 && list.employee_response === '' && this.state.announcement_id === list.announcement_id ?
                                                                                            (this.state.username + " and " + list.total_response + reactionFromYouAndOther)
                                                                                            :
                                                                                            list.total_response > 1 && list.employee_response === '' ?
                                                                                                (list.total_response + rectionFormOther)
                                                                                                :

                                                                                                list.total_response > 1 && list.employee_response != '' &&
                                                                                                (this.state.username + " and " + (list.total_response - 1) + reactionFromYouAndOther)

                                                                                    }
                                                                                    {
                                                                                        list.total_response === 0 && list.employee_response === '' && this.state.announcement_id === list.announcement_id &&
                                                                                        (this.state.username)
                                                                                    }
                                                                                </div>

                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ol>
                                        </div>
                                    )
                                }
                                {this.state.announcementData.length > 3 &&
                                    <div style={{ margin: '10px' }}>
                                        <div onClick={this.goToAnnouncement} style={{ cursor: 'pointer' }}
                                            className='row col-12 d-flex justify-content-center card-for-click mx-10'
                                        // style={{ marginTop: '-10px' }} 
                                        >
                                            <label style={{ cursor: 'pointer', margin: '5px', paddingBottom: '5px', color: 'white' }} className='card-content-title ' >
                                                MORE
                                            </label>
                                        </div>
                                    </div>
                                }

                            </>
                            :
                            <div className='row'>
                                <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '80px' }}>
                                    <div>
                                        <img src={nodataicon} alt="Nodata" />

                                    </div>
                                    <div>
                                        <label className='card-content-title grey-color'>No announcements have been shared yet!</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                <Reactionview
                    passAnnouncementId={this.state.passannouncement_id}
                    ref={instance => { this.child = instance; }}
                />
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(ManagerAnnouncement);

