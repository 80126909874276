import React, { Component, PropTypes } from 'react';
import './RegistrationForm.css';
import { BASE_WEB_URL_DEV, headers, single_employee_registration, bulk_employee_registration, update_registered_employee_details } from '../../../../constants/Config';
import Dropdown from '../../../shared/components/Dropdown';
import { Status, ReasonCode, validateNumber } from '../../../../utils/Utils'
import XLSX from 'sheetjs-style';
import * as XLS from "xlsx";
import { consentMessage, templateDownloadTitle, ExcelExportData, maximumRecordMessage, note1, note2 } from '../../../../constants/Messages'
import downloadicon from '../../../../assets/drishti_images/dowload.svg'
import { fetchApiCall, operativeSessionOut } from "../../../../constants/Service";
import { connect } from "react-redux";
import WarnIcon from '../../../../assets/drishti_images/login/WarnIcon.svg';
import { singleRegistration } from './SingleRegistration'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { toast } from 'react-toastify';
// import Papa from "papaparse";
// import { configStore } from "../../../../redux/ConfigStore";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
// import infoicon from '../../../../assets/drishti_images/infoicon.svg'
// import ConsentPopup from './consentPopup'
// import * as FileSaver from 'file-saver';
const BulkRegisterPlaceHolder = "Upload Bulk Data"
// const fileExtension = '.xlsx';
const fileExtension = '.csv';


class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeStatus: Status(),
            selectEmployeeStatus: "Select Status",
            oldEmployeeStatus: "",
            reasonCode: ReasonCode(),
            selectReasonCode: "Select Reason",
            // oldReasonCode :'',
            employeeId: this.props.passUpdateData ? this.props.passUpdateData.Employeeid : '',
            newEmpId: '',
            newPhoneNo: '',
            oldPhoneNo: '',
            showConsent: false,
            checked: false,
            BulkRegistrationFileName: '',
            BulkRegistrationDetails: '',
            loading: false,
            bulkData: [],
            arraylist: null,
            isStatusCodeChanged: false,
            isNumberChanged: false,
            disableButton: false,
            showError: false
        }
        // this.handler = this.handler.bind(this);
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }
    componentDidMount() {
        this.setState({
            newPhoneNo: this.props.passUpdateData ? this.props.passUpdateData.mobile_number : '',
            oldPhoneNo: this.props.passUpdateData ? this.props.passUpdateData.mobile_number : '',
            selectEmployeeStatus: this.props.passUpdateData ? this.props.passUpdateData.is_active == true ? "Active" : "Inactive" : "",
            oldEmployeeStatus: this.props.passUpdateData ? this.props.passUpdateData.is_active == true ? "Active" : "Inactive" : "",
        })
    }

    // updateData(result, file) {
    //     if (file.type === "text/csv") {
    //         const updateData = result.data;
    //         this.setState({ specificEmp: updateData.slice(0, -1) });
    //         console.log("specificEmp", this.state.specificEmp)
    //         const tempArray = [];
    //         this.state.specificEmp.forEach(e => {
    //             console.log("b0 ", e[0])
    //             console.log("b1 ", e[1])
    //             // tempArray.push(e[0], e[1])
    //             tempArray.push({ "emp_id": e[0], "comp_code": e[1] })
    //         });
    //         console.log("tempArray", tempArray)
    //         this.setState({
    //             specificEmp: tempArray
    //         })
    //     }
    //     else {
    //         toast.warn("Please upload CSV file only", {
    //             position: "bottom-center",
    //             hideProgressBar: true,
    //             className: 'toastWarn'
    //         })
    //         this.setState({
    //             specificEmp: ''
    //         })
    //     }
    // }

    exportTOExcel = (fileName) => {
        var ws = XLSX.utils.json_to_sheet(ExcelExportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Bulk Registration");
        XLSX.writeFile(wb, fileName + fileExtension);
    }

    // changeRegistrationType = (type) => {
    //     console.log("type:", type);
    //     if (type === "single") {
    //         console.log("type === single", type === "single")
    //         this.setState({
    //             registrationType: "bulk"
    //         })
    //     }
    //     else {
    //         console.log("type === bulk", type === "bulk")
    //         this.setState({
    //             registrationType: "single"
    //         })
    //     }
    // }

    setStatus = (dataValue) => {
        console.log("dataValue.value === this.state.oldEmployeeStatus ? true : false:", dataValue.value === this.state.oldEmployeeStatus ? true : false);
        this.setState({
            selectEmployeeStatus: dataValue.value,
            isStatusCodeChanged: dataValue.value !== this.state.oldEmployeeStatus ? true : false
        })
    }
    setReasonCode = (dataValue) => {
        this.setState({
            selectReasonCode: dataValue.value,
            disableButton: this.state.isNumberChanged && this.state.selectReasonCode !== "Select Reason" ? false : true,
            showError: false
        })
    }

    callsingleRegistration = () => {
        console.log("registration run")
        this.setState({
            loading: true
        })
        let body = {
            emp_id: this.state.newEmpId,
            mobile_number: this.state.newPhoneNo,
            logged_in_emp_id: this.props.adminLoginData.emp_id,
            comp_code: this.props.adminLoginData.company.company_code,
            consent_text: this.state.checked == true ? 'true' : 'false',
            reason_code: "New Joinee",
            action: "Insert"
        }
        console.log("body:", body);
        fetchApiCall(single_employee_registration, body, headers, false, 'admin')
            .then(res => {
                console.log("callsingleRegistration res:", res);
                if (res.status == 200) {
                    operativeSessionOut(this.props, res, "admin");
                    console.log("1");
                    this.setState({
                        loading: false
                    })
                    this.props.onDismiss()
                    if (res.data.status_code === "200") {
                        console.log("2", res.data.message);
                        this.setState({
                            loading: false,
                        },
                        // () => this.props.callgetEmployeeAPI()
                        )
                        this.props.callgetEmployeeAPI()
                        // this.props.reload()
                        // this.props.callgetEmployeeAPI()
                        setTimeout(() => {
                            toast.success(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastSuccess'
                            })
                        }, 100)
                    }
                    else {
                        this.setState({
                            loading: false,
                        })
                        setTimeout(() => {
                            toast.warn(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastWarn'
                            })
                        }, 100)
                    }
                }
            })
            .catch(err => {
                console.log("error :", err)
                setTimeout(() => {
                    toast.warn("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }, 100)
            })
    }

    bulkRegistration = () => {
        console.log("bulkRegistration run");
        this.setState({
            loading: true
        })

        let body = {
            bulkData: this.state.bulkData,
            logged_in_emp_id: this.props.adminLoginData.emp_id,
            comp_code: this.props.adminLoginData.company.company_code,
            consent_text: 'true',
            reason_code: "New Joinee",
            action: "Upload"
        }
        console.log("bulkRegistration body:", body)
        fetchApiCall(bulk_employee_registration, body, headers, false, 'admin')
            .then(res => {
                console.log("callsingleRegistration res:", res);
                if (res.status == 200) {
                    operativeSessionOut(this.props, res, "admin");
                    this.setState({
                        loading: false
                    })
                    this.props.onDismiss()
                    if (res.data.status_code === "200") {
                        this.setState({
                            loading: false
                        })
                        this.props.callgetEmployeeAPI()
                        setTimeout(() => {
                            toast.warn(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastSuccess'
                            })
                        }, 100)
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                        setTimeout(() => {
                            toast.warn(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastWarn'
                            })
                        }, 100)
                    }
                }
            })
            .catch(err => {
                console.log("error :", err)
                this.setState({
                    loading: false
                })
                setTimeout(() => {
                    toast.warn("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }, 100)
            })

    }

    // reload(){
    //     console.log("this.props.reloading()")
    //     this.props.onReload()
    //     console.log(" 12121 this.props.reloading()")
    // }

    updateRegisteredEmployeeDetails = () => {
        console.log("updateData run", this.state.selectReasonCode);
        if (this.state.isNumberChanged && this.state.selectReasonCode === "Select Reason") {
            console.log("please select reason code..")
            this.setState({
                showError: true
            })
        }
        else {
            this.setState({
                loading: true
            })

            const body = {
                unique_id: this.props.passUpdateData.unique_id,
                emp_id: this.props.passUpdateData.employee_id,
                mobile_number: this.state.newPhoneNo,
                emp_status: this.state.selectEmployeeStatus === "Active" ? true : false,
                logged_in_emp_id: this.props.adminLoginData.emp_id,
                comp_code: this.props.adminLoginData.company.company_code,
                consent_text: this.state.checked == true ? 'true' : 'false',
                reason_code: this.state.selectReasonCode === "Select Reason" ? "" : this.state.selectReasonCode,
                action: "update"
            }
            console.log("updateRegisteredEmployeeDetails body:", body)
            fetchApiCall(update_registered_employee_details, body, headers, false, 'admin')
                .then(res => {
                    console.log("updateRegisteredEmployeeDetails res:", res);
                    if (res.status == 200) {
                        operativeSessionOut(this.props, res, "admin");
                        this.setState({
                            loading: false
                        })
                        this.props.onDismiss()
                        this.props.callgetEmployeeAPI()
                        if (res.data.status_code === "200") {
                            this.setState({
                                loading: false
                            },
                                // () => this.props.callgetEmployeeAPI
                                // <Link to={'/admin/registrationinfo'}></Link>
                            )
                            setTimeout(() => {
                                toast.warn(res.data.message, {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    className: 'toastSuccess'
                                })
                            }, 100)
                            // window.location.reload()
                        }
                        else {
                            this.setState({
                                loading: false
                            },
                                // () => this.props.callgetEmployeeAPI
                            )
                            setTimeout(() => {
                                toast.warn(res.data.message, {
                                    position: "bottom-center",
                                    hideProgressBar: true,
                                    className: 'toastWarn'
                                })
                            }, 100)
                        }
                    }
                })
                .catch(err => {
                    console.log("error in update :", err)
                    setTimeout(() => {
                        toast.warn("Something went wrong", {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastWarn'
                        })
                    }, 100)
                }
                )
        }
    }

    addfile(event) {
        let file = event.target.files[0];
        console.log("file:", file.name)
        this.setState({
            BulkRegistrationFileName: file.name
        })
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            let arrayBuffer = fileReader.result;
            var data = new Uint8Array(arrayBuffer);
            // console.log("add file data:",data)
            var arr = new Array();
            for (var i = 0; i != data.length; ++i)
                arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLS.read(bstr, { type: "binary" });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            // console.log("final XLS:",XLS.utils.sheet_to_json(worksheet, { raw: true }));
            var arraylist = XLS.utils.sheet_to_json(worksheet, { raw: true });
            console.log("arraylist:", arraylist)
            this.setState({
                bulkData: XLS.utils.sheet_to_json(worksheet, { raw: true }),
                arraylist: arraylist.length
            })
        };
    }

    handleEmpChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
                this.setState({newEmpId: e.target.value ? validateNumber(e.target.value) : ""})
            }
    }

    // uploadBulkEmployeeRegistrationExel = (event) => {
    //     this.setState({ BulkRegistrationFileName: event.target.files[0] ? event.target.files[0].name : '' })
    //     if (event.target.files && event.target.files[0]) {
    //         let maxAllowedSize = 1048576 * 2;           // 2MB size
    //         if (event.target.files[0].size <= maxAllowedSize) {
    //             let reader = new FileReader();
    //             reader.readAsDataURL(event.target.files[0]);
    //             reader.onload = (e) => { this.setState({ BulkRegistrationDetails: e.target.result }) }
    //         }
    //         else {
    //             // toast.error('Please upload pdf less than 2MB', {
    //             //     position: "bottom-center",
    //             //     hideProgressBar: true,
    //             //     className: 'toastError'
    //             // })
    //             this.setState({ BulkRegistrationFileName: '', BulkRegistrationDetails: '' })
    //         }
    //     }
    //     else { this.setState({ BulkRegistrationFileName: '', BulkRegistrationDetails: '' }) }
    // }

    // handler =()=> {
    //     this.props.filterUser('filter')
    // }
    setNumberChanged = () => {
        this.setState({
            isNumberChanged: this.state.oldPhoneNo !== this.state.newPhoneNo ? true : false
        })
    }

    render() {
        const { registrationType, onDismiss, passUpdateData } = this.props
        const { employeeId } = this.state
        console.log("disableButton:", this.state.disableButton)
        console.log("isStatusCodeChanged:", this.state.isStatusCodeChanged);
        console.log("selectReasonCode:", this.state.selectReasonCode);
        console.log("isNumberChanged:", this.state.isNumberChanged);
        console.log("newEmpId:", this.state.newEmpId)
        console.log("arraylist:", this.state.arraylist)
        return (
            <>
                <div className="modal popup-box">
                    <LoaderIndicator loading={this.state.loading} />
                    <div className={registrationType === "singleRegistration" ? "registration-body single-registration-width p-0" : registrationType === "bulkRegistration" ? "registration-body bulk-registration-width p-0" : "registration-body update-registration-width p-0"}>
                        <div className="modal-headers" >
                            <h4 className="modal_title font-weight-700 black mb-0px">{registrationType === "singleRegistration" ? "Single Registration" : registrationType === "bulkRegistration" ? "Bulk Registration" : "Update Details"}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                        </div>
                        <div className='hr'>
                            <hr />
                        </div>

                        <div className="modal-body">
                            <div className='firstContainer'>
                                {registrationType === "bulkRegistration" &&
                                    <div className='secondContainer align-item-center-noflex '>
                                        <div className='flex-direction-row shifttoend'>
                                            <div className='m-10px dflex pointer'>
                                                <div onClick={() => this.exportTOExcel('BulkRegistrationTemplate')}>
                                                    <span className="performance-timeline-text-circle ">
                                                        {templateDownloadTitle}
                                                    </span>
                                                    <img src={downloadicon} alt="download" className='p-5px' />
                                                </div>
                                                {/* <a
                                                    type="button"
                                                    className='d-flex justify-content-center w-199px'
                                                    onClick={() => this.exportTOExcel(ExcelExportData, 'BulkRegistrationTemplate')}
                                                >
                                                    <div className=''>Template Download</div>
                                                </a> */}
                                            </div>
                                            {/* <OverlayTrigger placement="right" overlay={this.renderTooltip}>
                                                <img src={infoicon} alt='' style={{ 'marginLeft': '5px' }} />
                                            </OverlayTrigger> */}
                                        </div>
                                    </div>
                                }
                                <div className='m-10px'>
                                    {
                                        registrationType === "singleRegistration" &&
                                        <div className='mb-30px'>
                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className={this.state.newEmpId.length > 8 ? 'toggle-card-heading m-5px w-40 pb-25px' : 'toggle-card-heading m-5px w-40 '}>Employee Id</div>
                                                <div className='w-50'>
                                                    <div className='p-placeholder-text '>
                                                        <input
                                                            className={this.state.newEmpId.length > 8 ? "registration-invalid px-3" : "form-control registration-screen-placeholder px-3"}
                                                            type="text"
                                                            maxLength={8}
                                                            placeholder={"Enter employee id"}
                                                            value={this.state.newEmpId}
                                                            onChange={(e) =>
                                                                this.handleEmpChange(e)
                                                            }
                                                        />
                                                        <div>
                                                            {this.state.newEmpId.length > 8 &&
                                                                <>
                                                                    <img src={WarnIcon} className='m-lr' /><label className='PassWarning'>Invalid employee id</label>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className={this.state.newPhoneNo?.length >= 11 || this.state.newPhoneNo === "0000000000" ? 'toggle-card-heading m-5px w-40 pb-25px' : 'toggle-card-heading m-5px w-40 '}>Phone number</div>
                                                <div className='p-placeholder-text w-50'>
                                                    <input
                                                        className={this.state.newPhoneNo?.length >= 11 || this.state.newPhoneNo === "0000000000" ? "registration-invalid px-3" : "form-control registration-screen-placeholder px-3"}
                                                        type="text"
                                                        maxLength={10}
                                                        placeholder={"Enter phone number"}
                                                        value={this.state.newPhoneNo}
                                                        onChange={(e) => this.setState(
                                                            {
                                                                newPhoneNo: e.target.value ? validateNumber(e.target.value) : ''
                                                            }
                                                        )}
                                                    />
                                                    <div>
                                                        {this.state.newPhoneNo?.length >= 11 || this.state.newPhoneNo === "0000000000" &&
                                                            <>
                                                                <img src={WarnIcon} className='m-lr' /><label className='PassWarning'>Invalide phone number</label>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex-direction-row'>
                                                <div className='p-10px'>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.checked}
                                                        onChange={() => this.setState({ checked: !this.state.checked })}
                                                    />
                                                </div>
                                                <div className='p-10px'>
                                                    <label className='text17_light'>  {consentMessage}</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        registrationType === "bulkRegistration" &&
                                        <div className='mb-30px'>
                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className='toggle-card-heading m-5px w-35'>Upload bulk data</div>
                                                <input
                                                    className={this.state.arraylist > 400 || this.state.arraylist === 0 ? " px-3 form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                                                    type="file"
                                                    name='pan'
                                                    id="files"
                                                    accept=".csv,.xlsx,.xls"
                                                    title={this.state.BulkRegistrationFileName}
                                                    placeholder={BulkRegisterPlaceHolder}
                                                    readOnly={false}
                                                    // onChange={(e) => this.uploadBulkEmployeeRegistrationExel(e)}
                                                    // onChange={(e) => {
                                                    //     const files = e.target.files;
                                                    //     console.log("files : ", files);
                                                    //     if (files) {
                                                    //         console.log("files[0] : ", files[0]);
                                                    //         Papa.parse(files[0], {
                                                    //             complete: this.updateData
                                                    //         })
                                                    //     }
                                                    // }}
                                                    onChange={(event) => {
                                                        this.addfile(event)
                                                    }}
                                                />
                                            </div>
                                            <div className='p-sub-heading justify-content-end mr-15'>{maximumRecordMessage}</div>
                                            <div className='p-10px'>
                                                <div>Note:</div>
                                                <div className='p-sub-heading'>{note1}</div>
                                                <div className='p-sub-heading'>{note2}</div>
                                            </div>
                                        </div>

                                    }
                                    {
                                        registrationType === "updateDetails" &&
                                        <div className='mb-30px'>
                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className='toggle-card-heading m-5px w-40'>Employee Id</div>
                                                <div className='p-placeholder-text w-50'>
                                                    <input
                                                        className="un-editable registration-screen-placeholder px-3"
                                                        type="desable"
                                                        maxLength={6}
                                                        value={passUpdateData.employee_id}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className={this.state.newPhoneNo?.length >= 11 || this.state.newPhoneNo === "0000000000" ? 'toggle-card-heading m-5px w-40 pb-25px' : 'toggle-card-heading m-5px w-40 '}>Phone number</div>
                                                <div className='p-placeholder-text w-50'>
                                                    <input
                                                        className={this.state.newPhoneNo?.length >= 11 || this.state.newPhoneNo === "0000000000" ? "registration-invalid px-3" : "form-control registration-screen-placeholder px-3"}
                                                        type="number"
                                                        maxLength={10}
                                                        // value={this.state.newPhoneNo === "" ? passUpdateData.mobile_number : this.state.newPhoneNo}
                                                        value={this.state.newPhoneNo}
                                                        onChange={(e) => this.setState({ newPhoneNo: e.target.value ? validateNumber(e.target.value) : '' }, () => this.setNumberChanged())}
                                                    />
                                                    <div>
                                                        {this.state.newPhoneNo?.length >= 11 &&
                                                            <>
                                                                <img src={WarnIcon} className='m-lr' /><label className='PassWarning'>Invalide phone number</label>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className='toggle-card-heading m-5px w-40'>Status</div>
                                                <Dropdown
                                                    data={this.state.employeeStatus}
                                                    editable={true}
                                                    selectedValue={(listValue) => this.setStatus(listValue)}
                                                    value={this.state.selectEmployeeStatus}
                                                    render={'value'}
                                                    height={'widthwith'}
                                                    textBoxWidth={'w-50'}
                                                    bg={'whiteBG'}
                                                />
                                            </div>
                                            <div className='secondContainer align-item-center-noflex m-10px'>
                                                <div className='toggle-card-heading m-5px w-40'>Reason Code</div>
                                                <Dropdown
                                                    data={this.state.reasonCode}
                                                    selectedValue={(listValue) => this.state.isNumberChanged && this.setReasonCode(listValue)}
                                                    value={this.state.selectReasonCode}
                                                    render={'value'}
                                                    height={'widthwith'}
                                                    textBoxWidth={'w-50'}
                                                    // bg={this.state.showError ?'invalid' :'whiteBG'}
                                                    bg={this.state.showError ? 'invalid' : 'whiteBG'}
                                                />
                                            </div>
                                            {/* {this.state.showError && */}
                                            {/* {true &&
                                                <div className='row'>
                                                    <div className='toggle-card-heading m-5px w-40'></div>
                                                    <div className='text19'>
                                                        Select Reason code
                                                    </div>
                                                </div>
                                            } */}
                                        </div>
                                    }
                                    <div className='modal-footer btnBox'>
                                        {/* <div className='secondContainer align-item-center-noflex mt-1 buttom-margin justify-content-center'> */}
                                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn m-5px' />
                                        {registrationType === "singleRegistration" &&
                                            <input type='button' onClick={() => (this.state.checked && this.state.newEmpId.length >= 6 && this.state.newPhoneNo?.length == 10 && this.state.newPhoneNo != "0000000000") ? this.callsingleRegistration() : ""}
                                                value={"REGISTER"}
                                                className={(this.state.checked && this.state.newEmpId.length >= 6 && this.state.newPhoneNo?.length == 10 && this.state.newPhoneNo != "0000000000") ? 'validateBtn m-5px' : 'disableBtn m-5px'}
                                                disabled={(this.state.checked && this.state.newEmpId && this.state.newPhoneNo && this.state.newPhoneNo != "0000000000") ? false : true} />
                                        }
                                        {registrationType === "bulkRegistration" &&
                                            <input type='button' onClick={() => this.state.BulkRegistrationFileName === '' || this.state.arraylist > 400 || this.state.arraylist == 0 ? "" : this.bulkRegistration()}
                                                value={"UPLOAD & REGISTER"}
                                                className={this.state.BulkRegistrationFileName === '' || this.state.arraylist > 400 || this.state.arraylist == 0 ? 'disableBtn m-5px' : 'validateBtn m-5px'}
                                                disabled={this.state.arraylist > 400 || this.state.arraylist == 0 || this.state.BulkRegistrationFileName === ''} />
                                        }
                                        {registrationType === "updateDetails" &&
                                            <input type='button'
                                                // onClick={() => {(this.state.newPhoneNo != "" && this.state.newPhoneNo.length == 10 && this.state.selectEmployeeStatus != 'Select Status' ) && this.updateRegisteredEmployeeDetails()}}
                                                onClick={() => { (this.state.newPhoneNo != "" && this.state.newPhoneNo?.length == 10 && this.state.selectEmployeeStatus != 'Select Status' && (this.state.isStatusCodeChanged || this.state.disableButton) && this.state.newPhoneNo != "0000000000") && this.updateRegisteredEmployeeDetails() }}
                                                value={"UPDATE"}
                                                // className={(this.state.newPhoneNo != "" && this.state.newPhoneNo.length == 10 && this.state.selectEmployeeStatus != 'Select Status' && this.state.oldPhoneNo !== this.state.newPhoneNo ) ? 'validateBtn m-5px' : 'disableBtn m-5px'} />
                                                className={(this.state.newPhoneNo != "" && this.state.newPhoneNo?.length == 10 && this.state.selectEmployeeStatus != 'Select Status' && (this.state.isStatusCodeChanged || this.state.disableButton)) && this.state.newPhoneNo != "0000000000" ? 'validateBtn m-5px' : 'disableBtn m-5px'} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
    };
};

export default connect(mapStatesToProps, '')(RegistrationForm)