import React, { useContext, useEffect, useState } from "react";
import ClearanceTable from "./ClearanceTable";
import { reportTable1Col, FAndFSettlementReportCol, agencyWiseReportFullAccessAgencies, monthList, empTypeList, FNFAgency, } from "./constants";
import DataNotFound from "../../../shared/notFound/DataNotFound";
import DateFilter from "./DateFilter";
import { Pagination } from "react-bootstrap";
import Select from 'react-select';
import searchIcon from "../../../../assets/drishti_images/admin/searchIcon.svg";
import { addDays, subtractDays, } from "../../managerScreens/approval/commonUtils";
import {toaster, DDMMMYY } from "../../../../utils/Utils";
import DynamicDropdown from "../../../shared/components/DynamicDropdown";
import {
  getAgencyWiseClearanceReport,
  downloadAgencyWiseReport,
  getFAndFSettlementClearanceReport,
  getAgencyMasterList, getAgencyMasterListV1,
  getClearanceReportMonth
} from "../../../../constants/api.service";
import { commonDownloadFile } from "../../../shared/CommonDownloadFunction";
import exportReport from "../../../../assets/drishti_images/clearance/exportReport.svg";
import moment from "moment/moment";
import { exportTOExcel } from "../localConveyance/constants";
import downloadReport from "../../../../assets/drishti_images/clearance/downloadReport.svg";
import { RoleContext } from "./Contexts";
import useDidMountEffect from "../../../../customHooks/ComponentDidUpdateFunction";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";
import { configStore } from "../../../../redux/ConfigStore";

export default function ClearanceReport(props) {
  const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '185px',
        border: '1px solid rgb(242, 244, 251)',
        borderRadius: '10px'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontFamily: "UniNeue-Regular",
        fontWeight: '800',
        color: '#1E293B',
        fontSize: '0.875rem',
        fontStyle: 'normal',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#653DAF',
        borderRadius: '5px',
        padding: '2px 5px',
        color: 'white'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: 'white',
        ':hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        maxHeight: 'auto',
        overflowY: 'auto',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'white' : provided.color,
        padding: '10px',
        margin: '5px 0',
        cursor: 'pointer',
        ':active': {
            backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: '38px',
        overflowY: 'auto',
        backgroundColor:'rgb(242, 244, 251)',
        borderRadius: '10px 0px 0px 10px',
        boxShadow: 'inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff'
    }),
    indicatorsContainer: (provided)=>({
      ...provided,
      backgroundColor:'transparent', 
    }),
    indicatorSeparator: (provided)=>({
      ...provided,
      display: 'none' 
    }),
    control: (provided)=>({
      ...provided,
      backgroundColor: 'rgb(242, 244, 251)',
      borderColor: 'rgb(204, 204, 204)',
      borderRadius: '10px',
      boxShadow: 'inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff'
    }),
    input: (provided) => ({
      ...provided,
      borderRadius: '10px',
    })

  };
  const filterMaster = useContext(RoleContext)
  const date = new Date()
  const currentYear = date.getFullYear()
  const allOption = { title: 'All' };
  let modifiedReleaseStatusList = []
  if (agencyWiseReportFullAccessAgencies.some(agency => filterMaster?.cleRequestViewFilter?.map((agency) => { return agency.title }).includes(agency))) {
    modifiedReleaseStatusList = [allOption, ...filterMaster.releaseStatusList];
  }
  else {
    modifiedReleaseStatusList = [...filterMaster.releaseStatusList]
  }
  const { screenType } = props;
  const [errorMessage, setErrorMessage] = useState('')
  const [viewFilter, setViewFilter] = useState(filterMaster?.cleRequestViewFilter?.[0]);
  const [filterVal, setFilterVal] = useState(modifiedReleaseStatusList[0]);
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState(`${currentYear}-01-01`);
  const [toDate, setToDate] = useState(`${currentYear}-12-31`);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [empId, setEmpId] = useState("");
  const [selectedOption, setSelectedOption] = useState("agency_consolidated");
  const [selectedAgency, setSelectedAgency] = useState("")
  const [agencyList, setAgencyList] = useState([])
  const [reportMonthData, setReportMonthData] = useState('')
  const [employeeType, setEmployeeType] = useState({ id: 0, title: "White Collar" })
  const [selectedReportMonth, setSelectedReportMonth] = useState('')
  const [selectedReportYear, setSelectedReportYear] = useState('')
  const [currentPACodes,setCurrentPACodes] = useState(null);
  const [paFilter,setPAFilter]= useState(null)
  const [paList, setPAList] = useState([])
  const [agencyName,setAgencyName] = useState([])
  const getLastDayOfCurrentMonth = (year, month) => {
    let nextMonth = Number(month);
    // Get the last day by setting day to 0 of the current month
    return new Date(year, nextMonth, 0).getDate();
  }
  const formatAgencyConfigId = (agencyConfigIds) => {
    if (agencyConfigIds.includes('All')) {
      return 'All';
    } else {
      return agencyConfigIds.join(',');
    }
  };
  const fetchClearanceReport = (page, PA, month = selectedReportMonth?.month_count, year = selectedReportYear) => {
    const totalDays = (new Date(toDate) - new Date(fromDate)) / (1000 * 3600 * 24) + 1
    if (totalDays > 366) {
      setErrorMessage("Date range exceeds the limit of 365 days")
    } else {
      setErrorMessage('')
      let agency_cid = formatAgencyConfigId(selectedAgency.agency_config_id);
      setLoading(true);
      let params = {
        sep_start_date: fromDate,
        sep_end_date: toDate,
        page_number: page,
        status: filterVal?.value ?? "",
        emp_id: empId,
        agency_config_id: agency_cid,
        emp_type: employeeType?.title,
        pa_code: PA
      };
      getAgencyWiseClearanceReport(params, props)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            const { paginated_data, total_pages } = res.data.data;
            setTableData(paginated_data);
            setTotalPages(total_pages);
          } else {
            setTableData([]);
            setTotalPages(0);
            res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "");
          }
        })
        .catch((error) => {
          console.log("error in get clearance request API", error);
          setTableData([]);
          setTotalPages(0);
          error?.status != 404 &&
            toaster("warning", error?.data?.message ? error.data.message : "");
        })
        .finally(() => setLoading(false));
    }
  };

  useDidMountEffect(() => {
    setCurrentPage(1);
    setTotalPages(1);
    if (selectedOption == "released_clearance_application") {
      getFAndFReportData(1);
    } else {
      fetchClearanceReport(1, currentPACodes);
    }
  }, [fromDate, toDate, filterVal, selectedOption, selectedAgency, employeeType]);

  useDidMountEffect(() => {
    setFromDate(`${currentYear}-01-01`);
    setToDate(`${currentYear}-12-31`);
    setEmpId("")
  }, [selectedOption]);

  useEffect(() => {
    // let date = new Date()
    // let currentMonth = date.getMonth()
    // let monthName = monthList[currentMonth]
    // let currentYear = date.getFullYear()
    // setSelectedReportMonth({ 'month': monthName, 'month_count': String(currentMonth + 1).padStart(2, '0') })
    // setSelectedReportYear(currentYear)
    fetchAgencyList()
    // fetchClearanceReportMonths()
    if (filterMaster?.cleRequestViewFilter) {
      let tempPAList = []
      let x = filterMaster?.cleRequestViewFilter?.forEach(agency => tempPAList.push(...agency.pa_list))
      tempPAList = [...new Set(tempPAList)]
      let paListarray = tempPAList.map((pa)=>({value: pa,label:pa}))
      setPAList(paListarray)
      setCurrentPACodes(paListarray.map((pa)=>pa.value).join(','))
    }
  }, [filterMaster])

  const fetchAgencyList = () => {
    setLoading(true)
    let params = {
      comp_code: configStore.getState()?.adminLoginState?.company?.company_code
  }
    setAgencyName(filterMaster?.cleRequestViewFilter?.map((agency)=> {return agency.agency_name}))
    if (agencyWiseReportFullAccessAgencies.some(agency => filterMaster?.cleRequestViewFilter?.map((agency) => { return agency.title }).includes(agency)))
      getAgencyMasterListV1(params, props)
        .then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              let agencyList = res?.data?.data?.map((agency) => {
                return {
                  ...agency, agency_id: agency.id, title: agency.name
                }
              }
              )
              const allAgency = {
                name: "All",
                description: "All Agency List",
                release_order: 1,
                is_active: true,
                agency_config_id: res.data.data.flatMap((agency) => {
                  return agency.agency_config_id
                }),
                agency_id: "",
                title: "All"
              };
              agencyList = [allAgency, ...agencyList];
              setAgencyList(agencyList)
              setSelectedAgency(agencyList[0])
            }
          }
        ).catch((err) => { setAgencyList([]) }).finally(() => setLoading(false))
    else {
      setAgencyList(filterMaster.cleRequestViewFilter)
      setSelectedAgency(filterMaster.cleRequestViewFilter[0])
    }
  }
  const searchFilterFunction = () => {
    setCurrentPage(1);
    setTotalPages(1);
    if (selectedOption === "released_clearance_application") {
      getFAndFReportData(1);
    } else {
      fetchClearanceReport(1, currentPACodes);
    }
  };

  const handleAction = (index) => {
    setLoading(true);
    downloadAgencyWiseReport(props, tableData[index].clearance_id)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          let data = res.data;
          let fileNametest =
            res?.headers["content-disposition"]?.split("filename=")[1];
          let fileName = (fileNametest ? fileNametest : "Clearance_report") + ".pdf";
          let toasterMsg = "Your document has been downloaded.";
          commonDownloadFile(data, toasterMsg, fileName);
        } else {
          setTableData([])
          setTotalPages(0)
          res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
        }
      })
      .catch((error) => {
        console.log("downloadCompPDF catch error:", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const fetchClearanceReportMonths = (page) => {
    setLoading(true);
    getClearanceReportMonth(props)
      .then((res) => {
        console.log("getClearanceReportMonth>>>>>", res)
        const { data } = res.data
        if (res.status === 200 || res.status === 201) {
          setReportMonthData(data)
        } else {
          res.status != 404 && toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  const getFAndFReportData = (page, month = selectedReportMonth?.month_count, year = selectedReportYear) => {
    const totalDays = (new Date(toDate) - new Date(fromDate)) / (1000 * 3600 * 24) + 1
    if (totalDays > 366) {
      setErrorMessage("Date range exceeds the limit of 365 days")
    } else {
      setErrorMessage('')
      setLoading(true);
      let params = {
        sep_start_date: fromDate,
        sep_end_date: toDate,
        page_number: page,
        emp_id: empId,
        emp_type: employeeType?.title
      };
      getFAndFSettlementClearanceReport(params, props)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            const { paginated_data, total_pages } = res.data.data;
            setTableData(paginated_data);
            setTotalPages(total_pages);
          } else {
            setTableData([]);
            setTotalPages(0);
            res?.status != 404 &&
              toaster("warning", res.data.message ? res.data.message : "");
          }
        })
        .catch((error) => {
          console.log("error in get clearance request API", error);
          setTableData([]);
          setTotalPages(0);
          error?.status != 404 &&
            toaster("warning", error?.data?.message ? error.data.message : "");
        })
        .finally(() => setLoading(false));
    }
  };

  const exportCsvReport = () => {
    let agency_cid = formatAgencyConfigId(selectedAgency.agency_config_id);
    setLoading(true);
    let currentDate = moment().format("DD-MM-YYYY");
    let currentTime = moment().format("HH:mm");
    let fileName = "Clearance_" + currentDate + "_" + currentTime;
    let { month_count } = selectedReportMonth
    let params = {
      sep_start_date: fromDate,
      sep_end_date: toDate,
      status: filterVal?.value ?? "",
      emp_id: empId,
      agency_config_id: agency_cid,
      emp_type: employeeType?.title,
      pa_code: currentPACodes
    };
    getAgencyWiseClearanceReport(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.data?.paginated_data?.length == 0) {
            let exportClearanceData = [
              {
                "Employee ID": "-",
                "Employee Name": "-",
                "Company": "-",
                "Organization Unit 1": "-",
                "Employee Group": "-",
                "Level": "-",
                "Person Area": "-",
                "Person SubArea": "-",
                "Location": "-",
                "Actual Resignation Date": "-",
                "Last Working Date": "-",
                "Separation Reason": "-",
                "Reason For Action": "-",
                "Clearance Initiation Date": "-",
                "Clearance Agency": "-",
                "Clearance Status": "-",
                "Clearance Completion Date": "-",
                "Clearance Remarks": "-",
                "Additional Comment 1": "-",
                "Additional Comment 2": "-",
                "Additional Comment 3": "-",
                "Revoked Date": "-",
                ...((selectedAgency.agency_id === 1 || selectedAgency.agency_id === "") && agencyName.some(agency => agency == FNFAgency)  && {
                  "Payable Amount": "-",
                  "Recovery Amount": "-"
                })
              },
            ];
            exportTOExcel(fileName, exportClearanceData, ".csv");
          } else {
            let exportClearanceData = res?.data?.data?.paginated_data?.map(
              (data, idx) => {
                const formatDate = (date) => date ? DDMMMYY(date) : "-";
                return {
                  "Employee ID": data?.["emp_id"] ?? "-",
                  "Employee Name": data?.["emp_name"] ?? "-",
                  "Company": data?.["emp_comp_name"] ?? "-",
                  "Organization Unit 1": data?.["emp_department"] ?? "-",
                  "Employee Group": data?.["egroup"] ?? "-",
                  "Level": data?.["esubgroup"] ?? "-",
                  "Person Area": data?.["persarea"] ?? "-",
                  "Person SubArea": data?.["psubarea"] ?? "-",
                  "Location": data?.["emp_location"] ?? "-",
                  "Actual Resignation Date": formatDate(data?.["resignation_date"]) ?? "-",
                  "Last Working Date": formatDate(data?.["separation_date"]) ?? "-",
                  "Separation Reason": data?.["action_text"] ?? "-",
                  "Reason For Action": data?.["reason_text"] ?? "-",
                  "Clearance Initiation Date": formatDate(data?.["initiated_on"]) ?? "-",
                  "Clearance Agency": data?.["clearance_agency"] ?? "-",
                  "Clearance Status": data?.["clearance_status"] ?? "-",
                  "Clearance Completion Date": formatDate(data?.["released_on"]) ?? "-",
                  "Clearance Remarks": data?.["remark"] ?? "-",
                  "Additional Remark 1": data?.['additional_comments']["additional_comment_1"] ?? "-",
                  "Additional Remark 2": data?.['additional_comments']["additional_comment_2"] ?? "-",
                  "Additional Remark 3": data?.['additional_comments']["additional_comment_3"] ?? "-",
                  "Revoked Date": formatDate(data?.["revoked_date"]) ?? "-",
                  ...((selectedAgency.agency_id === 1 || selectedAgency.agency_id === "") && agencyName.some(agency => agency == FNFAgency) && {
                    "Payable Amount": data?.["payable_amt"] ?? "-",
                    "Recovery Amount": data?.["recovery_amt"] ?? "-"
                  })
                };
              }
            );
            exportTOExcel(fileName, exportClearanceData, ".csv");
          }

          setLoading(false);
        } else {
          res?.status != 404 &&
            toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  const exportFAndFSettlementReport = () => {
    setLoading(true);
    let currentDate = moment().format("DD-MM-YYYY");
    let currentTime = moment().format("HH:mm");
    let fileName = "F&F_Settlement_Clearance_" + currentDate + "_" + currentTime;
    let { month_count } = selectedReportMonth
    let params = {
      sep_start_date: fromDate,
      sep_end_date: toDate,
      emp_type: employeeType?.title
    };
    getFAndFSettlementClearanceReport(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.data?.paginated_data?.length == 0) {
            const exportClearanceData = [
              {
                "Per No": "-",
                "Name": "-",
                "Grade": "-",
                "Location": "-",
                "Separation Date": "-",
                "Separation Reason": "-",
                "Final Clearance Completed by Agency": "-",
                "Last Clearance Date": "-",
                "F&F Agency Clearance Date": "-",
                'F&F Remarks': "-",
                "Additional Remarks 1": "-",
                "Additional Remarks 2": "-",
                "Additional Remarks 3": "-",
                "Payable Amount": "-",
                "Recovery Amount": "-"
              },
            ];
            exportTOExcel(fileName, exportClearanceData, ".csv");
          } else {
            const exportClearanceData = res?.data?.data?.paginated_data?.map(
              (data, idx) => {
                return {
                  "Per No": data?.["emp_id"] ?? "-",
                  "Name": data?.["emp_name"] ?? "-",
                  "Grade": data?.["emp_grade"] ?? "-",
                  "Location": data?.["emp_location"] ?? "-",
                  "Separation Date": DDMMMYY(data?.["separation_date"]) ?? "-",
                  "Separation Reason": data?.["sep_reason"] ?? "-",
                  "Final Clearance Completed by Agency": data?.['final_clearance_completed_by_agency'] ?? "-",
                  "Last Clearance Date": DDMMMYY(data?.['last_clearance_date']) ?? "-",
                  "F&F Agency Clearance Date": DDMMMYY(data?.['ff_agency_clearance_date']) ?? "-",
                  'F&F Remarks': data?.['remark'] ?? "-",
                  "Additional Remarks 1": data?.['additional_comments']["additional_comment_1"] ?? "-",
                  "Additional Remarks 2": data?.['additional_comments']["additional_comment_2"] ?? "-",
                  "Additional Remarks 3": data?.['additional_comments']["additional_comment_3"] ?? "-",
                  "Payable Amount": data?.["payable_amt"] ?? "-",
                  "Recovery Amount": data?.["recovery_amt"] ?? "-"
                };
              }
            );
            exportTOExcel(fileName, exportClearanceData, ".csv");
          }

          setLoading(false);
        } else {
          res?.status != 404 &&
            toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  // const handleViewFilter = (data) => {
  //   setViewFilter(data);
  // };

  const handleEmpType = (data) => {
    setEmployeeType(data)
  };
  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      if (selectedOption === "released_clearance_application") {
        getFAndFReportData(currentPage - 1);
      } else {
        fetchClearanceReport(currentPage - 1, currentPACodes);
      }
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      if (selectedOption === "released_clearance_application") {
        getFAndFReportData(currentPage + 1);
      } else {
        fetchClearanceReport(currentPage + 1, currentPACodes);
      }
    }
  };

  const handleOptionChange = (event) => { setSelectedOption(event.target.value); setEmployeeType({ id: 0, title: "White Collar" }) };

  const handleReleaseStatusSelection = (data) => {
    if (data.title === 'ALL') {
      setFilterVal({ title: 'ALL' });
    } else {
      setFilterVal(data);
    }
  };
  // const handleMonthAction = (value) => {
  //   setSelectedReportMonth(value);
  //   if (selectedOption == "released_clearance_application") {
  //     getFAndFReportData(currentPage,value.month_count);
  //   } else {
  //     fetchClearanceReport(currentPage,value.month_count);
  //   }
  // }
  // const handleYearAction = (value) => {
  //   setSelectedReportYear(value);
  //   if (selectedOption == "released_clearance_application") {
  //     getFAndFReportData(currentPage,selectedReportMonth?.month_count,value);
  //   } else {
  //     fetchClearanceReport(currentPage,selectedReportMonth?.month_count,value);
  //   }
  // }
  const handlePAFilter =(selectedOptions) => {
    setPAFilter(selectedOptions);
    const selectedValues = selectedOptions.map(option => option.value).join(',');
    if (selectedValues == ""){
      console.log(paList.map((pa)=>pa.value).join(','))
      setCurrentPACodes(paList.map((pa)=>pa.value).join(','))
    }else{
      setCurrentPACodes(selectedValues);
    }
    setCurrentPage(1);
    setTotalPages(1);
    if (selectedOption == "released_clearance_application") {
      getFAndFReportData(1);
    } else {
      fetchClearanceReport(1, selectedValues == "" ? paList.map((pa)=>pa.value).join(',') : selectedValues);
    }
  };

  const handleEmpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setEmpId(e.target.value);
    }
  }

  return (
    <div className="scroll-auto w-100 h-71vh">
      <LoaderIndicator loading={loading} />
      <div className="flex-direction-column background-prop gap-10px">
        <div className="row align-item-center ml-2 pt-10px">
          <span className="bold-font mr-10px">Report Type: </span>

          <input
            type="radio"
            id="agency_consolidated"
            value="agency_consolidated"
            checked={selectedOption === "agency_consolidated"}
            onChange={handleOptionChange}
            className="purple-radio mr-10px"
          />
          <label className="td-choice-pay mr-15px" htmlFor="agency_consolidated">
            Agency Wise Report
          </label>
          {
            filterMaster.adminTabList["Report"].includes("Released Clearance Application") &&
            <>
              <input
                type="radio"
                id="released_clearance_application"
                value="released_clearance_application"
                checked={selectedOption === "released_clearance_application"}
                onChange={handleOptionChange}
                className="purple-radio mr-10px"
              />
              <label className="td-choice-pay mr-10px" htmlFor="released_clearance_application">
                Completed Clearance
              </label>
            </>
          }

        </div>
        <div className='row gap-10px'>
          {/* {
              reportMonthData?.report_months && */}
          <div className="flex-direction-row gap-10px align-item-center mt-10_2px">
            <>
              <DateFilter
                title="From Date"
                action={(val) => setFromDate(val)}
                value={fromDate}
              />
            </>
          </div>
          {/* {
              reportMonthData?.report_years && */}
          <div className="flex-direction-row gap-10px align-item-center mt-10_2px">
            <>
              <DateFilter
                title="To Date"
                action={(val) => setToDate(val)}
                value={toDate}
                minDate={subtractDays(fromDate, 0)}
                maxDate={addDays(fromDate, 365)}
              />
            </>
          </div>
          {/* } */}
          <div className="flex-direction-row gap-10px align-item-center mt-10_2px flex-wrap">
            {selectedOption === "agency_consolidated" && filterMaster?.releaseStatusList &&
              <>
                <div className="p-sub-heading mb-1">{"Clearance Status"}</div>
                <DynamicDropdown
                  data={(selectedAgency?.title == 'F & F Settlement' || selectedAgency?.title == 'All') ? modifiedReleaseStatusList : modifiedReleaseStatusList.filter(item => item?.title != 'Hold')}
                  render="title"
                  value={filterVal?.title}
                  selectedValue={handleReleaseStatusSelection}
                  arrow={"true"}
                  height={"NotificationHeight"}
                />
              </>}
            {
              selectedOption === "agency_consolidated" && agencyList &&
              <>
                <div className="p-sub-heading mb-1">{"Select Agency"}</div>
                <DynamicDropdown
                  data={agencyList}
                  render="title"
                  value={selectedAgency?.title}
                  selectedValue={(data) => setSelectedAgency(data)}
                  arrow={"true"}
                  height={"NotificationHeight"}
                />
              </>
            }
            <>
              <div className="p-sub-heading mb-1 ">{"Employee Type"}</div>
              <DynamicDropdown
                data={empTypeList}
                render="title"
                value={employeeType?.title ? employeeType?.title : "Select Type"}
                selectedValue={handleEmpType}
                arrow={"true"}
                height={"NotificationHeight"}
              />
            </>
            {
              selectedOption === "agency_consolidated" && paList != '' &&
              <>
                <div className="p-sub-heading mb-1 ">{"Select PA"}</div>
                <Select
                  isMulti
                  options={paList}
                  value={paFilter}                            
                  onChange={handlePAFilter}
                  className="basic-multi-select"
                  placeholder="Select"
                  styles={customStyles}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  classNamePrefix="cle"
                />
              </>
            }
          </div>
        </div>
        {errorMessage && <span className="element-style p-sub-heading">{errorMessage}</span>}
        <div className="row align-item-center justify-content-between ml-2 filter-top-border">
          <div className="row p-r">
            <div className="row mt-2 ml-4">
              <div className="p-placeholder-text mb-1 mr-3">
                <input
                  className="form-control p-placeholder px-2 p-placeholder-admin"
                  type="text"
                  maxLength={8}
                  placeholder="Enter Employee ID"
                  onChange={(e) => handleEmpChange(e)}
                  value={empId}
                />
              </div>
              <div
                type="button"
                className="serchIconSize right-top"
                onClick={() => searchFilterFunction()}
              >
                <div className="mr-4">
                  <img
                    alt="radioOn"
                    className="filterIcon"
                    src={searchIcon}
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {
            tableData.length > 0 &&
            <img
              className="button-hover-effect"
              src={exportReport}
              onClick={
                selectedOption === "agency_consolidated"
                  ? exportCsvReport
                  : exportFAndFSettlementReport
              }
            ></img>
          }
        </div>
      </div>

      {selectedOption === "agency_consolidated" && (
        <>
          {tableData && tableData.length > 0 ? (
            <div className="flex-direction-column scroll-auto mt-10px">
              <ClearanceTable
              data={tableData}
              columns={reportTable1Col}
              hasAction={
                (
                  agencyName.some(agency => agency == FNFAgency) 
                  && (selectedAgency.agency_id === 1 || selectedAgency.agency_id === "" )
                  ? true : false
                )}
              screenType={screenType}
              handleAction={(index) => handleAction(index)}
              primaryActionImg={downloadReport}
              currentPage={currentPage}
              pageSize={10}
            />
            </div>          
          ) : (
            <div className="h-60-percent justify-content-center align-item-center">
              <DataNotFound />
            </div>
          )}
        </>
      )}
      {/* table 2 */}
      {selectedOption === "released_clearance_application" && (
        <>
          {tableData && tableData.length > 0 ? (
            <div className="flex-direction-column scroll-auto mt-10px">
              <ClearanceTable
                data={tableData}
                columns={FAndFSettlementReportCol}
                hasAction={true}
                screenType={screenType}
                handleAction={(index) => handleAction(index)}
                primaryActionImg={downloadReport}
                currentPage={currentPage}
                pageSize={10}
              />
            </div>
          ) : (
            <div className="h-60-percent justify-content-center align-item-center">
              <DataNotFound />
            </div>
          )}
        </>
      )}
      {tableData.length > 0 && (
        <div className="w-100 justify-content-center">
          <Pagination>
            {currentPage != 1 && (
              <>
                <Pagination.Prev
                  onClick={() => {
                    handlePageCount("decrement");
                  }}
                />
                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>
                  {1}
                </Pagination.Item>
                <Pagination.Ellipsis disabled />
              </>
            )}
            <Pagination.Item active>{currentPage}</Pagination.Item>
            {currentPage != totalPages && (
              <>
                <Pagination.Ellipsis disabled />
                <Pagination.Item
                  disabled
                  onClick={() => setCurrentPage(totalPages)}
                >
                  {totalPages}
                </Pagination.Item>
                <Pagination.Next
                  onClick={() => {
                    handlePageCount("increment");
                  }}
                />
              </>
            )}
          </Pagination>
        </div>
      )}
    </div>
  );
}
