import React, { Component } from 'react';
import close from '../../../../../assets/images/close.png';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { yesNo_type } from '../../../../../utils/Utils';
import { toast } from 'react-toastify';
import { RiArrowDropDownLine } from 'react-icons/ri';

const errorMessage = 'Input is missing !'
const chooseItem = 'Choose an item'
const errorFutureDateMessage = 'Future date not allowed'
const agreeMessage = 'Above information given by me is true and correct and I have discussed with my Manager / HR / Ethics regarding this Conflict of Interest.'
class RequestFormCardA3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            agree: false,
            validate: true,
            name: '',
            location: '',
            association_type: '',
            error_type_of_association: '',
            associated_since: '',
            error_associated_since: '',
            is_interacting: chooseItem,
            is_interacted_data: yesNo_type(),
            is_benefitted: chooseItem,
            is_benefit_data: yesNo_type(),
            disclosed_data: yesNo_type(),
            is_disclosed: chooseItem,
            disclosure_description: '',
            relative_full_name: '',
            error_name_of_value_chain: '',
            error_location_of_value_chain: '',
            error_relationship: '',
            error_is_benefit: '',
            error_disclosed: '',
            error_disclosed_to: '',
            relationship: chooseItem,
        }
    }

    changeRelationship = (e) => {
        this.setState({ relationship: e.value })
        this.onsubmitChange(e.value, 'relationship')
        this.setState({
            relationship: e.relationship,
        })
    }

    changeIsInteracted = (e) => {
        this.setState({ is_interacting: e.value })
        this.onsubmitChange(e.code, 'is_interacting')
    }

    changeIsBenefit = (e) => {
        this.setState({ is_benefitted: e.value })
        this.onsubmitChange(e.code, 'is_benefitted')
    }

    changeDisclosed = (e) => {
        this.setState({ is_disclosed: e.value })
        this.onsubmitChange(e.code, 'is_disclosed')
    }

    removeCOIRequest = () => {
        this.onsubmitChange('', 'remove_data')
        this.props.removeCOIData()
    }

    onsubmitChange(text, type) {
        let _type = type.toString()
        let newArray = this.props.COIRequest;
        for (var i = 0; i <= this.props.length; i++) {
            if (i === this.props.index) {
                newArray[this.props.index] = {
                    ...newArray[this.props.index],
                    [_type]: text
                }
            }
        }
        if (type === 'remove_data') {
            newArray.splice(-1)
        }
        this.props.saveCOIA3Request(newArray)
    }

    handleChange = (type, value) => {
        let typeState = type.toString()
        this.setState({ [typeState]: value })
        this.onsubmitChange(value, typeState)
        this.onsubmitChange(this.props.loginData.userData.Perno, 'emp_id')
    }

    agreeCheck = () => {
        this.setState({ agree: !this.state.agree })
    }

    validate = () => {
        if (this.state.name === '' || !this.state.name) {
            this.setState({ error_name_of_value_chain: errorMessage })
        }
        else if (this.state.location === '' || !this.state.location) {
            this.setState({ error_location_of_value_chain: errorMessage })
        }
        else if (this.state.association_type === '') {
            this.setState({ error_type_of_association: errorMessage })
        }
        else if (this.state.associated_since.length > 0 && new Date(this.state.associated_since).getTime() > new Date().getTime()) {
            this.setState({ error_associated_since: errorFutureDateMessage })
        }
        else if (this.state.is_interacting === '' || this.state.is_interacting === chooseItem) {
            this.setState({ error_is_interacted: errorMessage })
        }
        else if (this.state.is_benefitted === '' || this.state.is_benefitted === chooseItem) {
            this.setState({ error_is_benefit: errorMessage })
        }
        else if (this.state.is_disclosed === '' || this.state.is_disclosed === chooseItem) {
            this.setState({ error_disclosed: errorMessage })
        }
        else if (this.state.is_disclosed === 'Yes' && this.state.disclosure_description === '') {
            this.setState({ error_disclosed_to: errorMessage })
        }
        else if (this.state.relationship === '' || this.state.relationship === chooseItem) {
            this.setState({ error_relationship: errorMessage })
        }
        else if (this.state.agree === false) {
            toast.warn('Please check on above declaration', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.setState({ validate: false }, () => this.props.isValidate(this.state.validate))
            if (!this.state.associated_since) {
                this.onsubmitChange(null, 'associated_since')
            }
            if (this.state.relative_full_name === '') {
                this.onsubmitChange('', 'relative_full_name')
            }
        }
    }

    render() {
        const { validate } = this.state
        const { length, index, isVisible, listType, isedit, relationshipDataArray } = this.props;
        return (
            <div className='w-100' style={index !== length - 1 || !isedit ? { pointerEvents: "none", opacity: "0.8", borderRadius: '3%', display: 'grid' } : { display: 'grid' }}>
                <LoaderIndicator loading={this.state.loading} />
                <div className=''>
                    {isVisible ?
                        <div className="row justify-content-end">
                            {index === length - 1 ?
                                <div
                                    className='justify-content-end'>
                                    <img
                                        alt="close"
                                        className="closeIcon"
                                        src={close}
                                        onClick={() => this.removeCOIRequest()}
                                    ></img>
                                </div>
                                : null}
                        </div>
                        : null}
                    <div className='form-heading-label font-weight-bold ml-4 mt-2'>
                        Option {this.props.type}
                    </div>
                    <div className='form-heading-label font-weight-normal mb-3 ml-4'>
                        {this.props.typeDescripton}</div>
                    <div className='mb-4 ml-2'>
                        <div className='forms-number-text mleft10 mb-1'>{this.props.type}</div>
                        <div className='type-bottom-line ml-3'></div>
                        <div className='solid-border mx-3'></div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Complete name of the value-chain partner/customer/competitor</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                type="text"
                                name='name'
                                value={!isedit && listType ? listType.name : this.state.name}
                                onChange={(e) => this.handleChange('name', e.target.value)}
                            />
                            {this.state.error_name_of_value_chain !== '' && this.state.name === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_name_of_value_chain}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Location of the value-chain partner/customer/competitor</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                value={!isedit && listType ? listType.location : this.state.location}
                                type="text"
                                name="location"
                                onChange={(e) => this.handleChange('location', e.target.value)}
                            />
                            {this.state.error_location_of_value_chain !== '' && this.state.location === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_location_of_value_chain}</div>}
                        </div>
                    </div>

                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Type of association/interest</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                value={!isedit && listType ? listType.association_type : this.state.association_type}
                                type="text"
                                name="association_type"
                                onChange={(e) => this.handleChange('association_type', e.target.value)}
                            />
                            {this.state.error_type_of_association !== '' && this.state.association_type === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_type_of_association}</div>}
                        </div>
                    </div>

                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Associated since</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                value={!isedit && listType ? listType.associated_since : this.state.associated_since}
                                type="text"
                                name="associated_since"
                                onChange={(e) => this.handleChange('associated_since', e.target.value)}
                            />
                            {((this.state.error_associated_since !== '' && this.state.associated_since.length == 0) || (new Date(this.state.associated_since).getTime() > new Date().getTime())) && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_associated_since}</div>}
                        </div>
                    </div>

                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Have you been interacting with the value-chain partner/customer/competitor?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.is_interacting}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {this.state.is_interacted_data.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeIsInteracted(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_is_interacted !== '' && this.state.is_interacting === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_is_interacted}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Have you received any financial or non-financial benefit due to this relationship?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.is_benefitted}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {this.state.is_benefit_data.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeIsBenefit(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_is_benefit !== '' && this.state.is_benefitted === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_is_benefit}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Was this disclosed to anyone in TML?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.is_disclosed}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {this.state.disclosed_data.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeDisclosed(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_disclosed !== '' && this.state.is_disclosed === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_disclosed}</div>}
                        </div>
                    </div>
                    {this.state.is_disclosed === 'Yes' &&
                        <div className="mb-3 mx-4" >
                            <div className="form-label-text ml-3 mb-2">If Yes to whom and when?</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="input-background px-3"
                                    type="text"
                                    name='disclosure_description'
                                    value={!isedit && listType ? listType.disclosure_description : this.state.disclosure_description}
                                    onChange={(e) => this.handleChange('disclosure_description', e.target.value)}
                                />
                                {this.state.error_disclosed_to !== '' && this.state.disclosure_description === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_disclosed_to}</div>}
                            </div>
                        </div>
                    }
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text">Full name of related person</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                value={!isedit && listType ? listType.relative_full_name : this.state.relative_full_name}
                                type="text"
                                name="relative_full_name"
                                onChange={(e) => this.handleChange('relative_full_name', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text">Relationship</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.relationship}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {relationshipDataArray.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeRelationship(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_relationship !== '' && this.state.relationship === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relationship}</div>}
                        </div>
                    </div>
                    <div className='mb-3 mx-4'>
                        <div className='form-agree-txt d-flex align-items-start'>
                            <input
                                type="checkbox"
                                className='mx-2 mt-1'
                                checked={this.state.agree}
                                onChange={() => this.agreeCheck()}
                            />
                            {agreeMessage}
                        </div>
                    </div>
                    <div className="justify-content-end align-item-center mt-3 mb-0">
                        {validate &&
                            <div className='justify-content-between mr-4'>
                                <div
                                    type='button'
                                    className='popup-back-btn popup-back-text'
                                    onClick={() => this.props.onDismiss()}
                                >Back</div>
                                <div
                                    type='button'
                                    className='popup-confirm-btn'
                                    onClick={() => this.validate()}
                                >Validate</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestFormCardA3;