import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { Month } from '../../../../utils/Utils';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import LeaveDetailsPopup from './LeaveDetailsPopup';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';

toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
const deleteText = 'Are you sure want to delete this leave request?';
class EmployeeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            screenwidth: 0,
            screenheight: 0,
            isSearch: false,
            emp_id: '',
            admin: true,
            empID: '',
            page: 1,
            count: [],
            incidentId: '',
            deleteLeaveData: '',
            leaveRequestDetails: [],
            managerData: [],
            empData: [],
            delete: true,
            isDelete: false,
            show: false,
            showModal: false,
            leaveMonthCode: moment(new Date()).format("MM"),
            leaveMonthValue: moment(new Date()).format("MMMM"),
            leaveRequestsMonth: Month(),
            leaveData: [],
            adminType: props.adminLoginData ? props.adminLoginData.adminData.user_group : [],
            adminRole: props.adminLoginData ? props.adminLoginData.adminData.user_role : [],
            adminRole1: props.adminLoginData ? props.adminLoginData.adminData.user_role[0] : '',
            fromDate: '',
            toDate: '',
            leaveRequestId: '',
            requestorEmail: '',
            requestorEmpID: ''
        }
    }

    getLeaveListingData = (empId, monthCode) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: empId,
            month: monthCode
        }
        fetchApiCall(Config.getAdminLeaveRequest, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    this.setState({
                        leaveRequestDetails: res.data.user_leave_request ? res.data.user_leave_request : [],
                        show: true,
                        loading: false
                    },
                        () => this.EmployeeData(this.state.empID));
                } else {
                    this.setState({
                        loading: false,
                        leaveRequestDetails: [],
                        show: false
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    show: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                });
            })
    }

    EmployeeData = (employeeId) => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId
        }
        fetchApiCall(Config.getLatestuserDetailsFromEdpCet, body, headers, true, '')
            .then(res => {
                // console.log('getLatestuserDetailsFromEdpCet', res);
                if (res.data.status_code == 200) {
                    if (res.data.user_details[0]) {
                        this.setState({
                            empData: res.data.user_details ? res.data.user_details : [],
                            managerData: res.data.manager_details ? res.data.manager_details : []
                        });
                    }
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    // delete api commented 

    // deleteLeaveRequestAdmin = (leaveList) => {
    //     this.setState({
    //         loading: true,
    //     });
    //     const req = {
    //         emp_id: this.state.emp_id,
    //         from_date: this.state.fromDate,
    //         to_date: this.state.toDate,
    //         leave_request_id: this.state.leaveRequestId,
    //     }
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     fetchApiCall(Config.deleteLeaveRequestAdmin, req, headers, false, 'admin')
    //         .then(res => {
    //             sessionOut(this.props, res, this.state.admin);
    //             if (res.data.status_code == 200) {
    //                 toast.success(res.data.message, {
    //                     position: "bottom-center",
    //                     hideProgressBar: true,
    //                     className: 'toastSuccess'
    //                 },
    //                     () => this.getLeaveListingData(this.state.empID, this.state.leaveMonthCode)
    //                 );
    //                 this.setState({
    //                     isDelete: false,
    //                     loading: false
    //                 });
    //                 // this.getLeaveListingData(this.state.empID, this.state.leaveMonthCode)
    //             } else {
    //                 toast.error(res.data.message, {
    //                     position: "bottom-center",
    //                     hideProgressBar: true,
    //                     className: 'toastError'
    //                 });
    //                 this.setState({
    //                     loading: false
    //                 });
    //             }
    //         }).catch(err => {
    //             toast.error(requestFailed, {
    //                 position: "bottom-center",
    //                 hideProgressBar: true,
    //                 className: 'toastError'
    //             });
    //             this.setState({ loading: false });
    //         })
    // }

    deleteLeaveByITSD = () => {
        this.setState({
            loading: true,
        });
        const req = {
            leave_request_id: this.state.leaveRequestId,
            leave_deleted_by: this.props.adminLoginData.adminData.user_email,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            // emp_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            emp_id: this.state.empID ? this.state.empID : '',
            incident_id: this.state.incidentId,
            group_name: this.props.adminLoginData.adminData.user_group[0],
            admin_role: this.props.adminLoginData.adminRoles[0]
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.leaveRequestDeleteByItsdAndTimeOffice, req, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    this.setState({
                        loading: false,
                        isDelete: false
                    });
                    this.getLeaveListingData(this.state.empID, this.state.leaveMonthCode)
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                });
                this.setState({ loading: false });
            })
    }

    handleChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
                this.setState({
                    empID: e.target.value
                })            
        }
    }

    leaveDeleteConfirmation = (leaveData) => {
        this.setState({
            deleteLeaveData: leaveData
        });
    }

    filterLeaveByMonth = (e, listValue) => {
        this.setState({
            leaveMonthCode: listValue.code,
            leaveMonthValue: listValue.value
        },
            // () => this.loadData(this.state.empID, this.state.leaveMonthCode)
        )
    }

    loadData = (empID, leaveMonthCode) => {
        if (empID == '') {
            toast.warn('Please enter employee ID', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (empID.length > 8) {
            toast.error('Please Enter valid employee ID', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else {
            this.getLeaveListingData(empID, leaveMonthCode)
            // this.EmployeeData(empID)
            this.setState({
                isSearch: true
            })
        }
    }

    leaveData = (data) => {
        this.setState({ showModal: !this.state.showModal, leaveData: data })
    }

    deleteLeaveData = (data) => {
        this.setState({
            isDelete: !this.state.isDelete,
            fromDate: data.from_date,
            toDate: data.to_date,
            leaveRequestId: data.leave_request_id,
            incidentId: ''
        })
    }

    deactivePopup = () => {
        this.setState({
            showModal: false,
            isDelete: false
        })
    }

    render() {
        const ITSD_Group = this.props.adminLoginData.adminRoles.includes('ITSD')
        return (
            <div className="justify-content-between">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0" >
                    <div className='row align-item-center'>
                        <div className='mt-3 ml-4'>
                            <div className='p-sub-heading ml-3 mb-1'>Employee ID</div>
                            <div className='p-placeholder-text widthMonth'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    placeholder="Enter Employee ID"
                                    type="text"
                                    maxLength={8}
                                    onChange={(e) => this.handleChange(e)}
                                    value={this.state.empID}
                                />
                            </div>
                        </div>
                        <div className='mt-3 mx-3'>
                            <div className='p-sub-heading ml-3 mb-1'>Month</div>
                            <div className='dropdown widthMonth'>
                                <button
                                    className='btn p-13px p-placeholder d-flex justify-content-between'
                                    type='button' id="dropdownMenuButton"
                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {this.state.leaveMonthValue}
                                    <RiArrowDropDownLine
                                        size={25}
                                        color={'rgb(185 188 197)'}
                                    />
                                </button>
                                <div className='dropdown-menu adminPickerScroller w-100' aria-labelledby="dropdownMenuButton">
                                    {this.state.leaveRequestsMonth.map((listValue, index) => {
                                        return (
                                            <label
                                                key={index}
                                                onClick={(e) => this.filterLeaveByMonth(e, listValue)}
                                                className='dropdown-item'>{listValue.value}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            type='button'
                            className='save-btn save-txt mt-38px'
                            onClick={() => this.loadData(this.state.empID, this.state.leaveMonthCode)}
                        >SEARCH
                        </div>
                    </div>
                    {this.state.isSearch ?
                        <div className=" pt-2 pushNotification-container" >
                            {this.state.leaveRequestDetails.length != 0 ?
                                <table className="table">
                                    <thead className=''>
                                        <tr>
                                            <th scope="col" style={{ 'width': '3%' }}><div className='thead pr-0'>Sr. No</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Employee ID</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Leave Category</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Leave Status</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Leave Type</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>From Date</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>To Date</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Approver ID</div></th>
                                            <th scope="col" style={{ 'width': '2%' }}><div className='thead' style={{ 'marginLeft': '15%' }}>Action</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.leaveRequestDetails.map((listValue, index) => {
                                            return (
                                                <tr key={index} className=''>
                                                    <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                    <td><div className="td" >{listValue.requestor_emp_id ? listValue.requestor_emp_id : ''}</div></td>
                                                    <td><div className="td">{listValue.leave_category ? listValue.leave_category : ''}</div></td>
                                                    <td><div className="td">{listValue.leave_status ? listValue.leave_status : ''}</div></td>
                                                    <td><div className="td">{listValue.leave_type ? listValue.leave_type : ''}</div></td>
                                                    <td><div className="td" >{moment(listValue.from_date).format("DD-MM-YYYY")}</div></td>
                                                    <td><div className="td" >{moment(listValue.to_date).format("DD-MM-YYYY")}</div></td>
                                                    <td><div className="td">{listValue.approver_emp_id ? listValue.approver_emp_id : ''}</div></td>

                                                    {listValue.leave_status === 'Approved and updated' || listValue.leave_status === 'Approved & posted' ?
                                                        <td className='d-flex'>
                                                            <div
                                                                onClick={() => this.leaveData(listValue, 'view')}
                                                            >
                                                                <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                            </div>
                                                            {this.props.adminLoginData.adminRoles.includes('itsd-lead') || this.props.adminLoginData.adminRoles.includes('time-office-lead') ?
                                                                <div
                                                                    onClick={() => this.deleteLeaveData(listValue)}
                                                                >
                                                                    <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                                </div> : null
                                                            }
                                                        </td>
                                                        :
                                                        <td className='d-flex'>
                                                            <div
                                                                onClick={() => this.leaveData(listValue, 'view')}
                                                            >
                                                                <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                            </div>
                                                        </td>
                                                    }

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                :
                                <div className='row'>
                                    {!this.state.loading &&
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={noDataTransfer} alt="noDataTransfer" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>No leave record found!</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div> : null
                    }
                    {this.state.isDelete &&
                        <div className="modal popup-box">
                            <div className="body-box p-0">
                                <div className="modal-headers mt-2" >
                                    <h4 className="modal_title black">Delete Confirmation</h4>
                                    <button type="button" className="close" onClick={() => this.setState({ isDelete: false })}>&times;</button>
                                </div>
                                <div className='hr'>
                                    <hr />
                                </div>
                                {this.props.adminLoginData.adminRoles.includes('itsd-lead') ?
                                    <div className="modal-body">
                                        <div className='flex-direction-column justify-content-center p-3'>
                                            <div className='align-self-center'>
                                                <img
                                                    alt="delete_popup"
                                                    src={delete_popup}
                                                    className="mb-2"
                                                ></img>
                                            </div>
                                            <form className='p-3'>
                                                <div className='p-sub-heading ml-3'>Please enter the Incident ID to delete the leave request</div>
                                                <div className='p-placeholder-text mb-2 mt-1'>
                                                    <input
                                                        className="form-control p-placeholder p-placeholder-admin px-2"
                                                        type="text"
                                                        onChange={(e) => this.setState({ incidentId: e.target.value, delete: false })}
                                                        value={this.state.incidentId}
                                                        placeholder={"Enter Incident ID"}
                                                        maxLength={14}
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                        <div className='modal-footer btnBox justify-content-end'>
                                            <div className='justify-content-around'>
                                                <div className='justify-content-start'>
                                                    <input type='button'
                                                        value='CANCEL'
                                                        className='popup-back-btn popup-back-text'
                                                        onClick={() => this.setState({ isDelete: false })}
                                                    />
                                                </div>
                                                <div className='justify-content-end'>
                                                    <input type='button'
                                                        value='DELETE'
                                                        className='popup-confirm-btn'
                                                        disabled={this.state.delete}
                                                        onClick={() => this.deleteLeaveByITSD(this.state.deleteLeaveData, this.state.incidentId)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                                {this.props.adminLoginData.adminRoles.includes('time-office-lead') ?
                                    <div className="modal-body">
                                        <div className='flex-direction-column justify-content-center p-3'>
                                            <div className='align-self-center'>
                                                <img
                                                    alt="delete_popup"
                                                    src={delete_popup}
                                                    className="mb-2"
                                                ></img>
                                            </div>
                                            <div className="popup_text text-center p-3 m-3">
                                                {deleteText}
                                            </div>
                                        </div>
                                        <div className='modal-footer btnBox justify-content-end'>
                                            <div className='justify-content-around'>
                                                <div className='justify-content-start'>
                                                    <input type='button'
                                                        value='CANCEL'
                                                        className='popup-back-btn popup-back-text'
                                                        onClick={() => this.setState({ isDelete: false })}
                                                    />
                                                </div>
                                                <div className='justify-content-end'>
                                                    <input type='button'
                                                        value='DELETE'
                                                        className='popup-confirm-btn'
                                                        onClick={() => this.deleteLeaveByITSD(this.state.deleteLeaveData)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                            </div>
                        </div>
                    }
                    {this.state.showModal &&
                        <LeaveDetailsPopup
                            action={'view'}
                            EmpData={this.state.empData}
                            ManagerData={this.state.managerData}
                            leaveData={this.state.leaveData}
                            onDismiss={this.deactivePopup}
                        />
                    }
                </div>
            </div >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(EmployeeScreen));