import React from "react";
import { deleteBudget, deleteInstallment, deleteLoanAllowed, deleteGradeGroupType, deleteNextLoan } from "../../../../../constants/api.service";
import { toaster } from "../../../../../utils/Utils";
import {ERROR_MESSAGE,WARNING,SUCCESS, DELETE_MESSAGE, DELETEBUDGET, DELETEINSTALLMENT, DELETELOANALLOWED, DELETEGGTYPE, DELETENEXTLOANSTART, NO, YES} from '../constants'

function ConfirmationPopUpLoan({
  title,
  crossBtn,
  note,
  image,
  bindedData,
  apiRequest
}) 


{
  //==============================================DELETE BUDGET DATA====================================================
  const deleteBudgetData = async()=>{
    let budget_id = bindedData?.budget_id;
    await deleteBudget(budget_id)
    .then((res)=>{
      if ((res?.status === 200 || res?.status === 201)) {
        toaster(SUCCESS,res?.data?.message ?? DELETE_MESSAGE)
        crossBtn()
        apiRequest(false)
      } else {
        toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
      }
    }).catch((error)=>{
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    })
  }
  
  
  //=========================================DELETE INSTALLMENT DATA=========================================
  const deleteInstallmentData = async() => {
    let installment_id = bindedData?.installment_id
    await deleteInstallment(installment_id)
    .then((res)=>{
      if ((res?.status === 200 || res?.status === 201)) {
        toaster(SUCCESS,res?.data?.message ?? DELETE_MESSAGE)
        crossBtn()
        apiRequest(false)
      } else {
        toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
      }
    }).catch((error)=>{
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    })
  }

  //========================================DELETE GRADE GROUP TYPE DATA====================================
  const deleteGradeGroupTypeData = async() =>{
    let grade_group_type_id = bindedData?.grade_group_type_id
    await deleteGradeGroupType(grade_group_type_id)
    .then((res)=>{
      if ((res?.status === 200 || res?.status === 201)) {
        toaster(SUCCESS,res?.data?.message ?? DELETE_MESSAGE)
        crossBtn()
        apiRequest(false)
      } else {
        toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
      }
    }).catch((error)=>{
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    })
  }


  //=========================================DELETE LOAN ALLOWED=============================================
  const deleteLoanAllowedData = async()=>{
    let loan_allowed_id = bindedData?.loan_allowed_id
    await deleteLoanAllowed(loan_allowed_id)
    .then((res)=>{
      if ((res?.status === 200 || res?.status === 201)) {
        toaster(SUCCESS,res?.data?.message ?? DELETE_MESSAGE)
        crossBtn()
        apiRequest(false)
      } else {
        toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
      }
    }).catch((error)=>{
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    })
  }



  //=========================================DELETE NEXT LOAN START=============================================
  const deleteNextLoanStartData = async()=>{
    let next_loan_id = bindedData?.next_loan_id
    await deleteNextLoan(next_loan_id)
    .then((res)=>{
      if ((res?.status === 200 || res?.status === 201)) {
        toaster(SUCCESS,res?.data?.message ?? DELETE_MESSAGE)
        crossBtn()
        apiRequest(false)
      } else {
        toaster(WARNING,res?.data?.message ?? ERROR_MESSAGE)
      }
    }).catch((error)=>{
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    })
  }

  const handleConfirm =()=>{
    if (title == DELETEBUDGET){
      deleteBudgetData()
    }
    if (title == DELETEINSTALLMENT){
      deleteInstallmentData()
    }
    if (title == DELETELOANALLOWED){
      deleteLoanAllowedData()
    }
    if (title == DELETEGGTYPE){
      deleteGradeGroupTypeData()
    }
    if (title == DELETENEXTLOANSTART){
      deleteNextLoanStartData()
    }
  }

  return (
    <div className="modal popup-box">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25rem">
        <div className="modal-content bg-white border_radius_12px">
          <div className="modal-header mx-2">
            <div className="row">
              <h4 className="popup-title mt-2">{title}</h4>
            </div>
            <button
              type="button"
              className="close pt-4"
              data-dismiss="modal"
              onClick={crossBtn}
            >
              &times;
            </button>
            <div className="solid-border"></div>
          </div>
          <div className="center-a-div mt-15px">
          <div className='align-self-center'><img alt="delete_popup" src={image} className="mb-2"></img></div>
            <pre className="flex-direction-column justify-content-center unineue-font td-choice-pay confirmation-message">
              {note}
            </pre>
          </div>
          <div className="modal-footer btnBox1">
          <input
              type="button"
              onClick={crossBtn}
              value={NO}
              className="cancelBtn"
            />
            <input
              type="button"
              onClick={handleConfirm}
              value={YES}
              className="validateBtn"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopUpLoan;
