import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import '../../../../assets/styles/popupStyles.css';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';

// const Declaration = 'The above information given by me is true & correct. If the same is found to be incorrect, it would be treated as TCoC violation.';
const Declaration = 'I attest that the information given is accurate and truthful. Any false or misleading information may be considered a violation of the TCoC.'
const confirmation = 'Are you sure you want to delete ?'

class DeclarationPopup extends Component {
    onClickApplyButton = () => {
        this.props.onDismiss();
    }
    render() {
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    <div className="modal-header">
                        {this.props.delete ?
                            <h4 className="popup-title">Delete Confirmation</h4>
                            : <h4 className="popup-title">Self Declaration</h4>
                        }
                        <button
                            type="button"
                            className="close pt-4"
                            data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;
                        </button>
                        <div className='solid-border'></div>
                    </div>
                    <div className="modal-body">
                        {this.props.delete ?
                            <div className='popup-text mx-4 mt-4 mb-4'>
                                <div className='d-flex justify-content-center'>
                                    <img
                                        alt="delete_popup"
                                        src={delete_popup}
                                        className="mb-3"
                                    ></img>
                                </div>
                                <div style={{ 'textAlign': 'center' }}>
                                    {confirmation}
                                </div>
                            </div> :
                            <div className='popup-text mx-4 mt-4 mb-4'>
                                {Declaration}
                            </div>
                        }
                    </div>
                    {this.props.delete ?
                        <div className="d-flex justify-content-end px-0 mt-3">
                            <div className='d-flex justify-content-end'>
                                <div
                                    type='button'
                                    className='declaration-no-btn'
                                    data-dismiss="modal"
                                    onClick={this.props.onDismiss}
                                >No</div>
                                <div
                                    type='button'
                                    className='declaration-yes-btn'
                                    onClick={() => this.props.deleteDetails()}
                                >Yes</div>
                            </div>
                        </div>
                        :
                        <div className='row justify-content-end'>
                            <button
                                type="submit"
                                className="declaration-yes-btn declaration-border-0px"
                                style={{ width: '40%' }}
                                onClick={() => this.props.saveDetails()}
                            >Agree
                            </button>
                        </div>
                    }
                </div>
            </div>

        );
    }
}

export default DeclarationPopup;