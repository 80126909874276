import React, { useEffect, useState } from 'react'
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { ADD, ADDBUDGET, ADDGGTYPE, ADDINSTALLMENT, ADDLOANALLOWED, ADDNEXTLOANSTART, budgetColumnFields, DECREMENT, DELETEBUDGET, DELETECONFIRM, DELETEGGTYPE, DELETEINSTALLMENT, DELETELOANALLOWED, DELETEMSGCONFIRM, DELETENEXTLOANSTART, ERROR_MESSAGE, GRADE_GROUP, GRADE_GROUP_TYPE, gradeGroupColumnFields, INCREMENT, installmentColumnFields, LOAN_MASTER, LOAN_NO, LOAN_TYPE, loanAllowedColumnFields, MASTER01, MASTER02, MASTER03, MASTER04, MASTER05, nextLoanStartColumnFields, NO_DATA_FOUND, SUCCESS, SUCCESS_MESSAGE, UNIT, UPDATE, UPDATEBUDGET, UPDATEGGTYPE, UPDATEINSTALLMENT, UPDATELOANALLOWED, UPDATENEXTLOANSTART, WARNING } from '../constants.js'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import LoanTable from '../commonComponent/LoanTable.js';
import MasterScreenPopUp from '../commonComponent/MasterScreenPopUp.js';
import ConfirmationPopUpLoan from '../commonComponent/ConfirmationPopUpLoan.js';
import delete_popup from '../../../../../assets/images/cfm/delete_popup.svg';
import { getBudgetData, getDropdownList, getFinancialYearList, getInstallmentData, getLoanAllowedData, getGradeGroupType, getNextLoan } from '../../../../../constants/api.service.js';
import { DDMMYYYY, toaster } from '../../../../../utils/Utils.js';
import { useSelector } from 'react-redux';
import PaginationTable from '../commonComponent/PaginationTable.js';

function LoanMasterScreen() {
  const [masterType, setMasterType] = useState([]);
  const [budgetPaginatedList, setBudgetPaginatedList] = useState([]);
  const [installmentPaginatedList, setInstallmentPaginatedList] = useState([]);
  const [gradeGroupTypePaginatedList, setGradeGroupTypePaginatedList] = useState([]);
  const [loanAllowedPaginatedList, setLoanAllowedPaginatedList] = useState([]);
  const [nextLoanStartPaginatedList, setNextLoanStartPaginatedList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedMasterType, setSelectedMasterType] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [addBtn,setAddBtn] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null);
  const [deletePopUpVisible,setDeletePopUpVisible] = useState(false);
  const AdminLoginData = useSelector((state) => state.adminLoginState);
  const [loanData,setLoanData] = useState([])
  const [loanDataNo,setLoanDataNo] = useState([])
  const [locationDataList,setLocationDataList] = useState([])
  const [gradeGroupTypeList,setGradeGroupTypeList] = useState([])
  const [financialYearList, setFinancialYearList] = useState([])
  const [selectedLocationDataList, setSelectLocationDataList] = useState([]);
  const [selectedFinancialYearList, setSelectedFinancialYearList] = useState([]);
  const [selectedLoanData, setSelectedLoanData] = useState([]);
  const [selectedGradeGroupTypeList, setSelectedGradeGroupTypeList] = useState([]);
  const [selectedLoanDataNo, setSelectedLoanDataNo] = useState([]);
  const [gradeGroupData, setGradeGroupData] = useState([]);
  const [selectedGradeGroup, setSelectedGradeGroup] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  //==============================================GET MASTER LOAN LIST====================================================
  const getLoanMasterList = async(dropDownType) =>{
    setLoader(true);
    let params={
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }

    await getDropdownList(params)
    .then((res)=>{
      if((res.status === 200 || res.status === 201)){
        const masterDataList = res?.data?.data?.map((data)=>({
          name: data?.loan_name,
          id : data?.loan_id
        }))
        setMasterType(masterDataList);
      }else{
        toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
      }
    }).catch((error) => {
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    }).finally(()=>{
      setLoader(false);
    })
  }

    //==============================================GET LOAN TYPE LIST====================================================
    const getLoanTypeList = async(dropDownType) =>{
      setLoader(true);
      let params={
        comp_code: AdminLoginData?.company?.company_code,
        drop_down_type: dropDownType
      }
      
      await getDropdownList(params)
      .then((res)=>{
        if((res.status === 200 || res.status === 201)){
          const loanTypeList = res?.data?.data?.map((data)=>({
            name: data?.loan_type_name,
            id : data?.loan_type_id
          }))
          loanTypeList.unshift({ name: "ALL", id:"" });
          setLoanData(loanTypeList);
          setSelectedLoanData(loanTypeList[0])
        }else{
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false);
      })
    }

      //==============================================GET LOAN NO LIST====================================================
  const getLoanNoList = async(dropDownType) =>{
    setLoader(true);
    let params={
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }
    
    await getDropdownList(params)
    .then((res)=>{
      if((res.status === 200 || res.status === 201)){
        const loanDataList = res?.data?.data?.map((data)=>({
          name: data?.loan_no_name,
          id : data?.loan_no_id
        }))
        loanDataList.unshift({ name: "ALL", id:"" });
        setLoanDataNo(loanDataList);
        setSelectedLoanDataNo(loanDataList[0])
      }else{
        toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
      }
    }).catch((error) => {
      toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
    }).finally(()=>{
      setLoader(false);
    })
  }

    //==============================================GET UNIT LIST====================================================
    const getUnitList = async(dropDownType) =>{
      setLoader(true);
      let params={
        comp_code: AdminLoginData?.company?.company_code,
        drop_down_type: dropDownType
      }
      
      await getDropdownList(params)
      .then((res)=>{
        if((res.status === 200 || res.status === 201)){
          const unitList = res?.data?.data?.map((data)=>({
            name: data?.unit_name,
            id : data?.unit_id
          }))
          unitList.unshift({ name: "ALL", id:"" });
          setLocationDataList(unitList);
          setSelectLocationDataList(unitList[0])
        }else{
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false);
      })
    }

    //==============================================GET GRADE GROUP TYPE LIST====================================================
    const getGradeGroupTypeList = async(dropDownType) =>{
      setLoader(true);
      let params={
        comp_code: AdminLoginData?.company?.company_code,
        drop_down_type: dropDownType
      }
      
      await getDropdownList(params)
      .then((res)=>{
        if((res.status === 200 || res.status === 201)){
          const gradeGroupType = res?.data?.data?.map((data)=>({
            name: data?.group_type_name,
            id : data?.group_type_id
          }))
          gradeGroupType.unshift({ name: "ALL", id:"" });
          setGradeGroupTypeList(gradeGroupType);
          setSelectedGradeGroupTypeList(gradeGroupType[0])
        }else{
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false);
      })
    }

    //==============================================GET FINANCIAL YEAR LIST====================================================
    const getFinancialYear = async() =>{
      setLoader(true);      
      await getFinancialYearList()
      .then((res)=>{
        if((res.status === 200 || res.status === 201)){
          const financialYear = res?.data?.data?.map((data)=>({
            name: data?.financial_year,
            to_year : data?.to_year,
            from_year: data?.from_year
          }))
          financialYear.unshift({ name: "ALL", id:"" });
          setFinancialYearList(financialYear);
          setSelectedFinancialYearList(financialYear[0])
        }else{
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false);
      })
    }

    //==============================================GET BUDGET DATA====================================================
    const getBudgetDataList = async(is_toaster=true)=>{
      setLoader(true);
      let params = {
        comp_code: AdminLoginData?.company?.company_code,
        unit:selectedLocationDataList?.id,
        group_type: selectedGradeGroupTypeList?.id,
        grade_group: selectedGradeGroup?.id,
        loan_no: selectedLoanDataNo?.id,
        loan_type: selectedLoanData?.id,
        from_year:selectedFinancialYearList?.from_year,
        to_year: selectedFinancialYearList?.to_year,
        page_number: currentPage
      }
      await getBudgetData(params)
      .then((res)=>{
        if ((res.status === 200 || res.status === 201)) {
          const result = res?.data?.data?.paginated_data ? res?.data?.data?.paginated_data  : []
          if(!result || result.length === 0){
            toaster(WARNING,NO_DATA_FOUND)
          }else{
            if(is_toaster){
              toaster(SUCCESS, res?.data?.message ?? SUCCESS_MESSAGE)
            }
          }
          const formattedData = result.map(item => ({
            ...item,
            comp_code: AdminLoginData?.company?.company_lng_txt
          }));
          setBudgetPaginatedList(formattedData)
          setTotalPages(res?.data?.data?.total_pages);
          setTotalRecords(formattedData.length);
        } else {
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        
        }
      }).catch((error)=>{
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false)
      })
    }

    //=============================GET INSTALLMENT DATA================================//
    const getInstallmentDataList= async(is_toaster=true) =>{
      setLoader(true);
      let params={
        comp_code: AdminLoginData?.company?.company_code,
        loan_type: selectedLoanData?.id,
        group_type: selectedGradeGroupTypeList?.id,
        grade_group: selectedGradeGroup?.id,
        page_number: currentPage
      }
      await getInstallmentData(params)
      .then((res)=>{        
        if ((res.status === 200 || res.status === 201)) {
          const result = res?.data?.data?.paginated_data ? res?.data?.data?.paginated_data : []
          if(!result || result.length === 0){
            toaster(WARNING,NO_DATA_FOUND)
          }else{
            if(is_toaster){
              toaster(SUCCESS, res?.data?.message ?? SUCCESS_MESSAGE)
            }
          }
          const formattedData = result.map(item => ({
            ...item,
            start_date: DDMMYYYY(item.start_date),
            comp_code: AdminLoginData?.company?.company_lng_txt
          }));
          setInstallmentPaginatedList(formattedData);
          setTotalPages(res?.data?.data?.total_pages);
          setTotalRecords(formattedData.length);
        } else {
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error)=>{
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(()=>{
        setLoader(false)
      })
    }

    //==============================================GET GRADE GROUP LIST====================================================
        const getGradeGroupList = async(dropDownType) =>{
          setLoader(true);
          let params={
            comp_code: AdminLoginData?.company?.company_code,
            drop_down_type: dropDownType
          }
          await getDropdownList(params)
          .then((res)=>{
            if((res.status === 200 || res.status === 201)){
              const gradeGroupList = res?.data?.data?.map((data)=>({
                name: data?.grade_group_name,
                id : data?.grade_group_id
              }))
              gradeGroupList.unshift({ name: "ALL", id:"" });
              setGradeGroupData(gradeGroupList);
              setSelectedGradeGroup(gradeGroupList[0]);
            }else{
              toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
            }
          }).catch((error) => {
            toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
          }).finally(()=>{
            setLoader(false);
          })
        }

    //=================================GET  LOAN ALLOWED DATA============================
      const getLoanAllowedDataList = async(is_toaster=true)=>{
        setLoader(true);
        let params={
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanData?.id,
          group_type: selectedGradeGroupTypeList?.id,
          page_number: currentPage
        }
        await getLoanAllowedData(params)
        .then((res)=>{     
          if ((res.status === 200 || res.status === 201)) {
            const result = res?.data?.data?.paginated_data  ? res?.data?.data?.paginated_data : []
            if(!result || result.length === 0){
              toaster(WARNING,NO_DATA_FOUND)
            }else{
              if(is_toaster){
                toaster(SUCCESS, res?.data?.message ?? SUCCESS_MESSAGE)
              }
            }
            const formattedData = result.map(item => ({
              ...item,
              start_date: DDMMYYYY(item.start_date),
              comp_code: AdminLoginData?.company?.company_lng_txt
            }));
            setLoanAllowedPaginatedList(formattedData);
            setTotalPages(res?.data?.data?.total_pages);
            setTotalRecords(formattedData.length);
          } else {
            toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
        })
      }

    
    //=============================GET GRADE GROUP TYPE DATA=========================
      const getGradeGroupTypeDataList = async(is_toaster=true)=>{
        setLoader(true);
        let params={
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanData?.id,
          group_type: selectedGradeGroupTypeList?.id,
          page_number: currentPage
        }
        await getGradeGroupType(params)
        .then((res)=>{    
          if ((res.status === 200 || res.status === 201)) {
            const result = res?.data?.data?.paginated_data ? res?.data?.data?.paginated_data : []
            if(!result || result.length === 0){
              toaster(WARNING,NO_DATA_FOUND)
            }else{
              if(is_toaster){
                toaster(SUCCESS, res?.data?.message ?? SUCCESS_MESSAGE)
              }
            }
            const formattedData = result.map(item => ({
              ...item,
              start_date: DDMMYYYY(item.start_date),
              comp_code: AdminLoginData?.company?.company_lng_txt
            }));
            setGradeGroupTypePaginatedList(formattedData);
            setTotalPages(res?.data?.data?.total_pages);
            setTotalRecords(formattedData.length);
          } else {
            toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
        })
      }



    //===================================GET NEXT LOAN START================================
    const getNextLoanStartDataList = async(is_toaster=true)=>{
      setLoader(true);
        let params={
          comp_code: AdminLoginData?.company?.company_code,
          loan_type: selectedLoanData?.id,
          group_type: selectedGradeGroupTypeList?.id,
          loan_no: selectedLoanDataNo?.id,
          page_number: currentPage
        }
        await getNextLoan(params)
        .then((res)=>{   
          if ((res.status === 200 || res.status === 201)) {
            const result = res?.data?.data?.paginated_data ? res?.data?.data?.paginated_data : []
            if(!result || result.length === 0){
              toaster(WARNING,NO_DATA_FOUND)
            }else{
              if(is_toaster){
                toaster(SUCCESS, res?.data?.message ?? SUCCESS_MESSAGE)
              }
            }
            const formattedData = result.map(item => ({
              ...item,
              start_date: DDMMYYYY(item.start_date),
              comp_code: AdminLoginData?.company?.company_lng_txt
            }));
            setNextLoanStartPaginatedList(formattedData);
            setTotalPages(res?.data?.data?.total_pages);
            setTotalRecords(formattedData.length);
          } else {
            toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
          }
        }).catch((error)=>{
          toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
        }).finally(()=>{
          setLoader(false)
        })
    }
    


  useEffect(() => {
    getLoanMasterList(LOAN_MASTER)
    getLoanTypeList(LOAN_TYPE)
    getLoanNoList(LOAN_NO)
    getUnitList(UNIT)
    getGradeGroupTypeList(GRADE_GROUP_TYPE)
    getFinancialYear()
    getGradeGroupList(GRADE_GROUP)
  }, []);

  const handlePageCount = (action) => {
    if (action === DECREMENT && currentPage > 1) {
      setCurrentPage(currentPage - 1)
    } else if (action === INCREMENT && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  };

  const paginationCall = (currentPageNo) => {
    setCurrentPage(currentPageNo);
  }

  const changeCurrentPage = (pageNum) =>{
    setCurrentPage(pageNum)
  }

  const switchPage = (value) =>{
    setCurrentPage(value);
  }
  
  const masterTypeSelected = (data) => {
    setSelectLocationDataList(locationDataList[0]);
    setSelectedFinancialYearList(financialYearList[0]);
    setSelectedLoanData(loanData[0]);
    setSelectedGradeGroupTypeList(gradeGroupTypeList[0]);
    setSelectedLoanDataNo(loanDataNo[0]);
    setShowTable(true)
    setSelectedGradeGroup(gradeGroupData[0]);
    setSelectedMasterType(data);
    setCurrentPage(1)
    setTotalRecords(0);
    setTotalPages(0)
  };

  const handleApiRequest=(is_toaster)=>{
    setCurrentPage(1)
    if(selectedMasterType?.id == MASTER01){
      getBudgetDataList(is_toaster)
    }
    else if (selectedMasterType?.id == MASTER02){
      getInstallmentDataList(is_toaster);
    }
    else if (selectedMasterType?.id == MASTER03){
      getGradeGroupTypeDataList(is_toaster);
    }
    else if (selectedMasterType?.id == MASTER04) {
      getLoanAllowedDataList(is_toaster);
    }
    else if (selectedMasterType?.id == MASTER05) {
      getNextLoanStartDataList(is_toaster);
    }
  }
  const actionButton = () => {
    setAddBtn(true)
    setPopUpVisible(true);
  };
  const editActionButton =(row)=>{
    setSelectedRow(row)
    setAddBtn(false)
    setPopUpVisible(true)
  }
  const closePopUp = ()=>{
    setPopUpVisible(false)
  }
  const closeDeletePopUp = ()=>{
    setDeletePopUpVisible(false)
  }
  const deleteActionButton=(row)=>{
    setSelectedRow(row)
    setDeletePopUpVisible(true)
  }
  useEffect(()=>{
    if(selectedMasterType?.id == MASTER01){
      getBudgetDataList(false)
    }
    else if (selectedMasterType?.id == MASTER02){
      getInstallmentDataList(false);
    }
    else if (selectedMasterType?.id == MASTER03){
      getGradeGroupTypeDataList(false);
    }
    else if (selectedMasterType?.id == MASTER04) {
      getLoanAllowedDataList(false);
    }
    else if (selectedMasterType?.id == MASTER05) {
      getNextLoanStartDataList(false);
    }
  },[currentPage])
  return (
    <div>
      <LoaderIndicator loading={loader} />
      <div className="ml-10 h-90">
        <div>
          <div className="gap-20">
            <div className="d-flex flex-direction-column">
              <div className="flex-direction-row justify-content-between align-item-center filter">
                <div className="d-flex flex-direction-row flex-wrap mx-2 align-items-center">
                  <div
                    className="flex-direction-column mb-21px ml-3"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Select Master"}
                      <span className='required-field'>*</span>
                    </div>
                    <DynamicDropdown
                      data={masterType}
                      render="name"
                      value={selectedMasterType?.name}
                      selectedValue={(data) => masterTypeSelected(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>
                  {selectedMasterType?.id === MASTER01 && <div
                    className="flex-direction-column ml-3 mb-21px min-width-130px"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Select Unit"}
                    </div>
                    <DynamicDropdown
                      data={locationDataList}
                      render="name"
                      value={selectedLocationDataList?.name}
                      selectedValue={(data) => setSelectLocationDataList(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>}
                  {selectedMasterType?.id === MASTER01 && <div
                    className="flex-direction-column ml-3 mb-21px min-width-130px"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Financial Year"}
                    </div>
                    <DynamicDropdown
                      data={financialYearList}
                      render="name"
                      value={selectedFinancialYearList?.name}
                      selectedValue={(data) => setSelectedFinancialYearList(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>}
                  {(selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER02 || selectedMasterType?.id === MASTER03 || selectedMasterType?.id === MASTER04 ||selectedMasterType?.id === MASTER05) && <div
                    className="flex-direction-column ml-3 mb-21px min-width-130px"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Loan Type"}
                    </div>
                    <DynamicDropdown
                      data={loanData}
                      render="name"
                      value={selectedLoanData?.name}
                      selectedValue={(data) => setSelectedLoanData(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>}
                  {(selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER02 || selectedMasterType?.id === MASTER03 || selectedMasterType?.id === MASTER04 ||selectedMasterType?.id === MASTER05) && <div
                    className="flex-direction-column mb-21px ml-3 min-width-130px"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Grade Group Type"}
                    </div>
                    <DynamicDropdown
                      data={gradeGroupTypeList}
                      render="name"
                      value={selectedGradeGroupTypeList?.name}
                      selectedValue={(data) => setSelectedGradeGroupTypeList(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>}
                  {(selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER02) && <div
                    className="flex-direction-column mb-21px ml-3 min-width-130px"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Grade Group"}
                    </div>
                    <DynamicDropdown
                      data={gradeGroupData}
                      render="name"
                      value={selectedGradeGroup?.name}
                      selectedValue={(data) => setSelectedGradeGroup(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>}
                  {(selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER05) && <div
                    className="flex-direction-column mb-21px ml-3 min-width-130px"
                  >
                    <div className="p-sub-heading ml-2">
                      {"Loan No."}
                    </div>
                    <DynamicDropdown
                      data={loanDataNo}
                      render="name"
                      value={selectedLoanDataNo?.name}
                      selectedValue={(data) => setSelectedLoanDataNo(data)}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-20rem"}
                    />
                  </div>}
                </div>
                {selectedMasterType && selectedMasterType?.id != null ? (
                  <div>
                  <button className="btn buttonStyle" onClick={handleApiRequest}
                  >
                    {"Search"}
                  </button>
                    <button className="btn buttonStyle px-20px m-10px" onClick={actionButton}
                    >
                      {selectedMasterType?.id === MASTER01 ? ADDBUDGET :
                        selectedMasterType?.id === MASTER02 ? ADDINSTALLMENT :
                          selectedMasterType?.id === MASTER03 ? ADDGGTYPE :
                            selectedMasterType?.id === MASTER04 ? ADDLOANALLOWED :
                              selectedMasterType?.id === MASTER05 ? ADDNEXTLOANSTART :
                                ADD}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {
             ( showTable && totalRecords > 0 ) ?
              (
                <div>
                  <LoanTable 
                   columns={selectedMasterType?.id === MASTER01 ? budgetColumnFields :
                    selectedMasterType?.id === MASTER02 ? installmentColumnFields :
                    selectedMasterType?.id === MASTER03 ? gradeGroupColumnFields :
                    selectedMasterType?.id === MASTER04 ? loanAllowedColumnFields :
                    selectedMasterType?.id === MASTER05 ? nextLoanStartColumnFields : ""}
                   data={selectedMasterType?.id === MASTER01 ? budgetPaginatedList :
                    selectedMasterType?.id === MASTER02 ? installmentPaginatedList :
                    selectedMasterType?.id === MASTER03 ? gradeGroupTypePaginatedList :
                    selectedMasterType?.id === MASTER04 ? loanAllowedPaginatedList :
                    selectedMasterType?.id === MASTER05 ? nextLoanStartPaginatedList :
                    ""}
                    hasAction={true}
                    deleteIcon={true}
                    editIcon={true}
                    editIconAction={editActionButton}
                    deleteIconAction={deleteActionButton}
                    isSrNo={true}/>
                    
                  {/* {
                    totalPages >= currentPage && (
                      <TablePagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePageCount={handlePageCount}
                        paginationCall={paginationCall}
                        changeCurrentPage={changeCurrentPage}
                      />
                    )
                  } */}
                  {
                    totalPages >= currentPage && (
                      <PaginationTable
                        switchPage = {switchPage}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalRecords={totalRecords}
                      /> 
                    )
                  }
                </div>
              ) : (
                <>
                  <div className='mt-8'>
                    <DataNotFound />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
      {popUpVisible && <MasterScreenPopUp title={addBtn ? (selectedMasterType?.id === MASTER01 ? ADDBUDGET :
              selectedMasterType?.id === MASTER02 ? ADDINSTALLMENT :
              selectedMasterType?.id === MASTER03 ? ADDGGTYPE :
              selectedMasterType?.id === MASTER04 ? ADDLOANALLOWED :
              selectedMasterType?.id === MASTER05 ? ADDNEXTLOANSTART :
              ADD) : selectedMasterType?.id === MASTER01 ? UPDATEBUDGET :
              selectedMasterType?.id === MASTER02 ? UPDATEINSTALLMENT :
              selectedMasterType?.id === MASTER03 ? UPDATEGGTYPE :
              selectedMasterType?.id === MASTER04 ? UPDATELOANALLOWED :
              selectedMasterType?.id === MASTER05 ? UPDATENEXTLOANSTART :
              UPDATE} 
              crossBtn={closePopUp}
              loanType={selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER02 || selectedMasterType?.id === MASTER03 || selectedMasterType?.id === MASTER04 || selectedMasterType?.id === MASTER05 ? true : false}
              loanNo={selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER05 ? true : false}
              unit={selectedMasterType?.id === MASTER01 ? true : false}
              gradeGroupType={selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER02 || selectedMasterType?.id === MASTER03 || selectedMasterType?.id === MASTER04 || selectedMasterType?.id === MASTER05 ? true : false}
              gradeGroup={selectedMasterType?.id === MASTER01 || selectedMasterType?.id === MASTER02 ? true : false}
              financialYear={selectedMasterType?.id === MASTER01 ? true : false}
              loanAmount={selectedMasterType?.id === MASTER02 ? true : false}
              budgetAmount={selectedMasterType?.id === MASTER01 ? true : false}
              intRate={selectedMasterType?.id === MASTER02 ? true : false}
              totalMonthRecovery = {selectedMasterType?.id === MASTER02 ? true : false}
              startDate={selectedMasterType?.id === MASTER02 || selectedMasterType?.id === MASTER03 || selectedMasterType?.id === MASTER04 || selectedMasterType?.id === MASTER05 ? true : false}
              noOfLoanAllowed={selectedMasterType?.id === MASTER04 ? true : false}
              monthInNumber={selectedMasterType?.id === MASTER05 ? true : false}
              btnValue={addBtn ? ADD : UPDATE}
              bindedData={selectedRow}
              apiRequest={handleApiRequest}
              
              />}

      {deletePopUpVisible && <ConfirmationPopUpLoan apiRequest={handleApiRequest} bindedData={selectedRow} title={selectedMasterType?.id === MASTER01 ? DELETEBUDGET :
              selectedMasterType?.id === MASTER02 ? DELETEINSTALLMENT :
              selectedMasterType?.id === MASTER03 ? DELETEGGTYPE :
              selectedMasterType?.id === MASTER04 ? DELETELOANALLOWED :
              selectedMasterType?.id === MASTER05 ? DELETENEXTLOANSTART :
              DELETECONFIRM} crossBtn={closeDeletePopUp} note={DELETEMSGCONFIRM} image={delete_popup}/>
                    }
    </div>
  )
}

export default LoanMasterScreen