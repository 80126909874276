import React from "react";
import edit from "../../../../../assets/drishti_images/profile/edit.svg";
import delete_btn from "../../../../../assets/drishti_images/transfer/delete_action.svg";
import approvewrite from '../../../../../assets/drishti_images/approvewrite.svg';
import rejectCross from '../../../../../assets/drishti_images/Rejectcross.svg';
import view from '../../../../../assets/drishti_images/view.svg';
import download from '../../../../../assets/drishti_images/downloadLatestImg.svg';

function LoanTable({ columns, text_align=null, pre_custom_width='custom-pre-width', data ,className = "",minHeight='',hasAction=false,viewIcon=false,editIcon=false,downloadIcon=false,deleteIcon=false,rejectIcon=false,approveIcon=false , cancelBtn=false, viewIconAction=null, editIconAction=null,deleteIconAction=null, rejectIconAction=null, approveIconAction=null, downloadIconAction=null, registerBtn=false,registerAction=null,isSrNo=false,rejectBtn=false,reopenBtn=false, reopenAction=null, cancelAction=null, rejectAction=null}) {
  return (
    <div>
      <div className={`scroll-x max-height-43vh mt-5px ${className}`} style={{minHeight: minHeight}}>
        <table className={`table gemsAdmin scroll-y ${text_align}`}>
          <thead className="">
            <tr className="">
              {isSrNo && <th >
                <div className="thead white-space-no-wrap">Sr. No.</div>
              </th>}
              {columns.map((val) => (
                <th >
                  <div className="thead white-space-no-wrap">{val.title}</div>
                </th>
              ))}
              {hasAction && <th >
                <div className="thead white-space-no-wrap">Action</div>
              </th>}
            </tr>
          </thead>
          <tbody className="">
            {data.map((row, index) => (
              <tr key={index} className="">
                {isSrNo && <td ><pre className={`unineue-font mt-1em size_875rem custom-pre-width`}>{index + 1}</pre></td>}
                {columns.map((column) => (
                  <td key={column.key}>
                    <pre className={`unineue-font mt-1em size_875rem ${pre_custom_width}`}>{row[column.key]}</pre>
                  </td>
                ))}
                {hasAction &&<td className="w-auto d-flex gap-5px thead">
                  {viewIcon && <img  className="pointer mt-height" src={view} alt="" onClick={()=> viewIconAction(row)} />}
                  {editIcon && <img  className="pointer mt-height" src={edit} alt="" onClick={() => editIconAction(row)} />}
                  {deleteIcon && <img  className="pointer mt-height" src={delete_btn} alt="" onClick={() => deleteIconAction(row)} />}
                  {rejectIcon && <img  className="pointer mt-height" src={rejectCross} alt="" onClick={()=> rejectIconAction(row)} />}
                  {approveIcon && <img  className="pointer mt-height" src={approvewrite} alt="" onClick={()=> approveIconAction(row)} />}
                  {downloadIcon && <img  className="pointer mt-height" src={download} alt="" onClick={()=> downloadIconAction(row)} />}
                  {registerBtn && <button className="pointer h-38px btn buttonStyle" onClick={()=>registerAction(row)}>Register</button>}
                  {rejectBtn && <button className="pointer h-38px btn buttonStyle" onClick={()=>rejectAction(row)}>Stamp</button>}
                  {reopenBtn && <button className="pointer h-38px btn buttonStyle" onClick={()=>reopenAction(row)}>Reopen</button>}
                  {cancelBtn && <button className="pointer h-38px btn buttonStyle" onClick={()=>cancelAction(row)}>Cancel</button>}
                </td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      </div>
  );
}

export default LoanTable;
