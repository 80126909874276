import React, { Component } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import LocalConveyance from './LocalConveyanceApporval'
import LocalConveynaceClaim from './LocalConveynaceClaim'
import Dropdown from '../../../shared/components/Dropdown';
import { claimHistoryCommonApiFun, getClaimHistoryList, getConveyanceList, getManagerList } from '../../../../constants/api.service'
import { connect } from 'react-redux';
import { toaster } from '../../../../utils/Utils';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import rightArrow from '../../../../assets/drishti_images/header/right-arrow-gif-unscreen.gif';

import moment from 'moment';
import DynamicDropdown from '../../../shared/components/DynamicDropdown';

const LCManual = "https://tatamotors.sharepoint.com/sites/c-app/s-MTMDev/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fc%2Dapp%2Fs%2DMTMDev%2FShared%20Documents%2FDrishti%20App%20User%20Manual%5FLocal%20Conveyance%20Module%20forTechnicians%20V1%2Epdf&parent=%2Fsites%2Fc%2Dapp%2Fs%2DMTMDev%2FShared%20Documents&p=true&ct=1711620247949&or=Teams%2DHL&ga=1&isSPOFile=1&OR=Teams%2DHL&CT=1711621982464&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIxNDE1LzI0MDIyOTIwNjAyIiwiSGFzRmVkZXJhdGVkVXNlciI6ZmFsc2V9"
const approvedClaimStatus = [
    { id: "0", title: 'Claim Approval' },
    { id: "1", title: 'New Claim (On Behalf)' }
]

const applicantStatus = [
    // { id: "0", title: 'All' },
    { id: "1", title: 'Pending' },
    { id: "2", title: 'Approved' },
    { id: "3", title: 'Rejected' },
    { id: "4", title: 'Settled' }
]

const approverApplicantStatus = [
    // { id: "0", key: 'All', title: 'All' },
    { id: "1", key: 'PENDING_BY_My', title: 'Pending for my approvals' },
    { id: "2", key: 'PENDING_BY_DEM', title: 'Pending for DEM approval' },
    { id: "2", key: 'REJECTED_BY_DEM', title: 'Claim rejected by DEM' },
    { id: "4", key: 'PENDING_BY_L3', title: 'Pending for L3 & Above approval' },
    { id: "3", key: 'PENDING_BY_ADMIN', title: 'Pending for Admin approval' },
    { id: "4", key: 'APPROVED_BY_ADMIN', title: 'Approved by Admin' },
    { id: "5", key: 'REJECTED_BY_ADMIN', title: 'Claim rejected by Admin' },
    { id: "6", key: 'ADMIN_BDC_DOCUMENT_GENERATED', title: 'Claim Approved & sent to Accountant for settlement' },
    { id: "7", key: 'CLAIM_SETTLED', title: 'Claim Settled' },

]

const monthList = [
    { id: '0', key: '01', title: 'January' },
    { id: '1', key: '02', title: 'February' },
    { id: '2', key: '03', title: 'March' },
    { id: '3', key: '04', title: 'April' },
    { id: '4', key: '05', title: 'May' },
    { id: '5', key: '06', title: 'June' },
    { id: '6', key: '07', title: 'July' },
    { id: '7', key: '08', title: 'August' },
    { id: '8', key: '09', title: 'September' },
    { id: '9', key: '10', title: 'October' },
    { id: '10', key: '11', title: 'November' },
    { id: '11', key: '12', title: 'December' },
]

export class LocalConveynaceHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checked: 0,
            approvedChecked: { id: "1", key: 'PENDING_BY_My', title: 'Pending for my approvals' },
            claimChecked: { id: "1", title: 'Pending' },
            totalPages: 0,
            pageNumber: 1,
            claimHistoryTotalPages: 0,
            claimHistoryPageNumber: 1,
            claimHistoryPaginatedData: [],
            paginatedData: [],
            isShowApprovalStatus: '',
            isShowClaimStatus: '',
            hideForNewClaim: false,
            claimListingPage: true,
            managerData: [],
            instructionData: [],
            selectedMonth: monthList[moment(new Date()).month()],
            claimHistorySelectedMonth: monthList[moment(new Date()).month()],
            searchedEmpName: '',
            claimHistorySelectedDestination: ''
        }
    }
    componentDidMount = () => {
        this.onChangedApprovalStatus(approverApplicantStatus[0])
        this.onShowApprovalStatus(true)
        this.onShowClaimStatus(false)
        this.onClaimListingPage(true)
        // this.getManagerList()
    }

    onShowApprovalStatus = (status) => {
        this.setState({ isShowApprovalStatus: status })
    }

    onShowClaimStatus = (status) => {
        this.setState({ isShowClaimStatus: status })
    }

    onHideForNewClaimStatus = (status) => {
        this.setState({ hideForNewClaim: status })
    }

    onClaimListingPage = (status) => {
        this.setState({ claimListingPage: status })
    }

    callingAppprovalListOnStatusChange = () => {
        this.setState({
            pageNumber: 1,
            totalPages: 0,
            paginatedData: []
        }, () => this.onShowApprovalStatus(true), this.getConveyanceList(this.state.approvedChecked.title)
        )
    }

    callingClaimListOnStatusChange = () => {
        this.setState({
            claimHistoryPageNumber: 1,
            claimHistoryTotalPages: 0,
            claimHistoryPaginatedData: []
        }, () => this.onShowClaimStatus(true), this.getClaimList(this.state.claimChecked.title)
        )
    }

    onChangedStatus = (key, title) => {
        key == 0 ?
            this.setState({
                checked: key,
                pageNumber: 1,
                totalPages: 0,
                paginatedData: [],
            }, () => this.callingAppprovalListOnStatusChange())
            : this.onShowApprovalStatus(false)

        key == 1 ?
            this.setState({
                checked: key,
                claimHistoryPageNumber: 1,
                claimHistoryTotalPages: 0,
                claimHistoryPaginatedData: []
            }, () =>
                this.callingClaimListOnStatusChange())
            : this.onShowClaimStatus(false)

    }

    onChangedApprovalStatus = (data) => {
        this.setState({
            approvedChecked: data,
            pageNumber: 1,
            totalPages: 0,
            paginatedData: []
        }, () => this.getConveyanceList(data.title))
    }

    onChangeMonthStatus = (data) => {
        this.setState({
            selectedMonth: data,
            pageNumber: 1,
            totalPages: 0,
            paginatedData: []
        }, () => this.getConveyanceList(this.state.approvedChecked.title))
    }

    onChangeClaimHistoryMonthStatus = (data) => {
        this.setState({
            claimHistorySelectedMonth: data,
            claimHistoryPageNumber: 1,
            claimHistoryTotalPages: 0,
            claimHistoryPaginatedData: []
        }, () => this.getClaimList(this.state.claimChecked.title))
    }

    setEmpName = (data) => {
        this.setState({
            searchedEmpName: data.target.value
        })
    }

    setclaimHistoryEmpName = (data) => {
        this.setState({
            claimHistorySelectedDestination: data.target.value
        })
    }

    searchEmpNameFilter = () => {
        this.setState({
            pageNumber: 1,
            totalPages: 0,
            paginatedData: []
        }, () => this.getConveyanceList(this.state.approvedChecked.title))
    }

    searchClaimHistoryEmpNameFilter = () => {
        this.setState({
            claimHistoryPageNumber: 1,
            claimHistoryTotalPages: 0,
            claimHistoryPaginatedData: []
        }, () => this.getClaimList(this.state.claimChecked.title))
    }

    onChangedClaimStatus = (data) => {
        this.setState({
            claimChecked: data,
            claimHistoryPageNumber: 1,
            claimHistoryTotalPages: 0,
            claimHistoryPaginatedData: []
        }, () => this.getClaimList(data.title))
    }

    getConveyanceList(claim_status) {
        this.setState({
            loading: true,
        })
        const param = {
            month: this.state.selectedMonth.key,
            search_text: this.state?.searchedEmpName ? this.state.searchedEmpName : '',
            page_number: this.state.pageNumber,
        }
        getConveyanceList(param, this.props, claim_status)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        totalPages: res?.data?.data?.total_pages,
                        pageNumber: res?.data?.data?.page_number,
                        paginatedData: this.state.paginatedData.concat(res?.data?.data?.paginated_data),
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        paginatedData: [],
                        pageNumber: 1,
                        totalPages: 0
                    })
                    toaster("warning", res?.data?.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    paginatedData: [],
                    pageNumber: 1,
                    totalPages: 0
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    getClaimList(claim_status) {
        this.setState({
            loading: true
        })

        let param = {
            emp_comp_code: this.props.loginData.company.company_code,
            page_number: this.state.claimHistoryPageNumber,
        }

        if (this.state?.claimHistorySelectedDestination.length > 0) param['search_text'] = this.state.claimHistorySelectedDestination
        if (this.state.claimHistorySelectedMonth != null) param['month'] = this.state.claimHistorySelectedMonth.key

        getClaimHistoryList(param, this.props, claim_status)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        claimHistoryTotalPages: res?.data?.data?.total_pages,
                        claimHistoryPageNumber: res?.data?.data?.page_number,
                        claimHistoryPaginatedData: this.state.claimHistoryPaginatedData.concat(res?.data?.data?.paginated_data),
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        claimHistoryPaginatedData: [],
                        claimHistoryPageNumber: 1,
                        claimHistoryTotalPages: 0
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    claimHistoryPaginatedData: [],
                    claimHistoryPageNumber: 1,
                    claimHistoryTotalPages: 0
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    getManagerList() {
        this.setState({
            loading: true,
        })

        getManagerList()
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        managerData: res?.data?.data?.manager_details,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        managerData: [],
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    managerData: [],
                })
                toaster("warning", error?.data?.message ? error.data.message : "")
            });
    }

    morePage = () => {
        this.setState({
            pageNumber: this.state.pageNumber + 1
        },
            () =>
                this.getConveyanceList(this.state.approvedChecked.title))
    }

    // =========================== claim functionality ===============================

    closeClaimInstructions = () => {
        this.onShowClaimStatus(true)
        this.onHideForNewClaimStatus(false)
        this.onClaimListingPage(true)
    }

    getNewClaimInstructions = () => {
        this.onShowClaimStatus(false)
        this.onHideForNewClaimStatus(true)
        this.onClaimListingPage(false)
        this.setState({
            loading: true,
        })

        claimHistoryCommonApiFun({}, this.props, 'get_local_conveyance_instructions/')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        instructionData: res?.data?.data?.instructions
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        instructionData: []
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    instructionData: []
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    pageReset = () => {
        this.setState({
            totalPages: 0,
            pageNumber: 1,
            paginatedData: []
        })
    }

    claimHistoryPageReset = () => {
        this.setState({
            claimHistoryTotalPages: 0,
            claimHistoryPageNumber: 1,
            claimHistoryPaginatedData: []
        })
    }

    claimHistoryMorePage = () => {
        this.setState({
            claimHistoryPageNumber: this.state.claimHistoryPageNumber + 1
        },
            () =>
                this.getClaimList(this.state.claimChecked.title))
    }

    ClaimHistoryReCall = () => {
        this.getClaimList('Pending')
    }

    render() {
        return (
            <div className='h-71vh'>
                <div className='m-10px'>
                    <LoaderIndicator loading={this.state.loading} />
                    <div className='d-flex row justify-content-between'>
                        <div className=''>
                            {!this.state.hideForNewClaim &&
                                <div className='flex-direction-row align-item-center-noflex'>
                                    {approvedClaimStatus.map((list, key) => {
                                        return (
                                            <span key={key} className='grid-status align-item-center-noflex pl-15 ml-10 px-5px'>
                                                {this.state.checked === key ?
                                                    <span className='pointer'
                                                        onClick={() => this.onChangedStatus(key, list.title)}
                                                    >
                                                        <img alt="radioOn" className='radio' src={radioOn} />
                                                        <label className="radioTxt ml-15px pointer" >{list.title}</label>
                                                    </span>
                                                    :
                                                    <span className='pointer'
                                                        onClick={() => this.onChangedStatus(key, list.title)}
                                                    >
                                                        <img alt="radioOff" className='radio' src={radioOff} />
                                                        <label className="radioTxt ml-15px pointer">{list.title}</label>
                                                    </span>
                                                }
                                            </span>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        <div className='row'>
                            {this.state.checked == 0 && this.state.isShowApprovalStatus &&
                                <>
                                    <Dropdown
                                        data={monthList}
                                        selectedValue={(data) => this.onChangeMonthStatus(data)}
                                        value={this.state.selectedMonth.title ? this.state.selectedMonth.title : 'Select '}
                                        render={'ApporvalStatus'}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                    />

                                    <div className='ml-2'>
                                        <DynamicDropdown
                                            data={approverApplicantStatus}
                                            selectedValue={(data) => this.onChangedApprovalStatus(data)}
                                            value={this.state.approvedChecked.title ? this.state.approvedChecked.title : 'Select '}
                                            render={'title'}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                            width={"w-35rem"}
                                        />
                                    </div>

                                    <div className='row'>
                                        <div className='p-placeholder-text mb-1 d-flex ml-2'>
                                            <input
                                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                                type="text"
                                                placeholder='Search'
                                                onChange={(e) => this.setEmpName(e)}
                                                value={this.state.searchedEmpName}
                                                maxLength={25}
                                            />
                                            <img alt="radioOn" className='empolye_search_input filterIcon' src={searchIcon} onClick={() => this.searchEmpNameFilter()} />
                                        </div>

                                    </div>
                                </>
                            }
                            {this.state.checked == 1 && this.state.isShowClaimStatus &&
                                <>
                                    <Dropdown
                                        data={monthList}
                                        selectedValue={(data) => this.onChangeClaimHistoryMonthStatus(data)}
                                        value={this.state.claimHistorySelectedMonth.title ? this.state.claimHistorySelectedMonth.title : 'Select '}
                                        render={'ApporvalStatus'}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                    />
                                    <div className='row'>
                                        <div className='p-placeholder-text mb-1 d-flex ml-2'>
                                            <input
                                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                                type="text"
                                                placeholder='Search'
                                                onChange={(e) => this.setclaimHistoryEmpName(e)}
                                                value={this.state.claimHistorySelectedDestination}
                                                maxLength={25}
                                            />
                                            <img alt="radioOn" className='empolye_search_input filterIcon' src={searchIcon} onClick={() => this.searchClaimHistoryEmpNameFilter()} />
                                        </div>
                                    </div>

                                    <div className='mr-2'>
                                        <Dropdown
                                            data={applicantStatus}
                                            selectedValue={(data) => this.onChangedClaimStatus(data)}
                                            value={this.state.claimChecked.title ? this.state.claimChecked.title : 'Select '}
                                            render={'ApporvalStatus'}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                        />
                                    </div>

                                    <button
                                        type="submit"
                                        className=' btn-primary common-btn common-button-text'
                                        disabled={false}
                                        onClick={(e) => this.getNewClaimInstructions()}
                                    >
                                        +&nbsp;{"ADD NEW CLAIM"}
                                    </button>
                                </>
                            }
                            <div className='mt-1'>
                                <img
                                    src={rightArrow}
                                    alt="rightArrow"
                                    className="right-arrow-gif"
                                />
                                <a href={LCManual} target="_blank"><label className='tata-policy-link mb-1 ml-1' type='button'>{'Local Conveyance Manual'}</label></a>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.checked == 0 &&
                        <LocalConveyance
                            approvedChecked={this.state.approvedChecked}
                            paginatedData={this.state.paginatedData}
                            onShowApprovalStatus={this.onShowApprovalStatus}
                            onChangedApprovalStatus={this.onChangedApprovalStatus}
                            morePage={this.morePage}
                            totalPages={this.state.totalPages}
                            pageNumber={this.state.pageNumber}
                            managerData={this.state.managerData}
                            pageReset={this.pageReset}
                        />
                    }
                    {
                        this.state.checked == 1 &&
                        <LocalConveynaceClaim
                            claimHistoryPaginatedData={this.state.claimHistoryPaginatedData}
                            onShowClaimStatus={this.onShowClaimStatus}
                            onHideForNewClaimStatus={this.onHideForNewClaimStatus}
                            closeClaimInstructions={this.closeClaimInstructions}
                            hideForNewClaim={this.state.hideForNewClaim}
                            claimListingPage={this.state.claimListingPage}
                            instructionData={this.state.instructionData}
                            managerData={this.state.managerData}
                            claimChecked={this.state.claimChecked}
                            claimHistoryPageNumber={this.state.claimHistoryPageNumber}
                            claimHistoryTotalPages={this.state.claimHistoryTotalPages}
                            ClaimHistoryReCall={this.ClaimHistoryReCall}
                            claimHistoryPageReset={this.claimHistoryPageReset}
                            claimHistoryMorePage={this.claimHistoryMorePage}
                            onClaimListingPage={(status) => this.onClaimListingPage(status)}
                            onLanding={() => {
                                this.setState({
                                    claimHistoryPaginatedData: [],
                                    claimHistoryPageNumber: 1,
                                    claimHistoryTotalPages: 0
                                }, () =>
                                    this.getClaimList("Pending")
                                )
                            }}
                        />
                    }
                </div>
            </div >

        )
    }
}
const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(LocalConveynaceHomeScreen)