import React, { useEffect, useState } from 'react'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { clarification, suggestionDetails, SuggestionDocument, SuggestionMemberDetails } from '../../suggestionScheme/constants';
import download from '../../../../../assets/drishti_images/download.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import edit from '../../../../../assets/drishti_images/profile/edit.svg'
import { Pagination } from 'react-bootstrap';
import './suggestion.css'
import ClearancePreviewComponent from '../../eSeparation/clearance/ClearancePreviewComponent';
import SuggesterPreviewCompoment from './SuggesterPreviewCompoment';
import { DDMMYYYY } from '../../../../../utils/Utils';
import ViewTable from './ViewTable';
import { getSuggestionLogs } from '../../../../../constants/api.service';
import { toast } from 'react-toastify';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import { maskMobile } from '../../../../shared/commonMaskFunction';


const ClarifcationDetailPopup = ({ selectedItem, showCheckbox, showAction, itemsPerPage, selectedOption, onDismiss }) => {
    const [loading, setLoading] = useState(false);
    const [documentView, setDocumentView] = useState(false);
    const [SuggestionDetailList, setSuggestionDetails] = useState(null);
    const [is_service_Unavailable, setIs_Service_Unavailable] = useState(false);
    const [clarification_Service_reason, setClarification_Service_reason_Service_reason] = useState(null);
    const [originalData,setOriginalData] = useState({});

    const handleSecondaryAction = (index) => {
        setDocumentView(true);
    };

    const getSuggestionDetails = async (selectedItem) => {
        setLoading(true);
        try {
            const res = await getSuggestionLogs(selectedItem.id);
            if (res.status === 200) {
                if (res?.data?.data && typeof res.data.data === "object") {
                    const notUpdatedData = {...res}
                    setOriginalData(notUpdatedData)
                    const updatedData ={  
                        ...res,
                        data: {
                            ...res.data,
                            data: {   
                                ...res.data.data,
                                member_details: res.data.data.member_details.map((val,ind)=>{
                                    return {
                                        ...val,
                                        contact_no: maskMobile(val.contact_no),
                                        is_unmasked: false
                                    }
                                })
                            }
                        }
                        
                    }
                    setSuggestionDetails(updatedData.data.data);
                }
                // setSuggestionDetails(res.data.data);
                setLoading(false);
            } else {
                toast.warn(res.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
                setLoading(false);
            }
        } catch (error) {
            toast.warn(error.message, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleMaskingOfData = (index) => {
        setSuggestionDetails((prevstate) => {
            const updatedMemberDetails = prevstate.member_details.map((val, ind) => {
                if (ind === index) {
                    const newMaskingState = !val.is_unmasked; 
                    return {
                        ...val,
                        is_unmasked: newMaskingState,
                        contact_no: newMaskingState
                            ? originalData.data.data.member_details[index].contact_no
                            : maskMobile(val.contact_no),
                    };
                }
                return val;
            });
            return{
                ...prevstate,
                member_details: updatedMemberDetails
            }
        });
    };

    const fields = [
        { title: 'Suggestion ID', value: SuggestionDetailList?.suggestion_details?.id ?? '----'},
        { title: 'Title', value: SuggestionDetailList?.suggestion_details?.title ?? '----' },
        { title: 'Category', value: SuggestionDetailList?.suggestion_details?.category_name ?? '----' },
        { title: 'Scheme Type:', value: SuggestionDetailList?.suggestion_details?.scheme_type_name ?? '----' },
        { title: 'Agency', value: SuggestionDetailList?.suggestion_details?.agency_name ?? '----' },
        { title: 'Division', value: SuggestionDetailList?.suggestion_details?.division_name ?? '----' },
        { title: 'Suggestion Submission Date', value: DDMMYYYY(SuggestionDetailList?.suggestion_details?.entered_on) ?? '----'},
        { title: 'Annual Saving in ₹', value: SuggestionDetailList?.suggestion_details?.annual_saving ?? '----' },
    ];

    const schemeType = SuggestionDetailList?.suggestion_details?.scheme_type_name;
    if (schemeType === 'SPECIAL' || schemeType === 'SPECIAL IMPLEMENTED') {
        fields.push({ title: 'Scheme Name', value: SuggestionDetailList?.suggestion_details?.scheme_name ?? '----' });
    }

    if (schemeType === 'IMPLEMENTED' || schemeType === 'SPECIAL IMPLEMENTED') {
        fields.push(
            { title: 'Implemented Date', value: DDMMYYYY(SuggestionDetailList?.suggestion_details?.implemented_date) },
            { title: 'Idea Bank Reference', value: SuggestionDetailList?.suggestion_details?.idea_bank_reference ?? '----' });
    }
    useEffect(() => {
        getSuggestionDetails(selectedItem);
    }, [])
  
    return (
        <>
        <div className={"modal popup-box "}>
            <LoaderIndicator loading={loading} />
            <div className={"modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem"}>
                <div className={`modal-content bg-white border_radius_12px max-height-80 ` + `w-60vw`}>
                    <div className="modal-header mx-2">
                        <div className='row'>
                            <h4 className="popup-title mt-2">Suggestion Details</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={() => { onDismiss() }}>&times;</button>
                        <div className='solid-border'></div>
                    </div>

                    <div className='scroll-y mt-2 mx-2'>
                        {selectedItem && (
                            <>
                                {/* <div className='toggle-card-heading ml-10 mb-10'>Suggestor</div> */}
                                <div className='d-flex flex-column mt-1'>
                                    <div className='row w-100  mb-3'>
                                        {fields.map((field, index) => (
                                            <div className="col-4 mt-2" key={index}>
                                                <div className="text14_bold">{field.title}:</div>
                                                <div className="text14_light mt-1" style={{maxHeight:'22px',overflowY:'auto',textWrap:'wrap'}}>{field.value}</div>
                                                <div className='mt-1'><hr /></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='mt-1'><hr /></div>
                                {/* Suggestion Details */}
                                <div className='d-flex flex-column mt-2'>
                                    <div className='w-100 mb-3'>
                                        {(SuggestionDetailList?.suggestion_details?.scheme_type_name === 'NORMAL' ||
                                            SuggestionDetailList?.suggestion_details?.scheme_type_name === 'SPECIAL') && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="text14_bold">Present Status:</div>
                                                        <div className="text14_light mt-1" style={{ maxHeight: '30px', overflowY: 'auto', textWrap: 'wrap' }}>{SuggestionDetailList?.suggestion_details?.present_status ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="text14_bold">Proposed Changes:</div>
                                                        <div className="text14_light mt-1" style={{ maxHeight: '30px', overflowY: 'auto', textWrap: 'wrap' }}>{SuggestionDetailList?.suggestion_details?.proposed_changes ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </>
                                            )}
                                        {(SuggestionDetailList?.suggestion_details?.scheme_type_name === 'IMPLEMENTED' ||
                                            SuggestionDetailList?.suggestion_details?.scheme_type_name === 'SPECIAL IMPLEMENTED') && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="text14_bold">Earlier Status:</div>
                                                        <div className="text14_light mt-1" style={{ maxHeight: '30px', overflowY: 'auto', textWrap: 'wrap' }}>{SuggestionDetailList?.suggestion_details?.earlier_status ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <div className="text14_bold">Implemented Changes:</div>
                                                        <div className="text14_light mt-1" style={{ maxHeight: '30px', overflowY: 'auto', textWrap: 'wrap' }}>{SuggestionDetailList?.suggestion_details?.changes_implemented ?? '----'}</div>
                                                        <div className='mt-1'><hr /></div>
                                                    </div>
                                                </>
                                            )}
                                        <div className="col-12 mt-3">
                                            <div className="text14_bold">Benefits:</div>
                                            <div className="text14_light mt-1" style={{ maxHeight: '30px', overflowY: 'auto', textWrap: 'wrap' }}>{SuggestionDetailList?.suggestion_details?.benefits ?? '----'}</div>
                                            <div className='mt-1'><hr /></div>
                                        </div>
                                    </div>

                                    <div className='w-100  mb-3'>
                                        <div className="col-12 mt-1">
                                            <div className="text14_bold">Other areas where this suggestion is applicable:</div>
                                            <div className="text14_light mt-1" style={{ maxHeight: '30px', overflowY: 'auto', textWrap: 'wrap' }}>{SuggestionDetailList?.suggestion_details?.applicable_areas ?? '----'}</div>
                                            <div className='mt-1'><hr /></div>
                                        </div>
                                    </div>
                                    <div className='mt-1'><hr /></div>
                                </div>
                                {/* Member Details */}
                                <div className='toggle-card-heading mt-10px ml-10 mb-10'>Member Details</div>
                                <div className='d-flex flex-column'>
                                    {!SuggestionDetailList?.member_details || SuggestionDetailList?.member_details.length === 0 ? (
                                        <DataNotFound />
                                    ) : (
                                        <div className='ml-3 mr-4'>
                                            <ViewTable data={SuggestionDetailList?.member_details} columns={SuggestionMemberDetails} isEyeImageForContact={true} handleMaskingOfData={handleMaskingOfData}/>
                                        </div>
                                    )}
                                </div>
                                <div className='mt-1'><hr /></div>
                                {/* Document To Upload */}
                                <div className='toggle-card-heading mt-10px ml-10 mb-10'>Document To Upload</div>
                                <div className='d-flex flex-column'>
                                    {!SuggestionDetailList?.document_details || SuggestionDetailList?.document_details.length === 0 ? (
                                        <DataNotFound />
                                    ) : (
                                        <div className='ml-3 mr-4'>
                                            <SuggesterPreviewCompoment data={SuggestionDetailList?.document_details.filter((e)=> e.document_category === 'suggestion_details')} download={true} viewDelete={false} />
                                        </div>
                                    )}
                                </div>
                                <div className='mt-1'><hr /></div>
                            </>
                        )}
                    </div>
                    <div className="popup-button-background justify-content-end align-item-center pr-3 p-10px">
                        <input type='button' value={'CLOSE'} onClick={onDismiss} className='validateBtn' />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};


export default ClarifcationDetailPopup