import React, { Component } from 'react'
import editIcon from '../../../../assets/drishti_images/profile/edit.svg'
import register from '../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../assets/drishti_images/manager/landing/reject.svg'
import XLSX from 'sheetjs-style';
// import { singleRegistration } from './SingleRegistration';
import { connect } from "react-redux";
// import download from '../../../../assets/drishti_images/download.svg';
import { headers, single_employee_registration } from '../../../../constants/Config';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall } from "../../../../constants/Service";
import { validateNumber } from '../../../../utils/Utils'
import { maskMobile, maskEmail} from '../../../shared/commonMaskFunction';
import eye from '../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../assets/drishti_images/eye-slash.svg';

const fileExtension = '.xlsx';
var ExcelExportData = [
    { "Empoyee ID": "", "Phone number": "" }
];
class ViewError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editKey: '',
            employeeID: '',
            updatedPhoneNo: '',
            mobile_number: '',
            loading: false
        }
    }
    // componentDidMount(){
    //     console.log("componentDidMount..",this.props.passErrorDataToErrorScreen)
    //     this.setState({
    //         mobile_number: this.props.passErrorDataToErrorScreen.mobile_number
    //     })
    // }

    exportTOExcel = (excelData, fileName) => {
        var ws = XLSX.utils.json_to_sheet(this.props.passErrorDataToErrorScreen);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Bulk Registration");
        XLSX.writeFile(wb, fileName + fileExtension);
    }
    callsingleRegistration = (employeeID, mobile_number) => {
        let body = {
            emp_id: employeeID,
            mobile_number: mobile_number,
            logged_in_emp_id: this.props.adminLoginData.emp_id,
            comp_code: this.props.adminLoginData.company.company_code,
            consent_text: true,
            reason_code: "New Join",
            action: "Insert"
        }
        console.log("body:", body)
        // singleRegistration(body)
        fetchApiCall(single_employee_registration, body, headers, false, 'admin')
            .then(res => {
                console.log("callsingleRegistration res:", res);
                if (res.status == 200) {
                    this.setState({
                        loading: false
                    })
                    this.props.onDismiss()
                    if (res.data.status_code === "200") {
                        this.setState({
                            loading: false
                        })
                        setTimeout(() => {
                            toast.success(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastSuccess'
                            })
                        }, 100)
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                        setTimeout(() => {
                            toast.warn(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastWarn'
                            })
                        }, 100)
                    }
                }
            })
            .catch(err => {
                console.log("error :", err)
                setTimeout(() => {
                    toast.warn("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }, 100)
            })
    }

    editData = (id, data) => {
        console.log("id and data:", id, data)
        this.setState({
            mobile_number: data.mobile_number,
            editKey: id,
            employeeID: data.emp_id
        })
    }
    validation = ()=>{
        if(this.state.employeeID.length < 6 || this.state.employeeID === "000000" || this.state.employeeID.length > 6){
            setTimeout(() => {
                toast.warn("Invalide Employee ID", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }, 100) 
        }
        else if(this.state.mobile_number.length < 10 || this.state.mobile_number === "0000000000" || this.state.mobile_number.length > 10){
            setTimeout(() => {
                toast.warn("Invalide Mobile number", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }, 100) 
        }
        else{
            this.callsingleRegistration(this.state.employeeID, this.state.mobile_number) 
        }
    }

    toggleMask = (id) =>{
        this.props.toggleMaskedData(id);
    }

    toggleBtnMask = ()=>{
        this.props.toggleAllMaskUnmaskData()
    }

    render() {
        console.log("this.props.passErrorDataToErrorScreen:", this.props.passErrorDataToErrorScreen);
        const { editKey, employeeID, updatedPhoneNo, mobile_number } = this.state
        const { onDismiss, passErrorDataToErrorScreen } = this.props
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.state.loading} />
                <div className="registration-body p-0 view-Error">
                    <div className="modal-headers mt-2" >
                        <h4 className="modal_title black">{"Error Logs"}</h4>
                        <div className='flex-direction-row'>
                            {/* <img src={download} alt='download' className='onHowre'/> */}
                            <a
                                type="button"
                                className='btn btn-primary common-btn common-button-text d-flex justify-content-center w-199px mr-10px'
                                onClick={() => this.exportTOExcel(ExcelExportData, 'BulkRegistrationTemplate')}
                            >
                                Download Error log
                            </a>
                            <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                        </div>
                    </div>
                    <div className='hr'>
                        <hr />
                    </div>
                    <div className="modal-body">
                        <div className='firstContainer'>
                            <div className='secondContainer errorScreenHeight'>
                                <table className="table m-15px">
                                    <thead className=''>
                                        <tr>
                                            <th scope="col"><div className='thead pr-0'>Employee ID</div></th>
                                            <th scope="col">
                                                <div className='justify-content-start'>
                                                    <div className='thead pr-0'>
                                                        Phone number
                                                    </div>
                                                    <div onClick={()=> this.toggleBtnMask()} className='mx-10px'>
                                                        <img alt="eyeImage" src={this.props.toggleAllBtnMaskUnmask ? eye : eyeslash} style={{ width: '1rem', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </th>
                                            <th scope="col"><div className='thead pr-0'>Failure Reason</div></th>
                                            <th scope="col"><div className='thead pr-0'>Edit</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {passErrorDataToErrorScreen.map((listValue, id) =>
                                            <tr key={id}>
                                                <td scope="col">
                                                    <div className='thead pr-0'>
                                                        {editKey === id ?
                                                            <div className='p-placeholder-text'>
                                                                <input
                                                                    className={this.state.employeeID.length >= 7 || this.state.employeeID==="000000" ? "registration-invalid px-3" : "form-control registration-screen-placeholder px-3"}
                                                                    type="number"
                                                                    maxLength={6}
                                                                    value={employeeID}
                                                                    onChange={(e) => this.setState({ employeeID: e.target.value ? validateNumber(e.target.value) : '' })}
                                                                />
                                                            </div>
                                                            :
                                                            listValue.emp_id
                                                        }
                                                    </div>
                                                </td>
                                                <td scope="col">
                                                    <div className='thead pr-0'>
                                                        {
                                                            editKey === id ?
                                                                <div className='p-placeholder-text'>
                                                                    <input
                                                                        className={this.state.mobile_number.length >= 11 || this.state.mobile_number === "0000000000"? "registration-invalid px-3" : "form-control registration-screen-placeholder px-3"}
                                                                        type="number"
                                                                        maxLength={10}
                                                                        value={mobile_number}
                                                                        // disabled={true}
                                                                        onChange={(e) => this.setState({ mobile_number: e.target.value ? validateNumber(e.target.value) : '' })}
                                                                    />
                                                                </div>
                                                            : 
                                                            <div className='justify-content-start'>
                                                                <div>
                                                                    {(listValue.isMasked ? (listValue?.mobile_number ?? '') : maskMobile(listValue?.mobile_number ?? ''))}
                                                                </div>
                                                                <div onClick={()=> this.toggleMask(id)} className='mx-10px'>
                                                                    <img alt="eyeImage" src={listValue.isMasked ? eye : eyeslash} style={{ width: '1rem', cursor: 'pointer' }} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                </td>
                                                <td scope="col"><div className='thead pr-0'>
                                                    {editKey === id ?
                                                        ""
                                                        : listValue.reason}</div></td>
                                                <td scope="col"><div className='thead pr-0'>
                                                    {editKey === id ?
                                                        <div className='flex-direction-row'>
                                                            <img src={register} alt="register" className='viewButton pointer m-5px' onClick={() => this.validation()} />  
                                                            {/* this.callsingleRegistration(employeeID, mobile_number) */}
                                                            <img src={reject} alt="register" onClick={() => this.setState({ editKey: '' })} className="viewButton pointer m-5px" />
                                                        </div>
                                                        :
                                                        <img src={editIcon} alt="edit" onClick={() => this.editData(id, listValue)} className='pointer' />
                                                    }
                                                </div></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
    };
};
export default connect(mapStatesToProps, '')(ViewError)