import React, { Component } from 'react';
import close from '../../../../../assets/images/close.png';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { yesNo_type } from '../../../../../utils/Utils';
import { toast } from 'react-toastify';
import { RiArrowDropDownLine } from 'react-icons/ri';

const errorMessage = 'Input is missing !'
const chooseItem = 'Choose an item'
const errorFutureDateMessage = 'Future date not allowed'
const agreeMessage = 'Above information given by me is true and correct and I have discussed with my Manager / HR / Ethics regarding this Conflict of Interest.'
class RequestFormCardA2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            agree: false,
            relative_full_name: '',
            relative_comp_name: '',
            relative_comp_add: '',
            nature_of_interest: chooseItem,
            relationship: chooseItem,
            is_working_relationship_exist: chooseItem,
            relative_joining_date: '',
            is_disclosed: chooseItem,
            disclosure_description: '',

            error_full_name_of_relatieve: '',
            error_relatieves_company_name: '',
            error_relative_comp_add: '',
            error_nature_Of_interest: '',
            error_relationship: '',
            error_relationship_exist: '',
            error_disclosed: '',
            error_disclosed_to: '',
            error_working_since: '',

            nature_Of_interest_data: [],
            relationshipExistData: yesNo_type(),
            disclosedData: yesNo_type(),
            relationshipData: [],
            validate: true,
        }
    }

    componentDidMount() {
        this.getNatureOfInterestData()
    }

    getNatureOfInterestData = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getNatureOfInterest, {
            emp_id: this.state.relatie_personal_no
        }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        nature_Of_interest_data: res.data.nature_of_interest_options ? res.data.nature_of_interest_options : [],
                        loading: false,
                    });
                    const natureOfInterestArray = this.state.nature_Of_interest_data.map((item, idx) => ({
                        ...item,
                        value: item.nature_of_interest
                    }))
                    this.setState({
                        nature_Of_interest_data: natureOfInterestArray,
                    });
                }
                else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false })
            })
    }

    changeRelationship = (e) => {
        this.setState({ relationship: e.value })
        this.onsubmitChange(e.value, 'relationship')
        this.setState({
            relationship: e.relationship,
        })
    }

    changeRelationshipExist = (data) => {
        this.setState({ is_working_relationship_exist: data.value })
        this.onsubmitChange(data.code, 'is_working_relationship_exist')
    }

    changeDisclosed = (e) => {
        this.setState({ is_disclosed: e.value })
        this.onsubmitChange(e.code, 'is_disclosed')
    }

    removeCOIRequest = () => {
        this.onsubmitChange('', 'remove_data')
        this.props.removeCOIData()
    }

    onsubmitChange(text, type) {
        let _type = type.toString()
        let newArray = this.props.COIRequest;
        for (var i = 0; i <= this.props.length; i++) {
            if (i === this.props.index) {
                newArray[this.props.index] = {
                    ...newArray[this.props.index],
                    [_type]: text
                }
            }
        }
        if (type === 'remove_data') {
            newArray.splice(-1)
        }
        this.props.saveCOIA2Request(newArray)
    }

    handleChange = (type, value) => {
        console.log("EVENT:", value)
        if (type === "relative_full_name") {
            const regMatch = /^[a-zA-Z ]*$/.test(value);
            let typeState = type.toString()

            if (regMatch) {
                console.log("regMatch:", regMatch)
                this.setState({
                    [typeState]: value
                })
            }
            this.onsubmitChange(value, typeState)
            this.onsubmitChange(this.props.loginData.userData.Perno, 'emp_id')
        } else {
            let typeState = type.toString()
            this.setState({ [typeState]: value })
            this.onsubmitChange(value, typeState)
            this.onsubmitChange(this.props.loginData.userData.Perno, 'emp_id')
        }
    }

    changeNatureOfInterest = (e) => {
        this.setState({ nature_of_interest: e.value })
        this.onsubmitChange(e.value, 'nature_of_interest')
        this.setState({
            nature_of_interest: e.nature_of_interest,
        })
    }

    agreeCheck = () => {
        this.setState({ agree: !this.state.agree })
    }

    validate = () => {
        if (this.state.relative_full_name === '' || !this.state.relative_full_name) {
            this.setState({ error_full_name_of_relatieve: errorMessage })
        }
        else if (this.state.relative_comp_name === '' || !this.state.relative_comp_name) {
            this.setState({ error_relatieves_company_name: errorMessage })
        }
        else if (this.state.relative_comp_add === '' || !this.state.relative_comp_add) {
            this.setState({ error_relative_comp_add: errorMessage })
        }
        else if (this.state.nature_of_interest === '' || this.state.nature_of_interest === chooseItem) {
            this.setState({ error_nature_Of_interest: errorMessage })
        }
        else if (this.state.relationship === '' || this.state.relationship === chooseItem) {
            this.setState({ error_relationship: errorMessage })
        }
        else if (this.state.is_working_relationship_exist === '' || this.state.is_working_relationship_exist === chooseItem) {
            this.setState({ error_relationship_exist: errorMessage })
        }
        else if (this.state.relative_joining_date.length > 0 && new Date(this.state.relative_joining_date).getTime() > new Date().getTime()) {
            this.setState({ error_working_since: errorFutureDateMessage })
        }
        else if (this.state.is_disclosed === '' || this.state.is_disclosed === chooseItem) {
            this.setState({ error_disclosed: errorMessage })
        }

        else if (this.state.is_disclosed === 'Yes' && this.state.disclosure_description === '') {
            this.setState({ error_disclosed_to: errorMessage })
        }
        else if (this.state.agree === false) {
            toast.warn('Please check on above declaration', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.setState({ validate: false }, () => this.props.isValidate(this.state.validate))
            if (!this.state.relative_joining_date) {
                this.onsubmitChange(null, 'relative_joining_date')
            }
        }
    }

    render() {
        const { validate, relationshipExistData } = this.state
        const { length, index, isVisible, listType, isedit, relationshipDataArray } = this.props;
        return (
            <div className='w-100' style={index !== length - 1 || !isedit ? { pointerEvents: "none", opacity: "0.8", borderRadius: '3%', display: 'grid' } : { display: 'grid' }}>
                <LoaderIndicator loading={this.state.loading} />
                <div className=''>
                    {isVisible ?
                        <div className="row justify-content-end">
                            {index === length - 1 ?
                                <div
                                    className='justify-content-end'>
                                    <img
                                        alt="close"
                                        className="closeIcon"
                                        src={close}
                                        onClick={() => this.removeCOIRequest()}
                                    ></img>
                                </div>
                                : null}
                        </div>
                        : null}
                    <div className='form-heading-label font-weight-bold ml-4 mt-2'>
                        Option {this.props.type}
                    </div>
                    <div className='form-heading-label font-weight-normal mb-3 ml-4'>
                        {this.props.typeDescripton}</div>
                    <div className='mb-4 ml-2'>
                        <div className='forms-number-text mleft10 mb-1'>{this.props.type}</div>
                        <div className='type-bottom-line ml-3'></div>
                        <div className='solid-border mx-3'></div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Full name of related person</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                type="text"
                                name='relative_full_name'
                                value={!isedit && listType ? listType.relative_full_name : this.state.relative_full_name}
                                onChange={(e) => this.handleChange('relative_full_name', e.target.value)}
                            />
                            {this.state.error_full_name_of_relatieve != '' && this.state.relative_full_name === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_full_name_of_relatieve}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Relative's company name</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                type="text"
                                name='relative_comp_name'
                                value={!isedit && listType ? listType.relative_comp_name : this.state.relative_comp_name}
                                onChange={(e) => this.handleChange('relative_comp_name', e.target.value)}
                            />
                            {this.state.error_relatieves_company_name !== '' && this.state.relative_comp_name === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relatieves_company_name}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Relative's company address</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                type="text"
                                name='relative_comp_add'
                                value={!isedit && listType ? listType.relative_comp_add : this.state.relative_comp_add}
                                onChange={(e) => this.handleChange('relative_comp_add', e.target.value)}
                            />
                            {this.state.error_relative_comp_add !== '' && this.state.relative_comp_add === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relative_comp_add}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Nature of interest</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.nature_of_interest}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {this.state.nature_Of_interest_data.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeNatureOfInterest(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_nature_Of_interest !== '' && this.state.nature_of_interest === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_nature_Of_interest}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Relationship</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.relationship}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {relationshipDataArray.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeRelationship(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_relationship !== '' && this.state.relationship === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relationship}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Does working relationship exist with relative?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.is_working_relationship_exist}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {relationshipExistData.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeRelationshipExist(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_relationship_exist !== '' && this.state.is_working_relationship_exist === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relationship_exist}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Relative working since?</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="input-background px-3"
                                type="text"
                                name='relative_joining_date'
                                value={!isedit && listType ? listType.relative_joining_date : this.state.relative_joining_date}
                                onChange={(e) => this.handleChange('relative_joining_date', e.target.value)}
                            />
                            {((this.state.error_working_since !== '' && this.state.relative_joining_date.length == 0) || (new Date(this.state.relative_joining_date).getTime() > new Date().getTime())) && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_working_since}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Was this disclosed to anyone in TML?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.is_disclosed}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {this.state.disclosedData.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeDisclosed(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_disclosed != '' && this.state.is_disclosed === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_disclosed}</div>}
                        </div>
                    </div>
                    {this.state.is_disclosed === 'Yes' &&
                        <div className="mb-3 mx-4" >
                            <div className="form-label-text ml-3 mb-2">If Yes to whom and when?</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="input-background px-3"
                                    type="text"
                                    name='disclosure_description'
                                    value={!isedit && listType ? listType.disclosure_description : this.state.disclosure_description}
                                    onChange={(e) => this.handleChange('disclosure_description', e.target.value)}
                                />
                                {this.state.error_disclosed_to !== '' && this.state.disclosure_description === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_disclosed_to}</div>}
                            </div>
                        </div>
                    }
                    <div className='mb-3 mx-4'>
                        <div className='form-agree-txt d-flex align-items-start'>
                            <input
                                type="checkbox"
                                className='mx-2 mt-1'
                                checked={this.state.agree}
                                onChange={() => this.agreeCheck()}
                            />
                            {agreeMessage}
                        </div>
                    </div>
                    <div className="justify-content-end align-item-center mt-3 mb-0">
                        {validate &&
                            <div className='justify-content-between mr-4'>
                                <div
                                    type='button'
                                    className='popup-back-btn popup-back-text'
                                    onClick={() => this.props.onDismiss()}
                                >Back</div>
                                <div
                                    type='button'
                                    className='popup-confirm-btn'
                                    onClick={() => this.validate()}
                                >Validate</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestFormCardA2;