import React, { Component } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import NewNotdata from '../../../shared/notFound/NewNoData';
import right_button_arrow from '../../../../assets/drishti_images/right-button-arrow.svg'
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import './LocalConveyanceApporval.css'
import '../../employeeScreens/profile/ProfileScreen.css'
import { constantValues, employeeDetails, employeeDetailsForm, expepnseTypeLabel, readOnlyLabel } from './constant';
import { claimHistoryCommonApiFun, claimHistoryDelete } from '../../../../constants/api.service';
import { toaster } from '../../../../utils/Utils';
import NewClaimForm from './NewClaimForm';
import moment from 'moment';
import DetailExpensePopUp from './DetailExpensePopUp';
import LocalConveyanceConfirmPopUp from './LocalConveyanceConfirmPopUp';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';

const listOfProjectDetails = ["CompName", "travel_type", "from_date", "to_date", "claim_status"]
const listOfProjectDtlKeys = { CompName: "Applicant Name :", travel_type: 'Travel Type :', from_date: "From Date :", to_date: "To Date :", claim_status: "Claim Detail status :" }
let tempemployeeDetailData = []

export class LocalConveynaceClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isInstructionShow: true,
            isClaimFormOpen: false,
            showClaimHistoryList: true,
            showSelectedConveyance: '',
            showDetailsPage: false,
            EmployeeData: {},
            ManagerData: {},
            selectedUploadBill: {},
            attachmeantData: {},
            uploadBillModal: false,
            historyClaimComponentData: [],
            isDetailsExpense: { isOpen: false, data: {} },
            isDeleteClaimPopUpOpen: false
        }
    }

    componentDidMount = () => {
        this.claimLabelReset()
    }

    claimLabelSet = () => {
        let checkVarType = employeeDetails
        for (let i = 0; i < Math.ceil(checkVarType.length / 4); i++) {
            let arr = checkVarType.slice(i * 4, (i + 1) * 4)
            tempemployeeDetailData.push(arr)
        }
    }

    claimLabelReset = () => {
        tempemployeeDetailData = []
    }

    proceedToSelect = () => {
        this.setState({
            isInstructionShow: false,
            isClaimFormOpen: true
        })
        this.props.onClaimListingPage(false)
    }

    fetchClaimHistory = (claim_id, Perno) => {
        const param = {
            claim_id: claim_id,
            emp_id: Perno,
        }

        claimHistoryCommonApiFun(param, this.props, 'claim_components')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        historyClaimComponentData: res?.data?.data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        historyClaimComponentData: [],
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    goToDetailScreen = (dataValue, idx) => {
        this.setState({
            loading: true,
            showClaimHistoryList: false,
            showSelectedConveyance: dataValue,
            showDetailsPage: true,
            EmployeeData: dataValue.emp,
        }, this.props.onShowClaimStatus(false),
            this.claimLabelSet(),
            this.fetchClaimHistory(dataValue.claim_id, dataValue.emp.Perno)
        )
    }

    getAttachmentList(emp_id, claim_id, chechExpenseType) {
        const param = {
            emp_id: emp_id,
            component_id: claim_id,
        }
        this.setState({
            loading: true,
        })
        claimHistoryCommonApiFun(param, this.props, 'claim_component_attachments')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        attachmeantData: res?.data?.data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        attachmeantData: [],
                    })
                    if (chechExpenseType.expense_type == 'Consolidated allowance' || chechExpenseType.expense_type == 'Incidental allowance') {
                    }
                    else {
                        toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    attachmeantData: [],
                })
                toaster("warning", error.data.message ? error.data.message : "")
            });
    }

    onDetailExpense = (data) => {
        this.getAttachmentList(this.state.EmployeeData.Perno, data.id, data)
        this.setState({
            isDetailsExpense: { isOpen: true, data: data }
        })
    }

    clearManagerData = () => {
        this.setState({
            ManagerData: {}
        })
    }

    selectedUploadBill = (data) => {
        this.setState({ uploadBillModal: true, selectedUploadBill: data })
    }

    deletClaim = () => {
        this.setState({
            showDetailsPage: false,
            showClaimHistoryList: true
        })
        this.setState({
            loading: true,
        })
        claimHistoryDelete({}, `claim_history/test/${this.state.showSelectedConveyance.claim_id}`)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                    })
                    toaster("success", res.data.message ? res.data.message : "")
                }
                else {
                    this.setState({
                        loading: false,
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
                toaster("warning", error.message ? error.message : "")
            });
        this.claimLabelReset()
        this.props.claimHistoryPageReset()
        this.props.ClaimHistoryReCall()
        this.props.onShowClaimStatus(true)
    }

    render() {
        let instructionFlag = false;
        let { showSelectedConveyance } = this.state
        const statusList = ["Pending for Admin approval", 'Approved by Admin', 'Claim Approved & sent to Accountant for settlement', 'Claim Settled']
        return (
            <>
                <LoaderIndicator loading={this.state.loading} />
                <div className={this.state.isClaimFormOpen ? 'h-67vh scroll-auto' : 'h-60vh scroll-auto'}>
                    <>
                        {
                            this.props.claimListingPage &&
                            <>
                                {this.state.showClaimHistoryList && this.props?.claimHistoryPaginatedData?.length !== 0 && this.props?.claimHistoryPaginatedData != undefined ?
                                    this.props.claimHistoryPaginatedData.map((dataValue, idx) =>
                                        <div key={idx} className='document_card p-10px m-10px pointer flex-direction-row justify-content-between align-item-center-noflex'
                                            onClick={() => this.goToDetailScreen(dataValue, idx)}
                                        >
                                            <span>
                                                <span className='text16_bold project-list-style mx-10px '>
                                                    {dataValue.claim_id}
                                                </span>
                                                <div className='flex-direction-row'>
                                                    {
                                                        listOfProjectDetails.map((key, idx) =>
                                                            < span key={idx} className='mx-10px' >
                                                                <span className='appcenter-app-name'>{listOfProjectDtlKeys[key]}&nbsp;&nbsp;</span>
                                                                <span className='feedback-sm-text'>{key == 'from_date' || key == 'to_date' ? moment(dataValue[key]).format('DD-MM-YYYY') : key == 'CompName' ? dataValue.emp[key] : dataValue[key] ? dataValue[key] : "NA"}</span>
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </span>
                                            <span className='mr-10'>
                                                <img alt="go to details" className='' src={right_button_arrow} />
                                            </span>
                                        </div>
                                    )
                                    :
                                    this.state.showClaimHistoryList && <span className='justify-content-center align-item-center-noflex flex_1 h-100'>
                                        <NewNotdata />
                                    </span>
                                }
                                {
                                    this.state.showClaimHistoryList && this.props.claimHistoryPageNumber < this.props.claimHistoryTotalPages && this.props.claimHistoryTotalPages > 1 &&
                                    <div className='m-10px'>
                                        <div
                                            className='row col-12 d-flex justify-content-center card-for-click mx-10'
                                            onClick={() => this.props.claimHistoryMorePage()}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <label className='card-content-title white-color p-5px' >
                                                {"MORE"}
                                            </label>
                                        </div>
                                    </div>
                                }

                                {this.state.showDetailsPage &&
                                    <>
                                        <div className='justify-content-between flex-direction-row ml-10px mt-10px mb-15px'>
                                            <span className='align-item-center'>
                                                <span onClick={() => this.setState({ showDetailsPage: false, showClaimHistoryList: true }, this.clearManagerData(), this.claimLabelReset(), this.props.onShowClaimStatus(true))}>
                                                    <img alt="Back" className='mr-10 pointer' src={backArrow} />
                                                </span>
                                                <span class="card-heading">{this.state.showSelectedConveyance ? this.state.showSelectedConveyance.claim_id : ''}</span>
                                            </span>
                                            <span>
                                                {this.state.showSelectedConveyance.claim_status == "Pending for DEM approval" &&
                                                    <div className='d-flex justify-content-end align-item-center'>
                                                        <input
                                                            type="button"
                                                            className=' btn-primary common-btn declaration_button_text px-3'
                                                            value="DELETE"
                                                            onClick={() => this.setState({ isDeleteClaimPopUpOpen: true })}
                                                        ></input>
                                                    </div>
                                                }
                                            </span>
                                        </div>

                                        <div className='mt-1rem h-48vh scroll-auto'>
                                            <div className='flex-direction-column mb-1 w-97 px-20px '>
                                                <span>
                                                    <span className='appcenter-app-name1'>{constantValues.claimStatus}</span>
                                                    <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.claim_status ? this.state?.showSelectedConveyance?.claim_status : 'NA'}</span>
                                                </span>
                                            </div>
                                            <div className='flex-direction-column mb-1 w-97 px-20px '>
                                                < span className='mt-1' >
                                                    <span>
                                                        <span className='appcenter-app-name1'>{constantValues.employeeId}</span>
                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.emp?.Perno ? this.state?.showSelectedConveyance?.emp?.Perno : 'NA'}</span>
                                                    </span>
                                                    <span className='mx-20px'>
                                                        <span className='appcenter-app-name1'>{constantValues.employeeName}</span>
                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.emp?.CompName ? this.state?.showSelectedConveyance?.emp?.CompName : 'NA'}</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className='flex-direction-column mb-1 w-97 px-20px '>
                                                < span className='mt-1' >
                                                    <span>
                                                        <span className='appcenter-app-name1'>{constantValues.ApproverId}</span>
                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.dem?.Perno ? this.state?.showSelectedConveyance?.dem?.Perno : 'NA'}</span>
                                                    </span>
                                                    <span className='mx-20px'>
                                                        <span className='appcenter-app-name1'>{constantValues.ApproverName}</span>
                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.dem?.CompName ? this.state?.showSelectedConveyance?.dem?.CompName : 'NA'}</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className='flex-direction-column mb-1 w-97 px-20px '>
                                                < span className='mt-1' >
                                                    <span>
                                                        <span className='appcenter-app-name1'>{constantValues.L3Id}</span>
                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.misc_approver?.Perno ? this.state?.showSelectedConveyance?.misc_approver?.Perno : 'NA'}</span>
                                                    </span>
                                                    <span className='mx-20px'>
                                                        <span className='appcenter-app-name1'>{constantValues.L3Name}</span>
                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.misc_approver?.CompName ? this.state?.showSelectedConveyance?.misc_approver?.CompName : 'NA'}</span>
                                                    </span>
                                                </span>
                                            </div>

                                            <hr class="line-horizontal mt-1 mb-1"></hr>
                                            <span className='m-2 card-heading w-100'>{employeeDetailsForm.claimDetails}</span>
                                            <div className='flex-direction-column mb-1 w-97'>
                                                <>
                                                    {tempemployeeDetailData.map((row) => {
                                                        return (
                                                            < span className='pg-project-grid px-20px mt-1' >
                                                                {row.map((data) => {
                                                                    let { key, title } = data
                                                                    const tempValue = showSelectedConveyance[key]
                                                                    if ((key === "dem_rejection_remark" || key === "misc_rejection_remark" || key === "admin_rejection_remark") && tempValue === null) {
                                                                        return null
                                                                    }
                                                                    if (key === 'total_approved_claim_amount') {
                                                                        const claimStatus = showSelectedConveyance['claim_status'];
                                                                        if (!(claimStatus === "Pending for Admin approval" || claimStatus === "Approved by Admin" || claimStatus === "Claim Approved & sent to Accountant for settlement" || claimStatus === "Claim Settled")) {
                                                                            return null;
                                                                        }
                                                                    }
                                                                    return (
                                                                        <span>
                                                                            <span className='appcenter-app-name1'>{title}</span>
                                                                            <span className='card-content-title1 ml-5px'>{
                                                                                key == 'from_date' || key == 'to_date' && showSelectedConveyance[key]
                                                                                    ? moment(showSelectedConveyance[key]).format('DD-MM-YYYY')
                                                                                    : showSelectedConveyance[key] !== null ? showSelectedConveyance[key] : "NA"}</span>
                                                                        </span>
                                                                    )
                                                                })}
                                                            </span>
                                                        )
                                                    })
                                                    }
                                                </>
                                            </div>
                                            <hr class="line-horizontal mt-1 mb-1"></hr>
                                            <span className='m-2 card-heading w-100 '>{readOnlyLabel.expenseType}</span>
                                            {this.state?.historyClaimComponentData.length > 0 ?
                                                <>
                                                    <div className={"row justify-content-start mt-10px"}>
                                                        <div className="row scroll-y h-27vh w-100">
                                                            <table className="table gemsAdmin">
                                                                <thead className='w-100'>
                                                                    <tr className='w-100'>
                                                                        <th scope="col"><div className='thead pr-0 w-35px'>{expepnseTypeLabel.srNo}</div></th>
                                                                        <th scope="col"><div className='thead'>{expepnseTypeLabel.expenseType}</div></th>
                                                                        <th scope="col"><div className='thead'>{expepnseTypeLabel.category}</div></th>
                                                                        <th scope="col"><div className='thead'>{expepnseTypeLabel.subCategory}</div></th>
                                                                        <th scope="col"><div className='thead'>{expepnseTypeLabel.totalAmount}</div></th>
                                                                        <th scope="col"><div className='thead'>{expepnseTypeLabel.maxAmount}</div></th>
                                                                        <th scope="col"><div className='thead'>{expepnseTypeLabel.approvableAmt}</div></th>
                                                                        {statusList.includes(showSelectedConveyance['claim_status']) &&
                                                                            <th scope="col"><div className='thead'>{expepnseTypeLabel.approvedAmt}</div></th>}
                                                                        <th scope="col" style={{ 'max-width': '150px' }}><div className='thead'>{expepnseTypeLabel.remark}</div></th>
                                                                        <th scope="col"><div className='thead ml-06rem'>{expepnseTypeLabel.viewDetails}</div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='adminScreentbody w-100'>
                                                                    {this.state.historyClaimComponentData.map((listValue, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td><div className="td w-20px">{index + 1}</div></td>
                                                                                <td> <div className="td d-flex">{listValue?.expense_type ? listValue.expense_type : "NA"}</div> </td>
                                                                                <td> <div className="td d-flex">{listValue?.sub_expense_type1 ? listValue.sub_expense_type1 : "NA"}</div> </td>
                                                                                <td> <div className="td d-flex">{listValue?.sub_expense_type2 ? listValue.sub_expense_type2 : "NA"}</div> </td>
                                                                                <td> <div className="td d-flex">{listValue?.total_expense_amount ? listValue.total_expense_amount : "NA"}</div> </td>
                                                                                <td> <div className="td d-flex">{(listValue?.expense_type == 'Accommodation allowance' || listValue?.expense_type == 'Daily allowance' || listValue?.sub_expense_type1 == 'Own vehicle') ? listValue?.permissible_expense_amount != null ? listValue.permissible_expense_amount : "NA" : "NA"}</div> </td>
                                                                                <td> <div className="td d-flex">{listValue?.approvable_expense_amount !== null ? listValue.approvable_expense_amount : "NA"}</div> </td>
                                                                                {statusList.includes(showSelectedConveyance['claim_status']) &&
                                                                                    <td> <div className="td d-flex">{listValue?.approved_expense_amount !== null ? listValue.approved_expense_amount : "NA"}</div> </td>}
                                                                                <td style={{ 'max-width': '150px' }}> <div className="td d-flex">{listValue.remark ? listValue.remark : "NA"}</div> </td>
                                                                                <td className='d-flex'>
                                                                                    <div onClick={() => this.onDetailExpense(listValue)}>
                                                                                        <img alt="view" src={view_action} className='pointer mx-2' />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <span className='d-flex justify-content-center align-item-center'>
                                                    <NewNotdata />
                                                </span>
                                            }
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {
                            this.props.hideForNewClaim &&
                            <>
                                {this.state.isInstructionShow &&
                                    <>
                                        <div className='d-flex justify-content-between align-items-baseline'>
                                            <div className='justify-content-between mt-3'>
                                                <span onClick={() => this.setState({ showClaimHistoryList: true }, this.props.closeClaimInstructions())}>
                                                    <img alt="Back" className='mr-10 pointer' src={backArrow} />
                                                </span>
                                                <label className='card-heading mx-4 mt-1'>Instructions</label>
                                            </div>
                                        </div>
                                        <div className='ml-35px'>
                                            {this.props.instructionData != null ?
                                                this.props.instructionData.map((listValue, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className='row mx-4 mr-4 mt-2'>
                                                                {listValue.line.split(" ").map((text) =>
                                                                    <span>{text !== "<b>" && text !== "</b>" && !instructionFlag &&
                                                                        <h2 className='coi-sub-text mr-4px'>
                                                                            {text}
                                                                        </h2>}
                                                                        {text === "<b>" && (instructionFlag = true)}
                                                                        {text !== "<b>" && text !== "</b>" && instructionFlag &&
                                                                            <span className='highlited-text mr-4px'>
                                                                                {text}
                                                                            </span>}
                                                                        {text === "</b>" && (instructionFlag = false)}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <span className='d-flex justify-content-center align-item-center '>
                                                    <NewNotdata />
                                                </span>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center align-item-center mt-4 mb-4'>
                                            <input
                                                type="button"
                                                className=' btn-primary common-btn declaration_button_text px-3'
                                                value="PROCEED FOR SELECTION"
                                                onClick={() => this.proceedToSelect()}
                                            ></input>
                                        </div>

                                    </>
                                }

                                {
                                    this.state.isClaimFormOpen &&
                                    <NewClaimForm
                                        onDismiss={() => {
                                            this.setState({
                                                isInstructionShow: true,
                                                isClaimFormOpen: false,
                                                showDetailsPage: false,
                                                showClaimHistoryList: true
                                            })
                                            this.props.closeClaimInstructions()
                                            this.props.onLanding()
                                            this.props.onShowClaimStatus(true)
                                        }
                                        } />
                                }

                            </>
                        }
                    </>
                </div >

                {
                    this.state.isDetailsExpense?.isOpen &&
                    <DetailExpensePopUp
                        header={'Expense'}
                        type={'detailExpense'}
                        isDetailsExpense={this.state.isDetailsExpense}
                        attachmeantData={this.state.attachmeantData}
                        selectedUploadBill={this.selectedUploadBill}
                        onDismiss={e => {
                            this.setState({
                                isDetailsExpense: {
                                    isOpen: false,
                                    data: {}
                                },
                                attachmeantData: []
                            })
                        }}
                    />
                }
                {this.state.isDeleteClaimPopUpOpen &&
                    <LocalConveyanceConfirmPopUp
                        heading={'Delete Claim Request'}
                        onDismiss={() => this.setState({ isDeleteClaimPopUpOpen: false })}
                        onConfirmAction={() => {
                            this.setState({ isDeleteClaimPopUpOpen: false })
                            this.deletClaim()
                        }}
                        message={
                            <div className='d-flex flex-direction-column'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete"
                                        src={delete_popup}
                                        className="mb-1"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3">
                                    Are you sure you want to delete the claim ?
                                </div>
                            </div>
                        }
                        confirmBtnLbl="YES"
                        cancelBtnLbl="NO"
                    />
                }
            </>
        )
    }
}

export default LocalConveynaceClaim