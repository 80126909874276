import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import DeclarationPopup from '../profile/DeclarationPopup';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg';
import delete_icon from '../../../../assets/images/cfm/delete_icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import LargeDropdown from '../../../shared/components/LargeDropdown';

const chooseItem = 'Select'
class EmploymentCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisibility: false,
            loading: false,
            isEdit: false,
            openDetail: false,
            delete: false,
            countryData: [],
            cityData: [],
            sectorData: [],
            CTCData: [],
            currencyData: [],
            departmentData: [],
            companyData: [],
            categoryCodeData: [],
            reasonForLeavingData: [],
            previous_emp_name: '',
            start_date: '',
            end_date: '',
            sector: chooseItem,
            sector_code: '',
            division: chooseItem,
            division_code: '',
            city: chooseItem,
            currency: chooseItem,
            currency_code: '',
            designation: '',
            country: chooseItem,
            country_code: '',
            ctc: chooseItem,
            ctc_code: '',
            company_type: chooseItem,
            company_type_code: '',
            category: chooseItem,
            category_code: '',
            reason: chooseItem,
            reason_code: '',
        }
    }
    componentDidMount() {
        if (!this.props.isEdit)
            return;

        // value added in Array of data for dropdown render value  
        const countryArray = this.props.countryData.map((item, idx) => ({
            ...item,
            label: item.country_text
        }))

        const cityArray = this.props.cityData.map((item, idx) => ({
            ...item,
            label: item.city_text
        }))

        const sectorDataArray = this.props.sectorData ? this.props.sectorData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const CTCDataArray = this.props.CTCData ? this.props.CTCData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const currencyDataArray = this.props.currencyData ? this.props.currencyData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const departmentDataArray = this.props.departmentData ? this.props.departmentData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const companyDataArray = this.props.companyData ? this.props.companyData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const categoryCodeDataArray = this.props.categoryCodeData ? this.props.categoryCodeData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const reasonForLeavingDataArray = this.props.reasonForLeavingData ? this.props.reasonForLeavingData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []
        this.setState(
            {
                countryData: countryArray,
                cityData: cityArray,
                sectorData: sectorDataArray,
                CTCData: CTCDataArray,
                currencyData: currencyDataArray,
                departmentData: departmentDataArray,
                companyData: companyDataArray,
                categoryCodeData: categoryCodeDataArray,
                reasonForLeavingData: reasonForLeavingDataArray
            })
    }
    toggleOpenDetail = () => {
        this.setState({
            openDetail: !this.state.openDetail
        })
    }
    validateEmployeeData = () => {
        let current_Date = new Date();
        let end_date = new Date(this.state.end_date);
        let start_date = new Date(this.state.start_date);

        if (this.state.previous_emp_name == '') {
            toast.error('Please enter previous employeer name', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.previous_emp_name.length > 60) {
            toast.error('Please enter Previous Employer Name less than 60 characters only', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.start_date == '') {
            toast.error('Please select start date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (start_date > current_Date) {
            toast.error('Start date cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.start_date >= this.props.loginData.userData.Doj) {
            toast.error('Start date cannot exceed date of joining', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.end_date == '') {
            toast.error('Please select end date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (end_date > current_Date) {
            toast.error('End date cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (start_date >= end_date) {
            toast.error('Start date cannot exceed end date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.end_date >= this.props.loginData.userData.Doj) {
            toast.error('End date cannot exceed date of joining', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.sector == '' || this.state.sector == chooseItem) {
            toast.error('Please select sector', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.division == '' || this.state.division == chooseItem) {
            toast.error('Please select department/division', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.country == '' || this.state.country == chooseItem) {
            toast.error('Please select country', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.city == '' || this.state.city == chooseItem) {
            toast.error('Please select city of employment', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.ctc == '' || this.state.ctc == chooseItem) {
            toast.error('Please select CTC', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.currency == '' || this.state.currency == chooseItem) {
            toast.error('Please select currency', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.designation == '') {
            toast.error('Please enter designation', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.designation.length > 40) {
            toast.error('Please enter designation less than 40 characters only', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.company_type == '' || this.state.company_type == chooseItem) {
            toast.error('Please select company type', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.category == '' || this.state.category == chooseItem) {
            toast.error('Please select category', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.reason == '' || this.state.reason == chooseItem) {
            toast.error('Please select reason for leaving', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.showModal();
        }
    }
    showModal = () => {
        this.setState({ popupVisibility: true });
    }
    hideModal = () => this.setState({ popupVisibility: false });

    saveEmployment() {
        this.validateEmployeeData();
    }
    deleteDataPopup = () => this.setState({ popupVisibility: true, delete: true });

    handleChangePrevEmp = (value) => {
        this.setState({
            previous_emp_name: value
        });

    }
    setStartDate = (fulldate) => {
        this.setState({
            start_date: fulldate
        });
    }
    setEndDate = (fulldate) => {
        this.setState({
            end_date: fulldate
        });
    }

    changeSector = (data) => {
        this.setState({
            sector: data.value,
            sector_code: data.code
        })
    }
    changeDepartment = (data) => {
        this.setState({
            division: data.value,
            division_code: data.code
        })
    }
    handleOnSelectCountry = (item) => {
        this.setState({
            country: item.country_text,
            country_code: item.country_code
        })
    }
    changeCity = (data) => {
        this.setState({
            city: data.city_text
        })
    }

    changeCtc = (data) => {
        this.setState({
            ctc: data.value,
            ctc_code: data.code
        })
    }

    changeCurrency = (data) => {
        this.setState({
            currency: data.value,
            currency_code: data.code
        })
    }
    changeComponyType = (data) => {
        this.setState({
            company_type: data.value,
            company_type_code: data.code
        })
    }
    changeCategory = (data) => {
        this.setState({
            category: data.value,
            category_code: data.code
        })
    }
    changeReason = (data) => {
        this.setState({
            reason: data.value,
            reason_code: data.code
        })
    }

    saveEmploymentDetails = () => {
        this.setState({
            popupVisibility: false
        });
        const body = {
            previous_employer_name: this.state.previous_emp_name ? this.state.previous_emp_name : '',
            employment_start_date: this.state.start_date ? this.state.start_date : '',
            employment_end_date: this.state.end_date ? this.state.end_date : '',
            sector: this.state.sector ? this.state.sector : '',
            sector_code: this.state.sector_code ? this.state.sector_code : '',
            division: this.state.division ? this.state.division : '',
            division_code: this.state.division_code ? this.state.division_code : '',
            city: this.state.city ? this.state.city : '',
            country: this.state.country ? this.state.country : '',
            country_code: this.state.country_code ? this.state.country_code : '',
            ctc: this.state.ctc ? this.state.ctc : '',
            ctc_code: this.state.ctc_code ? this.state.ctc_code : '',
            currency: this.state.currency ? this.state.currency : '',
            currency_code: this.state.currency_code ? this.state.currency_code : '',
            designation: this.state.designation ? this.state.designation : '',
            company_type: this.state.company_type ? this.state.company_type : '',
            company_type_code: this.state.company_type_code ? this.state.company_type_code : '',
            employment_category: this.state.category ? this.state.category : '',
            employment_category_code: this.state.category_code ? this.state.category_code : '',
            reason_for_leave: this.state.reason ? this.state.reason : '',
            reason_for_leave_code: this.state.reason_code ? this.state.reason_code : ''
        }
        this.props.saveEmployeeData(body);
    }
    deleteData = () => {
        this.setState({
            popupVisibility: false
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            previous_employer_name: this.props.item.previous_employer_name ? this.props.item.previous_employer_name : '',
            employment_start_date: this.props.item.employment_start_date ? this.props.item.employment_start_date : '',
            employment_end_date: this.props.item.employment_end_date ? this.props.item.employment_end_date : '',
            sector: this.props.item.sector ? this.props.item.sector : '',
            sector_code: this.props.item.sector_code ? this.props.item.sector_code : '',
            division: this.props.item.division ? this.props.item.division : '',
            division_code: this.props.item.division_code ? this.props.item.division_code : '',
            city: this.props.item.city ? this.props.item.city : '',
            country: this.props.item.country ? this.props.item.country : '',
            country_code: this.props.item.country_code ? this.props.item.country_code : '',
            ctc: this.props.item.ctc ? this.props.item.ctc : '',
            ctc_code: this.props.item.ctc_code ? this.props.item.ctc_code : '',
            currency: this.props.item.currency ? this.props.item.currency : '',
            currency_code: this.props.item.currency_code ? this.props.item.currency_code : '',
            designation: this.props.item.designation ? this.props.item.designation : '',
            company_type: this.props.item.company_type ? this.props.item.company_type : '',
            company_type_code: this.props.item.company_type_code ? this.props.item.company_type_code : '',
            employment_category: this.props.item.employment_category ? this.props.item.employment_category : '',
            employment_category_code: this.props.item.employment_category_code ? this.props.item.employment_category_code : '',
            reason_for_leave: this.props.item.reason_for_leave ? this.props.item.reason_for_leave : '',
            reason_for_leave_code: this.props.item.reason_for_leave_code ? this.props.item.reason_for_leave_code : ''
        }
        this.props.deleteEmploymentData(body)
    }
    render() {
        const { isEdit } = this.props
        return (
            <div>
                <div>
                    {isEdit ?
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='document-common-heading common-text-color'>Add New Employment Details
                                </div>
                                {!this.state.openDetail &&
                                    <div
                                        className='justify-content-end'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                    </div>
                                }
                            </div>
                            {!this.state.openDetail &&
                                <div>
                                    <div className='hz-lines mt-2 mb-2'></div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-4 mt-2 mb-2'>Employment Details</div>
                                    </div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Previous Employer Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={60}
                                                        placeholder="Enter name"
                                                        onChange={(e) => this.handleChangePrevEmp(e.target.value)}
                                                        value={this.state.previous_emp_name}
                                                    />
                                                    <div className='p-sub-note'>{'You can add upto 60 characters only'}</div>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Start Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="date"
                                                        placeholder='Enter date'
                                                        onChange={(e) => this.setStartDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>End Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="date"
                                                        placeholder='Enter date'
                                                        onChange={(e) => this.setEndDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Sector</div>
                                                <LargeDropdown
                                                    data={this.state.sectorData}
                                                    value={this.state.sector}
                                                    selectedValue={(data) => this.changeSector(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>

                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Last Department Division</div>
                                                <LargeDropdown
                                                    data={this.state.departmentData}
                                                    value={this.state.division}
                                                    selectedValue={(data) => this.changeDepartment(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Country</div>
                                                <LargeDropdown
                                                    data={this.state.countryData}
                                                    value={this.state.country}
                                                    selectedValue={(data) => this.handleOnSelectCountry(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Last City of Employment</div>
                                                <LargeDropdown
                                                    data={this.state.cityData}
                                                    value={this.state.city}
                                                    selectedValue={(data) => this.changeCity(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>CTC (Per annum)</div>
                                                <LargeDropdown
                                                    data={this.state.CTCData}
                                                    value={this.state.ctc}
                                                    selectedValue={(data) => this.changeCtc(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Currency</div>
                                                <LargeDropdown
                                                    data={this.state.currencyData}
                                                    value={this.state.currency}
                                                    selectedValue={(data) => this.changeCurrency(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Designation</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        placeholder="Enter designation"
                                                        maxLength={40}
                                                        onChange={(e) => this.setState({ designation: e.target.value })}
                                                    />
                                                    <div className='p-sub-note'>{'You can add upto 40 characters only'}</div>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Company Type</div>
                                                <LargeDropdown
                                                    data={this.state.companyData}
                                                    value={this.state.company_type}
                                                    selectedValue={(data) => this.changeComponyType(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Category</div>
                                                <LargeDropdown
                                                    data={this.state.categoryCodeData}
                                                    value={this.state.category}
                                                    selectedValue={(data) => this.changeCategory(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Reason for Leaving</div>
                                                {/* <div className='dropdown'>
                                                    <input className='p-placeholder px-3' type='text' list='data' placeholder='select'
                                                        id="dropdownMenuButton"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false"></input>
                                                    <datalist id='data' >
                                                        {
                                                            this.state.reasonForLeavingData.map((option, i) => (
                                                                <option>{option.value}</option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div> */}
                                                <LargeDropdown
                                                    data={this.state.reasonForLeavingData}
                                                    value={this.state.reason}
                                                    selectedValue={(data) => this.changeReason(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className='row justify-content-end'>
                                            <div className='justify-content-between'>
                                                <div
                                                    type='button'
                                                    className='cancl-btn cancel-txt'
                                                    onClick={() => this.props.cancel()}
                                                >Cancel</div>
                                                <div
                                                    type='button'
                                                    className='save-btn save-txt'
                                                    onClick={this.validateEmployeeData}
                                                >Save</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='justify-content-end mt-3'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                    </div>
                                </div>}
                        </div>
                        :
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='toggle-card-heading ml-2'>
                                    {this.props.item.previous_employer_name}
                                </div>
                                {!this.state.openDetail &&
                                    <div
                                        className='justify-content-end'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img
                                            alt="hide"
                                            src={down_arrow}
                                            className="up_arrow"
                                        ></img>
                                    </div>
                                }
                            </div>
                            {this.state.openDetail &&
                                <div>
                                    <div className='hz-lines mt-2 mb-2'></div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-2 mt-2 mb-2'>Employment Details</div>
                                        <div
                                            className='mr-2'
                                            onClick={this.deleteDataPopup}
                                        >
                                            <img
                                                alt="delete_icon"
                                                src={delete_icon}
                                                className="up_arrow"
                                            ></img>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Previous Employer Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.previous_employer_name} readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Start Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={moment(this.props.item.employment_start_date).format("DD-MM-YYYY")}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>End Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={moment(this.props.item.employment_end_date).format("DD-MM-YYYY")}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Sector</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.sector}
                                                        readOnly={true}
                                                        placeholder='Sector'
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Last Department Division</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.division}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Country</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.country}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Last City of Department</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.city}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>CTC (Per annum)</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.ctc}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Currency</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.currency}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Designation</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.designation}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Company Type</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.company_type}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Category</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.employment_category}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Reason for Leaving</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.reason_for_leave}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='justify-content-end mt-3'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={this.hideModal}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveEmploymentDetails}
                        deleteDetails={this.deleteData}
                        delete={this.state.delete} />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(EmploymentCards);