import React, { useContext, useEffect, useRef, useState } from 'react'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import DateFilter from './DateFilter'
import ClearanceTable from './ClearanceTable'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import { Pagination } from 'react-bootstrap'
import { RoleContext } from './Contexts'
import { addDays, subtractDays } from '../../managerScreens/approval/commonUtils'
import { traineeRequestsCol } from './constants'
import { exportToExcel, toaster } from '../../../../utils/Utils'
import Axios from 'axios'
import { clearanceBulkTemplates } from '../../../../constants/Messages'
import * as XLS from "xlsx";
import { configStore } from '../../../../redux/ConfigStore'
import { fetchTraineeEmployeeClearance,uploadTraineeEmployeeClearance } from '../../../../constants/api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'

function ClearanceForTrainee(props) {
    const filterMaster = useContext(RoleContext)
    const [tableData, setTableData] = useState([])
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [viewFilter, setViewFilter] = useState(null)
    const [filterVal, setFilterVal] = useState({ id: 0, value: 0, title: "Pending" })
    const maxChunkSize = 10
    const initialRenderRef = useRef(true)

    useEffect(() => {
        if (initialRenderRef.current == true) {
            initialRenderRef.current = false
        } else {
            setCurrentPage(1)
            setTotalPages(1)
            TraineeRequests(1)
        }
    }, [filterVal, viewFilter, fromDate, toDate])

    useEffect(() => {
        setViewFilter(filterMaster.cleRequestViewFilter[0])
    }, [filterMaster])

    const handleViewFilter = (data) => {
        setViewFilter(data)
    }
    const handlePageCount = (action) => {
        if (action === "decrement" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            TraineeRequests(currentPage - 1)
        } else if (action === "increment" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            TraineeRequests(currentPage + 1)
        }
    };

    const TraineeRequests = async (currentPage) => {
        setLoading(true)
        let params = {
          comp_code: configStore.getState()?.adminLoginState?.company?.company_code,
          agency_config_id: viewFilter?.value,
          from_date: fromDate,
          to_date: toDate,
          page_number: currentPage,
        //   is_download: false
        }
        await fetchTraineeEmployeeClearance(params, props)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              const { paginated_data, total_pages } = res.data.data
              setTableData(paginated_data)
              setTotalPages(total_pages)
            }
            else {
              setTableData([])
              setTotalPages(0)
              res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "Something went wrong")
            }
          })
          .catch((error) => {
            console.log("error in get Trainee request API", error)
            setTableData([])
            setTotalPages(0)
            error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "Something went wrong")
          })
          .finally(() => { setLoading(false) })
    }

    const bulkUploadApi  = async (bulkList) => {
        // let chunks = Math.ceil(bulkList?.data?.length / maxChunkSize)
        // console.log("chunks",chunks)

        // let chunkList = []
        // if (chunks > 1) {
        //     for (let i = 0; i < chunks; i++) {
        //         chunkList.push(bulkList?.data?.slice(i * maxChunkSize, (i * maxChunkSize) + maxChunkSize))
        //     }
        // }
        // else{
        //     chunkList = bulkList?.data?.slice(0, maxChunkSize)
        // }

        let body = {
            "agency_id": String(viewFilter?.agency_id),
            "comp_code":configStore.getState()?.adminLoginState?.company?.company_code,
            "data": bulkList?.data
        }

        await uploadTraineeEmployeeClearance(body)
        .then((res) => {
            setLoading(true)
            if (res.status === 200 || res.status === 201) {
                // Adding setTimeout because data is validating in backend after that sending to mail. now we need updated record so we call function here using setTimeout.
                setTimeout(() => {
                    toaster("success", res?.data?.message ? res.data.message : "Something went wrong")
                    TraineeRequests(1)
                }, 3000);
            }
            else {
                res?.status != 404 && 
                toaster("warning", res.data.message ? res.data.message : "Something went wrong")
                setLoading(false)
            }
        })
        .catch((error) => {
          console.log("error in get clearance request API", error)
          setLoading(false)
          error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "Something went wrong")
        })
        // .finally(() => { setLoading(false) })
    }
    
    const downloadTemplate = () => {
        setLoading(true)
        let params = {
          comp_code: configStore.getState()?.adminLoginState?.company?.company_code,
          agency_config_id: viewFilter?.value,
          from_date: fromDate,
          to_date: toDate,
          page_number: currentPage,
          is_download: true
        }
        fetchTraineeEmployeeClearance(params, props)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              const { paginated_data, total_pages } = res.data.data
              if (paginated_data.length > 0) {
                let colNames = [clearanceBulkTemplates.colNames]
                let formattedData = paginated_data.map((data, idx) => {
                    return {
                        "SR NO": idx + 1,
                        "Employee ID": data?.Perno,
                        "Recovery Amount (upto 2 decimals)": "",
                        "Payment Amount (upto 2 decimals)": "",
                        "Remark": ""
                    }
                })
                toaster("success", res?.data?.message ? res.data.message : "Something went wrong")
                exportToExcel(clearanceBulkTemplates.fileName, clearanceBulkTemplates.ext, formattedData, colNames)
                }
                if (paginated_data.length == 0) {
                    toaster("warning", "No records found to download template")
                }
            }
            else {
              res?.status != 404 && 
              toaster("warning", res.data.message ? res.data.message : "Something went wrong")
            }
          })
          .catch((error) => {
            console.log("error in get clearance request API", error)
            error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "Something went wrong")
          })
          .finally(() => { setLoading(false) })
    }
    const bulkUpload = (event) => {
        let file = event?.target?.files?.[0];
        if (file) {
            let fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                let arrayBuffer = fileReader.result;
                var data = new Uint8Array(arrayBuffer);
                // console.log("add file data:",data)
                var arr = new Array();
                for (var i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                var bstr = arr.join("");
                var workbook = XLS.read(bstr, { type: "binary" });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                // console.log("final XLS:",XLS.utils.sheet_to_json(worksheet, { raw: true }));
                let colNames = [clearanceBulkTemplates.colNames]
                var sheetData = XLS.utils.sheet_to_json(worksheet, { header: 1 });
                let headers = sheetData.length > 0 ? sheetData[0] : [];
        

                if (!areListsEqual(headers, colNames[0])){
                    return toaster("warning", "Please download the template, update and upload same document. ")
                }

                var arraylist = XLS.utils.sheet_to_json(worksheet, { raw: true });
                let bulkClearanceList = arraylist.map((data) => {
                    return {
                        emp_id: data?.["Employee ID"] ?? null,
                        pay_amount: data?.["Payment Amount (upto 2 decimals)"] ?? null,
                        recovery_amount: data?.["Recovery Amount (upto 2 decimals)"] ?? null,
                        remark: data?.["Remark"] ?? null
                    }
                })
                bulkUploadApi({data: bulkClearanceList})
            }
        };

    }

    const areListsEqual = (list1, list2) => {
        if (list1.length !== list2.length) return false; 
    
        let sortedList1 = list1.map(item => item.toLowerCase().trim()).sort();
        let sortedList2 = list2.map(item => item.toLowerCase().trim()).sort();
    
        return JSON.stringify(sortedList1) === JSON.stringify(sortedList2);
    };        
    
    return (
            <div className='flex-direction-column'>
                <LoaderIndicator loading={loading} />

                {/* ----------------------------------------------------------- filters ---------------------------------------------------------------------- */}
                <div className='row justify-content-between background-highlighter'>
                    <div className='row'>
                        <div className='ml-2'>
                            <DateFilter title="From Date" action={(val) => setFromDate(val)} value={fromDate} minDate={subtractDays(new Date(), filterMaster.dateRange.minDay)} maxDate={addDays(new Date(), 60)} />
                        </div>
                        <div className='ml-2'>
                            <DateFilter title="To Date" action={(val) => setToDate(val)} value={toDate} minDate={subtractDays(new Date(), filterMaster.dateRange.maxDays)} maxDate={addDays(new Date(), 60)} />
                        </div>
                        {
                            viewFilter &&
                            <div className='flex-direction-row gap-10px align-item-center'>
                                <div className='p-sub-heading ml-2'>{"Select Agency View"}</div>
                                <div className='d-flex align-item-center'></div>
                                <DynamicDropdown
                                    data={filterMaster.cleRequestViewFilter}
                                    render="title"
                                    value={viewFilter.title}
                                    selectedValue={(data) => handleViewFilter(data)}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                />
                            </div>
                        }
                    </div>
                    
                    <div className="">
                        <input type='button' value={'DOWNLOAD TEMPLATE'} className='ml-2 validateBtn' onClick={downloadTemplate} />
                        <label className='ml-2 validateBtn' onChange={bulkUpload} >
                            <input
                                id="Attachment"
                                style={{ display: "none" }}
                                type="file"
                                accept=".csv,.xls,.xlsx"
                                onClick={(e) => (e.target.value = "")}
                            />
                            {"BULK UPLOAD"}
                        </label>
                    </div>
                </div>

                {/* ------------------------------------------------------------ Table ----------------------------------------------------------------------- */}
                <div className='flex-direction-column scroll-auto clearance-table mt-10px'>
                    {
                        tableData && tableData.length > 0 ?
                            <ClearanceTable
                                data={tableData}
                                columns={traineeRequestsCol}
                                hasAction={false}
                                currentPage={currentPage}
                                pageSize={10}
                                screenType={"Clearance Request"}
                            />
                            :
                            <div className='mt-5 h-90-percent'>
                                <DataNotFound />
                            </div>
                    }
                </div>
                {tableData.length > 0 &&
                        <div className="col-md-12 w-100 d-flex justify-content-center paginator">
                            <Pagination>
                                {currentPage != 1 &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => { handlePageCount("decrement") }} />
                                        <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                                        <Pagination.Ellipsis disabled />
                                    </>
                                }
                                <Pagination.Item active>{currentPage}</Pagination.Item>
                                {currentPage != totalPages &&
                                    <>
                                        <Pagination.Ellipsis disabled />
                                        <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                                        <Pagination.Next
                                            onClick={() => { handlePageCount("increment") }} />
                                    </>
                                }
                            </Pagination>
                        </div>
                    }

            </div>
           
    )
}

export default ClearanceForTrainee