import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { location_change_data, transfer_request_region, time_management_status_data } from '../../../../../utils/Utils';
import { requestFailed } from '../../../../../constants/Messages';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';

const selectDefault = 'Select'
class ApplyPhase3 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            screenwidth: 0,
            screenheight: 0,
            showConfirm: false,
            deputation: false,
            transferRequestData: [],
            height: '',
            reasonData: [],
            swapData: [{ value: 'Yes' }, { value: 'No' }],
            locationChangeData: location_change_data(),
            regionData: transfer_request_region(),
            timeManagementStatusData: time_management_status_data(),
            swipping_location: '',
            swap: 'No',
            proposed_date_of_transfer: '',
            end_date_of_transfer: '',
            location: selectDefault,
            region: selectDefault,
            wsr: selectDefault,
            swipping_location: selectDefault,
            function_updated: selectDefault,
            sub_function_1_updated: selectDefault,
            sub_function_2_updated: selectDefault,
            psa_updated_text: '',

            newPositionData: [],
            locationCodeData: [],
            functionData: [],
            paData: [],
            psaData: [],
            subFunction1Data: [],
            subFunction2Data: [],
            label: '',
            psa: selectDefault,
            pa: selectDefault,
            timeValue: selectDefault,
            timeCode: '',
            locationChangeValue: selectDefault,
            locationChangeCode: '',
            reasonForTransfer: selectDefault,
            actionTypeCode: '',
            actionTypeValue: '',
            value: '',
            newReasonData: [],
            previousStage1: false,
            isTransferDetailsActive : false
        }
    }

    componentDidMount() {
        this.setState({
            paData: this.props.paData,
            functionData: this.props.functionData,
            locationCodeData: this.props.locationCodeData,
        });

        const functionArray = this.props.functionData.map((item, idx) => ({
            ...item,
            label: item.function_id_text
        }))

        const paArray = this.props.paData.map((item, idx) => ({
            ...item,
            label: item.pa_text
        }))

        const locationArray = this.props.locationCodeData.map((item, idx) => ({
            ...item,
            label: item.location
        }))

        this.setState({
            functionData: functionArray,
            paData: paArray,
            locationCodeData: locationArray,
        });
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'transferRequest')
    }

    handleChangePA = (data) => {
        this.setState({
            pa: data.pa_text,
        })
        this.getPSAData(data.pa_text)
    }

    handleChangePSA = (data) => {
        this.setState({
            psa: data.psa_txt,
        }, () => this.getWsrSwippingLocationData())
    }

    handleChangeRegion = (data) => {
        this.setState({ region: data.value })
    }

    handleChangeSubFunction2 = (data) => {
        this.setState({
            sub_function_2_updated: data.sub_function_2_text,
        })
    }

    handleChangeSubFunction1 = (data) => {
        this.setState({
            sub_function_1_updated: data.sub_function_1_text,
        }, () => this.getSubFunction2(data.sub_function_1_text))
    }

    handleChangeFunction = (data) => {
        this.setState({
            function_updated: data.function_id_text,
        })
        this.getSubFunction1(data.function_id_text)
    }

    handleChangeLocationCode = (data) => {
        this.setState({
            location: data.location,
        })
    }

    handleChangeLocation = (e, listValue) => {
        this.setState({
            locationChangeValue: listValue.value,
            locationChangeCode: listValue.code
        }, () => this.getReasonForTransfer(listValue.value))
    }

    handleChangeTimeManagement = (e, listValue) => {
        this.setState({
            timeValue: listValue.value,
            timeCode: listValue.code
        })
    }

    handleChangereason = (e, listValue) => {
        this.setState({
            reasonForTransfer: listValue.value,
            actionTypeCode: listValue.action_type,
            actionTypeValue: listValue.name_of_action_type
        })
        if (listValue.action_type === 'ZD') {
            this.setState({ deputation: true })
        }
        else {
            this.setState({ deputation: false })
        }
    }

    getPSAData = (PA) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            pa_code: PA,
        }
        fetchApiCall(Config.getPersonalSubAreaMasterOfTransfer, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        psaData: res.data.psa_code ? res.data.psa_code : []
                    });
                    const psaArray = this.state.psaData.map((item, idx) => ({
                        ...item,
                        id: idx,
                        label: item.psa_txt
                    }))
                    this.setState({ psaData: psaArray })
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                        psaData: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    getReasonForTransfer = (locationChange) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            location_opt: locationChange === 'Location Change' ? 'change' : 'nochange',
        }
        fetchApiCall(Config.getReasonForTransfer, body, headers)
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        reasonData: res.data.data ? res.data.data : [],
                    });
                    const reasonArray = this.state.reasonData.map((item, idx) => ({
                        ...item,
                        value: item.name_of_reason_for_action
                    }))
                    this.setState({ newReasonData: reasonArray })
                } else {
                    this.setState({
                        loading: false,
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                });
            })
    }

    getSubFunction1 = (functionValue) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            function_id_text: functionValue,
        }
        fetchApiCall(Config.getSubFunction1, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        subFunction1Data: res.data.sub_function_1 ? res.data.sub_function_1 : []
                    });
                    const subfunction1Array = this.state.subFunction1Data.map((item, idx) => ({
                        ...item,
                        id: idx,
                        label: item.sub_function_1_text
                    }))
                    this.setState({
                        subFunction1Data: subfunction1Array
                    })
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    getSubFunction2 = (subFunction1ID) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            function_id_text: this.state.function_updated,
            sub_function_1_text: subFunction1ID
        }
        fetchApiCall(Config.getSubFunction2, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        subFunction2Data: res.data.sub_function_2 ? res.data.sub_function_2 : []
                    });
                    const subfunction2Array = this.state.subFunction2Data.map((item, idx) => ({
                        ...item,
                        id: idx,
                        label: item.sub_function_2_text
                    }))
                    this.setState({
                        subFunction2Data: subfunction2Array
                    })
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    getWsrSwippingLocationData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            pa_code: this.state.pa,
            psa_code: this.state.psa
        }
        fetchApiCall(Config.getWsrSwippingLocationPaPsaMapping, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        wsr: res.data.wsr_code,
                        swipping_location: res.data.swiping_location,
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                });
            })
    }

    submitTransferRequest = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {

            emp_id: this.props.emp_id,
            transfer_emp_name: this.props.transfer_emp_name,
            location_change: this.state.locationChangeValue,
            position_code: this.props.newPositionDataToTransfer.position_code,
            reason_for_transfer: this.state.reasonForTransfer,
            proposed_date_of_transfer: this.props.newPositionDataToTransfer.transfer_date,
            end_date_of_transfer: this.state.deputation ? this.state.end_date_of_transfer : '',
            action_type: this.state.actionTypeCode,
            location: this.state.location,
            region: this.state.region,
            time_management_status: this.state.timeValue,
            pa: this.state.pa,
            psa: this.state.psa,
            wsr: this.state.wsr,
            swiping_location: this.state.swipping_location,
            function: this.state.function_updated,
            sub_function_1: this.state.sub_function_1_updated,
            sub_function_2: this.state.sub_function_2_updated,
            approving_bhr: this.props.current_bhr,
            is_deputation: this.state.deputation,
            created_by: this.props.loginData.userData.Perno,
            position_bhr_id: this.props.loginData.userData.Perno,
            modified_by: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.submitTransferRequest, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                        showConfirm: false,
                    });
                    this.props.setPhase3Complete()
                    setTimeout(() => { window.location.reload() }, 4000)
                    this.gotoTransferDashboard()
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                        showConfirm: false
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false,
                    showConfirm: false
                })
                toast.warn(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
    }

    gotoTransferDashboard = () => {
        window.location = (BASE_WEB_URL_DEV + 'transfer')
    }

    ValidateForm = () => {
        var transferDate = new Date(this.props.newPositionDataToTransfer.transfer_date).setHours(0, 0, 0, 0);
        var endDate = new Date(this.state.end_date_of_transfer).setHours(0, 0, 0, 0);

        if (this.state.function_updated === selectDefault || this.state.function_updated === '') {
            toast.warn('Please enter function', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.sub_function_1_updated === '' || this.state.sub_function_1_updated === selectDefault) {
            toast.warn('Please enter sub function 1', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.sub_function_2_updated === '' || this.state.sub_function_2_updated === selectDefault) {
            toast.warn('Please enter sub function 2', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.region === '' || this.state.region === selectDefault) {
            toast.warn('Please select region', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.pa === '') {
            toast.warn('Please enter Personnel Area', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.timeValue === '' || this.state.timeValue === selectDefault) {
            toast.warn('Please select time management status', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.psa === selectDefault) {
            toast.warn('Please enter Personnel Subarea', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.locationChangeValue === '' || this.state.locationChangeValue === selectDefault) {
            toast.warn('Please select location change', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.location === selectDefault || this.state.location === '') {
            toast.warn('Please enter location', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.reasonForTransfer === '' || this.state.reasonForTransfer === selectDefault) {
            toast.warn('Please select reason for transfer', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.deputation && this.state.end_date_of_transfer === '') {
            toast.warn('Please enter end date of transfer', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.deputation && endDate < transferDate) {
            toast.warn('End date could not be less than transfer date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else {
            this.submitTransferRequest()
        }
    }

    render() {
        return (
            <div className="justify-content-between">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0" >
                    <div>
                        <div className='row justify-content-between'>
                            <div className='p-heading mx-4 mt-2 mb-2'>Enter transfer details</div>
                        </div>
                        <div className='row'>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Releasing BHR</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        placeholder={this.props.current_bhr}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Position Code</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.props.newPositionData.position1}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Function</div>
                                <LargeDropdown
                                    data={this.state.functionData}
                                    value={this.state.function_updated}
                                    selectedValue={(data) => this.handleChangeFunction(data)}
                                />
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Sub Function 1</div>
                                <LargeDropdown
                                    data={this.state.subFunction1Data}
                                    value={this.state.sub_function_1_updated}
                                    selectedValue={(data) => this.handleChangeSubFunction1(data)}
                                />
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Sub Function 2</div>
                                <LargeDropdown
                                    data={this.state.subFunction2Data}
                                    value={this.state.sub_function_2_updated}
                                    selectedValue={(data) => this.handleChangeSubFunction2(data)}
                                />
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Region</div>
                                <div className='dropdown'>
                                    <button
                                        className='btn p-placeholder d-flex justify-content-between'
                                        type='button' id="dropdownMenuButton"
                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {this.state.region}
                                        <RiArrowDropDownLine
                                            size={25}
                                            color={'rgb(185 188 197)'}
                                        />
                                    </button>
                                    <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                        {this.state.regionData.map((listValue, i) => {
                                            return (
                                                <label key={i} className='dropdown-item' onClick={() => this.handleChangeRegion(listValue)}>
                                                    {listValue.value}
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Personnel Area</div>
                                <LargeDropdown
                                    data={this.state.paData}
                                    value={this.state.pa}
                                    selectedValue={(data) => this.handleChangePA(data)}
                                />
                            </div>
                            <div className='box col-4 mb-3 mt-2'>
                                <label className='p-sub-heading ml-3'>Time Management Status</label>
                                <div className='dropdown'>
                                    <button
                                        className='btn p-placeholder h-42px d-flex justify-content-between'
                                        type='button' id="dropdownMenuButton"
                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {this.state.timeValue}
                                        <RiArrowDropDownLine
                                            size={25}
                                            color={'rgb(185 188 197)'}
                                        />
                                    </button>
                                    <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                        {this.state.timeManagementStatusData.map((listValue, index) => {
                                            return (
                                                <label
                                                    key={index}
                                                    onClick={(e) => this.handleChangeTimeManagement(e, listValue)}
                                                    className='dropdown-item'>{listValue.value}
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Personnel Sub Area</div>
                                <LargeDropdown
                                    data={this.state.psaData}
                                    value={this.state.psa}
                                    selectedValue={(data) => this.handleChangePSA(data)}
                                />
                            </div>
                            {this.state.swipping_location &&
                                <div className='col-4 mb-3 mt-2'>
                                    <div className='p-sub-heading ml-3'>Swiping Location</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type='text'
                                            placeholder={this.state.swipping_location}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            }
                            <div className='box col-4 mb-3 mt-2'>
                                <label className='p-sub-heading ml-3'>Location Change</label>
                                <div className='dropdown'>
                                    <button
                                        className='btn p-placeholder h-42px d-flex justify-content-between'
                                        type='button' id="dropdownMenuButton"
                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        {this.state.locationChangeValue}
                                        <RiArrowDropDownLine
                                            size={25}
                                            color={'rgb(185 188 197)'}
                                        />
                                    </button>
                                    <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                        {this.state.locationChangeData.map((listValue, index) => {
                                            return (
                                                <label
                                                    key={index}
                                                    onClick={(e) => this.handleChangeLocation(e, listValue)}
                                                    className='dropdown-item'>{listValue.value}
                                                </label>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Location</div>
                                <LargeDropdown
                                    data={this.state.locationCodeData}
                                    value={this.state.location}
                                    selectedValue={(data) => this.handleChangeLocationCode(data)}
                                />
                            </div>
                            {this.state.newReasonData != 0 &&
                                <div className='box col-4 mb-3 mt-2'>
                                    <label className='p-sub-heading ml-3'>Reason For Transfer</label>
                                    <div className='dropdown'>
                                        <button
                                            className='btn p-placeholder h-42px d-flex justify-content-between'
                                            type='button' id="dropdownMenuButton"
                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {this.state.reasonForTransfer}
                                            <RiArrowDropDownLine
                                                size={25}
                                                color={'rgb(185 188 197)'}
                                            />
                                        </button>
                                        <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                            {this.state.newReasonData.map((listValue, index) => {
                                                return (
                                                    <label
                                                        key={index}
                                                        onClick={(e) => this.handleChangereason(e, listValue)}
                                                        className='dropdown-item'>{listValue.value}
                                                    </label>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.actionTypeValue &&
                                <div className='col-4 mb-3 mt-2'>
                                    <div className='p-sub-heading ml-3'>Action Type</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type='text'
                                            placeholder={this.state.actionTypeValue}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            }
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Start Date of Transfer</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.props.newPositionDataToTransfer.transfer_date != '' ? moment(this.props.newPositionDataToTransfer.transfer_date).format("DD-MM-YYYY") : ''}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            {this.state.deputation &&
                                <div className='col-4 mb-3 mt-2'>
                                    <div className='p-sub-heading ml-3'>End Date of Transfer</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type='date'
                                            value={this.state.end_date_of_transfer}
                                            onChange={(e) => this.setState({ end_date_of_transfer: e.target.value })}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='row justify-content-end'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() => this.gotoHome()}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={() => this.ValidateForm()}
                                >Submit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ApplyPhase3