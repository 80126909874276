import React from 'react'
import { tdStyle } from '../localConveyance/constants';
import ViewIcon from '../../../../assets/drishti_images/view.svg';
import AddIcon from '../../../../assets/drishti_images/addTableBtn.svg';
import './Clearance.css';
import CommonButton from '../../../shared/button/GradientButton';
import { DDMMMYY, DDMMYYYY } from '../../../../utils/Utils';
import moment from 'moment';
import { dontHighlightFNF } from './constants';
import eye from '../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../assets/drishti_images/eye-slash.svg';

function ClearanceTable(props) {
    const {
        data,
        columns,
        hasAction = true,
        handleAction,
        screenType = "",
        hasPrimaryAction = true,
        hasThirdAction,
        handleAllContact,
        handleAllEmail,
        hasSecondaryAction,
        handleSecondaryAction,
        isAllContactVisible,
        isAllEmailVisible,
        handleThirdAction,
        currentPage,
        pageSize,
        primaryActionImg = AddIcon,
        secondaryActionImg = ViewIcon,
        scrollEnabled = true,
        isFilterOpen = false,
        action
    } = props

    const handleViewAction = (index) => {
        handleAction(index)
    }
    
    return (
        <>
            <div className={(action === 'empDashboardTable' ? "scroll-y " : " ")}>
                <div className="main-contain">
                    <div className="row align-item-center justify-content-start">
                        <table className="table gemsAdmin">
                            <thead className='w-100'>
                                <tr className='w-100'>
                                    <th width={"5%"}><div className='thead'>Sr No.</div></th>
                                    {columns.map((col) => {
                                        return (
                                            <th width={col.width}>
                                                <div className="align-item-center flex-start">
                                                    <div className="thead">{col.title}</div>
                                                    {['Contact', 'Email'].includes(col.title) && (
                                                        <div className="align-item-center ml-5px" onClick={()=>{col.title === 'Contact' ? handleAllContact(isAllContactVisible) : handleAllEmail(isAllEmailVisible)}}>
                                                            <img
                                                                alt="eyeImage"
                                                                src={col.title === 'Email' ? (isAllEmailVisible ? eye : eyeslash) : (isAllContactVisible ? eye : eyeslash)}
                                                                style={{ width: '1rem', cursor: 'pointer' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </th>
                                        )
                                    })}
                                    {
                                        hasAction &&
                                        <th width={"5%"}><div className='thead'>Action</div></th>
                                    }
                                </tr>
                            </thead>
                            <tbody className='w-100'>
                                {data.map((row, index) => {
                                    return (
                                        <>
                                            <tr className={((!dontHighlightFNF.some((screen)=>screen == screenType) && row?.agency_name && row.agency_name == "F & F Settlement") && "bg-light-gray-3")}>
                                                <td width='5%'><div className={tdStyle}>{(currentPage ? (currentPage - 1) * pageSize : 0) + (index + 1)}</div></td>
                                                {columns.map((col) => {
                                                    let key = col.key
                                                    let subKey = col.subKey
                                                    let type = col.type
                                                    return (
                                                        <td width={col.width}>
                                                            <pre className={["unineue-font "+tdStyle + `${row[key] == "Pending" ? " pending" : row[key] == "Completed" ? " completed" : row[key] == "Revoked" ? " revoked" : row[key] == "Reopen" ? " reopen" : ""}`]}>{
                                                                type == "date" ? subKey ?
                                                                    DDMMMYY(row[key][subKey]) ?? "--"
                                                                    : DDMMMYY(row[key]) ?? '--'
                                                                    :
                                                                    type == "dateTime" ? row[key] ? moment(row[key]).format("DD-MM-YYYY HH:mm") : '--' :
                                                                        type == "boolean" ? row[key] == true ? "Yes" : "No" :
                                                                            subKey ?
                                                                                row[key][subKey] ?? "--"
                                                                                : row[key] ?? '--'
                                                            }</pre>
                                                        </td>
                                                    )
                                                })
                                                }
                                                {
                                                    hasAction &&
                                                    <td style={screenType == 'ClearanceInitiate' ? { width: '20%' } : { width: '5%' }} >
                                                        <div className='flex gap-10'>
                                                            {hasPrimaryAction && (screenType !== "Clearance Request" || (row?.is_agency_applicable_for_release == true && row?.is_applicable_for_additional_remarks == true)) &&
                                                                <span className='d-flex'>
                                                                    <div onClick={() => handleViewAction(index)}>
                                                                        {
                                                                            screenType == 'ClearanceInitiate' ?
                                                                                <CommonButton
                                                                                    label="START CLEARANCE"
                                                                                    onClick={() => { }}
                                                                                    isGradientBtn={true}
                                                                                />
                                                                                :
                                                                                <div>
                                                                                    <img alt="view" src={primaryActionImg} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </span>
                                                            }
                                                            {hasSecondaryAction ?
                                                                <span className='d-flex'>
                                                                    <div onClick={() => handleSecondaryAction(index)}>
                                                                        <img alt="view" src={secondaryActionImg} style={{ width: '1.5625rem', cursor: 'pointer' }} />
                                                                    </div>
                                                                </span> : null
                                                            }
                                                            {/* {hasThirdAction ?
                                                                <span className='d-flex'>
                                                                    <div onClick={() => {
                                                                        handleThirdAction(index)
                                                                    }}>
                                                                        <img alt="eyeImage" src={row.is_unmasked ? eye : eyeslash} style={{ width: '1rem', cursor: 'pointer' }} />
                                                                    </div>
                                                                </span> : null
                                                            } */}
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClearanceTable