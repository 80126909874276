import React, { useEffect, useState } from 'react'
import ClearanceTable from './ClearanceTable'
import { initiateTableCol } from './constants'
import DataNotFound from '../../../shared/notFound/DataNotFound';
import DateFilter from './DateFilter';
import { Pagination } from 'react-bootstrap'
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import { addDays, subtractDays } from '../../managerScreens/approval/commonUtils';
import { getStartClearanceRequests, startClearance } from '../../../../constants/api.service';
import { toaster } from '../../../../utils/Utils';
import ConfirmationPopUp from './ConfirmationPopUp';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import { someThingWentWrong } from '../../../../constants/Messages';

function ClearanceInitiation(props) {
  const { screenType } = props;
  const [tableData, setTableData] = useState([])
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [empId, setEmpId] = useState("")
  const [selectedEmployee, setSelectedEmployee] = useState([])

  useEffect(() => {
    setCurrentPage(1)
    setTotalPages(1)
    fetchClearanceRequests(currentPage)
  }, [fromDate, toDate])

  const fetchClearanceRequests = (page) => {
    setTableData([])
    setLoading(true)
    let params = {
      emp_id: empId,
      from_date: fromDate,
      to_date: toDate,
      page_number: page,
    }
    getStartClearanceRequests(props, params)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setTableData(res.data.data.paginated_data)
          setTotalPages(res.data.data.total_pages)
        }
        else {
          res?.status !== 404 && toaster("warning", res?.data?.message ?? someThingWentWrong)
          setTableData([])
        }
      }
      )
      .catch((err) => {
        err?.status !== 404 && toaster("warning", err?.data?.message ?? someThingWentWrong)
        console.log("error in get Start clearance api", err)
        setTableData([])
      })
      .finally(() => { setLoading(false) })
  }

  const searchFilterFunction = () => {
    setCurrentPage(1)
    setTotalPages(1)
    fetchClearanceRequests(currentPage)
  }

  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchClearanceRequests(currentPage - 1)
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      fetchClearanceRequests(currentPage + 1)
    }
  }

  const handleAction = (index) => {
    setSelectedEmployee(tableData[index])
  }

  const backAction = () => {
    setSelectedEmployee([])
  }

  const handleConfirmation = () => {
    setLoading(true)
    let req_body = {
      "emp_id": selectedEmployee.employee_id,
      "comp_code": selectedEmployee.employee_comp_code
    }
    startClearance(props, req_body)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setSelectedEmployee([])
          setCurrentPage(1)
          setTotalPages(1)
          fetchClearanceRequests(1)
          toaster("success", res.data.message)
        }
        else { res?.status !== 404 && toaster("warning", res.data.message) }
      })
      .catch((err) => {
        err?.status !== 404 && toaster("warning", err?.data?.message)
        console.log("error in Start clearance api", err)
      })
      .finally(() => { setLoading(false) })
  }

  const handleEmpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setEmpId(e.target.value)
    }
}

  return (
    <div className='flex-direction-column'>
      <div className='row align-item-center justify-content-between ml-2 background-highlighter'>
        <div className='row'>
          <div className='row ml-4'>
            <div className='p-placeholder-text mr-3'>
              <input
                className="form-control p-placeholder px-2 p-placeholder-admin"
                type="text"
                placeholder='Enter emp id'
                onChange={(e) => handleEmpChange(e)}
                value={empId}
              />
            </div>
            <div type='button' className='serchIconSize' onClick={() => searchFilterFunction()}
            ><div className='mr-4'><img alt="radioOn" className='filterIcon' src={searchIcon} ></img></div>
            </div>
          </div>
        </div>
        <div className='row'>
          <DateFilter title="From Date" action={(val) => setFromDate(val)} value={fromDate} minDate={subtractDays(new Date(), 60)} maxDate={addDays(new Date(), 60)} />
          <DateFilter title="To Date" action={(val) => setToDate(val)} value={toDate} minDate={subtractDays(new Date(), 60)} maxDate={addDays(new Date(), 60)} />
        </div>
      </div>
      {tableData && tableData.length > 0 ?
        <ClearanceTable data={tableData} columns={initiateTableCol} screenType={screenType} handleAction={(index) => handleAction(index)} currentPage={currentPage} pageSize={10} />
        :
        <div className='mt-5 h-90-percent'>
          <DataNotFound />
        </div>
      }
      {tableData.length > 0 &&
        <div className="col-md-12 w-100 d-flex justify-content-center paginator">
          <Pagination>
            {currentPage != 1 &&
              <>
                <Pagination.Prev
                  onClick={() => { handlePageCount("decrement") }} />
                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                <Pagination.Ellipsis disabled />
              </>
            }
            <Pagination.Item active>{currentPage}</Pagination.Item>
            {currentPage != totalPages &&
              <>
                <Pagination.Ellipsis disabled />
                <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                <Pagination.Next
                  onClick={() => { handlePageCount("increment") }} />
              </>
            }
          </Pagination>
        </div>
      }
      {
        Object.keys(selectedEmployee).length > 0 &&
        <ConfirmationPopUp
          title={"Start Clearance"}
          loading={loading}
          onDismiss={backAction}
          confirmBtnLbl={"CONFIRM"}
          cancelBtnLbl={"CANCEL"}
          onConfirmAction={handleConfirmation}
          message={
            <div className='d-flex flex-direction-column'>
              <div className='align-self-center'>
                <img
                  alt="Success_icon"
                  src={Success_icon}
                  className="mb-1"
                ></img>
              </div>
              <div className="popup_text text-center p-3">
                {"Do you want to start the clearance?"}
              </div>
            </div>
          }
        />
      }
    </div>
  )
}

export default ClearanceInitiation