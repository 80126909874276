import React, { useEffect, useState } from 'react'
import CommonCard from '../../../employeeScreens/suggestionScheme/commonComponent/CommonCard'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import leftArrow from '../../../../../assets/drishti_images/left-arrow.svg';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import Table from './Table';
import CommonButton from '../../../../shared/button/GradientButton';
import SuggestionOfficeCommonPopup from './SuggestionOfficeCommonPopup';
import SuggestionOfficeDetailPopup from './SuggestionOfficeDetailPopup';
import { statusCodes, suggestionOfficeTableFields } from '../../../employeeScreens/suggestionScheme/constants';
import { toaster } from '../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import searchIcon from '../../../../../assets/drishti_images/admin/searchIcon.svg';
import { getMasterAdminLists, getStatusMasterListsAdmin, getSuggestionOfficeDivision, getSuggestionOfficeList, getSuggestonOfficeAgencyDashboard, getSuggestionAwardCategory } from '../../../../../constants/api.service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { Pagination } from 'react-bootstrap';

function SuggestionOfficeHome() {
  const [loading, setLoading] = useState(false)
  const [office, setAgency] = useState('')
  const [filteredAgencyList, setFilteredAgencyList] = useState([])
  const [searchValue,setSearchValue]=useState('')
  const [divisionList, setdivisionList] = useState([])
  const [statusList, setstatusList] = useState({ id: 1, status_name: 'Pending at Suggestion Office', status_code: 2 })
  const [SuggestionOfficePaginatedList, setSuggestionOfficePaginatedList] = useState([])
  const [agencyList, setAgencyList] = useState([])
  const [selectedOffice, setSelectedOffice] = useState('')
  const [suggestionOfficeCardData, setSuggestionOfficeCardData] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({ status_name: 'Pending at Suggestion Office', status_code: 2 })
  const [selectedSchemeType, setSelectedSchemeType] = useState('Select')
  const [selectedSuggestion, setSelectedSuggestion] = useState([])
  const [selectedRecord, setSelectedRecord] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedPopupAction, setSelectedPopupAction] = useState('')
  const [rejectionRemark, setRejectionRemark] = useState('')
  const [sendBackRemark, setSendBackRemark] = useState('')
  const [approveRemark, setApproveRemark] = useState('')
  const [openCommonPopup, setOpenCommonPopup] = useState(false)
  const [fromDate, setFormDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [radioValue, setRadioValue] = useState('highValue')
  const [divisionCode, setDivisionCode] = useState(null)
  const [search, setSearchFunction] = useState('')
  const [suggestionId, setSuggestionId] = useState('')
  const [statusCode, setStatusCode] = useState(null)
  const [suggestionOfficeId, setSuggestionOfficeId] = useState(null)
  const AdminLoginData = useSelector(state => state.adminLoginState);
  const [ searchSuggestionOffice, setSearchSuggestionOffice ] = useState("");
  const [suggestionTypeList, setSuggestionTypeList] = useState([])
  const [ selectedSuggestionType, setSelectedSuggestionType ] = useState(null);
  const [ awardCategoryList, setAwardCategoryList ] = useState(null);
  const [ selectedAwardCategoryList, setSelectedAwardCategoryList ] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const allDivision = {
    "division_code": "ALL",
    "division_name": "ALL",
    "count": 0
}

  useEffect(() => {
    getSuggestionOfficeAgencyDashBoardList();
    getStatusList();
    getSchemeTypeList();
    getAwardCategory()
  }, [])


  const handleInputSubmit = (event) => {
    event.preventDefault();
    const pageNum = parseInt(inputValue);
 
    // Validate the input value
    if (pageNum > 0 && pageNum <= totalPages) {
      setCurrentPage(pageNum);
      paginationCall(pageNum);
      setInputValue(''); // Clear input after submission
    } else {
      toaster("warning", `Enter Valid Number 1 to ${totalPages}`)
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handlePageCount = (action) => {
    // setSelectedIndexes([]);
    setSelectedSuggestion([]);
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      paginationCall(currentPage - 1)
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      paginationCall(currentPage + 1)
    }
    
  };
  //--------------Get Suggestion Office API-------------------------
  const getSuggestionOfficeAgencyDashBoardList = () => {
    setLoading(true)
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea
    }
    getSuggestonOfficeAgencyDashboard(params)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setFilteredAgencyList(data?.data ? data?.data : [])
          const updatedAgencyList = data?.data ? [...data.data] : [];
          updatedAgencyList.unshift(allDivision);
          setAgencyList(updatedAgencyList)
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const suggestionOfficeCard = (suggestionOffice) => {
    setSelectedOffice(true);
    setSelectedSchemeType(suggestionOffice?.division_name)
    setDivisionCode(suggestionOffice?.division_code)
    // setSuggestionOfficeId(suggestionOffice?.sug_office)
    setSuggestionOfficeId(suggestionOffice?.division_code)
    getDivisionMaster(suggestionOffice?.division_code)
    getSuggestionOfficeData(suggestionOffice?.division_code,statusCode,divisionCode,search,fromDate,toDate,currentPage)
    setSuggestionId(suggestionOffice?.division_code)
    setStatusCode(2)
  }

  const commonSerachFilters = (data, type) => {
    setDivisionCode(data?.division_code)
    if (type === "suggOffice") {
      // setSelectedOffice(data?.suggestion_office_name)
      getDivisionMaster(data?.division_code)
      getSuggestionOfficeData(data?.division_code,statusCode,divisionCode,search,fromDate,toDate,currentPage);
      setSuggestionId(data?.division_code)
      setSelectedSuggestion([])
    } else if (type === "division") {
      setSelectedSchemeType(data?.division_name)
      setDivisionCode(data?.division_code)
      setSuggestionId(suggestionId)
      // getSuggestionOfficeData(suggestionId);
      setSelectedSuggestion([])
    } else if (type === "statusList") {
      setDivisionCode(divisionCode)
      setSelectedStatus(data?.status_name, data?.status_code)
      setStatusCode(data?.status_code)
      setSuggestionId(suggestionId)
      // getSuggestionOfficeData(suggestionId,statusCode,divisionCode,search,fromDate,toDate,currentPage);
      setSelectedSuggestion([])
    } else if (type === 'Search') {
      setStatusCode(statusCode)
      setDivisionCode(divisionCode)
      setSearchFunction(data)
      setSuggestionId(suggestionId)
      const newPage=1
      setCurrentPage(1)
      getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,newPage);
      setSelectedSuggestion([])
    }
  }

  useEffect(() => {
    if (statusCode !== null) {
      getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,currentPage);
    }
    else if (divisionCode !== null) {
      getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,currentPage);
    }
    else if( selectedSuggestionType !== null ) {
      getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,currentPage);
    } 
    else if( selectedAwardCategoryList !== null ) {
      getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,currentPage);
    } 
  }, [statusCode, divisionCode, selectedSuggestionType,selectedAwardCategoryList]);

  //--------------Get Division Master API-------------------------
  const getDivisionMaster = (id) => {
    setLoading(true)
    getSuggestionOfficeDivision(id)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setdivisionList(data?.data ? data?.data : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  //--------------Get Master Status API-------------------------
  const getStatusList = () => {
    setLoading(true)
    getStatusMasterListsAdmin("status")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setstatusList(data?.data ? data?.data?.status_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  //--------------Get Award Category API-------------------------
  const getAwardCategory = () => {
    setLoading(true)
    getSuggestionAwardCategory()
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          const newData = data?.data?.award_category_list?.map((itm)=>{
            return {
              ...itm,
              label:itm.award_category
            }
          });
          setAwardCategoryList(newData)
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  
  const getSchemeTypeList = () => {
    setLoading(true)

    getMasterAdminLists("scheme_type")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setSuggestionTypeList(data?.data ? data?.data?.scheme_type_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  //--------------Get Suggestion Requests API-------------------------
  const getSuggestionOfficeData = (id,statusCode,divisionCode,search,fromDate,toDate,currentPage) => {
    setLoading(true)
    const idData = id ? id : divisionCode;
    let params = {
      sug_office_code: '',
      division_code: divisionCode ? divisionCode : '',
      sug_status_code: selectedStatus.status_code === statusCodes['Pending at Suggestion Office'] ? 2 : statusCode,
      search: search ? search : '',
      scheme_type_code: selectedSuggestionType?.scheme_type_code ?? '',
      from_date: fromDate ? fromDate : '',
      to_date: toDate ? toDate : '',
      page_number: currentPage ? currentPage : 1,
      award_category: selectedAwardCategoryList ? selectedAwardCategoryList?.award_category : '',
      comp_code: AdminLoginData?.company?.company_code,
      pa: AdminLoginData?.userData?.PersArea
    }
    getSuggestionOfficeList(idData, params)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setLoading(false)
          setSuggestionOfficePaginatedList(data?.data ? data.data?.paginated_data : [])
          setTotalPages(data?.data ? data.data?.total_pages : null)
        }
        else {
          setLoading(false)
          toaster("warning", res?.data?.message ?? "Something Went Wrong!")
          setSuggestionOfficePaginatedList([])
        }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const searchSuggestionOfficeHandler = (e) => {
    const searchInput = e.target.value;  
    setSearchValue(searchInput);  
  
    if (searchInput === '') {
      setFilteredAgencyList([...agencyList]);  
    } else {
      const filteredData = agencyList.filter((value) =>
        value?.division_name?.toLowerCase().includes(searchInput?.toLowerCase())
      );
      setFilteredAgencyList(filteredData);  
    }
  };

  const searchFilterFunction = (e) => {
    const searchValue = e.target.value;
    setSearchFunction(searchValue);
  }

  const backAction = () => {
    setSelectedSchemeType('Select')
    setSelectedStatus({ status_name: 'Pending at Suggestion Office', status_code: 2 })
    // setstatusList('')
    setDivisionCode(null)
    setSearchFunction('')
    setStatusCode(null)
    setFormDate(null)
    setToDate(null)
    setSelectedOffice('');
    setFilteredAgencyList(agencyList);
    setSelectedAwardCategoryList(null)
    setAgency('')
    setSelectedSuggestion([])
    setTotalPages(1)
    setCurrentPage(1)
    setRadioValue('highValue')
  }

  const callSelectedPopupAction = (actionType) => {
    setSelectedPopupAction(actionType)
    setOpenCommonPopup(!openCommonPopup)
  }

  const handleSuggestionTypeFilter = (data) => { setSelectedSuggestionType(data) }

  const handleSuggestionAwardCategory = (data) => { setSelectedAwardCategoryList(data) }


  const handleFromDate = (data) => {
    setFormDate(data);
  }

  const handleToDate = (data) => {
    setToDate(data);
  }

  const paginationCall = (currentPageNo) => {
    getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,currentPageNo);
    setCurrentPage(currentPageNo)
  }

  const toDay = new Date().toISOString().split('T')[0];

  const closePopup = () => {
    setSelectedRecord('')
    setSelectedSuggestion([])
  }

  return (
    <div>
      <LoaderIndicator loading={loading} />
      <div className='ml-10 h-90 scroll-y'>
        {selectedOffice === '' ?
          <div className='row justify-content-between mt-10px'>
            <div className='popup-title'>{'Select Suggestion Office'}</div>
            <div className='row'>
              <div className='row ml-15px'>
                <div className='p-placeholder-text mr-3'>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Search Division'
                    onChange={(e) => { searchSuggestionOfficeHandler(e) }}
                    value={searchValue}
                  />
                </div>
              </div>
            </div>
          </div>
          :
          <div className='row justify-content-between mt-10px'>
          </div>
        }
        {selectedOffice === '' ?
          <div className='row mt-10px gap-20 h-90 scroll-y'>
            {filteredAgencyList.length > 0 ?
              filteredAgencyList.map((value) =>
                <div className='w-32 h-28vh pointer'>
                  <CommonCard onClick={() => suggestionOfficeCard(value)} >
                    <div className='flex-direction-column h-100 justify-content-center w-97 align-items-center gap-10'>
                      <div className='suggestion-card-content'>{value.count}</div>
                      <div className='document-innercard-content-title'>{value.division_name}</div>
                    </div>
                  </CommonCard>
                </div>
              )
              : <div className='justify-content-center align-items-center w-100'>
                <DataNotFound />
              </div>
            }
          </div>
          :
          <div>
            <div className='gap-10'>
              <div>
                   <span className='pointer align-items-center add-btn-text' onClick={backAction}>
                     <img alt="left arrow" src={leftArrow} className="left-arrow" />{'Back'}
                   </span>
                 </div>
              {/* <div className='d-flex flex-direction-column'> */}
              <div>
                {radioValue !== 'enquiry' ?
                //  <div className='d-flex flex-wrap align-items-center gap-10px filter-p5'>
                <div className='align-items-center gap-10px filter-p5'>
                 <div className='d-flex flex-wrap align-items-center mx-2'>
                   {/* <div className='flex-direction-column ml-3 mb-1'>
                     <div className='p-sub-heading ml-2'>{"Select Suggestion Office"}</div>
                     <DynamicDropdown
                       data={agencyList}
                       render="suggestion_office_name"
                       value={selectedOffice}
                       selectedValue={(data) => commonSerachFilters(data, "suggOffice")}
                       arrow={'true'}
                       height={'dependOnContent'}
                       width={"w-16rem"}
                       boxWidth="14rem"
                     />
                   </div> */}
                   <div className='flex-direction-column ml-3 mb-1'>
                     <div className='p-sub-heading ml-2'>{"Select Division"}</div>
                     <DynamicDropdown
                      //  data={divisionList}
                       data={agencyList}
                       render="division_name"
                       value={selectedSchemeType}
                       selectedValue={(data) => commonSerachFilters(data, "division")}
                       arrow={'true'}
                       height={'dependOnContent'}
                       width={"w-auto"}
                       boxWidth="w-auto"
                     />
                   </div>
                   <div className='flex-direction-column ml-3 mb-1'>
                     <div className='p-sub-heading ml-2'>{"Status"}</div>
                     <DynamicDropdown
                       data={statusList}
                       render="status_name"
                       value={selectedStatus.status_name === undefined ? selectedStatus : selectedStatus.status_name}
                       selectedValue={(data) => commonSerachFilters(data, "statusList")}
                       arrow={'true'}
                       height={'dependOnContent'}
                       width={"w-16rem"}
                       boxWidth="16rem"
                     />
                   </div>
                   <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading ml-2'>{"Type of suggestion"}</div>
                        <DynamicDropdown
                          data={suggestionTypeList}
                          render="scheme_type"
                          value={selectedSuggestionType?.scheme_type}
                          selectedValue={(data) => handleSuggestionTypeFilter(data)}
                          arrow={'true'}
                          height={'dependOnContent'}
                          width={"w-16rem"}
                          boxWidth="10rem"
                        />
                    </div>
                    <div className='flex-direction-column ml-3 mb-1'>
                        <div className='p-sub-heading ml-2'>{"Award Category"}</div>
                        <DynamicDropdown
                          data={awardCategoryList}
                          render="award_category"
                          value={selectedAwardCategoryList?.award_category}
                          selectedValue={(data) => handleSuggestionAwardCategory(data)}
                          arrow={'true'}
                          height={'dependOnContent'}
                          width={"w-16rem"}
                          boxWidth="10rem"
                        />
                    </div>
                   <div className='flex-direction-column ml-3 mb-1'>
                     <div className="p-sub-heading ml-2">Search</div>
                     <div className='d-flex align-items-center'>
                       <input
                         className="inpt input-date w-100 w-md-auto"
                         type="text"
                         placeholder='Title/Sugg No...'
                         onChange={(e) => searchFilterFunction(e)}
                         value={search}
                       />
                       <div type='button' className='serchIconSize-evaluator' onClick={() => commonSerachFilters(search, "Search")}>
                         <img alt="radioOn" className='filterIcon' src={searchIcon} />
                       </div>
                     </div>
                   </div>
                   <div className='flex-direction-column ml-3 mb-1'>
                     <div className='p-sub-heading ml-2'>{"From Date"}</div>
                     <input
                       className='inpt input-date w-100 w-md-auto'
                       type='date'
                       max={toDay}
                       value={fromDate}
                       placeholder='Select Start Date'
                       onChange={(e) => handleFromDate(e.target.value)}
                     />
                   </div>
                   <div className='flex-direction-column ml-3 mb-1'>
                     <div className='p-sub-heading ml-2'>{"To Date"}</div>
                     <input
                       className='inpt input-date w-100 w-md-auto'
                       type='date'
                       placeholder='Select To Date'
                       max={toDay}
                       value={toDate}
                       min={fromDate}
                       onChange={(e) => handleToDate(e.target.value)}
                     />
                   </div>
                   <div className='flex-direction-column ml-3 mb-1 filter-search-button'>
                     <CommonButton
                       label="SEARCH"
                       onClick={() =>{
                         const newPage = 1;
                         setCurrentPage(newPage);
                         getSuggestionOfficeData(divisionCode,statusCode,divisionCode,search,fromDate,toDate,newPage);
                       }}
                       isGradientBtn={true}
                       className={'px-20px w-100 w-md-auto'}
                     />
                   </div>
                 </div>
               </div>
               
                  :<></>
                }
              </div>
            </div>

            {SuggestionOfficePaginatedList?.length > 0 ?
              <div>
                <Table
                  hasAction
                  radioValue={radioValue}
                  columns={suggestionOfficeTableFields[0]}
                  data={SuggestionOfficePaginatedList}
                  onSelectSuggestion={(data) => { setSelectedSuggestion(data) }}
                  selectedSuggestion={selectedSuggestion}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  pageSize={6}
                  statusCode={statusCode}
                  statusList
                  getSuggestionOffice={getSuggestionOfficeData} 
                  paginationCall={(data) => paginationCall(data)}
                  suggestionOfficeId={suggestionOfficeId}
                  selectedStatus
                  onAction={(data) => setSelectedRecord(data)}
                  minHeight={"43vh"}
                />
              </div>
              :
              <div className='justify-content-center align-items-center w-100 h-54vh'>
                <DataNotFound />
              </div>
            }

            {openCommonPopup &&
              <>
                <SuggestionOfficeCommonPopup
                  title={selectedPopupAction}
                  action={'finalAction'}
                  agency_name={selectedOffice}
                  submitSendBack={(remarks) => { setSendBackRemark(remarks); setOpenCommonPopup(false) }}
                  submitRejection={(remarks) => { setRejectionRemark(remarks); setOpenCommonPopup(false) }}
                  submitApprove={(remarks) => { setApproveRemark(remarks); setOpenCommonPopup(false) }}
                  data={selectedSuggestion}
                  SuggestionOfficeCardData={suggestionOfficeCardData}
                  // selectedOffice={selectedOffice}
                  // loginData={loginData}
                  onDismiss={() => { setOpenCommonPopup(false);getSuggestionOfficeData(divisionCode);setSelectedSuggestion([]) }}
                />
              </>
            }

            {SuggestionOfficePaginatedList?.length > 0 &&
                <div className='row align-item-center justify-content-between Evaluator-background-highlighter gap-10 bottom-css'>
                  <div className='ptb-0_375 plr-0_75'>{selectedSuggestion.length + '/' + SuggestionOfficePaginatedList.length + ' records selected.'}</div>
                  {currentPage && (
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center'}}>
                    <Pagination>
                      {currentPage != 1 && (
                        <>
                          <Pagination.Prev
                            onClick={() => {
                              handlePageCount("decrement");
                            }}
                          />
                          <Pagination.Item disabled onClick={() => setCurrentPage(1)}>
                            {1}
                          </Pagination.Item>
                          <Pagination.Ellipsis disabled />
                        </>
                      )}
                      <Pagination.Item active>{currentPage}</Pagination.Item>
                      {currentPage != totalPages && (
                        <>
                          <Pagination.Ellipsis disabled />
                          <Pagination.Item
                            disabled
                            onClick={() => setCurrentPage(totalPages)}
                          >
                            {totalPages}
                          </Pagination.Item>
                          <Pagination.Next
                            onClick={() => {
                              handlePageCount("increment");
                            }}
                          />
                        </>
                      )}
                    </Pagination>
                    </div>
                )}
                {totalPages >= 2 && (
                  <div className='d-flex align-items-center'>
                       <input
                         className="inpt input-date w-100 w-md-auto"
                         type="text"
                         placeholder='Page No...'
                         onChange={handleInputChange}
                         value={inputValue}
                       />
                       <div type='button' className='serchIconSize-evaluator' onClick={(event) => handleInputSubmit(event)}>
                         <img alt="radioOn" className='filterIcon' src={searchIcon} />
                       </div>
                </div>)}
                { selectedSuggestion.length > 0 && (<div style={{ display: 'flex', gap: '7px', minWidth: '350px', justifyContent: 'flex-end' }}>

{
  <div className='row gap-7px'>
    { !selectedSuggestion.some(ele => ele.is_divisional_coordinator_send_back) &&     
      <CommonButton
        label="SEND BACK"
        onClick={() => { callSelectedPopupAction("Send_back") }}
        isGradientBtn={true}
        className={'px-20px'}
      />
    }
    <CommonButton
      label="REJECT"
      onClick={() => { callSelectedPopupAction("Reject") }}
      isGradientBtn={true}
      className={'px-20px'}
    />
    <CommonButton
      label="APPROVE"
      onClick={() => { callSelectedPopupAction("Approved") }}
      isGradientBtn={true}
      className={'px-20px'}
    />
  </div>
}
</div>)}
                </div>
            }
          </div>
      }
      {selectedRecord != '' && <SuggestionOfficeDetailPopup title={'Suggestion Details'} backAction={closePopup}  data={selectedSuggestion} selectedRecordId = {selectedRecord.id} suggestionStatus = {selectedStatus.status_name}/>}
      </div>
    </div>
  )
}

export default SuggestionOfficeHome