import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../TeamsStyle.css';
import eye from '../../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../../assets/drishti_images/eye-slash.svg';
import { maskMobile, maskEmail, maskBank, maskPAN } from '../../../../shared/commonMaskFunction';

class DetailsScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isEmailUnmasked: false,
            isContactNumUnmasked: false
        }
    }
    render() {
        const emailId = this.props.empdata.Imailid
        const location = this.props.empdata.CityTown
        const contactNo = this.props.empdata.PersMobile
        const designation = this.props.empdata.Jobtxt

        return (
            <div className='scroller-coi'>
                <div className='cfm-subcard'>
                    <div className='ml-0'>
                        <label className='details-heading mt-3 ml-3'>Personal Details</label>
                       
                        <div className='row mt-3'>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Email ID - {this.state.isEmailUnmasked ? (emailId ?? '') : maskEmail(emailId ?? '')}<span className='profile-card-text-light'>{ }</span>
                                <span
                                        onClick={() => this.setState({ isEmailUnmasked : !this.state.isEmailUnmasked })} // Toggle between true/false for mask/unmask
                                        className='mask-team-details'
                                    >
                                        <img
                                            alt="eyeslash"
                                            src={ this.state.isEmailUnmasked ? eye : eyeslash }
                                            className="infosize-unmask"
                                        ></img>
                                </span>

                            </div>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Location - {location}<span className='profile-card-text-light'>{ }</span>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Contact Number - {this.state.isContactNumUnmasked ? (contactNo ?? '') :  maskMobile(contactNo ?? '')} <span className='profile-card-text-light'>{ }</span>
                                <span
                                        onClick={() => this.setState({ isContactNumUnmasked : !this.state.isContactNumUnmasked })} // Toggle between true/false for mask/unmask
                                        className='mask-team-details'
                                    >
                                        <img
                                            alt="eyeslash"
                                            src={ this.state.isContactNumUnmasked ? eye : eyeslash }
                                            className="infosize-unmask"
                                        ></img>
                                </span>
                            </div>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Designation - {designation} <span className='profile-card-text-light'>{ }</span>
                            </div>
                        </div>
                        <div className='mt-3 mx-2'>
                            <hr />
                        </div>
                    </div>
                    {/* <div className='ml-0'>
                        <label className='details-heading mt-3 ml-3'>Past Ratings</label>
                        <div className='row mt-3'>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Email ID - maheshshendage23@gmail.com <span className='profile-card-text-light'>{ }</span>
                            </div>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Location - <span className='profile-card-text-light'>{ }</span>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Contact Number - 8828664844 <span className='profile-card-text-light'>{ }</span>
                            </div>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Designation - <span className='profile-card-text-light'>{ }</span>
                            </div>
                        </div>
                        <div className='mt-3 mx-2'>
                            <hr />
                        </div>
                    </div> */}

                    {/* <div className='ml-0'>
                        <label className='details-heading mt-3 ml-3'>Skillsets</label>
                        <div className='row mt-3'>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Technical Skills<span className='profile-card-text-light'>{ }</span>
                            </div>
                            <div className='col-md-6 details-sub-heading-bold'>
                                Soft Skills<span className='profile-card-text-light'>{ }</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfmData: state.cfmState
    }
}

export default (connect(mapStatesToProps)(DetailsScreen));
