// this is for first dashboard screen.
import React, { Component, PropTypes } from 'react'
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import plus from '../../../../assets/drishti_images/manager/landing/plus.svg'
import { BASE_WEB_URL_DEV, get_registered_employee_details, headers } from '../../../../constants/Config';
import RegistrationForm from './RegistrationForm';
import EditDetailsForm from './EditDetailsForm';
import RegisteredEmployeeTable from './RegisteredEmployeeTable';
import HistoryDetails from './HistoryDetails';
import { connect } from 'react-redux';
import * as registrationAction from './RegistrationAction';
import { bindActionCreators } from 'redux';
import { fetchApiCall, operativeSessionOut } from "../../../../constants/Service";
import { toast } from 'react-toastify';
import { someThingWentWrong, exportRegEmp } from '../../../../constants/Messages'
import { validateNumber, exportToExcel } from '../../../../utils/Utils'
import download from '../../../../assets/drishti_images/download.svg';

class ViewRegisterInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isViewRegisteredTable: this.props.adminRegistrationDate.tabType === "" || this.props.adminRegistrationDate.tabType === "isViewRegisteredTable" ? true : false,
            isViewHistory: this.props.adminRegistrationDate.tabType === "isViewHistory" ? true : false,
            isEditForm: false,
            registrationType: '',
            searchID: 'All',
            editData: [],
            searchFlag: false,
            totalPage: 1,
            currentPage: 1,
            registeredData: [],
            message: '',
            isRegistration: false,
            allMaskUnmask: false,
        }
    }

    componentDidMount() {
        this.getRegisteredEmployeeDetails(this.state.currentPage, "noSearch")
    }
    componentWillUnmount() {
        this.getRegisteredEmployeeDetails(this.state.currentPage, "noSearch")
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }

    toggleViewRegistration = () => {
        this.props.actions.adminRegistration("isViewRegisteredTable");
        this.setState({ isViewRegisteredTable: true, isViewHistory: false })
    }
    toggleViewHistory = () => {
        this.props.actions.adminRegistration("isViewHistory");
        this.setState({ isViewRegisteredTable: false, isViewHistory: true })
    }
    Openregistration = (paramRegistrationType) => {
        this.setState({
            isRegistration: true,
            registrationType: paramRegistrationType
        })
    }
    passValueToEditForm = (data) => {
        console.log("Data to be pass:", data)
        this.setState({
            isEditForm: true,
            editData: data
        })
    }
    // searchEmployee = (sercheditem) => {
    //     console.log("serchedID:++++==== ", sercheditem !== "", "2", sercheditem != undefined, "1", sercheditem != null);
    //     if (sercheditem !== "") {
    //         this.setState({
    //             searchID: sercheditem
    //         })
    //         let body = {
    //             filter: "Specific",
    //             emp_id: this.state.searchID,
    //             page_number: "1"
    //         }
    //         console.log("isViewRegisteredTable: ", this.state.isViewRegisteredTable)
    //         fetchApiCall(get_registered_employee_details, body, headers, true, 'admin')
    //             .then(res => {
    //                 if (res.status == 200 && res.data.status_code === "200") {
    //                     if (res.data.data.registered_employee_details_json_list != undefined) {
    //                         this.setState({
    //                             loading: false,
    //                             registeredData: res.data.data.registered_employee_details_json_list,
    //                             totalPage: res.data.data.total_page,
    //                             searchFlag: true
    //                         })
    //                     }
    //                 }
    //                 else {
    //                     this.setState({
    //                         loading: false,
    //                         registeredData: []
    //                     })
    //                     setTimeout(() => {
    //                         toast.warn(res.data.message, {
    //                             position: "bottom-center",
    //                             hideProgressBar: true,
    //                             className: 'toastWarn'
    //                         })
    //                     }, 100)
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log("error :", err)
    //                 setTimeout(() => {
    //                     toast.warn(someThingWentWrong, {
    //                         position: "bottom-center",
    //                         hideProgressBar: true,
    //                         className: 'toastWarn'
    //                     })
    //                 }, 100)
    //             })
    //     }
    //     else {
    //         this.getRegisteredEmployeeDetails()
    //     }
    // }

    getRegisteredEmployeeDetails = (pageNo, searchType) => {
        this.setState({
            loading: true
        })
        let body = {
            filter: searchType === "export" ? searchType : (searchType === "noSearch" ? "All" : "Specific"),
            emp_id: searchType === "noSearch" || searchType === "export" ? this.props.adminLoginData.emp_id : this.state.searchID,
            page_number: pageNo
        }
        fetchApiCall(get_registered_employee_details, body, headers, false, 'admin')
            .then(res => {
                if (res.status == 200) {
                    operativeSessionOut(this.props, res, "admin");
                    this.setState({
                        loading: false,
                    })
                    if (res.data.status_code === "200") {
                        if(Array.isArray(res?.data?.data?.registered_employee_details_json_list)){
                            res.data.data.registered_employee_details_json_list.forEach(ele =>{
                                ele.isMasked = false;
                            });
                        }
                        if (searchType === "export") {
                            this.setState({ loading: false });
                            this.exportAllData(res.data.data.registered_employee_details_json_list ? res.data.data.registered_employee_details_json_list : [])
                            return
                        } else {
                            this.setState({
                                loading: false,
                                registeredData: res.data.data.registered_employee_details_json_list ? res.data.data.registered_employee_details_json_list : [],
                                totalPage: res.data.data.total_page,
                                currentPage: res.data.data.page_number,
                                searchFlag: false,
                                message: res.data.message
                            })
                        }
                    }
                    else {
                        this.setState({
                            loading: false,
                            registeredData: [],
                            message: ''
                        })
                        setTimeout(() => {
                            toast.warn(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastWarn'
                            })
                        }, 100)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        registeredData: [],
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    registeredData: [],
                })
                console.log("error :", err)
                setTimeout(() => {
                    toast.warn(someThingWentWrong, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }, 100)
            })
    }

    handlePagination = (pageNo) => {
        this.getRegisteredEmployeeDetails(pageNo, "noSearch")
    }

    setSearch = (employeeId) => {
        this.setState({
            searchID: employeeId ? employeeId : "All"
        })
        if (employeeId.length == 0) {
            this.getRegisteredEmployeeDetails(1, "noSearch")
        }
    }

    exportAllData = (dataToBeExported) => {
        if (dataToBeExported) {
            let colNames = [exportRegEmp.colNames]
            let formattedData = dataToBeExported.map((data, idx) => {
                return {
                    srNo: idx + 1,
                    employee_id: data.employee_id,
                    mobile_number: data.mobile_number,
                    created_by: data.created_by,
                    modified_by: data.modified_by,
                    is_active: data.is_active ? "Active" : "Inactive"
                }
            })
            exportToExcel(exportRegEmp.fileName, exportRegEmp.ext, formattedData, colNames)
        }
    }

    handleEmpChange = (e) => {
            const value = e.target.value;
            if (/^\d{0,8}$/.test(value)) 
                {this.setSearch(e.target.value ? validateNumber(e.target.value) : "")}
    }

    toggleMaskData = (index) => {
        this.setState((prevState) => {
            const updatedData = prevState.registeredData.map((data, id) => 
                id === index ? { ...data, isMasked: !data.isMasked } : data
            );
            return { registeredData: updatedData };
        });
    };


    toggleMaskUnmaskData = () => {
        this.setState(
            (prevState) => ({
                allMaskUnmask: !prevState.allMaskUnmask
            }),
            () => {
                // This callback runs AFTER state update is completed
                this.setState((prevState) => {
                    const updatedData = prevState.registeredData.map((data) => ({
                        ...data,
                        isMasked: prevState.allMaskUnmask // Use updated state value
                    }));
                    return { registeredData: updatedData };
                });
            }
        );
    };
    

    render() {
        const { isRegistration, isEditForm } = this.state
        return (
            <>
                <div className='firstContainer p-12px'>
                    <div className='secondContainer align-item-center-noflex justify-content-between'>
                        <div className='m-5px'>
                            <img src={overviewButton} alt='' onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className='dflex'>
                            <div className='ml-10 mr-10px'>
                                <a
                                    type="button"
                                    className=' btn-primary common-btn common-button-text d-flex justify-content-center align-item-center-noflex'
                                    onClick={() => this.Openregistration('singleRegistration')}
                                >
                                    <div><img src={plus} className='p-5px' alt="plus button" /></div>
                                    <div className=''>SINGLE REGISTRATION</div>
                                </a>
                            </div>
                            <div className='ml-10 mr-10px'>
                                <a
                                    type="button"
                                    className=' btn-primary common-btn common-button-text d-flex justify-content-center align-item-center-noflex'
                                    onClick={() => this.Openregistration('bulkRegistration')}
                                >
                                    <div><img src={plus} className='p-5px' alt="plus button" /></div>
                                    <div className=''>BULK REGISTRATION</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='card-new p-12px mt-3'>
                        <div className='flex-direction-row justify-content-between'>
                            <div className='secondContainer scroller-header flex-flow'>
                                <div className={this.state.isViewRegisteredTable ? "tab-active" : "tab-inactive"} onClick={this.toggleViewRegistration}>
                                    Employee Details
                                </div>
                                <div className={this.state.isViewHistory ? "tab-active" : "tab-inactive"} onClick={this.toggleViewHistory}>
                                    Bulk History
                                </div>
                            </div>
                            <div>
                                {this.state.isViewRegisteredTable &&
                                    <div className='p-placeholder-text align-item-center'>
                                        <input
                                            className="form-control registration-screen-search px-3"
                                            type="number"
                                            maxLength={6}
                                            placeholder={"Enter employee Id"}
                                            value={this.state.searchID}
                                            // onChange={(e) => this.setState({ searchID: e.target.value })}
                                            onChange={(e) => this.handleEmpChange(e)}
                                        />
                                        <a
                                            type="button"
                                            className=' btn-primary common-btn common-button-text d-flex justify-content-center align-item-center h-10 ml-5px'
                                            // onClick={() => this.searchEmployee(this.state.searchID)}
                                            onClick={() => this.state.searchID !== "All" ? this.getRegisteredEmployeeDetails(1, "search") : ""}
                                        >
                                            SEARCH
                                        </a>
                                        <a
                                            type="button"
                                            className='ml-5px p-0'
                                            title='Export Employee Details'
                                            onClick={() => this.getRegisteredEmployeeDetails(this.state.currentPage, "export")}
                                        >
                                            <img src={download} alt="download" />
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='m-10px'></div>
                        {
                            this.state.isViewRegisteredTable &&
                            <RegisteredEmployeeTable
                                searchID={this.state.searchID}
                                employeeRegisteredData={this.state.registeredData}
                                message={this.state.message ? this.state.message : "No Registered Data"}
                                totalPage={this.state.totalPage}
                                currentPage={this.state.currentPage}
                                parentCallback={this.handlePagination}
                                loading={this.state.loading}
                                callgetEmployeeAPI={() => this.getRegisteredEmployeeDetails(1, "noSearch")}
                                toggleMaskData = {this.toggleMaskData}
                                maskUnmaskAll = {this.toggleMaskUnmaskData}
                                allMaskUnmaskToggle = {this.state.allMaskUnmask}
                            />
                        }
                        {
                            this.state.isViewHistory &&
                            <HistoryDetails />
                        }
                    </div>
                </div>
                {
                    isRegistration &&
                    <RegistrationForm
                        onDismiss={() => this.setState({
                            isRegistration: false
                        })}
                        // onDismiss={this.filterUser}
                        registrationType={this.state.registrationType}
                        callgetEmployeeAPI={() => this.getRegisteredEmployeeDetails(1, "noSearch")}
                    />
                }
                {
                    isEditForm &&
                    <EditDetailsForm
                        onDismiss={() => this.setState({ isEditForm: false })}
                        editdetails={this.state.editData}
                    />
                }
            </>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminRegistrationDate: state.adminRegistrationState,
        keycloakData: state.keycloakState,
        adminLoginData: state.adminLoginState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(registrationAction, dispatch),
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(ViewRegisterInfo);