import React, { useEffect, useState } from 'react'
import AgencyWiseTrack from './AgencyWiseTrack';
import leftArrow from '../../../../assets/drishti_images/left-arrow.svg';
import ConfirmationPopUp from './ConfirmationPopUp';
import { constantValues } from '../../managerScreens/approval/constant';
import { constantVals } from './constants';
import { getAgencyWiseClearance } from '../../../../constants/api.service';
import { DDMMMYY, toaster } from '../../../../utils/Utils';
import { someThingWentWrong } from '../../../../constants/Messages';
import { maskMobile, maskEmail} from '../../../shared/commonMaskFunction';
import eye from '../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../assets/drishti_images/eye-slash.svg';

function ClearanceRequestDashboard(props) {
    const { selectedEmployee, backAction, primaryFields, backBtnTitle, screenType, isSecondaryAction, secondaryBtnTitle, secondaryBtnAction, showNetAmount } = props
    const [ConfirmPopUp, setConfirmPopUp] = useState(false)
    const [netAmount, setNetAmount] = useState("NA")
    const [totalPayableAmt, setTotalPayableAmt] = useState("NA")
    const [totalRecoveryAmt, setTotalRecoveryAmt] = useState("NA")
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isContactUnmasked,setIsContactUnmasked] = useState(false)
    const [isEmailUnmasked,setIsEmailUnmasked] = useState(false)
    
    const fetchAgencyWiseClearanceDetails = () => {
        setLoading(true)
        getAgencyWiseClearance(props, selectedEmployee["clearance_id"], '')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    let { request } = res.data.data
                    res.data.data.request.map((req, index) => {
                          req.contact.map(contact => {
                                contact.agent.email = maskEmail(contact.agent.email ?? '');
                                contact.agent.mobile_no = maskMobile(contact.agent.mobile_no ?? '');
                          });
                      });
                    
                    res.data.data['request'] = request.map((data) => {
                        return {
                            ...data,
                            "contact_details": data?.contact.reduce((accumulator, val, index) =>
                                accumulator + "" + String(index + 1) + ' . ' + val.agent.domain_id + "\n" + (val.agent.email != '' ? val.agent.email : '--') + "\n" + val.agent.mobile_no + '\n', '')
                        }
                    })

                    setResponse(res.data.data)
                    showNetAmount && calcNetAmount(request)
                }
                else { res?.status !== 404 && toaster("warning", res?.data?.message ?? someThingWentWrong) }
            })
            .catch((error) => { error?.status !== 404 && toaster("warning", error?.data?.message ?? someThingWentWrong) })
            .finally(() => { setLoading(false) })
    }
    const calcNetAmount = (dataList) => {
        if (dataList && dataList.length > 0) {
            let totalPayableAmount = dataList.reduce((accumulator, currentValue) => {
                if (currentValue?.agency_name != 'F & F Settlement' && currentValue?.pay_amount) { return accumulator + currentValue.pay_amount }
                else { return accumulator + 0 }
            }, 0)
            let totalRecoveryAmount = dataList.reduce((accumulator, currentValue) => {
                if (currentValue?.agency_name != 'F & F Settlement' && currentValue?.recovery_amount) { return accumulator + currentValue.recovery_amount }
                else { return accumulator + 0 }
            }, 0)
            setTotalPayableAmt("Rs. " + totalPayableAmount)
            setTotalRecoveryAmt("Rs. " + totalRecoveryAmount)
            if (totalPayableAmount > totalRecoveryAmount) { setNetAmount(`Rs. ${totalPayableAmount - totalRecoveryAmount} payable`) }
            else if (totalPayableAmount < totalRecoveryAmount) { setNetAmount(`Rs. ${totalRecoveryAmount - totalPayableAmount} recoverable`) }
            else { setNetAmount(0) }
        }
    }
    useEffect(() => {
        fetchAgencyWiseClearanceDetails()
    }, [])
    return (
        <div>
            <div className='row justify-content-between mt-2 mb-10'>
                <span className='pointer align-item-center add-btn-text' onClick={backAction}>
                    <img
                        alt="left arrow"
                        src={leftArrow}
                        className="left-arrow"
                    />
                    {backBtnTitle}
                </span>
                {
                    isSecondaryAction &&
                    <input type='button' value={secondaryBtnTitle} className='ml-2 validateBtn' onClick={() => setConfirmPopUp(true)} />
                }
            </div>
            <div className='ml-10 scroll-auto overflow-x-clip'>
                <div className='toggle-card-heading mb-10'>{constantValues.employeeDetails}</div>
                <div className='emp-container pb-10px'>
                    {
                        primaryFields.map((field) => {
                            return (
                                <div className='detail-field'>
                                    <div className='details-lbl'>{field.label}:</div>
                                    <div className='field-value'>
                                        {selectedEmployee?.["employee"]?.[field.key] ?? "--"}</div>
                                </div>
                            )
                        })
                    }
                    <div className='detail-field'>
                        <div className='details-lbl'>Tata Group Joining Date:</div>
                        <div className='field-value'>{DDMMMYY(selectedEmployee?.employee?.["tata_group_joining_date"]) ?? "--"}</div>
                    </div>
                    <div className='detail-field'>
                        <div className='details-lbl'>TML Joining Date:</div>
                        <div className='field-value'>{DDMMMYY(selectedEmployee?.employee?.["tml_group_joining_date"]) ?? "--"}</div>
                    </div>
                    <div className='detail-field'>
                        <div className='details-lbl'>Actual Resignation Date:</div>
                        <div className='field-value'>{DDMMMYY(selectedEmployee?.["actual_resignation_date"]) ?? "--"}</div>
                    </div>
                    <div className='detail-field'>
                        <div className='details-lbl'>Last Working Date:</div>
                        <div className='field-value'>{DDMMMYY(selectedEmployee?.["separation_date"]) ?? "--"}</div>
                    </div>
                    <div className='detail-field'>
                        <div className='details-lbl'>Employee Personal Contact:</div>
                        <div className='field-value maskeddetails'>{selectedEmployee?.["employee_personal_mobile"]
                            ?   <>
                                {isContactUnmasked? selectedEmployee?.["employee_personal_mobile"] : maskMobile(selectedEmployee?.["employee_personal_mobile"])}
                                <img
                                    alt="eyeslash"
                                    src={isContactUnmasked ?eye:eyeslash}
                                    onClick={() => setIsContactUnmasked(!isContactUnmasked)} // Toggle between true/false for mask/unmask
                                ></img>
                                </>
                            : "--"}
                        </div>  
                    </div>
                    <div className='detail-field'>
                        <div className='details-lbl'>Employee Personal Email:</div>
                        <div className='field-value maskeddetails'>{selectedEmployee?.["employee_personal_email"] 
                            ? <>{isEmailUnmasked?selectedEmployee?.["employee_personal_email"]:maskEmail(selectedEmployee?.["employee_personal_email"])} 
                                <img
                                    alt="eyeslash"
                                    src={isEmailUnmasked ?eye:eyeslash}
                                    onClick={() => setIsEmailUnmasked(!isEmailUnmasked)} // Toggle between true/false for mask/unmask
                                ></img>
                            </>
                            : "--"}
                        </div>
                    </div>
                </div>
                <div className='mt-3px'><hr /></div>
                <div className='mt-10px'>
                    <div className='toggle-card-heading mb-10'>{constantVals.timeline}</div>
                    {
                        showNetAmount &&
                        <div className='d-flex flex-direction-row ml-3 justify-content-start w-100 gap-10'>
                            <div className='w-16'>
                                <div className='appcenter-app-name1 mt-0px mr-10'>{"Total Recovery Amount"} </div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        readOnly={true}
                                        placeholder='NA'
                                        value={totalRecoveryAmt}
                                    />
                                </div>
                            </div>
                            <div className='w-16'>
                                <div className='appcenter-app-name1 mt-0px mr-10'>{"Total Payable Amount"} </div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        readOnly={true}
                                        placeholder='NA'
                                        value={totalPayableAmt}
                                    />
                                </div>
                            </div>
                            <div className='w-16'>
                                <div className='appcenter-app-name1 mt-0px mr-10'>{"Net Amount"} </div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        readOnly={true}
                                        placeholder='NA'
                                        value={netAmount}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    <AgencyWiseTrack selectedClearance={selectedEmployee} screenType={screenType} response={response}/>
                </div>
            </div>

            {ConfirmPopUp &&
                <ConfirmationPopUp onDismiss={() => {
                    setConfirmPopUp(false)
                }} title={"Confirmation"} loading={false} onConfirmAction={() => {
                    secondaryBtnAction()
                    setConfirmPopUp(false)
                }} message={"Are you sure that you want to revoke clearance for selected Employee"} />
            }
        </div>
    )
}

export default ClearanceRequestDashboard