import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../../../../assets/styles/popupStyles.css';
import rightArrow from '../../../../assets/drishti_images/right-arrow-bg.svg';
import NewNotdata from '../../../shared/notFound/NewNoData';
import edit from '../../../../assets/images/cfm/edit.svg'
import '../../employeeScreens/leave/LeaveScreen.css';
import radioOff from '../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../assets/drishti_images/radioOn.png';
import Dropdown from '../../../shared/components/Dropdown';
import { applicants, adminScreenAction, admin_radio_fiters, adminGemsAllFilter } from '../../employeeScreens/gems/Constant'
import { getAdminProject, getDuration, getOrgUnit, getArea, publishAdminProject, submitUpdatedData, updateProjectStatus, updateMTRFR, fetchMTRSummary, fetchFRSummary, downloadReport, searchApplication, fetchProjectCount } from '../../employeeScreens/gems/Gems.api.service';
import { categoryFilter, exportToExcel, toaster } from '../../../../utils/Utils';
import UpdateProjectStatusPopup from './UpdateProjectStatusPopup';
import ApplicantsScreen from './ApplicantsScreen';
import GEMSConfirmPopup from './GEMSConfirmPopup'
import MTRFRObjectivePopup from './MTRFRObjectivePopup';
import moment from 'moment';
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import { warnings } from '../../employeeScreens/gems/Constant';
import exportExcelcon from '../../../../assets/drishti_images/admin/exportExcelcon.svg';
import exportExcelconActive from '../../../../assets/drishti_images/admin/exportExcelconActive.svg';
import { attendanceDetails, gemsDetails } from '../../../../constants/Messages';
import { toast } from 'react-toastify';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import './GemsAdmin.css';

const categoryListTypes = [
    { key: 0, value: "Stretch" },
    { key: 1, value: "Deputation" }
]
class GEMSSpocScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectDetails: [],
            selectedProjects: [],
            selectedProjectsBackup: [],
            isSelected: 0,
            checked: 0,
            tabFilterType: "Review",
            tabFilterStatus: "Approved",
            projectStatusDate: adminGemsAllFilter,
            projectCurrentStatus: 'All',
            radioFilters: admin_radio_fiters(),
            loading: false,
            appliedProject: [],
            page_number: 1,
            total_records: 0,
            total_pages: 0,
            updProjectStatusPopUp: false,
            applicantView: true,
            isEditProject: false,
            projectTitle: "",
            projectGuideName: "",
            projectBHR: "",
            projectStartDate: "",
            fetchProjectArea: [],
            setProjectArea: "",
            fetchProjectOU: [],
            setProjectOU: "",
            fetchProjectDuration: [],
            setProjectDuration: "",
            isPublishSelectedId: null,
            isPublishPopup: false,
            updateDataIndex: null,
            isUpdateSelectedId: null,
            isUpdatePopup: false,
            mtrFRShow: false,
            mtrFRShowData: [],
            project_id: '',
            hoverData: [],
            categoryFilter: categoryFilter(),
            filterValue: 'Category',
            filterCode: '',
            category: '',
            isExport: false,
            isProjectExport: false,
            titleText: '',
            empId: '',
            searchApplicantData: [],
            selectedTabStatus: '',
            projectsCount: []
        }
    }

    componentDidMount() {
        const { tabFilterStatus, page_number } = this.state
        this.setState({
            selectedProjects: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0]] : [],
            projectTitle: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].title] : [],
            projectGuideName: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].project_guide?.CompName] : [],
            projectBHR: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].bhr?.CompName] : [],
            projectStartDate: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].actual_start_date] ? [this.state.projectDetails[0].actual_start_date] : [this.state.projectDetails[0].planned_start_date] : [],
        })
        this.getProjects(tabFilterStatus, page_number)
        this.getCount()
    }

    updateConfrimation = (id, index) => {
        this.setState({
            isUpdatePopup: true,
            isUpdateSelectedId: id,
            updateDataIndex: index
        })
    }

    submitUpdated = (id, index) => {
        this.setState({
            loading: true
        })
        const body = {
            // "project_id": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].id : {},
            // "emp_id": this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            "title": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].title : {},
            "description": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].description : {},
            "location": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].location : {},
            "no_of_openings": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].no_of_openings : {},
            "duration": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].duration : {},
            "category": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].category : {},
            "org_unit": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].org_unit?.id : {},
            "area": this.state.selectedProjects !== {} ? this.state.selectedProjects[index].area?.id : {},
            "planned_start_date": this.state.selectedProjects[index].actual_start_date ? moment(this.state.selectedProjects[index].actual_start_date).format("YYYY-MM-DD") : this.state.selectedProjects[index].planned_start_date ? moment(this.state.selectedProjects[index].planned_start_date).format("YYYY-MM-DD") : null,
            "planned_end_date": this.state.selectedProjects[index].actual_end_date ? moment(this.state.selectedProjects[index].actual_end_date).format("YYYY-MM-DD") : this.state.selectedProjects[index].planned_end_date ? moment(this.state.selectedProjects[index].planned_end_date).format("YYYY-MM-DD") : null,
            "planned_mtr_date": this.state.selectedProjects[index].actual_mtr_date ? moment(this.state.selectedProjects[index].actual_mtr_date).format("YYYY-MM-DD") : this.state.selectedProjects[index].planned_mtr_date ? moment(this.state.selectedProjects[index].planned_mtr_date).format("YYYY-MM-DD") : null,
            "planned_fr_date": this.state.selectedProjects[index].actual_fr_date ? moment(this.state.selectedProjects[index].actual_fr_date).format("YYYY-MM-DD") : this.state.selectedProjects[index].planned_fr_date ? moment(this.state.selectedProjects[index].planned_fr_date).format("YYYY-MM-DD") : null,
        }
        submitUpdatedData(id, body)
            .then((res) => {
                console.log("submitUpdatedData res : ", res)
                this.setState({
                    loading: false,
                    isUpdatePopup: false
                })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "updated successfully")
                    this.setState({
                        isEditProject: false,
                        isSelected: index
                    })
                    this.getProjects(this.state.tabFilterStatus, 1)
                }
                else {
                    this.setState({
                        isEditProject: false
                    })
                    toaster("warning", res.data.message ? res.data.message : "Something went wrong")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                toaster("", "Something went wrong")
                console.log("submitUpdatedData error:", err)
            })
    }

    publishConfirmation = (id) => {
        this.setState({
            isPublishPopup: true,
            isPublishSelectedId: id
        })
    }

    publishProject = (id) => {
        this.setState({ loading: true, isPublishPopup: false })
        const body = {
            "status": "Published",
        }
        publishAdminProject(id, body)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({
                        checked: 1
                    })
                }
                else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
                this.getProjects("Published", 1)
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                toaster("", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    openEditScreen = () => {
        this.setState({ loading: true, isEditProject: true })
        this.getDurationCall(this.state.selectedProjects[0].category)
        getArea("admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        fetchProjectArea: res.data.data ? res.data.data.map(tempValue => ({ code: tempValue.id, value: tempValue.area })) : []
                    })
                }
                else {
                    this.setState({
                        fetchProjectArea: []
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                toaster("", "Something went wrong")
                console.log("Get Area catch error:", err)
            })

        getOrgUnit("admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        fetchProjectOU: res.data.data ? res.data.data.map(tempValue => ({ code: tempValue.id, value: tempValue.org_unit })) : []
                    })
                }
                else {
                    this.setState({
                        fetchProjectOU: []
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                toaster("", "Something went wrong")
                console.log("Get OrgUnit catch error:", err)
            })
    }

    getDurationCall = (catkey, callType, index, key) => {
        this.setState({
            loading: true
        })
        const params = {
            project_category: catkey
        }
        getDuration(params, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    if (callType === "Cat Call") {
                        let updatedSelectedProjects = this.state.selectedProjects;
                        this.setState({
                            fetchProjectDuration: res.data.data ? res.data.data.map(tempValue => ({ code: tempValue.id, value: tempValue.project_duration })) : []
                        },
                            () => console.log("Drop Click Value ", this.state.fetchProjectDuration[0].value),
                            updatedSelectedProjects[index][key] = res.data.data ? res.data.data[0].project_duration : " "
                        )
                        let getProjectDurationValue = this.state.fetchProjectDuration[0].value
                        let startDate = updatedSelectedProjects[index]['actual_start_date'] ? updatedSelectedProjects[index]['actual_start_date'] : updatedSelectedProjects[index]['planned_start_date']
                        let finalProjectDate = new Date(new Date(startDate).getTime() + getProjectDurationValue * 24 * 60 * 60 * 1000)
                        const firstDate = new Date(startDate);
                        const lastDate = finalProjectDate;
                        const timeDiffInMillis = lastDate - firstDate;
                        const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
                        const mtrDate = new Date(midpointTime);
                        updatedSelectedProjects[index]['actual_start_date'] = new Date(startDate)
                        updatedSelectedProjects[index]['actual_end_date'] = lastDate;
                        updatedSelectedProjects[index]['actual_fr_date'] = lastDate;
                        updatedSelectedProjects[index]['actual_mtr_date'] = mtrDate;
                        this.setState({
                            selectedProjects: updatedSelectedProjects,
                        })
                    } else {
                        this.setState({
                            fetchProjectDuration: res.data.data ? res.data.data.map(tempValue => ({ code: tempValue.id, value: tempValue.project_duration })) : []
                        })
                    }
                }
                else {
                    this.setState({
                        fetchProjectDuration: []
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                toaster("", "Something went wrong")
                console.log("Get Duration catch error:", err)
            })
    }

    goToApplicantScreen = (data) => {
        this.setState({
            hoverData: data,
            applicantView: false
        })
    }

    closeApplicantScreen = () => {
        const { selectedTabStatus, page_number } = this.state
        this.setState({
            applicantView: true
        })
        this.getProjects(selectedTabStatus, page_number)
    }

    morePage = () => {
        const { tabFilterStatus, page_number, selectedTabStatus } = this.state
        this.setState({
            page_number: this.state.page_number + 1
        },
            () => this.getProjects(this.state.selectedTabStatus, this.state.page_number))
    }

    projectStatus = (data) => {
        this.setState({
            titleText: '',
            isEditProject: false,
            projectCurrentStatus: data.value,
            selectedProjects: [],
            projectDetails: []
        }, () => this.getProjects(this.state.projectCurrentStatus, 1, this.state.filterCode))
    }

    changeStatusType = (data, category) => {
        this.setState({
            titleText: '',
            filterCode: data.key == 'All' ? '' : data.key,
            filterValue: data.value,
            category: this.state.category,
            projectCurrentStatus: this.state.projectCurrentStatus
        }, () => this.getProjects(category == 'category' && this.state.checked == 1 ? "Published" : category == 'category' && this.state.checked == 0 ? "Approved" : category == 'category' && this.state.checked == 2 ? this.state.projectCurrentStatus : '', this.state.page_number, this.state.filterCode))
    }

    searchProjectFilter = () => {
        this.setState({
            projectDetails: [],
            category: this.state.category,
            projectCurrentStatus: this.state.projectCurrentStatus
        }, () => this.getProjects(this.state.checked == 1 ? "Published" : this.state.checked == 0 ? "Approved" : this.state.checked == 2 ? this.state.projectCurrentStatus : '', this.state.titleText !== '' ? '' : this.state.page_number, this.state.filterCode))
    }

    selectProjectTab = (id, data) => {
        this.setState({
            isEditProject: false,
            isSelected: id,
            selectedProjects: [data],
            projectTitle: data.title,
            projectStartDate: data.actual_start_date ? data.actual_start_date : data.planned_start_date,
            projectBHR: data.bhr?.CompName,
            projectGuideName: data.project_guide?.CompName
        })
    }

    onPresentType = (key, type, status) => {
        if (type === "Search Application") {
            this.setState({
                tabFilterType: type,
                checked: key
            })
        } else {
            this.setState({
                titleText: '',
                isEditProject: false,
                projectTitle: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].title] : "",
                projectGuideName: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].project_guide?.CompName] : "",
                projectBHR: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].bhr?.CompName] : "",
                projectStartDate: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].actual_start_date] ? [this.state.projectDetails[0].actual_start_date] : "" : "",
                projectPlanedStartDate: this.state.projectDetails.length !== 0 ? [this.state.projectDetails[0].planned_start_date] ? [this.state.projectDetails[0].planned_start_date] : "" : "",
                projectCurrentStatus: "All",
                checked: key,
                tabFilterType: type,
                selectedProjects: [],
                projectDetails: [],
                filterValue: 'Category',
                filterCode: '',
                tabFilterStatus: '',
                category: '',
                searchApplicantData: [],
                empId: '',
                selectedTabStatus: status,
                isSelected: 0,
                page_number: 1
            }, () => this.getProjects(status, this.state.page_number))
        }
    }

    getProjects = (status, pageNo, type) => {
        this.setState({
            loading: true,
            type: '',
            projectDetails: pageNo == 1 ? [] : this.state.projectDetails
        })
        const param = {
            status: status == 'All' ? '' : status,
            category: type ? type : '',
            page_number: pageNo,
            title: this.state.titleText ? this.state.titleText : '',
        }
        getAdminProject(param, this.props)
            .then((res) => {
                const { selectedProjects } = this.state
                this.setState({
                    loading: false
                })
                if (res.status == 200 || res.status == 201) {
                    let selectedData
                    if (res.data.data.paginated_data.length > 0) {
                        selectedData = [res.data.data.paginated_data[0]]
                        if (selectedProjects.length == 0) {
                            selectedData = [res.data.data.paginated_data[0]]
                        } else {
                            res.data.data.paginated_data.some(function (element) {
                                if (element.id == selectedProjects[0].id) {
                                    selectedData = [element]
                                    return true
                                }
                                return false
                            })
                        }
                    } else {
                        selectedData = []
                        this.setState({
                            projectDetails: [],
                        })
                    }
                    this.setState({
                        projectDetails: this.state.projectDetails.concat(res.data.data.paginated_data ? res.data.data.paginated_data : ""),
                        selectedProjects: selectedData,
                        selectedProjectsBackup: selectedData,
                        page_number: res.data.data.page_number ? res.data.data.page_number : "",
                        total_records: res.data.data.total_records ? res.data.data.total_records : "",
                        total_pages: res.data.data.total_pages ? res.data.data.total_pages : ""
                    })
                }
                else {
                    this.setState({
                        projectDetails: [],
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    projectDetails: [],
                    selectedProjects: []
                })
                toaster("", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    updatePopup = (id) => {
        this.setState({
            updProjectStatusPopUp: true,
            project_id: id
        })
    }

    statusUpdate = (status, details) => {
        this.setState({
            loading: true,
            isPublishPopup: false
        })
        const body = {
            "status": status,
            // "emp_id": this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            "duration": this.state.selectedProjects[0]?.duration,
            "category": this.state.selectedProjects[0]?.category,
            "planned_start_date": this.state.selectedProjects[0]?.planned_start_date,
            "actual_start_date": details?.actual_start_date,
            "actual_end_date": details?.actual_end_date,
            "revised_mtr_date": details?.actual_mtr_date,
            "revised_fr_date": details?.actual_fr_date,
        }
        updateProjectStatus(this.state.project_id, body, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    { status === "Published" ? this.setState({ checked: 1 }) : this.setState({ checked: 2 }) }
                    this.setState({
                        updProjectStatusPopUp: false,
                        selectedProjects: []
                    },
                        () => this.getProjects(status === "Published" ? "Published" : "All", 1)
                    )
                }
                else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }

            })
            .catch((err) => {
                this.setState({
                    loading: false,
                })
                toaster("", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }

    updatState = (index, key, objVal, value) => {
        if (objVal === "") {
            let updatedSelectedProjects = this.state.selectedProjects;
            updatedSelectedProjects[index][key] = value;
            if (key == "actual_start_date") {
                let getProjectDurationValue = this.state.selectedProjects[index]['duration']
                let startDate = value
                let finalProjectDate = new Date(new Date(startDate).getTime() + getProjectDurationValue * 24 * 60 * 60 * 1000)
                const firstDate = new Date(startDate);
                const lastDate = finalProjectDate;
                const timeDiffInMillis = lastDate - firstDate;
                const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
                const mtrDate = new Date(midpointTime);
                updatedSelectedProjects[index]['actual_start_date'] = new Date(startDate)
                updatedSelectedProjects[index]['actual_end_date'] = lastDate
                updatedSelectedProjects[index]['actual_fr_date'] = lastDate
                updatedSelectedProjects[index]['actual_mtr_date'] = mtrDate
            }
            this.setState({
                selectedProjects: updatedSelectedProjects,
            });
        } else {
            let updatedSelectedProjects = this.state.selectedProjects;
            updatedSelectedProjects[index][key].objVal = value;
            this.setState({
                selectedProjects: updatedSelectedProjects,
            });
        }
    }

    changeDropdownValue = (index, key, objVal, data) => {
        if (objVal === "") {
            let updatedSelectedProjects = this.state.selectedProjects;
            updatedSelectedProjects[index][key] = data.value;
            if (key == "category") {
                let getProjectDurationValue = data.value
                let startDate = updatedSelectedProjects[index]['actual_start_date'] ? updatedSelectedProjects[index]['actual_start_date'] : updatedSelectedProjects[index]['planned_start_date']
                let finalProjectDate = new Date(new Date(startDate).getTime() + getProjectDurationValue * 24 * 60 * 60 * 1000)
                const firstDate = new Date(startDate);
                const lastDate = finalProjectDate;
                const timeDiffInMillis = lastDate - firstDate;
                const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
                const mtrDate = new Date(midpointTime);
                updatedSelectedProjects[index]['actual_start_date'] = new Date(startDate)
                updatedSelectedProjects[index]['actual_end_date'] = lastDate;
                updatedSelectedProjects[index]['actual_fr_date'] = lastDate;
                updatedSelectedProjects[index]['actual_mtr_date'] = mtrDate;
                this.getDurationCall(data.value, "Cat Call", index, "duration")
            }

            if (key == "duration") {
                let getProjectDurationValue = data.value
                let startDate = updatedSelectedProjects[index]['actual_start_date'] ? updatedSelectedProjects[index]['actual_start_date'] : updatedSelectedProjects[index]['planned_start_date']
                let finalProjectDate = new Date(new Date(startDate).getTime() + getProjectDurationValue * 24 * 60 * 60 * 1000)
                const firstDate = new Date(startDate);
                const lastDate = finalProjectDate;
                const timeDiffInMillis = lastDate - firstDate;
                const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
                const mtrDate = new Date(midpointTime);
                updatedSelectedProjects[index]['actual_start_date'] = new Date(startDate)
                updatedSelectedProjects[index]['actual_end_date'] = lastDate;
                updatedSelectedProjects[index]['actual_fr_date'] = lastDate;
                updatedSelectedProjects[index]['actual_mtr_date'] = mtrDate;
            }
            this.setState({
                selectedProjects: updatedSelectedProjects,
            });
        } else {
            let updatedSelectedProjects = this.state.selectedProjects;
            updatedSelectedProjects[index][key][objVal] = data.value;
            if (key == 'org_unit' || key == 'area') {
                updatedSelectedProjects[index][key].id = data.code;
            }
            this.setState({
                selectedProjects: updatedSelectedProjects,
            });
        }
    }

    showObjective = (dataValue) => {
        this.setState({
            mtrFRShow: true,
            mtrFRShowData: dataValue.id
        })
    }

    updateMTRFR = (data, obj) => {
        this.updateProjectObj(data)
    }

    updateProjectObj = (action, data) => {
        let body = {
            "gems_review_objective": action === 'submit' ? data : action.deliverable
        }
        if (action === "submit") {
            updateMTRFR(this.state.mtrFRShowData, body, "admin")
                .then((res) => {
                    this.setState({
                        loading: false,
                        mtrFRShow: false
                    })
                    if (res.status === 200 || res.status === 201) {
                        this.setState({
                            loading: false,
                            mtrFRShow: false
                        })
                        toaster("success", res.data.message ? res.data.message : "")
                    }
                    else {
                        this.setState({
                            loading: false,
                            mtrFRShow: false
                        })
                        toaster("warning", res.data.message ? res.data.message : "")
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                        mtrFRShow: false
                    })
                    console.log("raiseProject catch error:", err)
                })
        }
        else {
            console.log("updateProjectObj else")
        }
    }

    submitAddProject = (data, obj) => {
        let UpdatedBody = {
            title: data.projectTitle,
            description: data.projectDescription,
            area: data.projectAreaValue,
            org_unit: data.OUValue,
            location: data.pgLocation,
            no_of_openings: data.noOfPeople,
            duration: data.projectDuration,
            planned_start_date: moment(new Date(data.selectedStartDate)).format("YYYY-MM-DD"),
            planned_end_date: moment(new Date(data.selectedEndDate)).format("YYYY-MM-DD"),
            planned_mtr_date: moment(new Date(data.selectedMTRDate)).format("YYYY-MM-DD"),
            planned_fr_date: moment(new Date(data.selectedFRDate)).format("YYYY-MM-DD"),
            gems_review_objective: data.targetDeliverable,
        }
        let body = UpdatedBody
    }

    downloadMTR = (projectID) => {
        this.setState({
            loading: true
        })
        fetchMTRSummary(projectID, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchMTRSummary catch err>>", err)
            })
    }

    downloadFR = (projectID) => {
        this.setState({
            loading: true
        })
        fetchFRSummary(projectID, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchFRSummary catch err>>", err)
            })
    }

    getSeachAppData = (empId) => {
        this.setState({
            loading: true
        })
        searchApplication(empId)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status == 200 || res.status == 201) {
                    this.setState({
                        searchApplicantData: res.data.data ? res.data.data : [],
                    })
                }
                else {
                    this.setState({
                        searchApplicantData: []
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    searchApplicantData: []
                })
                toaster("", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    getCount = () => {
        this.setState({
            loading: true
        })
        fetchProjectCount()
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status == 200 || res.status == 201) {
                    this.setState({
                        projectsCount: res.data.data ? res.data.data : [],
                    })
                }
                else {
                    this.setState({
                        projectsCount: []
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    projectsCount: []
                })
                toaster("", "Something went wrong")
                console.log("get ProjectCount catch error:", err)
            })
    }

    downloadReport = (application) => {
        this.setState({
            loading: true
        })
        downloadReport(application, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    this.setState({ loading: false });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            })
            .catch((err) => {
                console.log('err', err);
                this.setState({
                    loading: false
                })
            })
    }

    onClickProjectExport = () => {
        this.setState({
            isProjectExport: !this.state.isProjectExport
        }, () => this.downloadReport('project'))
    }

    onClickExport = () => {
        this.setState({
            isExport: !this.state.isExport
        }, () => this.downloadReport('application'))
    }

    render() {
        const today = new Date();
        const priorDate = new Date(new Date().setDate(today.getDate() + 16));
        const toDay = priorDate.toISOString().substring(0, 10); // for hiding dates
        const { loading, isExport, isProjectExport } = this.state;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                <div className="main-contain" >
                    {this.state.applicantView ?
                        <>
                            <div className='d-flex row justify-content-between mt-3'>
                                <div className='d-flex row justify-content-start'>
                                    <div className='status-dashboard ml-4 mt-2'>{'Status :'}</div>
                                    {this.state.radioFilters.length != 0 && this.state.radioFilters.map((list, key) => {
                                        return (
                                            <div key={key} className='mx-4 mt-1 mb-2 '>
                                                {this.state.checked === key ?
                                                    <div className='row align-item-center' onClick={() => this.onPresentType(key, list.title, list.status)}>
                                                        <img alt="radioOn" className='radio' src={radioOn} />
                                                        <label className="radioTxt" >{list.title}</label>
                                                        {key !== 3 &&
                                                            <div className="radioTxt">{key === 0 ? '(' + this.state.projectsCount.review + ')' : key === 1 ? '(' + this.state.projectsCount.shortlisting + ')' : key === 2 ? '(' + this.state.projectsCount.all + ')' : ''}</div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className='row align-item-center' onClick={() => this.onPresentType(key, list.title, list.status)}>
                                                        <img alt="radioOff" className='radio' src={radioOff} />
                                                        <label className="radioTxt">{list.title}</label>
                                                        {key !== 3 &&
                                                            <div className="radioTxt">{key === 0 ? '(' + this.state.projectsCount.review + ')' : key === 1 ? '(' + this.state.projectsCount.shortlisting + ')' : key === 2 ? '(' + this.state.projectsCount.all + ')' : ''}</div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                                {this.state.tabFilterType !== "Search Application" &&
                                    <div className='flex-direction-row justify-content-end'>
                                        <div className='row'>
                                            <div className='p-placeholder-text mb-1'>
                                                <input
                                                    className="form-control p-placeholder px-2 p-placeholder-admin"
                                                    type="text"
                                                    placeholder='Search Project'
                                                    onChange={(e) => this.setState({ titleText: e.target.value })}
                                                    value={this.state.titleText}
                                                />
                                            </div>
                                            <div type='button' className='serchIconSize-gems' onClick={() => this.searchProjectFilter()}>
                                                <img alt="radioOn" className='filterIcon' src={searchIcon} />
                                            </div>
                                        </div>
                                        {this.state.tabFilterType == "All" &&
                                            <div className='dropdown-Text'>
                                                <Dropdown
                                                    data={this.state.projectStatusDate}
                                                    selectedValue={(data) => this.projectStatus(data)}
                                                    value={this.state.projectCurrentStatus}
                                                    render={'value'}
                                                    arrow={'true'}
                                                    height={'dependOnContent'}
                                                />
                                            </div>
                                        }
                                        <div className='dropdown-Text'>
                                            <Dropdown
                                                data={this.state.categoryFilter}
                                                selectedValue={(data) => this.changeStatusType(data, 'category')}
                                                value={this.state.filterValue}
                                                render={'value'}
                                                arrow={'true'}
                                                height={'NotificationHeight'}
                                            />
                                        </div>

                                        <div data-tooltip2={'Export Projects'} className="filterIconLabel infoIconStyle ml-1" >
                                            <div className='row mt-3px'
                                                onClick={() => this.onClickProjectExport()}
                                                onMouseOver={() => this.setState({ isProjectExport: true })}
                                                onMouseLeave={() => this.setState({ isProjectExport: false })}>
                                                <div className='mr-4'><img alt="excel" className='filterIcon' src={isProjectExport ? exportExcelconActive : exportExcelcon} ></img></div>
                                            </div>
                                        </div>

                                        <div data-tooltip2={'Export Applications'} className="exportApplicationLabel applicationIconStyle ml-1" >
                                            <div className='row mt-3px'
                                                onClick={() => this.onClickExport()}
                                                onMouseOver={() => this.setState({ isExport: true })}
                                                onMouseLeave={() => this.setState({ isExport: false })}>
                                                <div className='mr-4'><img alt="excel" className='filterIcon' src={isExport ? exportExcelconActive : exportExcelcon} ></img></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {this.state.tabFilterType === "Search Application" &&
                                <div className='flex-direction-row justify-content-start mb-10'>
                                    <div className='row' style={{ "gap": "15px" }}>
                                        <div className='status-dashboard ml-4 mt-2'>{'Appicant Id :'}</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                                type="number"
                                                placeholder='Search Employee'
                                                onChange={(e) => this.setState({ empId: e.target.value })}
                                                value={this.state.empId}
                                            />
                                        </div>
                                        <div className='mt-2px'>
                                            {this.state.empId !== '' ?
                                                <input
                                                    type="button"
                                                    value='SEARCH'
                                                    className=' validateBtn'
                                                    onClick={() => this.getSeachAppData(this.state.empId)} />
                                                :
                                                <input
                                                    type="button"
                                                    value='SEARCH'
                                                    className=' validateBtnDisabled'
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.tabFilterType === "Search Application" ?
                                <div>
                                    {this.state.searchApplicantData.length !== 0 ?
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><div className='thead pr-0'>{"Sr. No"}</div></th>
                                                    <th scope="col"><div className='thead pr-0'>{"Project ID"}</div></th>
                                                    <th scope="col"><div className='thead pr-0'>{"Project Title"}</div></th>
                                                    <th scope="col"><div className='thead pr-0'>{"Project Status"}</div></th>
                                                    <th scope="col"><div className='thead pr-0'>{"Applicant Id"}</div></th>
                                                    <th scope="col"><div className='thead pr-0'>{"Applicant Name"}</div></th>
                                                    <th scope="col"><div className='thead pr-0'>{"Application Status"}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.searchApplicantData.map((listValue, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td> <div className="td w-20px">{index + 1}</div></td>
                                                            <td> <div className="td d-flex">{listValue.project_uid !== null ? listValue.project_uid : "-"}</div></td>
                                                            <td> <div className="td d-flex">{listValue.project_title !== null ? listValue.project_title : "-"}</div></td>
                                                            <td> <div className="td d-flex">{listValue.project_status !== null ? listValue.project_status : "-"}</div></td>
                                                            <td> <div className="td d-flex">{listValue.applicant_uid !== null ? listValue.applicant_uid : "-"}</div></td>
                                                            <td> <div className="td d-flex">{listValue.applicant_company !== null ? listValue.applicant_company : "-"}</div></td>
                                                            <td> <div className="td d-flex">{listValue.application_status !== null ? listValue.application_status : "-"}</div></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        :
                                        <span className='justify-content-center align-item-center-noflex flex_1 h-100 mt-10p'>
                                            <NewNotdata />
                                        </span>
                                    }
                                </div>
                                :
                                <div className='flex-direction-row admin-gems-main'>
                                    <div className='scroll-auto mb-10px h-97-percent'>
                                        {
                                            this.state.projectDetails.length !== 0 ?
                                                this.state.projectDetails.map((dataValue, id) =>
                                                    <div className={`card-new align-item-center-noflex flex-direction-row mr-10 mb-10px pointer card-style justify-content-between ${this.state.isSelected == id ? "selected-tab" : ""}`}
                                                        onClick={() => this.selectProjectTab(id, dataValue)}
                                                        key={id}>
                                                        <span className='align-item-center'>
                                                            <div className='padding-left-10'>
                                                                <span>
                                                                    <span className='flex-direction-row p-5px document-innercard-content-title'>{dataValue.title}</span>
                                                                    <span className='project-short-view px-5px'>
                                                                        <span>
                                                                            <span className='admin-gems-description-title'>{"Start Date"}</span>
                                                                            <span className='feedback-sm-text'> {dataValue.actual_start_date ? moment(dataValue.actual_start_date).format('DD-MM-YYYY') : dataValue.planned_start_date ? moment(dataValue.planned_start_date).format('DD-MM-YYYY') : "-"}</span>
                                                                        </span>
                                                                        <span>
                                                                            <span className='admin-gems-description-title'>{"PG Name"}</span>
                                                                            <span className='feedback-sm-text'> {dataValue.project_guide?.CompName ? dataValue.project_guide?.CompName : "-"}</span>
                                                                        </span>
                                                                        {dataValue.category === "Deputation" ?
                                                                            <>
                                                                                <span>
                                                                                    <span className='admin-gems-description-title'>{"Applications Received"}</span>
                                                                                    <span className='feedback-sm-text'> {dataValue.appl_received ? dataValue.appl_received : "-"}</span>
                                                                                </span>
                                                                                <span>
                                                                                    <span className='admin-gems-description-title'>{"Applications Shortlisted By SPOC"}</span>
                                                                                    <span className='feedback-sm-text'> {dataValue.appl_shrtlted_by_spoc ? dataValue.appl_shrtlted_by_spoc : "-"}</span>
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <span>
                                                                                    <span className='admin-gems-description-title'>{"No. of Opening"}</span>
                                                                                    <span className='feedback-sm-text'> {dataValue.no_of_openings ? dataValue.no_of_openings : "-"}</span>
                                                                                </span>
                                                                                <span>
                                                                                    <span className='admin-gems-description-title'>{"BHR Name"}</span>
                                                                                    <span className='feedback-sm-text'> {dataValue.bhr?.CompName ? dataValue.bhr?.CompName : "-"}</span>
                                                                                </span>
                                                                            </>
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </span>
                                                        {
                                                            dataValue.appl_pending > 0 ?
                                                                <span className='arrow-right-and-count'>
                                                                    <span className='application-pending-count'>{dataValue.appl_pending} </span>
                                                                    <img
                                                                        alt="right arrow"
                                                                        src={rightArrow}
                                                                        className="right-arrow"
                                                                    />
                                                                </span>
                                                                :
                                                                <img
                                                                    alt="right arrow"
                                                                    src={rightArrow}
                                                                    className="right-arrow"
                                                                />
                                                        }
                                                    </div>
                                                )
                                                :
                                                <span className='justify-content-center align-item-center-noflex flex_1 h-100'>
                                                    <NewNotdata />
                                                </span>
                                        }
                                        {this.state.page_number < this.state.total_pages && this.state.total_pages > 1 &&
                                            <div className='m-10px'>
                                                <div
                                                    className='row col-12 d-flex justify-content-center card-for-click mx-10'
                                                    onClick={() => this.morePage()}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <label className='card-content-title white-color p-5px' >
                                                        {"MORE"}
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='card-inner ml-10px mb-10px p-10px'>
                                        {this.state.selectedProjects.length !== 0 ?
                                            <>
                                                {this.state.isEditProject ?
                                                    <>
                                                        {/* //------------------------------EDITABLE SCREEN--------------------------/ */}
                                                        {this.state.selectedProjects.map((listValue, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className='row overflow-gems-fields'>

                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project Guide Name"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    maxLength={50}
                                                                                    readOnly={true}
                                                                                    // onChange={(e) => this.updatState(index, "project_guide", "CompName", e.target.value)}
                                                                                    value={listValue.project_guide?.CompName}
                                                                                />
                                                                                <div className="justify-content-start time-text ml-3">
                                                                                    {this.lengthCalculate(listValue.project_guide?.CompName)}/50
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project Title"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    maxLength={50}
                                                                                    onChange={(e) => this.updatState(index, "title", "", e.target.value)}
                                                                                    value={listValue.title}
                                                                                />
                                                                                <div className="justify-content-start time-text ml-3">
                                                                                    {this.lengthCalculate(listValue.title)}/50
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project Guide Location"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    maxLength={50}
                                                                                    onChange={(e) => this.updatState(index, "location", "", e.target.value)}
                                                                                    value={listValue.location}
                                                                                />
                                                                                <div className="justify-content-start time-text ml-3">
                                                                                    {this.lengthCalculate(listValue.location)}/50
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Number of people"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    onChange={(e) => this.updatState(index, "no_of_openings", "", e.target.value)}
                                                                                    value={listValue.no_of_openings}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project Approver Name"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    maxLength={50}
                                                                                    readOnly={true}
                                                                                    placeholder={'-'}
                                                                                    // onChange={(e) => this.updatState(index, "manager", "CompName", e.target.value)}
                                                                                    value={listValue.manager?.CompName}
                                                                                />
                                                                                <div className="justify-content-start time-text ml-3">
                                                                                    {this.lengthCalculate(listValue.manager?.CompName)}/50
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project BHR Name"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    maxLength={50}
                                                                                    readOnly={true}
                                                                                    placeholder={'-'}
                                                                                    // onChange={(e) => this.updatState(index, "bhr", "CompName", e.target.value)}
                                                                                    value={listValue.bhr?.CompName}
                                                                                />
                                                                                <div className="justify-content-start time-text ml-3">
                                                                                    {this.lengthCalculate(listValue.bhr?.CompName)}/50
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Category"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                {listValue.status === "Approved" || listValue.status === "Published" || listValue.status === "Initiated" ?
                                                                                    <Dropdown
                                                                                        data={categoryListTypes}
                                                                                        selectedValue={(data) => this.changeDropdownValue(index, "category", "", data)}
                                                                                        value={listValue.category}
                                                                                        render={'value'}
                                                                                        arrow={'true'}
                                                                                        height={'innerCard'}
                                                                                    />
                                                                                    :
                                                                                    <Dropdown
                                                                                        data={categoryListTypes}
                                                                                        value={listValue.category}
                                                                                        render={'value'}
                                                                                        arrow={'true'}
                                                                                        height={'innerCard'}
                                                                                        disabled={true}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Duration (Days)"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                {listValue.status === "Approved" || listValue.status === "Published" || listValue.status === "Initiated" ?
                                                                                    <Dropdown
                                                                                        data={this.state.fetchProjectDuration}
                                                                                        selectedValue={(data) => this.changeDropdownValue(index, "duration", "", data)}
                                                                                        value={listValue.duration}
                                                                                        render={'value'}
                                                                                        arrow={'true'}
                                                                                        height={'innerCard'}
                                                                                    />
                                                                                    :
                                                                                    <Dropdown
                                                                                        data={this.state.fetchProjectDuration}
                                                                                        value={listValue.duration}
                                                                                        render={'value'}
                                                                                        arrow={'true'}
                                                                                        height={'innerCard'}
                                                                                        disabled={true}
                                                                                    />}
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Entity"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <Dropdown
                                                                                    data={this.state.fetchProjectOU}
                                                                                    selectedValue={(data) => this.changeDropdownValue(index, "org_unit", "org_unit", data)}
                                                                                    value={listValue.org_unit?.org_unit}
                                                                                    render={'value'}
                                                                                    arrow={'true'}
                                                                                    height={'innerCard'}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project Area"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <Dropdown
                                                                                    data={this.state.fetchProjectArea}
                                                                                    selectedValue={(data) => this.changeDropdownValue(index, "area", "area", data)}
                                                                                    value={listValue.area?.area}
                                                                                    render={'value'}
                                                                                    arrow={'true'}
                                                                                    height={'innerCard'}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div>
                                                                                <div className='p-sub-heading ml-3 required'>{'Actual Project Start Date'}</div>
                                                                                <div className='esep-placeholder-editable'>
                                                                                    {listValue.status === "Approved" || listValue.status === "Published" || listValue.status === "Initiated" ?
                                                                                        <input
                                                                                            className="form-control esep-placeholder-editable px-3"
                                                                                            type="date"
                                                                                            onKeyDown={(e) => e.preventDefault()}
                                                                                            value={listValue.actual_start_date ? moment(listValue.actual_start_date).format('YYYY-MM-DD') : moment(listValue.planned_start_date).format('YYYY-MM-DD')}
                                                                                            min={toDay}  //min props using for hiding the date current to prior dates
                                                                                            onChange={(e) => this.updatState(index, "actual_start_date", "", e.target.value)}
                                                                                        />
                                                                                        :
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.actual_start_date ? moment(listValue.actual_start_date).format('YYYY-MM-DD') : moment(listValue.planned_start_date).format('YYYY-MM-DD')}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Project End Date"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    value={listValue.actual_end_date ? moment(listValue.actual_end_date).format('DD-MM-YYYY') : moment(listValue.planned_end_date).format('DD-MM-YYYY')}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{'Project Description'}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <textarea
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    maxLength={200}
                                                                                    onChange={(e) => this.updatState(index, "description", "", e.target.value)}
                                                                                    value={listValue.description}
                                                                                >
                                                                                </textarea>
                                                                                <div className="justify-content-start time-text ml-3">
                                                                                    {this.lengthCalculate(listValue.description)}/200
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Mid Term Review Date"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    value={listValue.actual_mtr_date ? moment(listValue.actual_mtr_date).format('DD-MM-YYYY') : listValue.revised_mtr_date ? moment(listValue.revised_mtr_date).format('DD-MM-YYYY') : moment(listValue.planned_mtr_date).format('DD-MM-YYYY')}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3 required'>{"Final Review Date"}</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    name='PG_Name'
                                                                                    value={listValue.actual_fr_date ? moment(listValue.actual_fr_date).format('DD-MM-YYYY') : listValue.revised_fr_date ? moment(listValue.revised_fr_date).format('DD-MM-YYYY') : moment(listValue.planned_fr_date).format('DD-MM-YYYY')}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className='justify-content-end p-10px' style={{ gap: "10px" }}>
                                                                        <input
                                                                            type='button'
                                                                            value='CANCEL'
                                                                            className=' cancelBtn'
                                                                            onClick={() => this.setState({
                                                                                isEditProject: false,
                                                                                selectedProjects: this.state.selectedProjects
                                                                            })}
                                                                        />
                                                                        <input
                                                                            type="button"
                                                                            value='SUBMIT'
                                                                            className=' validateBtn'
                                                                            onClick={() => this.updateConfrimation(listValue.id, index)} />
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {this.state.selectedProjects.map((dataValue, id) =>
                                                            <span key={id}>
                                                                <span className='flex-direction-row justify-content-between align-item-center-noflex mt-10px mx-10px'>
                                                                    <span className='text1_25rem-bold '>{dataValue.title} {"-"} &nbsp;{dataValue.project_id}</span>
                                                                    <span className='flex-direction-row justify-content-between'>
                                                                        {dataValue.is_active_edit_project ?
                                                                            <div className='edit-icon'>
                                                                                <img onClick={() => this.openEditScreen()}
                                                                                    alt="edit"
                                                                                    src={edit}
                                                                                ></img>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        {dataValue.is_active_applicants ?
                                                                            <button
                                                                                type="submit"
                                                                                className=' validateBtn margin-left-20 '
                                                                                onClick={() => this.goToApplicantScreen(dataValue)}
                                                                            >
                                                                                {applicants.applicant}
                                                                            </button>
                                                                            :
                                                                            null
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className='justify-content-end mr-10 p-5px  mb-15px' >
                                                                    <span className='card-content-title project-list-style pointer'
                                                                        onClick={() => this.showObjective(dataValue)}
                                                                    >
                                                                        {applicants.MTRFRobjective}
                                                                    </span>
                                                                </span>
                                                                {this.state.selectedProjects.map((listValue, index) => {
                                                                    return (
                                                                        <div key={index} className='scroll-y h-37vh'>
                                                                            <div className='container-project-details px-10px'>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Guide Name:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.project_guide?.CompName ? listValue.project_guide?.CompName : '-'}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Guide Location:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.location ? listValue.location : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Status:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.status ? listValue.status : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Category:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.category ? listValue.category : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Duration (Days):"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.duration ? listValue.duration : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Guide Level:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.project_guide?.Esgtxt ? listValue.project_guide?.Esgtxt : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"No. of Opening:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.no_of_openings !== null ? listValue.no_of_openings : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Applications Received:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.appl_received !== null ? listValue.appl_received : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Applications Shortlisted By SPOC:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.appl_shrtlted_by_spoc !== null ? listValue.appl_shrtlted_by_spoc : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Selected In Other Projects:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.selected_in_other_projects !== null ? listValue.selected_in_other_projects : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Selected By Project Guide:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.appl_shrtlted_by_pg !== null ? listValue.appl_shrtlted_by_pg : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"BHR Name:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.bhr?.CompName ? listValue.bhr?.CompName : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Manager Name:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.manager?.CompName ? listValue.manager?.CompName : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Area:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.area?.area ? listValue.area?.area : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Entity:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.org_unit?.org_unit ? listValue.org_unit?.org_unit : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project Start Date:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.actual_start_date ? moment(listValue.actual_start_date).format('DD-MM-YYYY') : listValue.planned_start_date ? moment(listValue.planned_start_date).format('DD-MM-YYYY') : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Project End Date:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.actual_end_date ? moment(listValue.actual_end_date).format('DD-MM-YYYY') : listValue.planned_end_date ? moment(listValue.planned_end_date).format('DD-MM-YYYY') : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Mid Term Review Date:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.actual_mtr_date ? moment(listValue.actual_mtr_date).format('DD-MM-YYYY') : listValue.revised_mtr_date ? moment(listValue.revised_mtr_date).format('DD-MM-YYYY') : listValue.planned_mtr_date ? moment(listValue.planned_mtr_date).format('DD-MM-YYYY') : "-"}</span>
                                                                                </span>
                                                                                <span className='line-horizontal'>
                                                                                    <span className='admin-gems-description-title'>{"Final Review Date:"}</span>
                                                                                    <span className='feedback-sm-text ml-5px'>{listValue.actual_fr_date ? moment(listValue.actual_fr_date).format('DD-MM-YYYY') : listValue.revised_fr_date ? moment(listValue.revised_fr_date).format('DD-MM-YYYY') : listValue.planned_fr_date ? moment(listValue.planned_fr_date).format('DD-MM-YYYY') : "-"}</span>
                                                                                </span>
                                                                                {listValue.mtr_status &&
                                                                                    <span className='line-horizontal'>
                                                                                        <span className='admin-gems-description-title'>{"Mid Term Review Status:"}</span>
                                                                                        <span className='feedback-sm-text ml-5px'>{listValue.mtr_status ? listValue.mtr_status : "-"}</span>
                                                                                    </span>}
                                                                                {listValue.mtr_attachment &&
                                                                                    <span className='line-horizontal'>
                                                                                        <span className='admin-gems-description-title'>{"Mid Term Review Attachment:"}</span>
                                                                                        <span className='feedback-sm-text ml-5px'>{listValue.mtr_attachment ? listValue.mtr_attachment : "-"}</span>
                                                                                        <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer' onClick={() => this.downloadMTR(listValue.id)} />
                                                                                    </span>}
                                                                                {listValue.mtr_comments &&
                                                                                    <span className='line-horizontal'>
                                                                                        <span className='admin-gems-description-title'>{"Mid Term Review Comments:"}</span>
                                                                                        <span className='feedback-sm-text ml-5px'>{listValue.mtr_comments ? listValue.mtr_comments : "-"}</span>
                                                                                    </span>}
                                                                                {listValue.fr_status &&
                                                                                    <span className='line-horizontal'>
                                                                                        <span className='admin-gems-description-title'>{"Final Review Status:"}</span>
                                                                                        <span className='feedback-sm-text ml-5px'>{listValue.fr_status ? listValue.fr_status : "-"}</span>
                                                                                    </span>}
                                                                                {listValue.fr_attachment &&
                                                                                    <span className='line-horizontal'>
                                                                                        <span className='admin-gems-description-title'>{"Final Review Attachment:"}</span>
                                                                                        <span className='feedback-sm-text ml-5px'>{listValue.fr_attachment ? listValue.fr_attachment : "-"}</span>
                                                                                        <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer' onClick={() => this.downloadFR(listValue.id)} />
                                                                                    </span>}
                                                                                {listValue.fr_comments &&
                                                                                    <span className='line-horizontal'>
                                                                                        <span className='admin-gems-description-title'>{"Final Review Comments:"}</span>
                                                                                        <span className='feedback-sm-text ml-5px'>{listValue.fr_comments ? listValue.fr_comments : "-"}</span>
                                                                                    </span>}
                                                                            </div>
                                                                            <span className='p-10px flex-direction-row align-items-baseline'>
                                                                                <span className='admin-gems-description-title'>{"Description:"}</span>
                                                                                <span className='feedback-sm-text ml-5px'>{listValue.description}</span>
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                })}
                                                                <span className='justify-content-end p-10px' style={{ gap: "10px" }}>
                                                                    {dataValue.is_active_update_status ?
                                                                        <button
                                                                            type="submit"
                                                                            className=' validateBtn'
                                                                            onClick={() => this.updatePopup(dataValue.id)}
                                                                        >
                                                                            {adminScreenAction.updateStatus}
                                                                        </button>
                                                                        :
                                                                        null}
                                                                    {dataValue.is_active_publish_project &&
                                                                        <button
                                                                            type="submit"
                                                                            className=' validateBtn'
                                                                            onClick={() => this.publishConfirmation(dataValue.id)}
                                                                        >
                                                                            {adminScreenAction.publish}
                                                                        </button>
                                                                    }
                                                                </span>
                                                            </span>
                                                        )}
                                                    </>
                                                }
                                            </>
                                            :
                                            <span className='justify-content-center align-item-center-noflex flex_1 h-100'>
                                                <NewNotdata />
                                            </span>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <>
                            <ApplicantsScreen
                                closeApplicant={() => this.closeApplicantScreen()}
                                projectTitle={this.state.projectTitle}
                                projectStartDate={this.state.projectStartDate}
                                projectBHR={this.state.projectBHR}
                                projectGuideName={this.state.projectGuideName}
                                selectedProjectsId={this.state.selectedProjects[0]?.id}
                                hoverData={this.state.hoverData}
                            />
                        </>
                    }
                </div>
                {this.state.updProjectStatusPopUp &&
                    <UpdateProjectStatusPopup
                        onDismiss={() => this.setState({ updProjectStatusPopUp: false })}
                        statusUpdate={(data, details) => this.statusUpdate(data, details)}
                        projectId={this.state.project_id}
                        selectedProData={this.state.selectedProjects[0]}
                    // projectStatus={this.state.status} ///both need data
                    />
                }
                {this.state.isPublishPopup &&
                    <GEMSConfirmPopup
                        publish={() => this.publishProject(this.state.isPublishSelectedId)}
                        action={"Publish"}
                        title={"Publish Confirmation"}
                        text={'Are you sure you want to publish the project?'}
                        onDismiss={() => this.setState({ isPublishPopup: false })}
                    />
                }
                {this.state.isUpdatePopup &&
                    <GEMSConfirmPopup
                        submit={() => this.submitUpdated(this.state.isUpdateSelectedId, this.state.updateDataIndex)}
                        action={"Update"}
                        title={"Update Confirmation"}
                        text={'Are you sure you want to update the project?'}
                        onDismiss={() => this.setState({ isUpdatePopup: false })}
                    />
                }
                {this.state.mtrFRShow &&
                    <MTRFRObjectivePopup
                        onDismiss={() => this.setState({ mtrFRShow: false })}
                        id={this.state.mtrFRShowData}
                        updateMTRFR={(data, object) => this.updateMTRFR(data, object)}
                        isEditable={(data) => this.setState({ isEditable: data })}
                        isEditablePass={this.state.isEditable}
                        submitAddProjectDetails={() => this.submitAddProject()}
                        submit={(actionType, data) => this.updateProjectObj(actionType, data)}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        serviceData: state.activePopupState,
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(GEMSSpocScreen); 