import React, { Component } from 'react';
import '../ProfileScreen.css';
import DeclarationPopup from '../../profile/DeclarationPopup';
import down_arrow from '../../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../../assets/drishti_images/documents/up_arrow.svg';
import delete_icon from '../../../../../assets/images/cfm/delete_icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import { validateNumber } from '../../../../../utils/Utils';
import { maskMobile, maskEmail, maskBank } from '../../../../shared/commonMaskFunction';
import eye from '../../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../../assets/drishti_images/eye-slash.svg';

const chooseselect = 'Select'
class BankCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisibility: false,
            loading: false,
            isEdit: false,
            openDetail: false,
            delete: false,
            countryData: [],
            cityData: [],
            bankNameData: [],
            paymentMethodData: [],
            currencyData: [],
            city: '',
            city_code: '',
            country: chooseselect,
            country_code: '',
            account_holder_name: '',
            bank_country_code: '',
            bank_country: '',
            bank_city_code: '',
            bank_city: chooseselect,
            bank_postal_code: '',
            bank: chooseselect,
            bank_code: '',
            ifsc_code: '',
            account_number: '',
            confirm_account_number: '',
            payment_method_code: '',
            payment_method: chooseselect,
            payment_currency_code: '',
            payment_currency: chooseselect,
            isBankAccountUnmasked: false,
        }
    }
    componentDidMount() {
        if (!this.props.isEdit)
            return;

        // id added in Array of data because ReactSearchAutocomplete requires id in Array 
        const countryArray = this.props.countryData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.country_text
        }))

        const cityArray = this.props.cityData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.city_text
        }))

        const bankArray = this.props.bankNameData ? this.props.bankNameData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const paymentMethodDataArray = this.props.paymentMethodData ? this.props.paymentMethodData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        const currencyDataArray = this.props.currencyData ? this.props.currencyData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.value
        })) : []

        this.setState({
            countryData: countryArray,
            cityData: cityArray,
            bankNameData: bankArray,
            paymentMethodData: paymentMethodDataArray,
            currencyData: currencyDataArray
        });
    }

    toggleOpenDetail = () => {
        this.setState({
            openDetail: !this.state.openDetail
        })
    }

    validateEmployeeData = () => {
        if (this.state.bank === '' || this.state.bank === chooseselect) {
            toast.error('Please select bank name', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.account_holder_name === '') {
            toast.error('Please enter account holder name', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.country === '' || this.state.country === chooseselect) {
            toast.error('Please select country', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.bank_city === '' || this.state.bank_city === chooseselect) {
            toast.error('Please select city', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.bank_postal_code === '') {
            toast.error('Please enter bank postal code', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.ifsc_code === '') {
            toast.error('Please enter ifsc code', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
            // } else if (this.state.first_name.length > 40) {
            //     toast.warn("Please enter first name less than 40 characters only", { hideProgressBar: true });
        } else if (this.state.account_number === '') {
            toast.error('Please enter account number', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
            // } else if (this.state.last_name.length > 40) {
            //     toast.warn("Please enter last name less than 40 characters only", { hideProgressBar: true });
        } else if (this.state.account_number !== this.state.confirm_account_number) {
            toast.error('Account number does not match', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
         else if (this.state.payment_method === '' || this.state.payment_method === chooseselect) {
            toast.error('Please select payment method', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.payment_currency === '' || this.state.payment_currency === chooseselect) {
            toast.error('Please select payment currency', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else {
            this.saveEmploymentDetails();
            // this.showModal();
        }
    }

    // showModal = () => {
    //     this.setState({ popupVisibility: true });
    // }

    hideModal = () => this.setState({ popupVisibility: false });

    saveEmployment() {
        this.validateEmployeeData();
    }

    deleteDataPopup = () => this.setState({ popupVisibility: true, delete: true });

    handleChangeAccHolderName = (value) => {
        this.setState({
            account_holder_name: value
        });
    }

    handleChangeBankPostalCode = (value) => {
        value = value.replace(/[^0-9]/g, '');
        this.setState({
            bank_postal_code: value
        });
    }

    handleChangeBankIfscCode = (value) => {
        this.setState({
            ifsc_code: value
        });
    }

    handleBankAccNumber = (value) => {
        this.setState({
            account_number: value
        });
    }

    handleConfBankAccNumber = (value) => {
        this.setState({
            confirm_account_number: value
        });
    }
    changeBankName = (data) => {
        this.setState({
            bank: data.value,
            bank_code: data.code
        })
    }

    changeCountry = (data) => {
        this.setState({
            country: data.country_text,
            country_code: data.country_code
        })
    }

    changeCity = (data) => {
        this.setState({
            bank_city: data.city_text,
            // bank_city_code: data.code
        })
    }
    changeCurrancy = (data) => {
        this.setState({
            payment_currency: data.value,
            payment_currency_code: data.code
        })
    }

    changePaymentMethod = (data) => {
        this.setState({
            payment_method: data.value,
            payment_method_code: data.code
        })
    }

    saveEmploymentDetails = () => {
        this.setState({
            popupVisibility: false
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            account_holder_name: this.state.account_holder_name ? this.state.account_holder_name : '',
            bank_country_code: this.state.country_code ? this.state.country_code : '',
            bank_country: this.state.bank_country ? this.state.bank_country : '',
            bank_city: this.state.bank_city ? this.state.bank_city : '',
            bank_postal_code: this.state.bank_postal_code ? this.state.bank_postal_code : '',
            bank_code: this.state.bank_code ? this.state.bank_code : '',
            bank: this.state.bank ? this.state.bank : '',
            ifsc_code: this.state.ifsc_code ? this.state.ifsc_code : '',
            account_number: this.state.account_number ? this.state.account_number : '',
            payment_method_code: this.state.payment_method_code ? this.state.payment_method_code : '',
            payment_method: this.state.payment_method ? this.state.payment_method : '',
            payment_currency: this.state.payment_currency ? this.state.payment_currency : '',
            payment_currency_code: this.state.payment_currency_code ? this.state.payment_currency_code : '',
        }
        this.props.saveEmployeeData(body);
    }

    deleteData = () => {
        this.setState({
            popupVisibility: false
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            account_holder_name: this.props.item.account_holder_name ? this.props.item.account_holder_name : '',
            bank_country_code: this.props.item.country_code ? this.props.item.country_code : '',
            bank_country: this.props.item.bank_country ? this.props.item.bank_country : '',
            bank_city: this.props.item.bank_city ? this.props.item.bank_city : '',
            bank_postal_code: this.props.item.bank_postal_code ? this.props.item.bank_postal_code : '',
            bank_code: this.props.item.bank_code ? this.props.item.bank_code : '',
            bank: this.props.item.bank ? this.props.item.bank : '',
            ifsc_code: this.props.item.ifsc_code ? this.props.item.ifsc_code : '',
            account_number: this.props.item.account_number ? this.props.item.account_number : '',
            payment_method_code: this.props.item.payment_method_code ? this.props.item.payment_method_code : '',
            payment_method: this.props.item.payment_method ? this.props.item.payment_method : '',
            payment_currency: this.props.item.payment_currency ? this.props.item.payment_currency : '',
            payment_currency_code: this.props.item.payment_currency_code ? this.props.item.payment_currency_code : '',
        }
        this.props.deleteEmploymentData(body)
    }
    render() {
        const { isEdit } = this.props
        return (
            <div>
                <div>
                    {isEdit ?
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='document-common-heading common-text-color'>Add New Bank Details
                                </div>
                                {/* {!this.state.openDetail &&
                                    <div
                                        className='justify-content-end'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                    </div>
                                } */}
                            </div>
                            {!this.state.openDetail &&
                                <div>
                                    <div className='hz-lines mt-2 mb-2'></div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-4 mt-2 mb-2'>Bank Details</div>
                                    </div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank Name</div>
                                                <LargeDropdown
                                                    data={this.state.bankNameData}
                                                    value={this.state.bank}
                                                    selectedValue={(data) => this.changeBankName(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Account Holder Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={58}
                                                        onChange={(e) => this.handleChangeAccHolderName(e.target.value)}
                                                        placeholder="Enter Account Holder Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Country</div>
                                                <LargeDropdown
                                                    data={this.state.countryData}
                                                    value={this.state.country}
                                                    selectedValue={(data) => this.changeCountry(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>City</div>

                                                <LargeDropdown
                                                    data={this.state.cityData}
                                                    value={this.state.bank_city}
                                                    selectedValue={(data) => this.changeCity(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank Postal Code</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="number"
                                                        maxLength={6}
                                                        onChange={(e) => this.handleChangeBankPostalCode(e.target.value)}
                                                        placeholder="Enter Bank Postal Code"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank IFSC</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={11}
                                                        onChange={(e) => this.handleChangeBankIfscCode(e.target.value)}
                                                        placeholder="Enter Bank IFSC Code"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank Account Number</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="number"
                                                        maxLength={18}
                                                        onChange={(e) => this.handleBankAccNumber(e.target.value)}
                                                        placeholder="Enter Bank Account No."
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Confirm Bank Account Number</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="number"
                                                        maxLength={18}
                                                        onChange={(e) => this.handleConfBankAccNumber(e.target.value)}
                                                        placeholder="Enter Account No."
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Payment Method</div>
                                                <LargeDropdown
                                                    data={this.state.paymentMethodData}
                                                    value={this.state.payment_method}
                                                    selectedValue={(data) => this.changePaymentMethod(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Payment Currency</div>
                                                <LargeDropdown
                                                    data={this.state.currencyData}
                                                    value={this.state.payment_currency}
                                                    selectedValue={(data) => this.changeCurrancy(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className='row justify-content-end'>
                                            <div className='justify-content-between'>
                                                <div
                                                    type='button'
                                                    className='cancl-btn cancel-txt'
                                                    onClick={() => this.props.cancel()}
                                                >Cancel</div>
                                                <div
                                                    type='button'
                                                    className='save-btn save-txt'
                                                    onClick={() => this.validateEmployeeData()}
                                                >Save</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='toggle-card-heading ml-2'>
                                    {this.props.item.bank + ' Details'}
                                </div>
                                {!this.state.openDetail &&
                                    <div
                                        className='justify-content-end'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img
                                            alt="hide"
                                            src={down_arrow}
                                            className="up_arrow"
                                        ></img>
                                    </div>
                                }
                            </div>
                            {this.state.openDetail &&
                                <div>
                                    <div className='hz-lines mt-2 mb-2'></div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-2 mt-2 mb-2'>Bank Details</div>
                                        {/* <div
                                            className='mr-2'
                                            onClick={this.deleteDataPopup}
                                        >
                                            <img
                                                alt="delete_icon"
                                                src={delete_icon}
                                                className="up_arrow"
                                            ></img>
                                        </div> */}
                                    </div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.bank}
                                                        readOnly={true}
                                                        placeholder="Select Bank Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Account Holder Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.account_holder_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Country</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.bank_country}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>City</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.bank_city}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank Postal Code</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.bank_postal_code}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank IFSC</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.ifsc_code}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Bank Account Number</div>

                                                <div className='p-placeholder-text p-placeholder contact-field'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.state.isBankAccountUnmasked ? (this.props.item.account_number) : maskBank(this.props.item.account_number)}
                                                        readOnly={true}
                                                    />
                                                    <div 
                                                        onClick={() => this.setState({ isBankAccountUnmasked : !this.state.isBankAccountUnmasked })} // Toggle between true/false for mask/unmask
                                                        className='email-feild'>
                                                        <img alt="eyeslash"
                                                            src={ this.state.isBankAccountUnmasked ? eye : eyeslash }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Payment Method</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.payment_method}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Payment Currency</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.payment_currency}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='justify-content-end mt-3'
                                        onClick={() => this.toggleOpenDetail('education')}>
                                        <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={this.hideModal}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveEmploymentDetails}
                        deleteDetails={this.deleteData}
                        delete={this.state.delete} />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(BankCards);