import React, { Fragment, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { suggestionMasterScreen } from "./constant";
import { useSelector } from "react-redux";
import {
  noticeboardUploadDocument,
  addSuggestionSchemeName,
  getSuggestonOfficeAgencyDashboard,
  getAdminMemberDetails,
  getSuggAgency,
  addAdminMasterAgency,
  addDivsionalCordinator,
  addEvaluator,
  addDivisionAdminMaster,
  addCostCenterAdminMaster,
  getCostCenterName,
  getPSAList
} from "../../../../../constants/api.service";
import { configStore } from "../../../../../redux/ConfigStore";
import { toaster } from "../../../../../utils/Utils";
import LoaderIndicator from "../../../../shared/loader/LoaderIndicator";
import { minImageHeight,minImageWidth,maxImageHeight,maxImageWidth } from './constant';
import LargeDropdown from "../../../../shared/components/LargeDropdown";
import Select, { components } from 'react-select';

function AddPopUpScreen({
  dismisAddForm,
  closeAddPopUp,
  tabName
}) {
  const [schemeName, setSchemeName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const fileInputRef = useRef(null);
  const [agencyData, setAgencyData] = useState([]);
  const [agency, setAgency] = useState('');
  const [attachmentName, setAttachmentName] = useState("");
  const [documentLink, setdocumentLink] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [divisionName, setDivisionName] = useState(null);
  const [divisionCode, setDivisionCode] = useState(null);
  const [agencyName, setAgencyName] = useState(null);
  const [agencyCode, setAgencyCode] = useState(null);
  const [divisionData, setDivisionData] = useState([]);
  const [division, setDivision] = useState("");
  const [loading, setloading] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [validateBtn,setValidateBtn] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [validateDropdown,setValidateDropdown] = useState(false);
  const [showAgency,setShowAgency] = useState(false);
  const [costCenterNum,setCostCenterNum] = useState(null);
  const [costCenterName,setCostCenterName] = useState(null);
  const [perArea,setPerArea] =  useState(null);
  const [selectedPsa,setSelectedPsa] = useState(null);
  const [selectedPsaLabel,setSelectedPsaLabel] = useState(null);
  const [psaList,setPsaList] = useState([]);
  const [compCode,setCompCode] = useState(null);
  const adminLoginState = useSelector((state) => state.adminLoginState);
  const [validCostCenterDetails,setValidCostCenterDetails] = useState(false);

  const validateForm = () => {
    if(tabName.key == suggestionMasterScreen.SchemeList){
      if(!schemeName || !startDate || !endDate){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      addSchemeName();
    }
    if(tabName.key == suggestionMasterScreen.AgencyList){
      if(!agencyName || !division){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      addAgencyToMaster();
    }
    if(tabName.key == suggestionMasterScreen.DivisionList){
      if(!divisionName){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      // addSchemeName();
      addDivisionToMaster();
    }
    if(tabName.key == suggestionMasterScreen.Evaluator){
      if(!division || !agency || !employeeId){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      addEvaluatorToMaster();
    }
    if(tabName.key == suggestionMasterScreen.DivisionCordinator){
      if(!division || !employeeId){
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      addDivisionalCordinatorToMaster();
    }
    if(tabName.key == suggestionMasterScreen.CostCenter) {
      if(!division || !costCenterNum || !costCenterName || !perArea || !(selectedPsaLabel?.length) ) {
        toaster("warning", 'Please fill all the mandatory fields');
        return;
      }
      addCostCenterToMaster();
    }
  };

  const handleSchemeName = (e) => {
    setSchemeName(e);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
    setEndDate('')
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleUpload = () => {
    UploadDocumentDetail(
      documentLink?.documentLink,
      attachmentName?.documentType,
      attachmentName?.documentName
    );
    setAttachmentName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const UploadDocumentDetail = async (
    attachment,
    attachmentType,
    attachmentName
  ) => {
    setloading(true);
    let body = {
      emp_id: configStore.getState().adminLoginState?.userData?.PersArea,
      attachment: attachment,
      attachment_name: attachmentName,
      attachment_type: attachmentType,
    };
    await noticeboardUploadDocument(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          const document = {
            // type: viewDocumentTypeFilter?.title,
            name: res?.data?.data?.attachment_name,
            extension: attachmentType,
            attachmentURL: res?.data?.data?.attachment_presigned_url,
            attachment: res?.data?.data?.attachment_path,
            path: res?.data?.data?.attachment_path,
            document_category: "Scheme name",
          };
          // setdocumentData([...documentData, document]);
          setDocumentData(document);
          setShowDocument(true);
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
        }
      })
      .catch((error) => {
        console.log("error in S3 delete documents-->", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const fileSelectHandler = (event) => {
    handleImageUpload(event);
    if (event?.target?.files && event?.target?.files[0]) {
      let typeOfImage = event?.target?.files[0]?.type;
      let ImageType = typeOfImage.split("/");
      setAttachmentName({
        documentName: event?.target?.files[0]?.name,
        documentType: ImageType[1] || "",
      });

      let maxAllowedSize = 2097152; // 2MB size
      if (event?.target?.files[0]?.size <= maxAllowedSize) {
        let reader = new FileReader();
        reader.readAsDataURL(event?.target?.files[0]);
        reader.onload = (e) => {
          setdocumentLink({ documentLink: e.target.result });
        };
      } else {
        toast.warn("Please upload image less than 2MB", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
        setdocumentLink({ documentLink: "" });
      }
    } else {
      setAttachmentName({ documentName: "", documentType: "" });
      setdocumentLink({ documentLink: "" });
    }
  };

  const validateImage = (file) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);
    img.onload = function () {
      // Validate image dimensions
      if (
        img.width >= minImageWidth && img.width <= maxImageWidth &&
        img.height >= minImageHeight && img.height <= maxImageHeight
      ){
        setValidateBtn(true);
      } else {
        toaster("warning",`Please upload an image with dimensions between ${minImageWidth}x${minImageHeight} and ${maxImageWidth}x${maxImageHeight} pixels.`);
        setValidateBtn(false);
      }
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      validateImage(file);
    }
  };

  const addSchemeName = async () => {
    setloading(true);
    let body = {
      scheme_name: schemeName,
      start_date: startDate,
      end_date: endDate,
      image_path: documentData?.attachment ?? '',
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await addSuggestionSchemeName(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeAddPopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeAddPopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeAddPopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const addAgencyToMaster = async () => {
    setloading(true);
    let body = {
      agency_name: agencyName?.trim() ?? '',
      division: division?.id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await addAdminMasterAgency(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeAddPopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeAddPopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeAddPopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const addDivisionalCordinatorToMaster = async () => {
    setloading(true);
    let body = {
      emp_id: employeeId,
      division_code: division?.id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await addDivsionalCordinator(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeAddPopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeAddPopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeAddPopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const addEvaluatorToMaster = async () => {
    setloading(true);
    let body = {
      emp_id: employeeId,
      division_code: division?.id,
      agency_code:agency?.id,
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await addEvaluator(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeAddPopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeAddPopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeAddPopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const addDivisionToMaster = async () => {
    setloading(true);
    let body = {
      // agency_name: agencyName,
      division_name: divisionName?.trim() ?? '',
      pa: configStore.getState().adminLoginState?.userData?.PersArea,
      comp_code: configStore.getState().adminLoginState?.company?.company_code,
    };
    await addDivisionAdminMaster(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeAddPopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeAddPopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeAddPopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const addCostCenterToMaster = async () => {
    setloading(true);
    let body = {
      cost_center_no: costCenterNum,
      cost_center_name: costCenterName,
      pa: perArea,
      psa: selectedPsaLabel ?? '',
      division: division.id,
      comp_code:  configStore.getState().adminLoginState?.company?.company_code
    };
    await addCostCenterAdminMaster(body)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          toaster("success", res?.data?.message ? res?.data?.message : "");
          closeAddPopUp();
        } else {
          res?.status != 404 &&
            toaster("warning", res?.data?.message ? res?.data?.message : "");
            closeAddPopUp();
        }
      })
      .catch((error) => {
        console.log("error ", error);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error?.data?.message : "");
        closeAddPopUp();
      })
      .finally(() => {
        setloading(false);
      });
  };

  const handleDivisionName = (e) =>{
    setDivisionName(e);
  }

  const handleDivisionCode = (e) =>{
    setDivisionCode(e);
  }

  const handleAgencyName = (e) =>{
    setAgencyName(e);
  }

  const handleAgencyCode = (e) =>{
    setAgencyCode(e);
  }

  const handleDivision = (val)=>{
    setDivision(val);
    if(tabName.key !== suggestionMasterScreen.DivisionCordinator){
      setShowAgency(true);
      setAgency('');
      getAgency(val.id)
    }
  }

  const handleAgency = (val)=>{
    setAgency(val);
  }

  const handleEmployeeChange = (e)=>{
    setDivision(null);
    setAgency(null); 
    setAgencyData([]);
    const value = e.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setEmployeeId(e.target.value);
    }
  }

  const handleCostCenterNum = (val)=>{
    setCostCenterNum(val);
    setPsaList([]);
    setCostCenterName('');
    setPerArea('');
    setSelectedPsa([]);
    setSelectedPsaLabel([]);
  }

  const handleCostCenterName = (val)=>{
    setCostCenterName(val);
  }

  const handlePerArea = (val)=>{
    setPerArea(val);
  }

  const handlePsa = (val)=>{
    // setPsa(val);
  }

  const handleCompCode = (data)=>{
    setCompCode(data);
  }


  // ---------------------Get Division Details------------ //
  const getDivision = () => {
    setloading(true);
    const param = {
      comp_code: adminLoginState?.userData?.CompCode,
      pa: adminLoginState?.userData?.PersArea,
    };
    getSuggestonOfficeAgencyDashboard(param)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const division = res?.data?.data?.map((division) => ({
            id: division?.division_code,
            label: division?.division_name,
          }));
          setDivisionData(division);
          // const allStatus = { label: 'All', id: 0 };
          // setDivisionWithAllData([allStatus, ...division]);
        } else {
          toast.warn(res?.data?.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          setDivisionData([]);
        }
      })
      .catch((errors) => {
        toast.warn(errors, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      })
      .finally(() => {
        setloading(false);
      });
  };

  // ---------------------Get Agency Details Based of Division ID------------ //
  const getAgency = (divisionId) => {
    setloading(true);
    const params = { division: divisionId };
    getSuggAgency(params)
        .then(res => {
            if (res?.status === 200) {
                const agency_list = res?.data?.data?.agency_list.map(agency => ({
                    id: agency?.agency_code,
                    label: agency?.agency_name
                }));
                setAgencyData(agency_list);
            } else {setAgencyData([]);}
        })
        .catch(errors => {
            toast.warn(errors, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        })
        .finally(() => {setloading(false);});
  };

  const getClassNames = ()=>{
    const baseClass = 'modal-content bg-light ';
    const result = {};
    if(tabName.key == suggestionMasterScreen.SchemeList){
      result.modalClass = baseClass+'toDoModalMedium'
      result.modalHeight = 'h-80';
      result.formHeight = 'todoModalBody w-100 h-100';
      return result;
    }
    if(tabName.key == suggestionMasterScreen.DivisionList){
      // return baseClass+'toDoModal'
      result.modalClass = baseClass+'toDoModal h-19rem';
      result.modalHeight = 'h-70';
      result.formHeight = 'h-80';
      return result;
    }
    if(tabName.key == suggestionMasterScreen.AgencyList){
      //  return baseClass+'toDoModalMedium'
       result.modalClass = baseClass+'toDoModalMedium'
       result.modalHeight = 'h-80';
       result.formHeight = 'todoModalBody w-100 h-100';
       return result;
    }
    // return baseClass+'toDoModalMedium'
    result.modalClass = baseClass+'toDoModalMedium'
    result.modalHeight = 'h-80';
    result.formHeight = 'todoModalBody w-100 h-100';
    return result;
  }


  // ---------------------Get Member Details------------ //
  const getMemberDetail = async (emp) => {
    setloading(true)
    let params = {
      divc_emp_id: emp ? emp : employeeId ?? '',
      comp_code: adminLoginState?.userData?.CompCode,
      pa: adminLoginState?.userData?.PersArea,
    }
    try {
        const res = await getAdminMemberDetails(params);
        if (res.status === 200) {
            setloading(true);
            if (res?.data?.data != 0) {
              toaster("success", "Employee Id is valid")
              setValidateDropdown(true);
            } else {
              toaster("warning", "Employee Id is Invalid")
            }
        } else {
            toast.warn(res?.data?.message, {
              position: "bottom-center",
              hideProgressBar: true,
              className: "toastWarn",
            });
            setValidateDropdown(false);
            setDivision(null);
            setAgency(null);
        }
    } catch (error) {
        console.error('Error fetching member details:', error);
        setloading(false)
    } finally { setloading(false) }
  };

  // ---------------------Get PSA Details------------ //
  const getCostCenterPSADetail = async (costNo) => {
    setloading(true)
    let params = {
      cost_center_no: costNo ? costCenterNum : '',
      pa: adminLoginState?.userData?.PersArea,
    }
    try {
        const res = await getPSAList(params);
        if (res.status === 200) {
            setloading(true);
            if (res?.data?.data != 0) {
              const formattedPSAList = res?.data?.data.map(status => ({
                label: status,
                value: status,
              }));
              setPsaList(formattedPSAList);
              setValidCostCenterDetails(true);
              getCostCenterNameDetail(costNo ? costCenterNum : '');
              toast.success(res?.data?.message || 'Cost center details fetched successfully', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastSuccess'
              });
            } 
        } else {
            setValidCostCenterDetails(false);
            setPsaList([]);
            setCostCenterName('');
            setPerArea('');
            setSelectedPsa([]);
            setSelectedPsaLabel([]);
            toast.warn(res?.data?.message || 'Something went wrong', {
              position: "bottom-center",
              hideProgressBar: true,
              className: "toastWarn",
            });
        }
    } catch (error) {
        console.error('Error fetching member details:', error);
        setloading(false)
    } finally { setloading(false) }
  };

  // ---------------------Get  Cost Center name------------ //
  const getCostCenterNameDetail = async (costNo) => {
    setloading(true)
    let params = {
      pa: adminLoginState?.userData?.PersArea,
    }
    try {
        const res = await getCostCenterName(costNo,params);
        if (res.status === 200) {
            setloading(true);
            if (res?.data?.data != 0) {
              setCostCenterName(res?.data?.data?.Cosctrtxt)
              setPerArea(adminLoginState?.userData?.PersArea);
              setCompCode(configStore.getState().adminLoginState?.company?.company_code);
            } 
        } else {
            toast.warn(res?.data?.message, {
              position: "bottom-center",
              hideProgressBar: true,
              className: "toastWarn",
            });
        }
    } catch (error) {
        console.error('Error fetching cost center name:', error);
        setloading(false)
    } finally { setloading(false) }
  };

  const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '250px',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#653DAF',
        borderRadius: '5px',
        padding: '2px 5px',
        color: 'white'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: 'white',
        ':hover': {
            backgroundColor: 'transparent',
            color: 'white',
        },
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'white' : provided.color,
        padding: '10px',
        margin: '5px 0',
        cursor: 'pointer',
        ':active': {
            backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        maxHeight: '38px',
        overflowY: 'auto',
    }),
  };

  
  const handleStatusFilter =(selectedOptions) => {
    setSelectedPsa(selectedOptions);
    const labels = selectedOptions.map(item => item.label);
    setSelectedPsaLabel(labels);
  };

  useEffect(()=>{
    getDivision();
  },[]);

  const applyClassNames = getClassNames();

  return (
    <div className="modal popup-box" id="addTodoModal">
      <LoaderIndicator loading={loading} />
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className={applyClassNames?.modalClass} style={{ overflow: 'auto' }}>
          <div className="modal-header edit-cardtitle">
            <h4 className="modal_title">Add {tabName?.value}</h4>
            <button type="button" className="close" onClick={dismisAddForm}>
              &times;
            </button>
          </div>
          <div className={applyClassNames?.modalHeight}>
            <form className={applyClassNames?.formHeight} style={{ overflow: 'auto' }}>
              {/* Special Scheme */}
              {tabName.key == suggestionMasterScreen.SchemeList && (
                <div className="p-1 w-100">
                <div>
                  <div className="p-sub-heading ml-3 required">
                    {suggestionMasterScreen.SchemeName}
                  </div>
                  <div className="p-placeholder-text mb-2 w-50rem">
                    <input
                      className="form-control p-placeholder p-placeholder-admin px-2"
                      type="text"
                      onChange={(e) => handleSchemeName(e.target.value)}
                      value={schemeName}
                      placeholder={"Enter Scheme Name"}
                      maxLength={50}
                    />
                  </div>

                  <div className="scroll-webkit-x" style={{ width: "90%" }}>
                    <span className="mr-20px w-45-percent">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.StartDate}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder"
                          type="date"
                          value={startDate || ""}
                          style={{ height: "38px" }}
                          onChange={(e) => handleStartDate(e.target.value)}
                          min={today}
                        />
                      </div>
                    </span>

                    <span className="w-45-percent">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.EndDate}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder"
                          type="date"
                          value={endDate || ""}
                          style={{ height: "38px" }}
                          onChange={(e) => handleEndDate(e.target.value)}
                          min={startDate}
                          disabled={!startDate}
                        />
                      </div>
                    </span>
                  </div>
                  <div>
                    <div className="p-sub-heading">Upload Document</div>
                    <div
                      className="scroll-webkit-x"
                      style={{ width: "80%", marginBottom: "5px" }}
                    >
                      <div className="p-placeholder-text">
                        <input
                          className="form-control p-placeholder"
                          type="file"
                          accept=".png,.jpg,.jpeg,.pdf"
                          ref={fileInputRef}
                          style={{ height: "43px" }}
                          placeholder={"Select Document"}
                          onChange={(e) => fileSelectHandler(e)}
                        />
                        {/* {errors.attachmentName && <span className="p-sub-heading ml-3 errorBlock">{errors.attachmentName}</span>} */}
                        <div>
                          <span className="noteTextMsg">
                            {
                              `You can upload Attachment upto 2MB size only.(pdf/png/jpg/jpeg)`
                            }
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 mb-4 d-flex  align-items-center">
                        <input
                          type="button"
                          value="UPLOAD"
                          className={`${validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                          onClick={handleUpload}
                          disabled={!validateBtn}
                        />
                      </div>
                    </div>
                    <div>
                      <span className="noteTextMsg">
                        {
                          `Note: Upload an image with a minimum width of ${minImageWidth}px and height of ${minImageHeight}px, or a maximum width of ${maxImageWidth}px and height of ${maxImageHeight}px`
                        }
                      </span>
                    </div>
                  </div>

                  {showDocument && (
                    <div className="sugg-container2">
                      <img src={documentData.attachmentURL} alt="Uploaded Image" className="sugg-container-Image"/>
                    </div>
                  )}
                </div>
                </div>
              )}

              {/* Division */}
              {tabName.key == suggestionMasterScreen.DivisionList && (
                <div className="p-1 w-100">
                <div>
                  <div className="p-sub-heading ml-4 required">
                    {suggestionMasterScreen.DivisionName}
                  </div>
                  <div className="p-placeholder-text mb-2 ml-3 w-25rem">
                    <input
                      className="form-control p-placeholder p-placeholder-admin px-2"
                      type="text"
                      onChange={(e) => handleDivisionName(e.target.value)}
                      value={divisionName}
                      placeholder={"Enter Division Name"}
                      maxLength={50}
                    />
                  </div>
                </div>
                </div>
              )}

              {/* Agency */}
              {tabName.key == suggestionMasterScreen.AgencyList && (
                <div className="p-1 w-100">
                  <div>
                    <div className="p-sub-heading ml-3 required">
                      {suggestionMasterScreen.AgencyName}
                    </div>
                    <div className="p-placeholder-text mb-2 w-35rem">
                      <input
                        className="form-control p-placeholder p-placeholder-admin px-2"
                        type="text"
                        onChange={(e) => handleAgencyName(e.target.value)}
                        value={agencyName}
                        placeholder={"Enter Agency Name"}
                        maxLength={50}
                      />
                    </div>

                    {/* <div className="p-sub-heading ml-3 required">
                      {suggestionMasterScreen.AgencyCode}
                    </div>
                    <div className="p-placeholder-text mb-2 w-35rem">
                      <input
                        className="form-control p-placeholder p-placeholder-admin px-2"
                        type="text"
                        onChange={(e) => handleAgencyCode(e.target.value)}
                        value={agencyCode}
                        placeholder={"Enter Agency Code"}
                        // maxLength={50}
                      />
                    </div> */}

                    <div className="p-sub-heading ml-3 required">
                      Select Division
                    </div>
                    <div className="p-placeholder-text mb-2 w-25rem">
                      <LargeDropdown
                        data={divisionData}
                        value={division?.label ?? 'Select'}
                        selectedValue={(data) => handleDivision(data)}
                      />
                    </div>

                    {/* {
                      division ? 
                      <div>
                        <div className="p-sub-heading ml-3 required">
                          {suggestionMasterScreen.DivisionCode}
                          </div>
                          <div className="p-placeholder-text mb-2 w-20rem">
                            <input
                              className="form-control p-placeholder p-placeholder-admin px-2"
                              type="text"
                              // onChange={(e) => handleAgencyCode(e.target.value)}
                              value={division?.id ?? ''}
                              // placeholder={"Enter Agency Code"}
                              disabled={true}
                              // maxLength={50}
                            />
                        </div>
                      </div> : null
                    } */}

                  </div>
                </div>
              )}

              {/* Evaluator */}
              {(tabName.key == suggestionMasterScreen.Evaluator || tabName.key == suggestionMasterScreen.DivisionCordinator) && (
                <div className="p-1 w-100">
                  <div>
                    <div className="p-sub-heading ml-3 required">
                      {suggestionMasterScreen.EmployeeId}
                    </div>
                    <div className="scroll-webkit-x">
                      <div className="p-placeholder-text mb-2 w-25rem scroll-webkit-x">
                          <span>
                            <input
                              className="form-control p-placeholder"
                              type="text"
                              maxLength={8}
                              value={employeeId}
                              placeholder='Enter Employee ID'
                              onChange={(e) => handleEmployeeChange(e)}
                              // disabled={isLimitReached}
                            />
                          </span>
                          <span className="ml-10px mt-10px">
                            <input
                              type="button"
                              value='VALIDATE'
                              className={`${employeeId ? 'validateBtn' : 'validateBtnDisabled'}`}
                              onClick={()=> {getMemberDetail(employeeId)}}
                              disabled={!employeeId}
                            />
                          </span>
                      </div>
                    </div>
                    <div className="p-sub-heading ml-3 required">
                      Select Division
                    </div>
                    <div className="p-placeholder-text mb-2 w-25rem">
                      <LargeDropdown
                        data={divisionData}
                        value={division?.label ?? 'Select'}
                        selectedValue={(data) => handleDivision(data)}
                        disabled = {!validateDropdown}
                      />
                    </div>

                    {
                      showAgency ? 
                      <div>
                        <div className="p-sub-heading ml-3 required">
                          Select Agency
                        </div>
                        <div className="p-placeholder-text mb-2 w-25rem">
                          <LargeDropdown
                            data={agencyData}
                            value={agency?.label ?? 'Select'}
                            selectedValue={(data) => handleAgency(data)}
                          />
                        </div>
                      </div> : null
                    }

                  </div>
                </div>
              )}

              {/* Cost Center */}
              {(tabName.key == suggestionMasterScreen.CostCenter) && (
                <div className="p-1 w-100">

                   <div className="p-sub-heading ml-3 required">
                      Select Division
                  </div>
                  <div className="p-placeholder-text mb-2 w-20rem">
                      <LargeDropdown
                        data={divisionData}
                        value={division?.label ?? 'Select'}
                        selectedValue={(data) => handleDivision(data)}
                        // disabled = {!validateDropdown}
                      />
                  </div>


                  <div className="justify-content-start">
                    <div className="w-20rem mr-4_5rem">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.CostCenterNo}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder p-placeholder-admin px-2"
                          type="text"
                          onChange={(e) => handleCostCenterNum(e.target.value)}
                          value={costCenterNum}
                          placeholder={"Enter Cost Center Number"}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    <div className="ml-10px mt-20px">
                      <input
                        type="button"
                        value='VALIDATE'
                        className={`${costCenterNum ? 'validateBtn' : 'validateBtnDisabled'}`}
                        onClick={()=> {getCostCenterPSADetail(costCenterNum)}}
                        disabled={!costCenterNum}
                      />
                    </div>
                  </div>

                  <div className="justify-content-start">
                    <div className="w-20rem mr-4_5rem">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.CostCenterName}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder p-placeholder-admin px-2"
                          type="text"
                          onChange={(e) => handleCostCenterName(e.target.value)}
                          value={costCenterName}
                          placeholder={"Enter Cost Center Name"}
                          maxLength={100}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="w-20rem">
                      <div className="p-sub-heading ml-3 required">
                        {suggestionMasterScreen.pa}
                      </div>
                      <div className="p-placeholder-text mb-2">
                        <input
                          className="form-control p-placeholder p-placeholder-admin px-2"
                          type="text"
                          onChange={(e) => handlePerArea(e.target.value)}
                          value={perArea}
                          placeholder={"Enter Person Area"}
                          maxLength={10}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-20rem">
                    <div className="p-sub-heading ml-3 required">
                      {suggestionMasterScreen.psa}
                    </div>
                    <Select
                      isMulti
                      options={psaList}
                      value={selectedPsa}
                      onChange={handleStatusFilter}
                      className="basic-multi-select"
                      placeholder="Select"
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      disabled={!validCostCenterDetails}
                    />
                  </div>
                </div>
              )}
            </form>
            <div className="modal-footer btnBox justify-content-end">
              <div className="justify-content-around mb-4px">
                <div className="justify-content-start">
                  <input
                    type="button"
                    value="CANCEL"
                    className="popup-back-btn popup-back-text"
                    onClick={(e) => dismisAddForm()}
                  />
                </div>
                <div className="justify-content-start">
                  <input
                    type="button"
                    value="ADD"
                    className="popup-confirm-btn"
                    onClick={(e) => validateForm()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPopUpScreen;


