import React, { Component } from 'react'
import "react-circular-progressbar/dist/styles.css";
import './LeaveScreen.css';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import * as KeycloakActions from '../login/KeyCloakAction';
import { bindActionCreators } from 'redux';
import ApplyLeavePopup from './ApplyLeavePopup';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import LeaveHistory from './LeaveHistory';
import UpcomingHolidays from './UpcomingHolidays';
import LeaveQuota from './LeaveQuota';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import { checkActivePoup, checkPopupCount } from '../../../../utils/CheckActivePopup';
import DynamicCustomPopupModal from '../../../shared/dynamicCustomPopum/DynamicCustomPopup'
import * as homeActions from '../landingScreen/ActivePopupAction';
import UpcommingHoliday from './UpcomingHolidays';
import LeaveHistoryMain from './LeaveHistoryMain';
import ApplyPreviousLeavePopup from '../previousYearLeave/ApplyPreviousLeavePopup';

const date = new Date();
const currentYear = (date.getFullYear() - 1);
const previousLeave = 'previousLeave'
const currentLeave = 'currentLeave'
class LeaveScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            applyLeave: false,
            applyPrevLeave: false,
            loading: false,
            is_service_Unavailable: false,  // service unavailble from backend
            is_apply_leave_service_Unavailable: false,
            leave_Service_reason: '',
            isActive: false,
            message: '',
            note_content: '',
            note_title: '',
            note_type: '',
            image_url: '',
            image_hyperlink: '',

            is_previous_year_leave: false,
            previous_year_leaves_reason: '',
            isCurrentLeaveActive: true,
            isPreviousLeaveActive: false,
            category: currentLeave, 
        }
    }

    componentDidMount() {
        this.checkService();
        this.checkActivePoupDetails()
    }

    checkActivePoupDetails() {
        var activeDetails = checkActivePoup('leave');
        this.setState({
            isActive: activeDetails && checkPopupCount('leave') ? true : false,
            message: activeDetails ? activeDetails : '',
            note_content: activeDetails.note_content ? activeDetails.note_content : '',
            note_title: activeDetails.note_title ? activeDetails.note_title : '',
            note_type: activeDetails.note_type ? activeDetails.note_type : '',
            image_url: activeDetails.image_url ? activeDetails.image_url : '',
            image_hyperlink: activeDetails.image_hyperlink ? activeDetails.image_hyperlink : '',
        }, () => {
            if (this.state.isActive) {
                this.props.homeActions.getActivePopupCount('leave')
            }
        })
    }

    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "leave" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    leave_Service_reason: item.reason
                })
            }
            if (item.service_type == "leave_request" && item.is_active == false) {
                this.setState({
                    is_apply_leave_service_Unavailable: true,
                    leave_Service_reason: item.reason
                })
            }
            if (item.service_type == "previous_year_leaves" && item.is_active == false) {
                this.setState({
                    is_previous_year_leave: true,
                    previous_year_leaves_reason: item.reason
                })
            }
        })
    }

    applyleave = () => {
        this.setState({ applyLeave: !this.state.applyLeave })
    }

    applyPrevLeave = () => {
        this.setState({ applyPrevLeave: !this.state.applyPrevLeave })
    }

    closepopup = () => {
        this.setState({ applyLeave: false, applyPrevLeave: false })
    }
    //------------Leave History Tb Functions-----------------------
    toggleCurrentDetails = (key) => {
        this.setState({ isCurrentLeaveActive: true, isPreviousLeaveActive: false, category: key })
    }

    togglePreviousDetails = (key) => {
        this.setState({ isCurrentLeaveActive: false, isPreviousLeaveActive: true, category: key })
    }
    //------------------------------------------------

    render() {
        return (
            <div>
                {this.state.is_service_Unavailable ?
                    <div className="col-md-12 h-100vh justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.leave_Service_reason} width={true} />
                    </div>
                    :
                    <div className='p-12px'>
                        <LoaderIndicator loading={this.state.loading} />
                        <div className='row'>
                            <div className="col-md-12 px-0">
                                <div className='row justify-content-between align-item-center mt-0 pt-0 mb-3'>
                                    <div className='LeaveSummaryText edit-cardtitle ml-2'>{'Leaves Summary'}</div>
                                    <div className='d-flex'>
                                        {!this.state.is_apply_leave_service_Unavailable &&
                                            <div className='applyLeaveBtnmargin mr-4'>
                                                <input
                                                    type="button"
                                                    className='btn applyLeaveBtn'
                                                    value="Apply leave"
                                                    onClick={() => this.applyleave()}
                                                ></input>
                                            </div>
                                        }
                                        {!this.state.is_previous_year_leave &&
                                            <div className='applyLeaveBtnmargin mr-3'>
                                                <input
                                                    type="button"
                                                    className=' cancelBtn'
                                                    value={"Regularization " + currentYear}
                                                    onClick={() => this.applyPrevLeave()}
                                                ></input>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='leave-grid-screen'>
                                    <div className="leave-quota">
                                        <LeaveQuota />
                                    </div>
                                    <div className="upcoming-holiday">
                                        <UpcommingHoliday />
                                    </div>
                                    <div className="leave-history">
                                        <LeaveHistoryMain
                                            togglePreviousDetails={this.togglePreviousDetails}
                                            toggleCurrentDetails={this.toggleCurrentDetails}
                                            currentLeave={currentLeave}
                                            previousLeave={previousLeave}
                                            isCurrentLeaveActive={this.state.isCurrentLeaveActive}
                                            isPreviousLeaveActive={this.state.isPreviousLeaveActive}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.applyLeave &&
                            <ApplyLeavePopup
                                applyLeave={this.state.applyLeave}
                                show={false}
                                closeapplyLeave={this.closepopup}
                            />
                        }
                        {this.state.applyPrevLeave &&
                            <ApplyPreviousLeavePopup
                                applyPrevLeave={this.state.applyPrevLeave}
                                show={false}
                                closeapplyLeave={this.closepopup}
                                togglePreviousDetails={this.togglePreviousDetails}
                            />
                        }
                    </div>
                }
                {this.state.isActive &&
                    <DynamicCustomPopupModal
                        loading={this.state.isActive}
                        note_title={this.state.note_title}
                        note_content={this.state.note_content}
                        note_type={this.state.note_type}
                        image_url={this.state.image_url}
                        image_hyperlink={this.state.image_hyperlink}
                        message={this.state.message}
                        onDismiss={this.hideModal} />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        homeActions: bindActionCreators(homeActions, dispatch)
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(LeaveScreen);