import React, { useEffect, useState } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import DataNotFound from '../../../shared/notFound/DataNotFound';
import ApplyForLoan from './loanSystemScreens/ApplyForLoan';
import LoanSanctioning from './loanSystemScreens/LoanSanctioning';
import LoanMasterScreen from './loanSystemScreens/LoanMasterScreen';
import LoanLetterPrintingForLoanSystem from './loanSystemScreens/LoanLetterPrintingForLoanSystem';
import MeritStamping from './loanSystemScreens/MeritStamping';
import Report from './loanSystemScreens/Report';

function LoanSystemHomeScreen() {

    const [ filteredTabs, setFilteredTabs ] = useState(null);
     const [loading, setLoading] = useState(false)
     const [activeTabIndex, setActiveTabIndex] = useState(0)

     const [isApplicableList, setIsApplicableList] = useState([])

  const RoleWiseTabs = [
    // { name: "Apply for Loan", component: <ApplyForLoan/>, type: 'is_sug_super_admin_applicable' },
    // { name: "Merit Stamping", component: <MeritStamping/>, type: 'is_sug_office_applicable' },
    // { name: "Loan Sanctioning", component: <LoanSanctioning/>, type:  'is_sug_office_applicable'},
    // { name: "Loan Letter", component: <LoanLetterPrintingForLoanSystem/>, type: 'is_sug_office_applicable' },
    // { name: "Report", component: <Report/>, type: 'is_sug_office_applicable' },
    { name: "Loan Master Maintainance",component: <LoanMasterScreen/>, type: 'is_sug_office_applicable'},
  ]
  useEffect(()=>{
    setFilteredTabs(RoleWiseTabs)
    const responseData =({is_sug_office_applicable: true, is_sug_super_admin_applicable: true})
    setIsApplicableList(responseData)
  },[])
  const toggleLocalTabs = (index) => {
    setActiveTabIndex(index)
  }
  const AllTabs = [
  ]

  return (
    <div className="row pb-0 pt-12px px-12px">
      <LoaderIndicator loading={loading} />
      <div className='col-md-12 justify-content-center align-item-center px-0'>
        <div className="col-md-12 card-new m-rem h-83vh of-hidden">
          {filteredTabs?.length > 0 ?
            <>
              <div className='ml-10px justify-content-between'>
                <div className='row mt-3 ss'>
                {AllTabs.concat(RoleWiseTabs.filter((screen) => isApplicableList[screen.type] == true)).map((tab, index) => {
                    return (
                      <div className={index == activeTabIndex ? "tab-active" : "tab-inactive"} onClick={() => toggleLocalTabs(index)}>
                        {tab.name}
                      </div>
                    )
                  })}
                </div>
                <div className='d-flex row align-item-center'>
                </div>
              </div>
              <div className='mt-3px'><hr /></div>
              <div className='w-100 h-90'>
              {AllTabs.concat(filteredTabs)?.[activeTabIndex]?.component}
              </div>
            </>
            : <DataNotFound />}
        </div>
      </div>
    </div>
  )
}
export default LoanSystemHomeScreen