import React, { Component } from 'react';
import './announcementScreen.css'
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
// import pin from '../../../../assets/drishti_images/push-pin.svg';
// import unpin from '../../../../assets/drishti_images/unpin.svg';
import whitecross from '../../../../assets/drishti_images/white-cross.svg';
import { Portalemoji, Newemoji, ReactionScreenEmoji } from '../../../../utils/Utils';
import * as announcmentAction from './announcementAction';
import { bindActionCreators } from 'redux';
import Reactionview from './annoucementEmployeeReactionView';
import NoAnnouncement from '../../../../assets/drishti_images/nodatafound/announcement-nodata.svg';
import likegrey from '../../../../assets/drishti_images/announcement/portalEmoji/like-grey.svg';
import clapgrey from '../../../../assets/drishti_images/announcement/portalEmoji/clap-grey.svg';
import lovegrey from '../../../../assets/drishti_images/announcement/portalEmoji/love-grey.svg';
import thoughtfullgrey from '../../../../assets/drishti_images/announcement/portalEmoji/thoughtfull-grey.svg';
import innovativegrey from '../../../../assets/drishti_images/announcement/portalEmoji/innovative-grey.svg';

import likecolor from '../../../../assets/drishti_images/announcement/portalEmoji/like-color.svg';
import clapcolor from '../../../../assets/drishti_images/announcement/portalEmoji/clap-color.svg';
import lovecolor from '../../../../assets/drishti_images/announcement/portalEmoji/love-color.svg';
import thoughtfullcolor from '../../../../assets/drishti_images/announcement/portalEmoji/thoughtfull-color.svg';
import innovativecolor from '../../../../assets/drishti_images/announcement/portalEmoji/innovative-color.svg';

import { handleClickForReactions } from "../../../shared/reactionHandle";


const rectionFormOther = " others"
const reactionFromYouAndOther = " others"
class AnnouncmentScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notification_type: ['All', 'Platform', 'Organization'],
            announcementType: 'All',
            loading: false,
            portalEmojiData: Portalemoji(),
            emojiData: Newemoji(),
            reactionScreenEmoji: ReactionScreenEmoji(),
            page_number: 1,
            announcementData: [],
            clickImage: '',
            collectdata: [],
            datapresent: false,
            message: '',
            responsedata: [],
            announcementId: '',
            announcement_id: '',
            total_page: 0,
            whichReaction: '',
            username: '',
            likeactive: false,
            applaudactive: false,
            loveactive: false,
            innovative: false,
            thoughtfulactive: false,
        }
        this.child = React.createRef();
    }

    componentDidMount() {
        this.setState({
            announcementType: this.state.notification_type[0],
            username: this.props.loginData.userData.Firstname
        })
        this.getAnnouncement();
    }

    getAnnouncement = () => {
        this.setState({
            loading: true,
            page_number: this.state.page_number,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            announcement_type: this.state.announcementType,
            employee_id: this.props.loginData.userData.Perno,
            page_number: this.state.page_number
        }
        fetchApiCall(Config.get_announcement, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        // announcementData: res.data.data,
                        announcementData: this.state.announcementData.concat(res.data.data),
                        loading: false,
                        datapresent: true,
                        message: '',
                        total_page: res.data.total_page
                    }
                        // , () => this.collectpageno()
                    )
                } else {
                    this.setState({
                        loading: false,
                        announcementData: [],
                        show: true,
                        datapresent: false,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    updateReactionAnnouncement(announcementid, response) {
        this.setState({
            loading: true,
            announcement_id: announcementid,
            whichReaction: response,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            announcement_id: announcementid,
            employee_id: this.props.loginData.userData.Perno,
            employee_name: this.props.loginData.userData.CompName,
            employee_response: response
        }
        fetchApiCall(Config.update_announcement_user_responce, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    viewImage = (img) => {
        this.setState({
            clickImage: img
        })

    }

    onChanged = (value) => {
        this.setState({ announcementType: value, announcementData: [] }, () => this.getAnnouncement())
    }

    callChildFunctionFromParent = (announcementid) => {
        this.setState({
            announcementId: announcementid
        }, () => this.child.getAnnouncementEmployeeRecord(this.state.announcementId, 'All'))
    }

    morePage = () => {
        this.setState({
            page_number: this.state.page_number + 1
        },
            () => this.getAnnouncement()
        )
    }

   
  handleClick = (id, reaction) => {
    this.setState(
      (prevReactions) => ({
        announcementData: handleClickForReactions(
          id,
          reaction,
          prevReactions.announcementData,
          this.props.loginData,
          "announcement_id"
        ),
      }),
      () => this.updateReactionAnnouncement(id, reaction)
    );
    console.log("id, reaction:", id, reaction);

    }

    render() {
        return (
            <div className='row'>
                <div className="col-md-12 card-new">
                    <div className='row justify-content-between align-item-center'>
                        <div className="edit-cardtitle common-text-color mt-0">Activity Feed</div>
                        <div style={{ width: '8.688rem', marginTop: '5px', marginBottom: '5px' }}>
                            <Dropdown
                                data={this.state.notification_type}
                                selectedValue={(value) => this.onChanged(value)}
                                value={this.state.announcementType}
                            />
                        </div>
                    </div>
                    <hr />
                    {/* ********************for showning unpin all data ************************** */}
                    <div className="card-item">
                        {/* {this.state.datapresent === true ? */}
                        {this.state.announcementData.length !== 0 ?
                            <div>
                                <>
                                    {this.state.announcementData.map((list, id) =>
                                        <div className='row' key={id}>
                                            <div className='col-md-12 pl-0'>
                                                <div className='row pb-3 pt-3'>
                                                    <div className="col-md-4">
                                                        <div className=''>
                                                            <img
                                                                src={list.image_link}
                                                                alt=""
                                                                className="announcement-img"
                                                                onClick={() => this.viewImage(list.image_link)}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModalCenter"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md pl-0">
                                                        <div className='d-flex flex-column' style={{ minHeight: '200px' }}>
                                                            <div className="pl-1 pr-1 pt-2 pb-0">
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div className="announcement-inner-title">
                                                                        <a style={{ color: 'black' }} href={list.announcement_url !== '' ? list.announcement_url : null} target="_blank">
                                                                            {list.announcement_title}
                                                                        </a>
                                                                    </div>
                                                                    {/* <div>
                                                                <img src={pin} className="pin" 
                                                                onClick={() => this.collectdata(list.image_link, list.notification_title, list.notification_content, list.announcement_id)}
                                                                 />
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="mb-auto pt-0 pl-1 pr-1">
                                                                <div className='row announcement-inner-content pt-2'>
                                                                    {list.announcement_description}
                                                                </div>
                                                            </div>
                                                            <div className="pt-0 pl-1 pr-1 pb-0">
                                                                <div className='row'>
                                                                    <div className="row outer-emoji align-item-center ">
                                                                        <div
                                                                            className='d-flex justify-content-start align-item-center'
                                                                            data-bs-toggle="modal"
                                                                            data-target="#openEmployeeReaction"
                                                                            onClick={() => this.callChildFunctionFromParent(list.announcement_id)}
                                                                        >
                                                                            {[...new Map(list.feedback.map(item => [item["employee_response"], item])).values()].map((feedB, id) =>
                                                                                <div key={id} style={{ marginRight: '-5px' }}>
                                                                                    {this.state.emojiData.map((emo, id) =>
                                                                                        <div key={id}>
                                                                                            {emo.description === feedB.employee_response &&
                                                                                                <>
                                                                                                    <img src={emo.src} alt=""/>
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}

                                                                            {/* {list.feedback.map((feedB, id) =>
                                                                                <div key={id} style={{ marginRight: '-5px' }}>
                                                                                    {this.state.emojiData.map((emo, id) =>
                                                                                        <div key={id} >
                                                                                            {emo.description === feedB.employee_response &&
                                                                                                <>
                                                                                                    <img src={emo.src} />
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )} */}


                                                                            {/* ******************************************************* */}

                                                                            <div
                                                                                className=' reaction-text ml-2'
                                                                            >
                                                                                {
                                                                                    list.total_response === 1 && list.employee_response === '' && this.state.announcement_id === list.announcement_id ?
                                                                                        (this.state.username + " and " + list.total_response + reactionFromYouAndOther)
                                                                                        :
                                                                                        list.total_response === 1 && list.employee_response === '' ?
                                                                                            (list.total_response + rectionFormOther)
                                                                                            :
                                                                                            list.total_response === 1 && list.employee_response != '' &&
                                                                                            (this.state.username)
                                                                                }
                                                                                {
                                                                                    list.total_response > 1 && list.employee_response === '' && this.state.announcement_id === list.announcement_id ?
                                                                                        (this.state.username + " and " + list.total_response + reactionFromYouAndOther)
                                                                                        :
                                                                                        list.total_response > 1 && list.employee_response === '' ?
                                                                                            (list.total_response + rectionFormOther)
                                                                                            :
                                                                                            list.total_response > 1 && list.employee_response != '' &&
                                                                                            (this.state.username + " and " + (list.total_response - 1) + reactionFromYouAndOther)
                                                                                }
                                                                                {
                                                                                    list.total_response === 0 && list.employee_response === '' && this.state.announcement_id === list.announcement_id &&
                                                                                    (this.state.username)
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* ***************************** reaction large emoji*********************** */}
                                                            <div className="pl-1 pr-0 pb-0 pt-0">
                                                                <hr />
                                                                <div className='row d-flex justify-content-between'>
                                                                    <div onClick={() => this.handleClick(list.announcement_id, 'like')}>
                                                                        {
                                                                            list.employee_response === 'like' ?
                                                                                <img src={likecolor}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                                :
                                                                                <img src={likegrey}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => this.handleClick(list.announcement_id, 'applaud')}>
                                                                        {
                                                                            list.employee_response === 'applaud' ?
                                                                                <img src={clapcolor}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                                :
                                                                                <img src={clapgrey}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => this.handleClick(list.announcement_id, 'love')}>
                                                                        {
                                                                            list.employee_response === 'love' ?
                                                                                <img src={lovecolor}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                                :
                                                                                <img src={lovegrey}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => this.handleClick(list.announcement_id, 'thoughtful')}>
                                                                        {
                                                                            list.employee_response === 'thoughtful' ?
                                                                                <img src={thoughtfullcolor}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                                :
                                                                                <img src={thoughtfullgrey}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => this.handleClick(list.announcement_id, 'innovative')}>
                                                                        {
                                                                            list.employee_response === 'innovative' ?
                                                                                <img src={innovativecolor}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                                :
                                                                                <img src={innovativegrey}
                                                                                    alt=""
                                                                                    className="portal-emoji"
                                                                                />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )}
                                </>

                                {this.state.page_number < this.state.total_page && this.state.total_page > 1 &&
                                    <div style={{ margin: '10px' }}>
                                        <div
                                            className='row col-12 d-flex justify-content-center card-for-click mx-10'
                                            onClick={() => this.morePage()}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <label style={{ cursor: 'pointer', margin: '5px', paddingBottom: '5px' }} className='card-content-title white-color' >
                                                MORE
                                            </label>
                                        </div>
                                    </div>

                                }
                            </div>
                            :
                            <div className=''>
                                <div className='d-flex justify-content-center flex-column' style={{ height: '27.688rem', marginTop: '20px' }}>
                                    <div className='align-self-center'>
                                        <img src={NoAnnouncement} alt="Nodata" />

                                    </div>
                                    <div className='align-self-center'>
                                        <label className='card-content-title grey-color'>No announcements have been shared yet!</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {/* ***********************************model for showing img******************** */}
                    <div className="modal fade" id="exampleModalCenter" tabIndex={"-1"} role="" >
                        <div className="modal-dialog modal-dialog-centered" role="">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className='row d-flex justify-content-end'>
                                        <img src={whitecross} alt="" className="white-cross " data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <img src={this.state.clickImage} alt="" className="model-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ********************model for reaction people************************************** */}
                    <Reactionview
                        passAnnouncementId={this.state.announcementId}
                        ref={instance => { this.child = instance; }}
                    />
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
        announcedata: state.announcementState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        announcementaction: bindActionCreators(announcmentAction, dispatch),

    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(AnnouncmentScreen);


