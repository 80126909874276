import React, { Component } from 'react';
import { connect } from 'react-redux';
import LeaveApproval from './LeaveApproval';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import DeleteLeaveApproval from './DeleteLeaveApproval';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import { EseparationDashboard } from '../../employeeScreens/eSeparation/manager/EseparationDashboard';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { withRouter } from 'react-router-dom';
import ReporteeLeaveQuota from './ReporteeLeaveQuota';
import PreviousLeaveApproval from './PreviousLeaveApproval';
import PreviousDeleteLeaveApproval from './PreviousDeleteLeaveApproval';
import TraineeLeaveApproval from './TraineeLeaveApproval';
import { configStore } from '../../../../redux/ConfigStore';
class ManagerApprovalScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSupervisor : configStore?.getState()?.loginState?.OTData?.is_trainee_approver,
            isManager :  configStore.getState()?.keycloakState?.keycloakData?.is_employee_reporting,
            loading: false,
            isLeaveApproveActive: false,
            isEsepApprovalActive: false,
            isTraineeLeaveApproveActive:false,
            isReporteeReportActive: false,
            isDeleteLeaveApproveActive: false,
            isPreviousYearLeaveActive: false,
            isPreviousYearDeleteActive: false,
            is_service_Unavailable: false,  // service unavailble from backend
            manager_approval_Service_reason: '',
            is_traineeservice_Unavailable:false,
            trainee_approval_Service_reason:'',

            is_previous_year_leave: false,
            previous_year_leaves_reason: '',

            isDEMApprovalPresent: false,
            approvalsRequestData: [],
            leaveApprovalList: [],
            page: 1,
            count: [],
            empID: '',
        }
    }

    componentDidMount() {
        this.checkService();
        const { location } = this.props;
        if (location?.state?.isEsepManager === true) {
            this.toggleEsepApproval();
        } else if (location?.state?.isLeaveApproveActive === true) {
            this.toggleLeaveApprove();
        } else if (location?.state?.isReporteeReportActive === true) {
            this.toggleReporteeQuota();
        }
        else if (this.state.isManager){
            this.setState({isLeaveApproveActive:true},()=>{
                this.toggleLeaveApprove()})
        }
        else if (this.state.isSupervisor) {
            this.setState({isTraineeLeaveApproveActive:true},()=>{
                this.toggleTraineeLeaveApprove()})
        }
        this.setState({
            leaveApprovalList: location?.state?.leaveApprovalList
        })
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "manager_leave_approval" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    manager_approval_Service_reason: item.reason
                })
            }
            if (item.service_type == "previous_year_leaves" && item.is_active == false) {
                this.setState({
                    is_previous_year_leave: true,
                    previous_year_leaves_reason: item.reason
                })
            }
            if (item.service_type == "trl_leave_approval" && item.is_active == false) {
                this.setState({
                    is_traineeservice_Unavailable: true,
                    trainee_approval_Service_reason: item.reason
                })
            }
        })
    }

    componentWillMount() {
        this.getDemEsepApprovals('Pending')
    }

    toggleLeaveApprove = () => {
        this.setState({ isLeaveApproveActive: true, isDeleteLeaveApproveActive: false, isEsepApprovalActive: false, isReporteeReportActive: false, isPreviousYearLeaveActive: false, isPreviousYearDeleteActive: false,isTraineeLeaveApproveActive:false})
    }

    toggleDeleteLeaveApprove = () => {
        this.setState({ isDeleteLeaveApproveActive: true, isLeaveApproveActive: false, isEsepApprovalActive: false, isReporteeReportActive: false, isPreviousYearLeaveActive: false, isPreviousYearDeleteActive: false,isTraineeLeaveApproveActive:false })
    }
    //Previous year leave
    togglePreviousYearLeave = () => {
        this.setState({ isDeleteLeaveApproveActive: false, isLeaveApproveActive: false, isEsepApprovalActive: false, isReporteeReportActive: false, isPreviousYearLeaveActive: true, isPreviousYearDeleteActive: false,isTraineeLeaveApproveActive:false })
    }

    togglePreviousYearDelete = () => {
        this.setState({ isDeleteLeaveApproveActive: false, isLeaveApproveActive: false, isEsepApprovalActive: false, isReporteeReportActive: false, isPreviousYearLeaveActive: false, isPreviousYearDeleteActive: true,isTraineeLeaveApproveActive:false })
    }

    toggleEsepApproval = () => {
        this.setState({ isLeaveApproveActive: false, isDeleteLeaveApproveActive: false, isEsepApprovalActive: true, isReporteeReportActive: false, isPreviousYearLeaveActive: false, isPreviousYearDeleteActive: false,isTraineeLeaveApproveActive:false }, () => this.getDemEsepApprovals('Pending'))
    }

    toggleReporteeQuota = () => {
        this.setState({ isLeaveApproveActive: false, isDeleteLeaveApproveActive: false, isEsepApprovalActive: false, isReporteeReportActive: true, isPreviousYearLeaveActive: false, isPreviousYearDeleteActive: false,isTraineeLeaveApproveActive:false })
    }

    toggleTraineeLeaveApprove = () => {
        this.setState({ isLeaveApproveActive: false, isDeleteLeaveApproveActive: false, isEsepApprovalActive: false, isReporteeReportActive: false, isPreviousYearLeaveActive: false, isPreviousYearDeleteActive: false,isTraineeLeaveApproveActive:true})
    }


    goBack = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');
    }


    //-----------------Get Dem Approvals Data API-----------------------
    getDemEsepApprovals = (type) => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            search_text: this.state.empID ? this.state.empID : '',
            status: type,
            page_number: this.state.page
        }
        console.log('getDemEsepApprovals body----->', body);
        fetchApiCall(Config.getDemEsepApprovals, body, headers, true, '')
            .then(res => {
                console.log('getDemEsepApprovals res----->', res);
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        approvalsRequestData: res.data.data.paginated_data ? res.data.data.paginated_data : [],
                        isDEMApprovalPresent: res.data.data.is_approval_present ? res.data.data.is_approval_present : false,
                        count: demData ? demData : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        approvalsRequestData: [],
                        isDEMApprovalPresent: false,
                        count: []
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    approvalsRequestData: [],
                    isDEMApprovalPresent: false,
                    count: []
                });
            })
    }

    //--------------Pagination Function Handled------------------------------
    handlePageCount = (type) => {
        let pageCountInc = this.state.page + 1
        let pageCountDec = this.state.page - 1
        if (this.state.isEsepApprovalActive) {
            if (type === "increment") {
                if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                    this.setState({ page: pageCountInc }, () => this.getDemEsepApprovals())
                }
            }
            else if (type === "decrement") {
                if (this.state.page > 1) { this.setState({ page: pageCountDec }, () => this.getDemEsepApprovals()) }
            }
        }
    }

    selectedPage = (pageCount) => {
        if (this.state.isEsepApprovalActive) {
            this.setState({ page: pageCount }, () => this.getDemEsepApprovals())
        } 
    }


    handleChange = (e) => { 
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
            this.setState({ empID: e.target.value }) 
        }
    }

    clearEmpID = (e) => {
        this.setState({ empID: '' }) 
    }

    searchEmpIdFunction = (type) => {
        if (this.state.isEsepApprovalActive) {
            this.getDemEsepApprovals(type);
        }
    }

    render() {
        const { location } = this.props;
        const { isEsepApprovalActive, isReporteeReportActive, is_service_Unavailable, isSupervisor, isManager , is_traineeservice_Unavailable } = this.state;
        return (
            <div className="row px-0 px-12px">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 ">
                    <div className='row px-0 pt-0' >
                        <img src={overviewButton} alt='' onClick={this.goBack} style={{ cursor: 'pointer' }} />
                    </div>
                    {!isEsepApprovalActive && !isReporteeReportActive && this.state.is_service_Unavailable ?
                        <div className="col-md-12 h-100vh justify-content-center align-item-center">
                            <ServiceUnavailable reason={this.state.manager_approval_Service_reason} />
                        </div>
                        :
                        <div className='row mt-1'>
                            <div className='card-new col-md-12'>
                                <div className='mt-3 col-md-12 mr-2 flex flex-direction-row scroll-x w-97 ml-4'>
                                    <div className='flex-direction-row -ml-20px'>
                                        {!is_service_Unavailable  && isManager &&
                                            <div className={this.state.isLeaveApproveActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleLeaveApprove()}>{'Leave Approval'}</div>}
                                        {!is_service_Unavailable && isManager &&
                                            <div className={this.state.isDeleteLeaveApproveActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDeleteLeaveApprove()}>{'Delete Leave Approval'}</div>}
                                        {!this.state.is_previous_year_leave && isManager &&
                                            <div className={this.state.isPreviousYearLeaveActive ? "tab-active" : "tab-inactive"} onClick={() => this.togglePreviousYearLeave()}>{'Previous Year Attd. Reg. Approval'}</div>}
                                        {!this.state.is_previous_year_leave && isManager &&
                                            <div className={this.state.isPreviousYearDeleteActive ? "tab-active" : "tab-inactive"} onClick={() => this.togglePreviousYearDelete()}>{'Delete Previous Year Attd. Reg. Approval'}</div>}
                                        {location?.state?.isReporteeReportActive === true && isManager &&
                                            <div className={this.state.isReporteeReportActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleReporteeQuota()}>{'Reportee Leave Quota'}</div>}
                                        {this.state.isDEMApprovalPresent && isManager &&
                                            <div className={this.state.isEsepApprovalActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleEsepApproval()}>{'e-Separation Approvals'}</div>}
                                        {!is_traineeservice_Unavailable && isSupervisor &&
                                            <div className={this.state.isTraineeLeaveApproveActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleTraineeLeaveApprove()}>{'Trainee Leave Approval'}</div>}    
                                    </div>
                                </div>
                                <div className='hz-lines mr-4 ml-4'></div>
                                <div>
                                    {this.state.isLeaveApproveActive && isManager &&
                                        <div>
                                            <LeaveApproval
                                                loginData={this.props.loginData}
                                            />
                                        </div>
                                    }
                                    {this.state.isDeleteLeaveApproveActive && isManager &&
                                        <div>
                                            <DeleteLeaveApproval
                                                loginData={this.props.loginData} />
                                        </div>
                                    }
                                    {this.state.isPreviousYearLeaveActive && isManager &&
                                        <div>
                                            <PreviousLeaveApproval
                                                loginData={this.props.loginData}
                                            />
                                        </div>
                                    }
                                    {this.state.isPreviousYearDeleteActive && isManager &&
                                        <div>
                                            <PreviousDeleteLeaveApproval
                                                loginData={this.props.loginData} />
                                        </div>
                                    }
                                    {this.state.isEsepApprovalActive && isManager &&
                                        <div>
                                            <EseparationDashboard
                                                approvalsRequestData={this.state.approvalsRequestData}
                                                getDemEsepApprovals={this.getDemEsepApprovals}
                                                isEsepApprovalActive={this.state.isEsepApprovalActive}
                                                toggleApprove={this.toggleEsepApproval}
                                                handlePageCount={this.handlePageCount}
                                                selectedPage={this.selectedPage}
                                                count={this.state.count}
                                                page={this.state.page}
                                                handleChange={this.handleChange}
                                                clearEmpID= {this.clearEmpID}
                                                empID = {this.state.empID}
                                                searchEmpIdFunction={this.searchEmpIdFunction}
                                            />
                                        </div>
                                    }
                                    {this.state.isReporteeReportActive && isManager &&
                                        <div>
                                            <ReporteeLeaveQuota
                                                leaveApprovalList={this.state.leaveApprovalList}
                                            />
                                        </div>
                                    }
                                    {this.state.isTraineeLeaveApproveActive && isSupervisor &&
                                        <div>
                                            <TraineeLeaveApproval
                                                loginData={this.props.loginData}
                                                isTraineeLeaveApproveActive={this.state.isTraineeLeaveApproveActive}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default withRouter(connect(mapStatesToProps)(ManagerApprovalScreen));