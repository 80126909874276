import React, { Component } from 'react';
import '../ProfileScreen.css';
import { connect } from 'react-redux';
import edit from '../../../../../assets/drishti_images/profile/edit.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import * as profileActions from '../ProfileAction';
import { bindActionCreators } from 'redux';
import DeclarationPopup from '../../profile/DeclarationPopup';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { maskMobile, maskEmail } from '../../../../shared/commonMaskFunction';
import eye from '../../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../../assets/drishti_images/eye-slash.svg';

let emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
var phoneno = /^\d{10}$/;

class ContactDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOfficeEdit: false,
            isPersonalMobEdit: false,
            isBussMobEdit: false,
            isEmergEdit: false,
            isEmailEdit: false,


            contactDetail: [],
            popupVisibility: false,

            empId: '',
            officeMobileNumber: '',
            personalMobileNumber: '',
            bussiOffPhoneNumber: '',
            emergContact: '',
            personalEmailId: '',
            loading: false,
            isActive: false,
            message: '',
            isPersonalNumUnmasked: false,
            isOfficeNumUnmasked: false,
            isBusinessNumUnmasked: false,
            isEmergencyNumUnmasked: false,
            isPersonalEmailUnmasked: false
        }
    }
    componentDidMount() {
        this.getContactDetails(this.props.loginData.userData.Perno);
    }

    getContactDetails(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { 'emp_id': employeeId }
        fetchApiCall(Config.getContactDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.status_code == 200 || res.data.status_code == 300) {
                    if (res.data.status_code == 300) {
                        // setTimeout(() => { Toast.show(res.data.message, Toast.SHORT) }, 100)
                    }
                    this.setState({
                        officeMobileNumber: res.data.data.office_mobile ? res.data.data.office_mobile.office_mobile_number : '',
                        personalMobileNumber: res.data.data.personal_mobile ? res.data.data.personal_mobile.personal_mobile_number : '',
                        bussiOffPhoneNumber: res.data.data.business_office_phone ? res.data.data.business_office_phone.business_office_phone_number : '',
                        emergContact: res.data.data.emergency_contact ? res.data.data.emergency_contact.emergency_contact_number : '',
                        personalEmailId: res.data.data.personal_email ? res.data.data.personal_email.personal_email_id : '',
                        loading: false,
                        isPersonalNumUnmasked: false,
                        isOfficeNumUnmasked: false,
                        isBusinessNumUnmasked: false,
                        isEmergencyNumUnmasked: false,
                        isPersonalEmailUnmasked: false
                    });
                    this.props.contactActions.getContactDetails(res.data.data);
                } else {
                    this.setState({
                        loading: false,
                        isPersonalNumUnmasked: false,
                        isOfficeNumUnmasked: false,
                        isBusinessNumUnmasked: false,
                        isEmergencyNumUnmasked: false,
                        isPersonalEmailUnmasked: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    // handleChange = (type, value) => {
    //     let typeState = type.toString()
    //     this.setState({ [typeState]: value })
    // }

    validateContactData = () => {
        if (this.state.isPersonalMobEdit) {
            if (this.state.personalMobileNumber === '' || (!phoneno.test(this.state.personalMobileNumber) || this.state.personalMobileNumber.length < 10) || this.state.personalMobileNumber === this.props?.contactData?.contactData?.personal_mobile?.personal_mobile_number) {
                toast.warn('Please enter valid personal mobile number', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.setState({
                    popupVisibility: true
                })
            }
        }
        else if (this.state.isOfficeEdit) {
            if (this.state.officeMobileNumber === '' || (!phoneno.test(this.state.officeMobileNumber) || this.state.officeMobileNumber.length < 10) || this.state.officeMobileNumber === this.props?.contactData?.contactData?.office_mobile?.office_mobile_number) {
                toast.warn('Please enter valid office mobile number', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.setState({
                    popupVisibility: true
                })
            }
        }
        else if (this.state.isBussMobEdit) {
            if (this.state.bussiOffPhoneNumber === '' || (!phoneno.test(this.state.bussiOffPhoneNumber) || this.state.bussiOffPhoneNumber.length < 10) || this.state.bussiOffPhoneNumber === this.props?.contactData?.contactData?.business_office_phone?.business_office_phone_number) {
                toast.warn('Please enter valid bussiness mobile number', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.setState({
                    popupVisibility: true
                })
            }
        }
        else if (this.state.isEmergEdit) {
            if (this.state.emergContact === '' || (!phoneno.test(this.state.emergContact) || this.state.emergContact.length < 10) || this.state.emergContact === this.props?.contactData?.contactData?.emergency_contact?.emergency_contact_number) {
                toast.warn('Please enter valid emergency contact number', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.setState({
                    popupVisibility: true
                })
            }
        }
        else if (this.state.isEmailEdit) {
            if (this.state.personalEmailId === '' || !emailReg.test(this.state.personalEmailId) || this.state.personalEmailId === this.props?.contactData?.contactData?.personal_email?.personal_email_id) {
                toast.warn('Please enter valid email Id', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.setState({
                    popupVisibility: true
                })
            }
        }
    }
    // *******************************************************************************

    saveContactDetails = () => {
        this.setState({
            loading: true,
            popupVisibility: false,
        });

        let req = {}
        if (this.state.isOfficeEdit) {
            req = {
                contact: this.state.officeMobileNumber ? this.state.officeMobileNumber : '',
                contact_type: this.props.contactData.contactData.office_mobile ? this.props.contactData.contactData.office_mobile.contact_type : '',
                sequence_no: this.props.contactData.contactData.office_mobile ? this.props.contactData.contactData.office_mobile.office_mobile_sequence_no : ''
            }
        } else if (this.state.isPersonalMobEdit) {
            req = {
                contact: this.state.personalMobileNumber ? this.state.personalMobileNumber : '',
                contact_type: this.props.contactData.contactData.personal_mobile ? this.props.contactData.contactData.personal_mobile.contact_type : '',
                sequence_no: this.props.contactData.contactData.personal_mobile ? this.props.contactData.contactData.personal_mobile.personal_mobile_sequence_no : ''
            }
        } else if (this.state.isBussMobEdit) {
            req = {
                contact: this.state.bussiOffPhoneNumber ? this.state.bussiOffPhoneNumber : '',
                contact_type: this.props.contactData.contactData.business_office_phone ? this.props.contactData.contactData.business_office_phone.contact_type : '',
                sequence_no: this.props.contactData.contactData.business_office_phone ? this.props.contactData.contactData.business_office_phone.business_office_phone_sequence_no : ''
            }
        } else if (this.state.isEmergEdit) {
            req = {
                contact: this.state.emergContact ? this.state.emergContact : '',
                contact_type: this.props.contactData.contactData.emergency_contact ? this.props.contactData.contactData.emergency_contact.contact_type : '',
                sequence_no: this.props.contactData.contactData.emergency_contact ? this.props.contactData.contactData.emergency_contact.emergency_contact_sequence_no : ''
            }
        } else if (this.state.isEmailEdit) {
            req = {
                contact: this.state.personalEmailId ? this.state.personalEmailId : '',
                contact_type: this.props.contactData.contactData.personal_email ? this.props.contactData.contactData.personal_email.contact_type : '',
                sequence_no: this.props.contactData.contactData.personal_email ? this.props.contactData.contactData.personal_email.personal_email_id_sequence_no : ''
            }
        }

        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            data: req
        }
        console.warn("save body: ", body)
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updateContactDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        isOfficeEdit: false,
                        isPersonalMobEdit: false,
                        isBussMobEdit: false,
                        isEmergEdit: false,
                        isEmailEdit: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getContactDetails(this.props.loginData.userData.Perno);

                } else {
                    this.setState({
                        isEdit: false,
                        popupVisibility: false,
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false,
                    popupVisibility: false
                });
                toast.error("Unable to contact server\nPlease try again later", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='mt-3'>
                            <div className='row '>
                                <div className='col-4 mb-3'>
                                    <div className='row '>
                                        <div className='col-md-9 p-sub-heading ml-4'>Personal Mobile Number</div>
                                        <div className='col-md px-0'>
                                            {this.state.isOfficeEdit || this.state.isBussMobEdit || this.state.isEmergEdit || this.state.isEmailEdit ? null :
                                                <div className='mt-0'>
                                                    <img
                                                        alt="edit"
                                                        src={edit}
                                                        className="plus-img"
                                                        onClick={() =>{ 
                                                            this.setState({ isPersonalMobEdit: true, isOfficeEdit: false, isBussMobEdit: false, isEmergEdit: false, isEmailEdit: false })
                                                        }
                                                    }
                                                    ></img>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-md-9 px-0'>
                                            <div className='p-placeholder-text p-placeholder contact-field ml-3'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    maxLength={'10'}
                                                    name='personal_no'
                                                    value={this.state.isPersonalMobEdit ? (this.state.personalMobileNumber ?? '') : (this.state.isPersonalNumUnmasked  ? (this.state.personalMobileNumber ?? '') : maskMobile(this.state.personalMobileNumber ?? ''))}
                                                    readOnly={!this.state.isPersonalMobEdit}
                                                    onChange={(e) => {
                                                        this.setState({ personalMobileNumber: e.target.value })
                                                    }}
                                                />
                                            
                                                {
                                                    !this.state.isPersonalMobEdit &&
                                                    <div 
                                                        onClick={() => this.setState({ isPersonalNumUnmasked : !this.state.isPersonalNumUnmasked })} // Toggle between true/false for mask/unmask
                                                        className='email-feild'>
                                                        <img
                                                            alt="eyeimage"
                                                            src={ this.state.isPersonalNumUnmasked ? eye : eyeslash }
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-9 p-sub-heading ml-4'>Office Mobile Number</div>
                                        <div className='col-md px-0'>
                                            {this.state.isPersonalMobEdit || this.state.isBussMobEdit || this.state.isEmergEdit || this.state.isEmailEdit ? null :
                                                <div className='mt-0'>
                                                    <img
                                                        alt="edit"
                                                        src={edit}
                                                        className="plus-img"
                                                        onClick={() => this.setState({ isPersonalMobEdit: false, isOfficeEdit: true, isBussMobEdit: false, isEmergEdit: false, isEmailEdit: false })}
                                                    ></img>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-md-9 px-0'>
                                                    <div className='p-placeholder-text p-placeholder contact-field ml-3'>
                                                        <input
                                                            className="form-control p-placeholder px-3"
                                                            type="text"
                                                            maxLength={'10'}
                                                            name='office-no'
                                                            value={this.state.isOfficeEdit ? (this.state.officeMobileNumber ?? '') : (this.state.isOfficeNumUnmasked ? (this.state.officeMobileNumber ?? '') : maskMobile(this.state.officeMobileNumber ?? ''))}
                                                            readOnly={!this.state.isOfficeEdit}
                                                            onChange={(e) => {
                                                                this.setState({ officeMobileNumber: e.target.value })
                                                            }}
                                                        />
                                            
                                                            {
                                                                !this.state.isOfficeEdit &&
                                                                <div 
                                                                    onClick={() => this.setState({ isOfficeNumUnmasked : !this.state.isOfficeNumUnmasked })} // Toggle between true/false for mask/unmask
                                                                    className='email-feild'>
                                                                    <img
                                                                        alt="eyeimage"
                                                                        src={ this.state.isOfficeNumUnmasked ? eye : eyeslash }
                                                                    />
                                                                </div>
                                                            }
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-9 p-sub-heading ml-4'>Business Contact Number</div>
                                        <div className='col-md px-0'>
                                            {this.state.isPersonalMobEdit || this.state.isOfficeEdit || this.state.isEmergEdit || this.state.isEmailEdit ? null :
                                                <div className='mt-0'>
                                                    <img
                                                        alt="edit"
                                                        src={edit}
                                                        className="plus-img"
                                                        onClick={() => this.setState({ isPersonalMobEdit: false, isOfficeEdit: false, isBussMobEdit: true, isEmergEdit: false, isEmailEdit: false })}
                                                    ></img>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-9 px-0'>
                                            <div className='p-placeholder-text p-placeholder contact-field ml-3'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    maxLength={'10'}
                                                    name='business_no'
                                                    value={this.state.isBussMobEdit ? (this.state.bussiOffPhoneNumber ?? '') : this.state.isBusinessNumUnmasked ?  (this.state.bussiOffPhoneNumber ?? '') : maskMobile(this.state.bussiOffPhoneNumber)}
                                                    readOnly={!this.state.isBussMobEdit}
                                                    onChange={(e) => {
                                                        this.setState({ bussiOffPhoneNumber:  e.target.value })
                                                    }}
                                                />
                                            
                                                    {
                                                        !this.state.isBussMobEdit &&
                                                        <div 
                                                            onClick={() => this.setState({ isBusinessNumUnmasked : !this.state.isBusinessNumUnmasked })} // Toggle between true/false for mask/unmask
                                                            className='email-feild'>
                                                            <img
                                                                alt="eyeimage"
                                                                src={ this.state.isBusinessNumUnmasked ? eye : eyeslash }
                                                            />
                                                        </div>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row '>
                                <div className='col-4 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-9 p-sub-heading ml-4'>Emergency Contact Number</div>
                                        <div className='col-md px-0'>
                                            {this.state.isPersonalMobEdit || this.state.isOfficeEdit || this.state.isBussMobEdit || this.state.isEmailEdit ? null :
                                                <div className='mt-0'>
                                                    <img
                                                        alt="edit"
                                                        src={edit}
                                                        className="plus-img"
                                                        onClick={() => this.setState({ isPersonalMobEdit: false, isOfficeEdit: false, isBussMobEdit: false, isEmergEdit: true, isEmailEdit: false })}
                                                    ></img>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col-md-9 px-0'>
                                            <div className='p-placeholder-text p-placeholder contact-field ml-3'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    maxLength={'10'}
                                                    name='emergency_no'
                                                    value={this.state.isEmergEdit ? (this.state.emergContact ?? '') : this.state.isEmergencyNumUnmasked ? (this.state.emergContact ?? '') : maskMobile(this.state.emergContact ?? '')}
                                                    readOnly={!this.state.isEmergEdit}
                                                    onChange={(e) => {
                                                        this.setState({ emergContact: e.target.value })
                                                    }}
                                                />
                                            
                                                    {
                                                        !this.state.isEmergEdit &&
                                                        <div 
                                                            onClick={() => this.setState({ isEmergencyNumUnmasked : !this.state.isEmergencyNumUnmasked })} // Toggle between true/false for mask/unmask
                                                            className='email-feild'>
                                                            <img
                                                                alt="eyeimage"
                                                                src={ this.state.isEmergencyNumUnmasked ? eye : eyeslash }
                                                            />
                                                        </div>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-9 p-sub-heading ml-4'>Personal Email-ID</div>
                                        <div className='col-md px-0'>
                                            {this.state.isPersonalMobEdit || this.state.isOfficeEdit || this.state.isBussMobEdit || this.state.isEmergEdit ? null :
                                                <div className='mt-0'>
                                                    <img
                                                        alt="edit"
                                                        src={edit}
                                                        className="plus-img"
                                                        onClick={() => this.setState({ isPersonalMobEdit: false, isOfficeEdit: false, isBussMobEdit: false, isEmergEdit: false, isEmailEdit: true })}
                                                    ></img>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='row d-flex '>
                                        <div className='col-md-9 px-0'>
                                            <div className='p-placeholder-text p-placeholder contact-field ml-3'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    name='functional_manager'
                                                    value={this.state.isEmailEdit ? (this.state.personalEmailId ?? '') : this.state.isPersonalEmailUnmasked  ? (this.state.personalEmailId ?? '') : maskEmail(this.state.personalEmailId ?? '')}
                                                    readOnly={!this.state.isEmailEdit}
                                                    onChange={(e) => {
                                                        this.setState({ personalEmailId: e.target.value })
                                                    }}
                                                />
                                            
                                                    {
                                                        !this.state.isEmailEdit &&
                                                        <div 
                                                            onClick={() => this.setState({ isPersonalEmailUnmasked : !this.state.isPersonalEmailUnmasked })} // Toggle between true/false for mask/unmask
                                                            className='email-feild'>
                                                            <img
                                                                alt="eyeimage"
                                                                src={ this.state.isPersonalEmailUnmasked ? eye : eyeslash }
                                                            />
                                                        </div>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row d-flex justify-content-end mt-4'>
                    {this.state.isOfficeEdit || this.state.isPersonalMobEdit || this.state.isBussMobEdit || this.state.isEmergEdit || this.state.isEmailEdit ?
                        <div className='justify-content-between'>
                            <div
                                type='button'
                                className='cancl-btn cancel-txt'
                                onClick={() => {
                                    this.getContactDetails(this.props.loginData.userData.Perno)
                                    this.setState({ isPersonalMobEdit: false, isOfficeEdit: false, isBussMobEdit: false, isEmergEdit: false, isEmailEdit: false })
                                }}
                            >Cancel</div>
                            <div
                                type='button'
                                className='save-btn save-txt'
                                onClick={() => this.validateContactData()}
                            >Save</div>
                        </div>
                        :
                        null
                    }
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveContactDetails}
                    />
                }
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        contactData: state.profileState
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        contactActions: bindActionCreators(profileActions, dispatch),
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(ContactDetail);
