import React, { Component } from 'react';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../../conflictOfInterest/COIStyle.css';
import '../../../../../assets/styles/popupStyles.css';
import '../PaySpaceStyle.css'
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { toast } from 'react-toastify';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import fileDownloadIcon from '../../../../../assets/drishti_images/fileDownloadIcon.png';
import radioOff from '../../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../../assets/drishti_images/radioOn.png';
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction';
import { downloadPDF } from '../../gems/Gems.api.service';
import animeArrow from "../../../../../assets/drishti_images/header/right-arrow-gif-unscreen.gif";

const standard = 'Compensation Statement as per Standard Structure'
const current = `Compensation Statement as per Employee's Choice Pay Selection`
class CompensationStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isServiceUnavailable: false,  // service unavailble from backend
            choicePayCompReason: '',
            openFixedCard: false,
            openFlexiCard: false,
            responseData: {},
            radioFilters: [
                {
                    id: 1,
                    title: 'Compensation Statement as per Standard Structure',
                    value: 'Standard selection'
                },
                {
                    id: 2,
                    title: `Compensation Statement as per Employee's Choice Pay Selection`,
                    value: 'Current selection'
                },
            ],
            checked: 0,
            category: 'Standard selection',
            isInstructionShow: true,
            instructionData: [],
            filterWageType: '',

            isCurrentVisible: false,
            isStandardVisible: true,
        }
    }

    componentDidMount() {
        this.checkService()
        this.getCompensationStructureInstructions()

        if (this.props.isStandardStructureVisible) {
            this.setState({ category: 'Standard selection', isStandardVisible: true, isCurrentVisible: false })
        }
        else {
            this.setState({ category: 'Current selection', isCurrentVisible: true, isStandardVisible: false })
        }
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "compensation_structure" && item.is_active === false) {
                this.setState({
                    isServiceUnavailable: true,
                    choicePayCompReason: item.reason
                })
            }
        })
    }

    isStandardVisible = (key) => {
        this.setState({ isStandardVisible: true, isCurrentVisible: false, category: key }, () => this.getCompensationStructure())
    }

    isCurrentVisible = (key) => {
        this.setState({ isStandardVisible: false, isCurrentVisible: true, category: key }, () => this.getCompensationStructure())
    }

    //------------Get Compensation Instructions API-----------------------
    getCompensationStructureInstructions = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {}
        fetchApiCall(Config.getCompensationStructureInstructions, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        instructionData: res.data.data.instructions,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        instructionData: [],
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
                this.getCompensationStructure()
            }).catch(err => {
                console.log("getCompensationStructureInstructions error ", err)
                this.setState({
                    loading: false
                });
            })
    }

    //------------Get Compensation Structure API-----------------------
    getCompensationStructure = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
            category: this.state.category ? this.state.category : '',
        }
        fetchApiCall(Config.getCompensationStructure, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        responseData: res.data.data.comp_structure ? res.data.data.comp_structure : null,
                    })
                    const filteredData = res.data.data.comp_structure.total_pay.filter(item => item.wage_type === "89VP");
                    this.setState({
                        filterWageType: filteredData[0].wage_type
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        responseData: {}
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("getCompensationStructure error ", err)
                this.setState({
                    loading: false
                });
            })
    }

    downloadCompPDF = () => {
        this.setState({
            loading: true
        })
        const param = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
            category: this.state.category ? this.state.category : '',
        }
        downloadPDF(param, this.props)
            .then((response) => {
                if (response) {
                    let data = response.data
                    let fileNametest = response.headers['content-disposition'].split('filename=')[1]
                    let fileName = (fileNametest ? fileNametest : 'Compensation_Structure') + '.pdf'
                    let toasterMsg = "Your document has been downloaded."
                    commonDownloadFile(data, toasterMsg, fileName)
                    this.setState({
                        loading: false,
                    })
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
                console.log("downloadCompPDF catch error:", error)
            });
    }

    toggleStandardCard = () => {
        this.setState({ openStandardCard: !this.state.openStandardCard })
    }

    toggleFixedCard = () => {
        this.setState({ openFixedCard: !this.state.openFixedCard })
    }

    toggleFlexiCard = () => {
        this.setState({ openFlexiCard: !this.state.openFlexiCard })
    }

    toggleTotalCard = () => {
        this.setState({ openTotalCard: !this.state.openTotalCard })
    }

    toggleStandardOthersCard = () => {
        this.setState({ standardOthersCard: !this.state.standardOthersCard })
    }

    proceedToSelect = () => {
        this.setState({ isInstructionShow: false })
    }

    render() {
        const { loading, filterWageType, responseData, choicePayCompReason, isServiceUnavailable, isInstructionShow, instructionData } = this.state;
        let instructionFlag = false;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                {isServiceUnavailable == true ?
                    <div className="col-md-12 mt-5 h-60vh  justify-content-center align-item-center">
                        <ServiceUnavailable reason={choicePayCompReason} width={true} />
                    </div>
                    :
                    <>
                        {isInstructionShow ?
                            <div className='p-1 scroll-auto'>
                                {instructionData.length != 0 ?
                                    <div>
                                        <div className='d-flex justify-content-between align-items-baseline'>
                                            <div className='justify-content-between mt-3'>
                                                <label className='card-heading mx-4 mt-1'>{"Instructions"}</label>
                                            </div>
                                        </div>
                                        <div className='ml-35px scroll-y h-40vh'>
                                            {instructionData.map((listValue, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className='row mx-4 mr-4 mt-2'>
                                                            {listValue.line.split(" ").map((text) =>
                                                                <span>{text !== "<b>" && text !== "</b>" && !instructionFlag &&
                                                                    <h2 className='coi-sub-text mr-4px'>
                                                                        {text}
                                                                    </h2>}
                                                                    {text === "<b>" && (instructionFlag = true)}
                                                                    {text !== "<b>" && text !== "</b>" && instructionFlag &&
                                                                        <span className='highlited-text mr-4px'>
                                                                            {text}
                                                                        </span>}
                                                                    {text === "</b>" && (instructionFlag = false)}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='d-flex justify-content-center bottom-0 p-4'>
                                            <input
                                                type="button"
                                                className=' btn-primary common-btn declaration_button_text px-3'
                                                value="PROCEED"
                                                onClick={() => this.proceedToSelect()}
                                            ></input>
                                        </div>
                                    </div>
                                    :
                                    !loading &&
                                    <div className='mt-5'>
                                        <DataNotFound />
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <div className='d-flex justify-content-between mt-3'>
                                    <div className='d-flex'>
                                        <label className='text14_bold ml-4 mt-1'>{"Select :  "}</label>
                                        <div className='d-flex ml-2'>
                                            <div className='d-flex row justify-content-start ml-2_5rem'>
                                                {this.props.isStandardStructureVisible &&
                                                    <div onClick={() => this.isStandardVisible('Standard selection')}>
                                                        <img alt="radio" className={`radio ${this.state.isStandardVisible ? 'radioOn' : 'radioOff'}`} src={this.state.isStandardVisible ? radioOn : radioOff} />
                                                        <label className={`radioTxt ${this.state.isStandardVisible ? 'selected' : ''}`}>{standard}</label>
                                                    </div>}
                                                {this.props.isChoicepayStructureVisible &&
                                                    <div onClick={() => this.isCurrentVisible('Current selection')}>
                                                        <img alt="radio" className={`radio ${this.state.isCurrentVisible ? 'radioOn' : 'radioOff'}`} src={this.state.isCurrentVisible ? radioOn : radioOff} />
                                                        <label className={`radioTxt ${this.state.isCurrentVisible ? 'selected' : ''}`}>{current}</label>
                                                    </div>}
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex row'>
                                        <img
                                            src={animeArrow}
                                            alt="Birthday"
                                            className="right-arrow-gif-icon"
                                        />
                                        <a target="_blank"><label className='tata-policy-link mr-2' type='button'>{'Click here to download'}</label></a>

                                        <div data-tooltip2={'Compensation Statement'} className="COMPDownloadIcon DownloadIconStyle ml-1 mr-3" >
                                            <div className='pointer' onClick={this.downloadCompPDF}>
                                                <img
                                                    alt="hide"
                                                    src={fileDownloadIcon}
                                                    className="fileDownloadIcon"
                                                ></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='document_card p-3 mb-1rem'>
                                    <div className='p-1 scroll-y h-65vh'>
                                        <div>
                                            {responseData.standard ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead'>{'Fixed Components (A)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.responseData.standard.map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            {responseData.retirals ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead'>{'Retirals (B)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.responseData.retirals.map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            {responseData.choice_pay ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead'>{'Choice Pay ( C )'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.responseData.choice_pay.filter(listValue => listValue.wage_type !== "89FL").map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>

                                        {/* <div>
                                            {responseData.choice_pay ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead'>{'Choice Pay Total'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.responseData.choice_pay.filter(listValue => listValue.wage_type === "89FL").map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div> */}

                                        <div>
                                            {responseData.total_pay ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead '>{'Total Fixed Pay (TPF)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.responseData.total_pay.filter(listValue => listValue.wage_type === "89FP").map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>

                                        <div>
                                            {responseData.total_pay ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead '>{filterWageType === '89VP' ? 'Target Variable Pay (VP)/Bonus' : 'Target Sales Incentive (SI)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            {filterWageType === '89VP' ?
                                                                <tbody>
                                                                    {this.state.responseData.total_pay.filter(listValue => listValue.wage_type === "89VP").map((listValue, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                                <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                                :
                                                                <tbody>
                                                                    {this.state.responseData.total_pay.filter(listValue => listValue.wage_type === "89SN").map((listValue, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                                <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>

                                        <div className='mb-1'>
                                            {responseData.total_pay ?
                                                <div className="main-contain" >
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className='w-50'><div className='thead '>{'Total Target Income with Benefits (TTIB)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Annual Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Monthly Amount (₹)'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.responseData.total_pay.filter(listValue => listValue.wage_type === "89TI").map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_long_text ? listValue.wage_long_text : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay ">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay ">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(CompensationStructure);