import React, { Component } from 'react';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../../conflictOfInterest/COIStyle.css';
import '../../../../../assets/styles/popupStyles.css';
import '../PaySpaceStyle.css';
import down_arrow from '../../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../../assets/drishti_images/documents/up_arrow.svg';
import WarnIcon from '../../../../../assets/drishti_images/login/WarnIcon.svg';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import infoicon from '../../../../../assets/drishti_images/infoicon.svg';
import Dropdown from '../../../../shared/components/Dropdown'
import '../../../employeeScreens/leave/LeaveScreen.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import ConfirmPopup from '../../todo/ConfirmPopup'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import birthday_Wishe from "../../../../../assets/drishti_images/header/right-arrow-gif-unscreen.gif";

const dataSubmit = `You have successfully submitted choice pay selection for  `
const avoidWageTypes = ["89FL"]
const NoticePeriodPolicy = 'https://tatamotors.sharepoint.com/sites/c-app/s-MTMDev/_layouts/15/WopiFrame.aspx?sourcedoc=https://tatamotors.sharepoint.com/sites/c-app/s-MTMDev/DL_IRMEnablePolicies/HumanResource/Compensation%20Policy%20V1.6.docx&action=default'
const ChoicePayGuidelines = 'https://tatamotors.sharepoint.com/:p:/r/sites/c-app/s-MTMDev/_layouts/15/Doc.aspx?sourcedoc=%7BA49E2670-665F-4B83-B8CD-350465B0769E%7D&file=Choice%20Pay%20Guidelines.pptx&action=edit&mobileredirect=true'

class ChoicePayMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openStandardCard: false,
            openFixedCard: false,
            openFlexiCard: false,
            openTotalCard: false,
            standardOthersCard: false,
            responseData: {},
            isInstructionShow: true,

            isServiceUnavailable: false,  // service unavailble from backend
            choicePaySelectionReason: '',
            vaildationPopup: false,
            isWarn: null,
            startDate: '',
            endDate: '',
            accomodationType: '',
            conveyanceTypes: '',
            fyYear: '',
            empSubgroup: '',
            isShowValidate: false,
            warnShow: null
        }
    }

    componentDidMount() {
        this.checkService()
        this.getChoicePayComponents();
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "choice_pay_selection" && item.is_active == false) {
                this.setState({
                    isServiceUnavailable: true,
                    choicePaySelectionReason: item.reason
                })
            }
        })
    }

    //------------Get Choice Pay Selection API-----------------------
    getChoicePayComponents = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
        }
        fetchApiCall(Config.getChoicePayComponents, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        responseData: JSON.parse(JSON.stringify(res.data.data)),
                        startDate: res.data.data.start_date ? res.data.data.start_date : '',
                        endDate: res.data.data.end_date ? res.data.data.end_date : '',
                        conveyanceTypes: res.data.data.conveyance_type ? res.data.data.conveyance_type : '',
                        accomodationType: res.data.data.accomodation_type ? res.data.data.accomodation_type : '',
                        fyYear: res.data.data.fy_year ? res.data.data.fy_year : '',
                        empSubgroup: res.data.data.emp_subgroup ? res.data.data.emp_subgroup : '',
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        responseData: {},
                        startDate: '',
                        endDate: '',
                        conveyanceTypes: '',
                        accomodationType: '',
                        fyYear: '',
                        empSubgroup: '',
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("getChoicePayComponents error ", err)
                this.setState({
                    loading: false,
                    responseData: {},
                    startDate: '',
                    endDate: '',
                    conveyanceTypes: '',
                    accomodationType: '',
                    fyYear: '',
                    empSubgroup: '',
                });
            })
    }

    //------------Validate Choice Pay Selection API-----------------------

    validateChoicePay = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        const responseData = this.state.responseData
        delete responseData.start_date;
        delete responseData.end_date;
        delete responseData.conveyance_type;
        delete responseData.accomodation_type;
        delete responseData.fy_year;
        delete responseData.emp_subgroup;
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.state.empSubgroup ? this.state.empSubgroup : '',
            accomodation_type: this.state.accomodationType ? this.state.accomodationType : '',
            conveyance_types: this.state.conveyanceTypes ? this.state.conveyanceTypes : '',
            fy_year: this.state.fyYear ? this.state.fyYear : '',
            start_date: this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : '',
            end_date: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : '',
            data: responseData ? responseData : ''
        }
        console.log('validate body----->', body);
        fetchApiCall(Config.validateChoicePay, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        vaildationPopup: true
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        vaildationPopup: false
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("validateChoicePay error ", err)
                this.setState({
                    loading: false,
                    vaildationPopup: false
                });
            })
    }

    //------------Validate Choice Pay Selection API-----------------------

    submitChoicePayDetails = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        const responseData = this.state.responseData
        delete responseData.start_date;
        delete responseData.end_date;
        delete responseData.conveyance_type;
        delete responseData.accomodation_type;
        delete responseData.fy_year;
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            esubgroup: this.state.empSubgroup ? this.state.empSubgroup : '',
            accomodation_type: this.state.accomodationType ? this.state.accomodationType : '',
            conveyance_types: this.state.conveyanceTypes ? this.state.conveyanceTypes : '',
            fy_year: this.state.fyYear ? this.state.fyYear : '',
            start_date: this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : '',
            end_date: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : '',
            data: responseData ? responseData : ''
        }
        console.log('Submit body------>', body);
        fetchApiCall(Config.submitChoicePayDetails, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        vaildationPopup: false,
                        isInstructionShow: true,
                        choicePayDataPresent: true
                    })
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.getChoicePayInstructions()
                }
                else {
                    this.setState({
                        loading: false,
                        vaildationPopup: false,
                        isInstructionShow: false
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("submitChoicePayDetails error ", err)
                this.setState({
                    loading: false
                });
            })
    }

    validateSelection = () => {
        const dropdownArray = this.state.responseData.choice_pay.filter(item => item.criteria === 'P')
        const validateDropdownArray = dropdownArray.reduce((item) => (item));

        const validInput = this.state.responseData.choice_pay.filter(item => item.criteria === 'A')
        const validateInput = validInput.reduce((item) => (item));

        if (validateDropdownArray === null || validateDropdownArray == []) {
            toast.error('Please select current selection', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (validateInput.annual_amount === null || validateInput.annual_amount === '') {
            toast.error('Please enter valid annual amount', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.validateChoicePay();
        }
    }

    proceedToSelect = () => {
        this.setState({ isInstructionShow: false })
    }

    toggleStandardCard = () => {
        this.setState({ openStandardCard: !this.state.openStandardCard })
    }

    toggleFixedCard = () => {
        this.setState({ openFixedCard: !this.state.openFixedCard })
    }

    toggleFlexiCard = () => {
        this.setState({ openFlexiCard: !this.state.openFlexiCard })
    }

    toggleTotalCard = () => {
        this.setState({ openTotalCard: !this.state.openTotalCard })
    }

    toggleStandardOthersCard = () => {
        this.setState({ standardOthersCard: !this.state.standardOthersCard })
    }

    renderSFTooltip = props => (
        <div className='flex-direction-column tool-tip-font-choicePay'>
            {props.criteria === 'P' ?
                <div className='justify-content-center align-item-center-noflex'> {props.wage_text + ' ₹ (' + props.min_value + '%' + ' - ' + props.max_value + '%' + ')'}</div>
                :
                <div className='justify-content-center align-item-center-noflex'> {props.wage_text + ' ₹'}</div>
            }
            {props.meessage && props.meessage !== null ?
                <>
                    <div className='mt-3px'><hr /></div>
                    <div className='justify-content-center align-item-center-noflex tooltip-disc-padding'>{props.meessage}</div>
                </>
                :
                <>{null}</>
            }
            <div className='mt-3px'><hr /></div>
            <div>
                <div className='flex-direction-row justify-content-around'>
                    <div>{"Min Amt-Annual"}</div><div>{"Std Amt-Annual"}</div><div>{"Max Amt-Annual"}</div>
                </div>
                <div className='flex-direction-row justify-content-around'>
                    <span>{props.min_amount != null ? props.min_amount : '-'}</span><span>{props.standard_amount != null ? props.standard_amount : '-'}</span><span>{props.max_amount != null ? props.max_amount : '-'}</span>
                </div>
            </div>
        </div>
    );

    renderWarntip = (props, warnShow) => (
        <div className='tool-tip-font-warn'>
            {warnShow !== null ?
                <div>{"Kindly type a whole number."}</div>
                :
                <div>{"Entered amount not between the range provided for "}{props.wage_text}</div>
            }
        </div>
    );

    updatState = (index, listValue, key, value) => {
        var flexiIndex = this.getIndex(this.state.responseData.choice_pay)

        //------Decimal point check
        if (value.includes('.')) {
            this.setState({
                warnShow: index
            })
        } else {
            this.setState({
                warnShow: null
            })
        }

        // Remove non-digit characters from the input value
        const parsedValue = value.trim() === '' ? null : Number(value.replace(/[^\d.]/g, ''));
        if (parsedValue !== null && (parsedValue >= Number(listValue.min_amount) && parsedValue <= Number(listValue.max_amount))) {
            let updatedChoicePayData = this.state.responseData;
            updatedChoicePayData.choice_pay[index][key] = parsedValue;

            // Calculate the new monthly amount
            const monthlyAmount = parsedValue !== null ? (parsedValue / 12).toFixed(2) : null;
            updatedChoicePayData.choice_pay[index].monthly_amount = parseFloat(monthlyAmount);

            this.setState({
                responseData: updatedChoicePayData,
                isWarn: null
            });
        } else if (parsedValue === null) {
            let updatedChoicePayData = this.state.responseData;
            updatedChoicePayData.choice_pay[index][key] = null;
            updatedChoicePayData.choice_pay[index].monthly_amount = null;

            this.setState({
                responseData: updatedChoicePayData,
                isWarn: null
            });
        } else {
            this.setState({
                isWarn: index
            });
        }

        //----------Choice pay annual total amount---------------------------------- 
        const choicePayTotal = this.state.responseData.choice_pay.filter(item =>
            item.wage_type === '89FL'
        );

        //----------All choice pay component addition---------------------------------- 
        const filteredData = this.state.responseData.choice_pay.filter(item =>
            item.wage_type !== "2702" &&
            item.wage_type !== "89FL"
        );
        const sumAnnualAmount = filteredData.reduce((total, item) => total + (item.annual_amount || 0), 0);

        //---All choice pay component total and substract from Choice pay annual total----
        const flexiAnnualTotal = (choicePayTotal[0].annual_amount - sumAnnualAmount).toFixed(2) //---Flexi pay annual amount

        const flexiMonthlyTotal = (flexiAnnualTotal / 12).toFixed(2) //---Flexi pay monthly amount

        // Calculate the monthly amount by dividing the parsed value by 12
        const monthlyAmount = parsedValue !== null ? (parsedValue / 12).toFixed(2) : null;

        let tempUpdatedInputData = this.state.responseData;
        tempUpdatedInputData.choice_pay[index].selected_value = parseFloat(monthlyAmount);
        this.setState({
            responseData: tempUpdatedInputData
        })

        //--Flexi annual and monthly total-------------------------
        let updatedChoicePayData = this.state.responseData
        updatedChoicePayData.choice_pay[flexiIndex].annual_amount = parseFloat(flexiAnnualTotal)
        updatedChoicePayData.choice_pay[flexiIndex].monthly_amount = parseFloat(flexiMonthlyTotal)
        this.setState({
            responseData: updatedChoicePayData,
        });
        this.validateResponse();
    }

    getIndex(data) {
        const index = data.findIndex(object => {
            return object.wage_type === '2702';
        });
        return index
    }

    selectChoicePayValue = (index, listValue, key, data) => {
        let tempUpdatedData = this.state.responseData
        var flexiIndex = this.getIndex(this.state.responseData.choice_pay)

        if (listValue.max_value === data.value) {
            var monthlyAmt = listValue.max_amount
            var annualAmt = parseFloat((listValue.max_amount ? listValue.max_amount / 12 : 0).toFixed(2))
            tempUpdatedData.choice_pay[index].annual_amount = monthlyAmt
            tempUpdatedData.choice_pay[index].monthly_amount = annualAmt
        } else if (listValue.min_value === data.value) {
            tempUpdatedData.choice_pay[index].annual_amount = listValue.min_amount;
            tempUpdatedData.choice_pay[index].monthly_amount = parseFloat((listValue.min_amount ? (listValue.min_amount) / 12 : 0).toFixed(2));
        }

        tempUpdatedData.choice_pay[index][key] = data.value;
        this.setState({
            responseData: tempUpdatedData
        })
        //----------Choice pay annual total amount---------------------------------- 
        const choicePayTotal = this.state.responseData.choice_pay.filter(item =>
            item.wage_type === '89FL'
        );
        //----------All choice pay component addition---------------------------------- 
        const filteredData = this.state.responseData.choice_pay.filter(item =>
            item.wage_type !== "2702" &&
            item.wage_type !== "89FL"
        );
        const sumAnnualAmount = filteredData.reduce((total, item) => total + (item.annual_amount || 0), 0);
        //---All choice pay component total and substract from Choice pay annual total----
        const flexiAnnualTotal = (choicePayTotal[0].annual_amount - sumAnnualAmount).toFixed(2) //---Flexi pay annual amount

        const flexiMonthlyTotal = (flexiAnnualTotal / 12).toFixed(2) //---Flexi pay monthly amount

        //--Flexi annual and monthly total-------------------------
        let updatedChoicePayData = this.state.responseData
        updatedChoicePayData.choice_pay[flexiIndex].annual_amount = parseFloat(flexiAnnualTotal);
        updatedChoicePayData.choice_pay[flexiIndex].monthly_amount = parseFloat(flexiMonthlyTotal);
        this.setState({
            responseData: updatedChoicePayData,
        });
        this.validateResponse();
    }

    validateResponse = () => {
        let dataUpdated = this.state.responseData.choice_pay.filter((cPayData) => (cPayData.selected_value !== null && (cPayData.selected_value !== cPayData.default_value)))
        if (dataUpdated.length == 0) {
            this.setState({
                isShowValidate: false,
            });
        }
        else {
            this.setState({
                isShowValidate: true,
            });
        }
    }

    render() {
        const { loading, responseData, warnShow, isInstructionShow, choicePaySelectionReason, isServiceUnavailable, vaildationPopup, isWarn, startDate, endDate } = this.state;
        const { instructionData, isInChoicePaySchedule, choicePayDataPresent, isEmployeeAccessChoicePay, selectTaxRegime } = this.props;
        let instructionFlag = false;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                {isServiceUnavailable == true ?
                    <div className="col-md-12 mt-5 h-60vh  justify-content-center align-item-center">
                        <ServiceUnavailable reason={choicePaySelectionReason} width={true} />
                    </div>
                    :
                    <>
                        {isInstructionShow ?
                            <>
                                <div className='d-flex justify-content-between mb-24px'>
                                    <div>
                                        <label className='card-heading mx-4 mt-1'>{"Instructions"}</label>
                                    </div>
                                    <div className='d-flex'>
                                        <div>
                                            <a href={NoticePeriodPolicy} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'Compensation Policy'}</label></a>
                                        </div>
                                        <div>
                                            <a href={ChoicePayGuidelines} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'Choice Pay Guidelines'}</label></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='ml-35px'>
                                    {instructionData.map((listValue, index) => {
                                        return (
                                            <div key={index}>
                                                <div className='row mx-4 mr-4 mt-2'>
                                                    {listValue.line.split(" ").map((text) =>
                                                        <span>{text !== "<b>" && text !== "</b>" && !instructionFlag &&
                                                            <h2 className='coi-sub-text mr-4px'>
                                                                {text}
                                                            </h2>}
                                                            {text === "<b>" && (instructionFlag = true)}
                                                            {text !== "<b>" && text !== "</b>" && instructionFlag &&
                                                                <span className='highlited-text mr-4px'>
                                                                    {text}
                                                                </span>}
                                                            {text === "</b>" && (instructionFlag = false)}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {isEmployeeAccessChoicePay ?
                                    <>
                                        {isInChoicePaySchedule &&
                                            <div className='d-flex justify-content-center align-item-center mt-4 mb-4'>
                                                {choicePayDataPresent ?
                                                    <div>
                                                        <h1 className='paySpace-text green-tag mx-4 mt-4'>{dataSubmit + (this.state.startDate && moment(this.state.startDate).format("DD-MM-YYYY"))}</h1>
                                                    </div>
                                                    :
                                                    <div>
                                                        <input
                                                            type="button"
                                                            className=' btn-primary common-btn declaration_button_text px-3'
                                                            value="PROCEED FOR SELECTION"
                                                            onClick={() => this.proceedToSelect()}
                                                        ></input>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                    :
                                    <div>
                                        <h1 className='paySpace-text green-tag mx-4 mt-4'>{selectTaxRegime}</h1>
                                    </div>
                                }
                            </>
                            :
                            <div>
                                <div className='d-flex justify-content-between align-items-baseline flex-row-reverse'>
                                    <div className='send-text mt-2'>
                                        {"As On: ("}{startDate && endDate ? moment(startDate).format("DD-MM-YYYY") + ") - (" + moment(endDate).format("DD-MM-YYYY") + ")" : ''}
                                    </div>
                                    <div>
                                        <img
                                            src={birthday_Wishe}
                                            alt="Birthday"
                                            className="right-arrow-gif-icon"
                                        />
                                        <a href={ChoicePayGuidelines} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'Choice Pay Guidelines'}</label></a>
                                    </div>
                                </div>
                                <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'>
                                    <div
                                        className='d-flex justify-content-between align-item-center'
                                        onClick={() => this.toggleStandardCard()}
                                    >
                                        <div className='toggle-card-heading ml-2'>
                                            {'Standard Components'}
                                        </div>
                                        {!this.state.openStandardCard ?
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={down_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={up_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {this.state.openStandardCard &&
                                        <>
                                            <div className='max-h-37vh scroll-y'>
                                                {responseData.standard ?
                                                    <div className="main-contain" >
                                                        <div className="row align-item-center justify-content-start ">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.responseData.standard.map((listValue, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_text ? listValue.wage_text : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='mt-5'>
                                                        <DataNotFound />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'>
                                    <div
                                        className='d-flex justify-content-between align-item-center'
                                        onClick={() => this.toggleFixedCard()}
                                    >
                                        <div className='toggle-card-heading ml-2'>
                                            {'Retirals'}
                                        </div>
                                        {!this.state.openFixedCard ?
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={down_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={up_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {this.state.openFixedCard &&
                                        <>
                                            <div className='max-h-37vh scroll-y'>
                                                {responseData.retirals ?
                                                    <div className="main-contain" >
                                                        <div className="row align-item-center justify-content-start ">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.responseData.retirals.map((listValue, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_text ? listValue.wage_text : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='mt-5'>
                                                        <DataNotFound />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='choice-pay-sector mt-3 p-3 mb-24px col-md-12 pointer'>
                                    <div
                                        className='d-flex justify-content-between align-item-center'
                                        onClick={() => this.toggleFlexiCard()}
                                    >
                                        <div className='toggle-card-heading-choice-pay ml-2'>
                                            {'Choice Pay'}
                                        </div>
                                        {!this.state.openFlexiCard ?
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={down_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={up_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {this.state.openFlexiCard &&
                                        <>
                                            <div className='table-container'>
                                                {responseData.choice_pay ?
                                                    <div className="main-contain" >
                                                        <div className="row align-item-center justify-content-start ">
                                                            <table className="table choice-pay-thead">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"><div className='thead'>{'Choice Pay Component'}</div></th>
                                                                        <th scope="col"><div className='thead'>{''}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Previous Selection (₹)'}</div></th>
                                                                        <th scope="col"><div className='thead'>{'Current Selection'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Current Selection Annual Amt (₹)'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Current Selection Monthly Amt (₹)'}</div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.responseData.choice_pay.map((listValue, index) => {
                                                                        if (!avoidWageTypes.includes(listValue.wage_type)) {
                                                                            return (
                                                                                <tr key={index} >
                                                                                    <td className='table-row-width'> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_text ? listValue.wage_text : '-'}</div></td>
                                                                                    <td>
                                                                                        <div className="td-choice-pay d-flex">
                                                                                            <OverlayTrigger placement="right" overlay={this.renderSFTooltip(listValue)}>
                                                                                                <img
                                                                                                    alt="infoicon"
                                                                                                    src={infoicon}
                                                                                                    className="info_icon_flexi_pay"
                                                                                                ></img>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.previous_value !== null ? listValue.previous_value : '-'}</div> </td>
                                                                                    <td>
                                                                                        <div className="td-choice-pay d-flex">
                                                                                            {listValue.is_editable && listValue.criteria === "P" ?
                                                                                                <div className='dropdown filter-btn-choice-pay' >
                                                                                                    <Dropdown
                                                                                                        data={listValue.dropdown_value}
                                                                                                        selectedValue={(data) => this.selectChoicePayValue(index, listValue, "selected_value", data)}
                                                                                                        value={listValue.selected_value !== null ? (listValue.selected_value + '%') : (listValue.default_value + '%')}
                                                                                                        render={'value'}
                                                                                                        height={''}
                                                                                                    />
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td> <div className="td-choice-pay d-flex amount-justify-content-end">
                                                                                        {listValue.is_editable && listValue.criteria == 'A' ?
                                                                                            <>
                                                                                                <div className='d-flex column'>
                                                                                                    <div className='column d-flex'>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                className={(isWarn === index || warnShow === index) ? 'invalid-input' : 'inpt w-70 ml-65px text-align-right'}
                                                                                                                type='text'
                                                                                                                value={listValue.annual_amount}
                                                                                                                onChange={(e) => this.updatState(index, listValue, "annual_amount", e.target.value)}
                                                                                                            />
                                                                                                        </div>
                                                                                                        {(isWarn === index || warnShow === index) &&
                                                                                                            <div className='warnIcon'>
                                                                                                                <OverlayTrigger placement="right" overlay={this.renderWarntip(listValue, warnShow)}>
                                                                                                                    <img
                                                                                                                        alt="infoicon"
                                                                                                                        src={WarnIcon}
                                                                                                                        className="info_icon_flexi_pay"
                                                                                                                    ></img>
                                                                                                                </OverlayTrigger>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {listValue.annual_amount !== null ? listValue.annual_amount : '-'}
                                                                                            </>
                                                                                        }
                                                                                    </div> </td>
                                                                                    <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount : '-'}</div> </td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='mt-5'>
                                                        <DataNotFound />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'>
                                    <div
                                        className='d-flex justify-content-between align-item-center'
                                        onClick={() => this.toggleTotalCard()}
                                    >
                                        <div className='toggle-card-heading ml-2'>
                                            {'Total Pay'}
                                        </div>
                                        {!this.state.openTotalCard ?
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={down_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='justify-content-end'>
                                                    <img
                                                        alt="hide"
                                                        src={up_arrow}
                                                        className="up_arrow"
                                                    ></img>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {this.state.openTotalCard &&
                                        <>
                                            <div className='max-h-37vh scroll-y'>
                                                {responseData.total_pay ?
                                                    <div className="main-contain" >
                                                        <div className="row align-item-center justify-content-start ">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Standard Annual Amt (₹)'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.responseData.total_pay.map((listValue, index) => {
                                                                        return (
                                                                            <tr key={index} >
                                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_text ? listValue.wage_text : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.standard_amount !== null ? listValue.standard_amount.toFixed(2) : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div></td>
                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div></td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='mt-5'>
                                                        <DataNotFound />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                {this.state.responseData.standard_others !== null || this.state.responseData.standard_others != [] &&
                                    <div className='document_card mt-3 p-3 mb-24px col-md-12 pointer'>
                                        <div
                                            className='d-flex justify-content-between align-item-center'
                                            onClick={() => this.toggleStandardOthersCard()}
                                        >
                                            <div className='toggle-card-heading ml-2'>
                                                {'Standard Others'}
                                            </div>
                                            {!this.state.standardOthersCard ?
                                                <>
                                                    <div className='justify-content-end'>
                                                        <img
                                                            alt="hide"
                                                            src={down_arrow}
                                                            className="up_arrow"
                                                        ></img>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='justify-content-end'>
                                                        <img
                                                            alt="hide"
                                                            src={up_arrow}
                                                            className="up_arrow"
                                                        ></img>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {this.state.standardOthersCard &&
                                            <>
                                                <div className='max-h-37vh scroll-y'>
                                                    {responseData.standard_others ?
                                                        <div className="main-contain" >
                                                            <div className="row align-item-center justify-content-start ">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                            <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                            <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.responseData.standard_others.map((listValue, index) => {
                                                                            return (
                                                                                <tr key={index} >
                                                                                    <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_text ? listValue.wage_text : '-'}</div></td>
                                                                                    <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div></td>
                                                                                    <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div></td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='mt-5'>
                                                            <DataNotFound />
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                                <div className='d-flex justify-content-end mt-4 mb-4'>
                                    <input type='button' value='BACK' className='cancelBtn' onClick={() => this.setState({ isInstructionShow: true, openStandardCard: false, openTotalCard: false, openFlexiCard: false, openFixedCard: false, standardOthersCard: false })} />
                                    {this.state.isShowValidate &&
                                        <input type='button' value='SUBMIT' className='ml-2 validateBtn' onClick={() => this.validateSelection()} />}
                                </div>
                            </div>
                        }
                        {vaildationPopup &&
                            <ConfirmPopup
                                loading={loading}
                                onDismiss={() => this.setState({ vaildationPopup: false })}
                                approve={() => this.submitChoicePayDetails()}
                                type={'choicePay'}
                            />
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState,
    }
}

export default connect(mapStatesToProps)(ChoicePayMain);