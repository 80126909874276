import React, { useEffect, useState, useRef } from 'react'
import rightarrowbg from '../../../../../assets/drishti_images/left-arrow.svg';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import {schemeCode, SuggestionMemberDetails} from '../../suggestionScheme/constants';
import ConfirmationPopup from './ConfirmationPopup';
import SuggesterPreviewCompoment from './SuggesterPreviewCompoment';
import SuggestionDashboard from './SuggestionDashboard';
import ViewTable from './ViewTable';
import { AddSuggestions, deleteS3Documents, get_agency, get_division, getCategory, getMemberDetails, getschemeList, getSchemeType, getSuggestionLogs, uploadDocument, validateIdeaBankReference } from '../../../../../constants/api.service';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { toaster } from '../../../../../utils/Utils';
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import { maskMobile } from '../../../../shared/commonMaskFunction';
const AddSuggestion = ({ handleBack, setTab }) => {
    const DocumentType = [
        { id: 1, title: 'After' },
        { id: 2, title: 'Before' },
        { id: 3, title: 'Other' },
    ];
    const [otherDocumentCount,setOtherDocumentCount] = useState(1)
    const [viewSchemeFilter, setViewSchemeFilter] = useState('')
    const [typeScheme, setSchemeType] = useState([])
    const [category, setCategory] = useState([])
    const [schemeList, setschemeList] = useState([])
    const [agencyData, setAgencyData] = useState([])
    const [divisionData, setDivisionData] = useState([])
    const [selectCategory, setselectCategory] = useState('')
    const [loading, setloading] = useState(false)
    const [viewDocumentTypeFilter, setViewDocumentTypeFilter] = useState('')
    const [employeeId, setEmployeeId] = useState('');
    const [memberData, setMemberData] = useState([]);
    const [showMemberData, setshowMemberData] = useState(false);
    const [presentStatus, setpresentStatusCounts] = useState(null);
    const [proposedChangesCounts, setproposedChangesCounts] = useState(null);
    const [otherAreasCounts, setotherAreas] = useState(null);
    const [BenefitsCounts, setBenefitsCounts] = useState(null);
    const [attachmentName, setAttachmentName] = useState('');
    const [documentData, setdocumentData] = useState([]);
    const [showDocmentData, setshowDocmentData] = useState(false);
    const [openConfirmPopUp, setopenConfirmPopUp] = useState(false);
    const [ListScheme, setListScheme] = useState('');
    const [title, setTitle] = useState('');
    const [division, setDivision] = useState('');
    const [agency, setAgency] = useState('');
    const [implementDate, setimplementDate] = useState(null);
    const [errors, setErrors] = useState({});
    const [showDashBoard, setShowDashBoard] = useState(false);
    const [validateBtn, setvalidateBtn] = useState(false);
    const [availableDocumentTypes, setAvailableDocumentTypes] = useState(DocumentType);
    const [annualSaving, setAnnualSaving] = useState(null);
    const [earlierStatus, setearlierStatus] = useState(null);
    const [implementStatus, setimplementStatus] = useState(null);
    const [ideaBankRef, setIdeaBankRef] = useState(null);
    const [documentLink, setdocumentLink] = useState(null);
    const fileInputRef = useRef(null);
    const loginData = useSelector(state => state.loginState);
    const [isIdeaBankRefValid, setIsIdeaBankRefValid] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const today = new Date().toISOString().split('T')[0];
    const [is_service_Unavailable, setIs_Service_Unavailable] = useState(false);
    const [landing_Service_reason, setLanding_Service_reason] = useState(null);
    const [originalData,setOriginalData] = useState([]);

    const memberLimit = (viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special) ? 4 : 
    (viewSchemeFilter?.scheme_type_code === schemeCode?.Implemented || viewSchemeFilter?.scheme_type_code === schemeCode["Special Implemented"]) ? 8 : 0;
    const isLimitReached = memberData.length >= memberLimit;
    const isUploadButtonDisabled = !viewDocumentTypeFilter.title || !attachmentName.documentName;
    useEffect(() => {
        if (viewSchemeFilter.scheme_type_code) {
            setErrors(prevErrors => ({
                ...prevErrors,
                employeeId: '',
                selectCategory: '',
                ListScheme: '',
                implementDate: '',
                division: '',
                agency: '',
                presentStatus: '',
                proposedChangesCounts: '',
                BenefitsCounts: '',
            }));
        }
    }, [viewSchemeFilter.scheme_type_code]);
    useEffect(()=>{
        getSchemeTypeDetails();
        getCategoryDetail()
        getDivision()
    },[])

    const checkValidity = () => {
        if (viewSchemeFilter?.scheme_type_code === schemeCode?.Normal) {
            if (title && selectCategory && memberData?.length>0 && presentStatus && proposedChangesCounts && BenefitsCounts && division && agency) {
                return false; 
            }
        } else if (viewSchemeFilter.scheme_type_code === schemeCode.Special) {
            if (ListScheme && title && memberData?.length>0 && selectCategory  && presentStatus && proposedChangesCounts && BenefitsCounts && division && agency) {
                return false; 
            }
        } else if (viewSchemeFilter?.scheme_type_code === schemeCode?.Implemented) {
            if (implementDate && title && memberData?.length>0 && selectCategory && earlierStatus && implementStatus && BenefitsCounts && division && agency) {
                return false; 
            }
        } else if (viewSchemeFilter?.scheme_type_code === schemeCode["Special Implemented"]) {
            if (ListScheme && implementDate && title && memberData?.length>0  && selectCategory  && earlierStatus && implementStatus && BenefitsCounts && division && agency) {
                return false; 
            }
        }
        return true; 
    };
    useEffect(() => {
        checkValidity();
        setvalidateBtn(checkValidity());
    }, [title, selectCategory, viewSchemeFilter, memberData, presentStatus, proposedChangesCounts, BenefitsCounts, division, agency, implementDate, earlierStatus, implementStatus, ListScheme]);

    const validate = (name, value) => {
        const requiredFields = {
            employeeId: 'Employee ID is required',
            // attachmentName: 'Attachment is required',
            title: 'Title is required',
            selectCategory: 'Category is required',
            ListScheme: 'List of Schemes is required',
            implementDate: 'Implement Date is required',
            viewSchemeFilter: 'Type of Schemes is required',
            BenefitsCounts: 'Benefits are required',
            division: 'Division is required',
            agency: 'Agency is required',
            // viewDocumentTypeFilter: 'Document Type is required',
        };
        const specialFields = {
            presentStatus: {
                condition: [schemeCode?.Normal, schemeCode?.Special],
                message: 'Present Status is required',
            },
            proposedChangesCounts: {
                condition: [schemeCode?.Normal, schemeCode?.Special],
                message: 'Proposed Changes are required',
            },
            earlierStatus: {
                condition: [schemeCode?.Implemented, schemeCode["Special Implemented"]],
                message: 'Earlier Status is required',
            },
            implementStatus: {
                condition: [schemeCode?.Implemented, schemeCode["Special Implemented"]],
                message: 'Implement Status is required',
            },
        };
        let error = '';
        if (requiredFields[name]) {
            if (!value && (name !== 'employeeId' || memberData?.length === 0)) {
                error = requiredFields[name];
            }
        } else if (specialFields[name]) {
            if (!value && specialFields[name].condition.includes(viewSchemeFilter?.scheme_type_code)) {
                error = specialFields[name].message;
            }
        }
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
        return error;
    };

    // Validation function
    const validateAllFields = () => {
        const requiredFields = {
            employeeId: 'Employee ID is required',
            // attachmentName: 'Attachment is required',
            title: 'Title is required',
            selectCategory: 'Category is required',
            ListScheme: 'List of Schemes is required',
            implementDate: 'Implement Date is required',
            viewSchemeFilter: 'Type of Schemes is required',
            BenefitsCounts: 'Benefits are required',
            division: 'Division is required',
            agency: 'Agency is required',
            // viewDocumentTypeFilter: 'Document Type is required',
        };

        const specialFields = {
            presentStatus: {
                condition: [schemeCode?.Normal, schemeCode?.Special],
                message: 'Present Status is required',
            },
            proposedChangesCounts: {
                condition: [schemeCode?.Normal, schemeCode?.Special],
                message: 'Proposed Changes are required',
            },
            earlierStatus: {
                condition: [schemeCode?.Implemented, schemeCode["Special Implemented"]],
                message: 'Earlier Status is required',
            },
            implementStatus: {
                condition: [schemeCode?.Implemented, schemeCode["Special Implemented"]],
                message: 'Implement Status is required',
            },
        };

        let currentErrors = {};

        // Validate required fields
        for (const [fieldKey, errorMessage] of Object.entries(requiredFields)) {
            const value = eval(fieldKey); // Using eval to get the value of the individual state variable
            if (!value && (fieldKey !== 'employeeId' || memberData?.length === 0)) {
                currentErrors[fieldKey] = errorMessage; // Assigning error message to the key
            }
        }

        // Validate special fields
        for (const [fieldKey, { condition, message }] of Object.entries(specialFields)) {
            const value = eval(fieldKey); // Using eval to get the value of the individual state variable
            if (!value && condition.includes(viewSchemeFilter?.scheme_type_code)) {
                currentErrors[fieldKey] = message; // Assigning error message to the key
            }
        }

        setErrors(currentErrors);
        return currentErrors;
    };
    
    

    const handleViewFilter = (data) => {
        setViewSchemeFilter(data)
        validate('viewSchemeFilter', data);
        setListScheme('');
        setMemberData([])
        setproposedChangesCounts('');
        setpresentStatusCounts('');
        setearlierStatus('');
        setimplementStatus('');
        setotherAreas('');
        setBenefitsCounts('');
        setAnnualSaving('');
        setIdeaBankRef('');
        setimplementDate(null);
        setErrors({
            presentStatus: '',
            earlierStatus: '',
            implementStatus: '',
            proposedChangesCounts: '',
            earlierStatus: '',
            implementStatus: '',
            BenefitsCounts: '',
        });
        if (data?.title.toLowerCase() === 'special' || data?.title.toLowerCase() === 'special implemented') {
            getSchemeListDetail(data.scheme_type_code)}
    }
    const handleCategory = (data) => {
        setselectCategory(data)
        validate('selectCategory', data);
    }
    const handleDivision = (data) => {
        setDivision(data)
        setAgency('');
        validate('division', data);
        getAgency(data?.id);
    }
    const handleAgency = (data) => {
        setAgency(data)
        validate('agency', data);
    }
    const handleListScheme = (data) => {
        setListScheme(data)
        validate('ListScheme', data);
    }

    const handleDocumentFilter = (data) => {
        setViewDocumentTypeFilter(data)
        validate('viewDocumentTypeFilter', data)
    }

    const handleAddMember = async () => {
        if (!employeeId) {
            setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Employee ID is required' }));
            return;
        }
        if (!/^\d{6}$|^\d{8}$/.test(employeeId)) {
            setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Employee ID must be exactly 6 or 8 digits long' }));
            return;
        }
        try {
            getMemberDetail(employeeId);
        } catch (error) {
            console.error("Error fetching member details:", error);
            setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Error fetching member details' }));
        }
    };

    const handleSubmit = () => {
        // const requiredFields = ['title', 'selectCategory', 'employeeId', 'presentStatus', 'proposedChangesCounts', 'BenefitsCounts', 'division', 'agency', 'implementStatus', 'earlierStatus'];
        // if (viewSchemeFilter.scheme_type_code !==schemeCode.Normal && viewSchemeFilter.scheme_type_code !== schemeCode.Implemented) {
        //     requiredFields.push('viewSchemeFilter');
        // }
        // if (viewSchemeFilter.scheme_type_code === schemeCode.Special || viewSchemeFilter.scheme_type_code === schemeCode["Special Implemented"]) {
        //     requiredFields.push('ListScheme');
        // }
        // if (viewSchemeFilter.scheme_type_code === schemeCode.Implemented || viewSchemeFilter.scheme_type_code === schemeCode["Special Implemented"]) {
        //     requiredFields.push('implementDate');
        // }
        // let hasError = false;
        // requiredFields.forEach(field => {
        //     if (validate(field, eval(field))) {
        //         hasError = true;
        //     }
        // });

        // Validate Employee IDF
        // const employeeIdError = validate('employeeId', employeeId);
        // if (employeeIdError) {
        //     setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Employee ID required' }));
        //     hasError = true;
        // } else {
        //     setErrors(prevErrors => ({ ...prevErrors, employeeId: '' }));

        // }

        // if (!hasError) {
            setopenConfirmPopUp(true);
        // }
    };

    const handleChange = (e, name) => {
        const { value } = e.target;
        if (name === 'employeeId') {
            const employeeIdError = !/^\d{0,6}$|^\d{0,8}$/.test(value) ? 'Employee ID must be exactly 6 or 8 digits long' : '';
            setErrors(prevErrors => ({ ...prevErrors, employeeId: employeeIdError }));
            if (!employeeIdError) setEmployeeId(value);
            return;
        }
        // State update logic based on name
        const stateUpdateMap = {
            presentStatus: (val) => {
                setpresentStatusCounts(viewSchemeFilter.scheme_type_code === schemeCode.Normal || viewSchemeFilter.scheme_type_code === schemeCode.Special ? val : null);
            },
            earlierStatus: (val) => {
                setearlierStatus(!(viewSchemeFilter.scheme_type_code === schemeCode.Normal || viewSchemeFilter.scheme_type_code === schemeCode.Special) ? val : null);
            },
            proposedChangesCounts: (val) => {
                setproposedChangesCounts(viewSchemeFilter.scheme_type_code === schemeCode.Normal || viewSchemeFilter.scheme_type_code === schemeCode.Special ? val : null);
            },
            otherAreasCounts: (val) => {
                setotherAreas(val || null);
            },
            implementStatus: (val) => {
                setimplementStatus(val || null);
            },
            BenefitsCounts: (val) => {
                setBenefitsCounts(val || null);
            },
            title: (val) => {
                setTitle(val || null);
            },
            division: (val) => {
                setDivision(val || null);
            },
            agency: (val) => {
                setAgency(val || null);
            },
            implementDate: (val) => {
                setimplementDate(val);
            },
        };
        // Update state based on the name
        if (stateUpdateMap[name]) {
            stateUpdateMap[name](value);
        }
        validate(name, value);
    };
    
    const lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }
    // ---------------------Delete Document from Table------------ //
    const handleAddDocumentDelete = (index) => {
        const updatedDocuments = [...documentData];
        const deletedDocument = updatedDocuments[index];
        deleteS3Document(deletedDocument?.path,deletedDocument,updatedDocuments,index);
    };

    const handleUpload = () => {
        if (validate('viewDocumentTypeFilter', viewDocumentTypeFilter) || validate('attachmentName', attachmentName)) return;
        UploadDocumentDetail(documentLink?.documentLink, attachmentName?.documentType, attachmentName?.documentName);
        setViewDocumentTypeFilter('');
        setAttachmentName('');
        setshowDocmentData(true)
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDeleteDocument = (index, pre) => {
        if (!Array.isArray(index)) { index = [index]; }
        if (pre === 'prev') {
            const updatedDocuments = [...documentData];
            const deletedDocument = updatedDocuments[index];
            updatedDocuments.splice(index, 1);
            setdocumentData(updatedDocuments);
            if (deletedDocument?.attachment_name !== 'Other') {
                setAvailableDocumentTypes(prevTypes => {
                    const updatedTypes = prevTypes.filter(type => type.title !== 'Other');
                    return [...updatedTypes, { title: deletedDocument?.attachment_name }];
                });
            } else {
                const otherExists = availableDocumentTypes.some(type => type.title === 'Other');
                if (!otherExists) {
                    setAvailableDocumentTypes(prevTypes => [...prevTypes, { title: 'Other' }]);
                }
            }
        }else{
            const updatedMembers = [...memberData];
            const initialLength = updatedMembers?.length;
            index.sort((a, b) => b - a).forEach(index => {
                updatedMembers.splice(index, 1);
            });
            if (updatedMembers?.length < initialLength) {
                toaster("success", "Member Successfully Deleted")
            }
            setMemberData(updatedMembers);
        }
    };
    
    const fileSelectHandler = (event) => {
        if (event?.target?.files && event?.target?.files[0]) {
            let typeOfImage = event?.target?.files[0]?.type;
            let ImageType = typeOfImage.split('/');
            setAttachmentName({
                documentName: event?.target?.files[0]?.name,
                documentType: ImageType[1] || ''
            });
            
            let maxAllowedSize = 2097152; // 2MB size
            if (event?.target?.files[0]?.size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event?.target?.files[0]);
                reader.onload = (e) => {
                    setdocumentLink({ documentLink: e.target.result });
                };
            } else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
                setdocumentLink({ documentLink: '' });
            }
        } else {
            setAttachmentName({ documentName: '', documentType: '' });
            setdocumentLink({ documentLink: '' });
        }
    };
    // ---------------------Submit Suggestion API------------ //
    const addSuggestion = async () => {
        setloading(true)
        let payload = {
            title: title,
            category: selectCategory?.id,
            scheme_type: viewSchemeFilter?.id,
            scheme_type_code: viewSchemeFilter?.scheme_type_code,
            agency: agency?.id,
            scheme_name: ListScheme?.id,
            present_status: presentStatus != '' ? presentStatus : null,
            proposed_changes: proposedChangesCounts != '' ? proposedChangesCounts : null,
            earlier_status: earlierStatus != '' ? earlierStatus : null,
            changes_implemented: implementStatus != '' ? implementStatus : null,
            applicable_areas: otherAreasCounts != '' ? otherAreasCounts : null,
            benefits: BenefitsCounts,
            annual_saving: annualSaving != '' ? annualSaving : null,
            // idea_bank_reference: isIdeaBankRefValid ? ideaBankRef : null,
            idea_bank_reference: ideaBankRef ? parseInt(ideaBankRef) : null,
            to_agency_name: agency?.label,
            from_role: "Suggestor",
            to_role: 'Evaluator',
            action: "Submitted",
            to_status: 1,
            implemented_date: implementDate != '' ? implementDate : null,
            suggestion_path: documentData,
            sug_member_details: memberData,
        };
        await AddSuggestions(payload)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    toast.warn(res?.data?.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    setResponseData(res);
                    setopenConfirmPopUp(false)
                    setTab()
                    setShowDashBoard(true);
                }
                else {
                    res?.status != 404 && toaster("warning", res?.data?.message ? res.data?.message : "")
                    setopenConfirmPopUp(false)
                    setShowDashBoard(false);
                }
            })
            .catch((error) => {
                console.log("error in get ADD request API", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error?.data?.message : "")
                setopenConfirmPopUp(false)
            })
            .finally(() => { setloading(false) })
    }

    // ---------------------Scheme Type API------------ //
    const getSchemeTypeDetails = () => {
        setloading(true);
        getSchemeType()
            .then(res => {
                if (res.status === 200) {
                    const TypeScheme = res?.data?.data?.scheme_type_list.map(scheme => ({
                        id: scheme?.id,
                        title: scheme?.scheme_type,
                        scheme_type_code: scheme?.scheme_type_code
                    }));
                    setSchemeType(TypeScheme);
                } else {setSchemeType([]);}
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => {setloading(false);});
    };
    
    // ---------------------Get Category Details------------ //
    const getCategoryDetail = () => {
        setloading(true);
        getCategory()
            .then(res => {
                if (res?.status === 200) {
                    const Category = res?.data?.data?.category_list.map(category => ({
                        id: category?.id,
                        label: category?.category_name,
                    }));
                    setCategory(Category);
                } else {setCategory([]);}
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => {setloading(false);});
    };
    
    // ---------------------Get Division Details------------ //
    const getDivision = () => {
        setloading(true);
        const param = {
            comp_code: loginData?.userData?.CompCode,
            pa: loginData?.userData?.PersArea
        };
        get_division(param)
            .then(res => {
                if (res.status === 200) {
                    const division = res?.data?.data?.division_list.map(division => ({
                        id: division?.division_code,
                        label: division?.division_name
                    }));
                    setDivisionData(division);
                } else {
                    toast.warn(res?.data?.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    });
                    setDivisionData([]);
                }
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => {setloading(false);});
    };
    
// ---------------------Get Agency Details Based of Division ID------------ //
    const getAgency = (divisionId) => {
        setloading(true);
        const params = { division: divisionId };
        get_agency(params)
            .then(res => {
                if (res?.status === 200) {
                    const agency_list = res?.data?.data?.agency_list.map(agency => ({
                        id: agency?.agency_code,
                        label: agency?.agency_name
                    }));
                    setAgencyData(agency_list);
                } else {setAgencyData([]);}
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => {setloading(false);});
    };
    
    // ---------------------Get Schemes List Details------------ //
    const getSchemeListDetail = (schemeTypeCode) => {
        setloading(true);
        const param = { 
            scheme_type_code: schemeTypeCode,
            pa:loginData?.userData?.PersArea,
            comp_code:loginData?.userData?.CompCode,
         };

        getschemeList(param)
            .then(res => {
                if (res.status === 200) {
                    const schemeList = res?.data?.data?.scheme_list.map(schemeList => ({
                        id: schemeList?.id,
                        label: schemeList?.scheme_name,
                    }));
                    setschemeList(schemeList);
                } else {setschemeList([]);}
            })
            .catch(errors => {
                toast.warn(errors, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
            })
            .finally(() => {setloading(false);});
    };
    // ---------------------Check Idea Bank Reference------------ //
    // const getSuggestionDetails =  (id) => {
    //     setloading(true)
    //      getSuggestionLogs(id)
    //         .then((res) => {
    //             if (res?.status === 200 || res?.status === 201) { 
    //                 toaster("success", "Suggestion ID is Valid") 
    //                 setIsIdeaBankRefValid(true); 
    //             }
    //             else { 
    //                 toaster("warning", "Suggestion ID is Invalid")
    //                 setIsIdeaBankRefValid(false); 
    //              }
    //         })
    //         .catch((error) => {
    //             console.log("error in S3 delete documents-->", error)
    //             error?.status != 404 && toaster("warning", error ? error : "")
    //         })
    //         .finally(() => { setloading(false) })
    // }
    const getSuggestionDetails =  (id) => {
        setloading(true)
        let payload = {
          idea_bank_reference: parseInt(id),
          sug_member_details: memberData
        };
        validateIdeaBankReference(payload)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) { 
                    // toaster("success", "Suggestion ID is Valid") 
                    // setIsIdeaBankRefValid(true); 
                    toaster("success", res?.data?.message ? res?.data?.message : "") 
                }

                else { 
                    // toaster("warning", "Suggestion ID is Invalid")
                    // setIsIdeaBankRefValid(false);
                    toaster("warning", res?.data?.message ? res?.data?.message : "")  
                }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error ? error : "")
            })
            .finally(() => { setloading(false) })
    }
        // ---------------------DELETE Documents From S3------------ //
    const deleteS3Document = async (pathdoc,deletedDocument,updatedDocuments,index) => {
        setloading(true)
        let params = {
            emp_id: loginData?.userData?.Perno,
            path: pathdoc
        }
        await deleteS3Documents(params)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    if (updatedDocuments){
                        if(deletedDocument?.type == 'Other'){
                            setOtherDocumentCount(otherDocumentCount - 1)
                            const titleExists = availableDocumentTypes.some(item => item.title === 'Other');
                            if(!titleExists){
                                setAvailableDocumentTypes(prevTypes => [...prevTypes, { title: deletedDocument?.type }])
                            }
                        }
                        updatedDocuments.splice(index,1);
                        setdocumentData(updatedDocuments);
                    }
                    if (deletedDocument?.type !== 'Other') {
                        setAvailableDocumentTypes(prevTypes => [...prevTypes, { title: deletedDocument?.type }]);
                    }
                    toaster("success", res?.data?.message ? res?.data?.message : "") 
                }
                    else { res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "")}
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.message ? error?.message : "")
            })
            .finally(() => { setloading(false) })
    }

    // ---------------------Get Member Details------------ //
    const getMemberDetail = async (employeeId) => {
        setloading(true)
        let params = {
            emp_id: employeeId,
            comp_code: loginData?.userData?.CompCode,
        }
        try {
            const res = await getMemberDetails(params);
            if (res.status === 200) {
                setloading(false)
                if (res?.data?.data != 0) {
                    const response = {
                        emp_id: res?.data?.data?.emp_id,
                        cost_center: res?.data?.data?.cost_center,
                        cost_center_name: res?.data?.data?.cost_center_name,
                        name: res?.data?.data?.emp_name,
                        designation: res?.data?.data?.designation,
                        grade: res?.data?.data?.grade,
                        contact_no: res?.data?.data?.contact_no,
                        is_unmasked: false,
                        comp_code: res?.data?.data?.comp_code,
                        pa: res?.data?.data?.pa,
                        psa: res?.data?.data?.psa,
                        egroup: res?.data?.data?.egroup,
                        esubgroup: res?.data?.data?.esubgroup,
                    };

                    setOriginalData((prevOriginalData) => {
                        const isDuplicate = prevOriginalData.some(member => member?.emp_id === response?.emp_id);
                        if (isDuplicate) {
                            setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Employee ID is already added.' }));
                            return prevOriginalData;
                        } else {
                      
                            return [...prevOriginalData, response];
                        }
                    })

                    const newMember = {
                        emp_id: res?.data?.data?.emp_id,
                        cost_center: res?.data?.data?.cost_center,
                        cost_center_name: res?.data?.data?.cost_center_name,
                        name: res?.data?.data?.emp_name,
                        designation: res?.data?.data?.designation,
                        grade: res?.data?.data?.grade,
                        contact_no: maskMobile(res?.data?.data?.contact_no),
                        is_unmasked: false,
                        comp_code: res?.data?.data?.comp_code,
                        pa: res?.data?.data?.pa,
                        psa: res?.data?.data?.psa,
                        egroup: res?.data?.data?.egroup,
                        esubgroup: res?.data?.data?.esubgroup,
                    };
                    setMemberData(prevMemberData => {
                        const isDuplicate = prevMemberData.some(member => member?.emp_id === newMember?.emp_id);
                        if (isDuplicate) {
                            setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Employee ID is already added.' }));
                            return prevMemberData;
                        } else {
                            setEmployeeId('');
                            setshowMemberData(true);
                            setErrors(prevErrors => ({ ...prevErrors, employeeId: '' }));
                            toast.warn("Member Added Successfully", {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastSuccess'
                            });
                            return [...prevMemberData, newMember];
                        }
                    });
                } else {
                    setErrors(prevErrors => ({ ...prevErrors, employeeId: res?.data?.message }));
                }
            } else {
                setErrors(prevErrors => ({ ...prevErrors, employeeId: res?.data?.message }));
            }
        } catch (error) {
            console.error('Error fetching member details:', error);
            setErrors(prevErrors => ({ ...prevErrors, employeeId: 'Error fetching member details.' }));
            setloading(false)
        } finally { setloading(false) }
    };

    // ---------------------POST Documnet to S3 ------------ //
    const UploadDocumentDetail = async (attachment, attachmentType, attachmentName) => {
        setloading(true)
        let body = {
            emp_id: loginData?.userData?.Perno,
            attachment: attachment,
            attachment_name: attachmentName,
            attachment_type: attachmentType,
        };
        await uploadDocument(body)
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    const document = {
                        type: viewDocumentTypeFilter?.title,
                        name: res?.data?.data?.attachment_name,
                        extension: attachmentType,
                        attachment: res?.data?.data?.attachment_presigned_url,
                        path: res?.data?.data?.attachment_path,
                        role: "Suggestor",
                        document_category: "suggestion_details"
                    };
                    setdocumentData([...documentData, document]);
                    if(viewDocumentTypeFilter.title == 'Other'){
                        setOtherDocumentCount(otherDocumentCount + 1)
                        if(otherDocumentCount == 3){
                            const updatedDocumentTypes = availableDocumentTypes.filter(type => type.title !== viewDocumentTypeFilter.title);
                            setAvailableDocumentTypes(updatedDocumentTypes);
                        }
                    }
                    if (viewDocumentTypeFilter.title != 'Other') {
                        const updatedDocumentTypes = availableDocumentTypes.filter(type => type.title !== viewDocumentTypeFilter.title);
                        setAvailableDocumentTypes(updatedDocumentTypes);
                    }
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                }
                else { res?.status != 404 && toaster("warning", res?.data?.message ? res?.data?.message : "") }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error?.data?.message : "")
            })
            .finally(() => { setloading(false) })
    }

    const onConfirmAction = () => {
        addSuggestion();
    }

    const handleThirdAction = (index) => {
        setMemberData(
            memberData.map((val,ind)=>{
                if(index===ind){
                    const newMaskingState = !val.is_unmasked;
                    return {
                        ...val,
                        is_unmasked: newMaskingState,
                        contact_no: newMaskingState
                            ?
                                originalData[index].contact_no
                            :
                                maskMobile(val.contact_no)
                    }
                }
                return val
            })
        )
    }

    const handleContactUpdate = (updates) => {
        if (!Array.isArray(updates)) { updates = [updates]; }
        const updatedMemberData = [...memberData];
        updates.forEach(({ index, value }) => {
            const cpoyvalue = value;
            setOriginalData((prev)=>{
                return prev.map((val,ind)=>{
                    if(ind==index){
                        return{
                            ...val,
                            contact_no: cpoyvalue,
                            is_unmasked: false
                        }
                    }
                    return val
                })
            })

            updatedMemberData[index] = {
                ...updatedMemberData[index],
                contact_no: maskMobile(value),
                is_unmasked: false
            };
        });
        setMemberData(updatedMemberData);
    };
        // checkService = () => {
    //     this.props.serviceData.availableServiceData.forEach((item, i) => {
    //         if (item.service_type == "time_attendance" && item.is_active == false) {
    //                 setLanding_Service_reason(true)
    //                 setLanding_Service_reason(item.reason)
    //         }
    //     })
    // }
    return (
        <>
        {is_service_Unavailable ? (
                <div className="col-md-12 h-100vh justify-content-center align-item-center">
                    <ServiceUnavailable reason={landing_Service_reason} />
                </div>
            ):(
            showDashBoard ?
                <><SuggestionDashboard triggerUpdate={showDashBoard} /></>
                :
                <>
                    <div className='d-flex flex-direction-column '>
                        <LoaderIndicator loading={loading} />
                        <div className=' row' >
                    
                                <img
                                    alt="hide"
                                    src={rightarrowbg}
                                    className="up_arrow pointer"
                                    onClick={()=>handleBack(documentData, responseData)}
                                />
                                <div className='toggle-card-heading ml-1 pointer' onClick={()=>handleBack(documentData, responseData)}>
                                    {'Back'}
                                </div>
                            
                            
                        </div>
                        <div className='mt-2 suggestion-screen-screen-scroll' >
                            <div className='flex-direction-column'>
                                <span className='document-innercard-content-title common-text-color'>Suggestion Entry</span>
                                <div className='mt-1'>
                                    <div className='row ml-4 w-100'>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Title</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder"
                                                    type="text"
                                                    style={{ height: '43px' }}
                                                    placeholder='Enter Title'
                                                    minLength={10}
                                                    maxLength={100}
                                                    onChange={(e) => handleChange(e, 'title')}
                                                />
                                                <div className="justify-content-end time-text">
                                                    {lengthCalculate(title)}/100
                                                </div>
                                                {errors.title && <span className="p-sub-heading ml-3 errorBlock">{errors.title}</span>}
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Category</div>
                                            <div className='p-placeholder-text'>
                                                   <LargeDropdown
                                                    data={category}
                                                    value={selectCategory.label}
                                                    selectedValue={(data) => handleCategory(data)}
                                                />
                                                {errors.selectCategory && <span className="p-sub-heading ml-3 errorBlock">{errors.selectCategory}</span>}
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Scheme Type</div>
                                            <div className='p-placeholder-text w-20rem'>
                                                <DynamicDropdown
                                                    data={typeScheme}
                                                    render="title"
                                                    value={viewSchemeFilter.title}
                                                    selectedValue={(data) => handleViewFilter(data)}
                                                    arrow={"true"}
                                                    // height={"NotificationHeight"}
                                                    height={"dependOnContent"}
                                                />
                                                {errors.viewSchemeFilter && <span className="p-sub-heading ml-3 errorBlock">{errors.viewSchemeFilter}</span>}
                                            </div>
                                        </div>
                                        {(viewSchemeFilter.scheme_type_code === schemeCode.Special || viewSchemeFilter.scheme_type_code === schemeCode["Special Implemented"]) && (
                                                <div className='col-12 col-md-3 mb-3'>
                                                    <div className='p-sub-heading ml-2 required'>Scheme Name</div>
                                                    <div className='p-placeholder-text'>
                                                        <LargeDropdown
                                                            data={schemeList}
                                                            value={ListScheme.label?ListScheme.label:'Select...'}
                                                            selectedValue={(data) => handleListScheme(data)}
                                                        />
                                                            {errors.ListScheme && <span className="p-sub-heading ml-3 errorBlock">{errors.ListScheme}</span>}
                                                    </div>
                                                </div>
                                            )}
                                        {(viewSchemeFilter.scheme_type_code === schemeCode.Implemented || viewSchemeFilter.scheme_type_code === schemeCode["Special Implemented"]) && (
                                                <div className='col-12 col-md-3 mb-3'>
                                                    <div className='p-sub-heading required'>Implemented Date</div>
                                                    <div className='p-placeholder-text'>
                                                        <input
                                                            className="form-control p-placeholder"
                                                            type="date"
                                                            value={implementDate || ''}
                                                            style={{ height: '38px' }}
                                                            onChange={(e) => handleChange(e, 'implementDate')}
                                                            max={today}
                                                        />
                                                        {errors.implementDate && <span className="p-sub-heading ml-3 errorBlock">{errors.implementDate}</span>}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                    <hr />
                                    <div className='flex-direction-column'>
                                        <span className='mt-3 mb-1'>Member Details</span>
                                        <div className='row align-item-center'>
                                            <div className='col-12 col-md-3 mb-3'>
                                                <div className='p-sub-heading required'>Enter Employee Id</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder"
                                                        type="text"
                                                        value={employeeId}
                                                        placeholder='Enter Employee ID'
                                                        onChange={(e) => handleChange(e, 'employeeId')}
                                                        disabled={isLimitReached}
                                                    />
                                                    <div className="justify-content-start time-text">
                                                        Note: After entering the Employee ID, click 'Add Member
                                                    </div>
                                                    {errors.employeeId && <span className="p-sub-heading ml-3 errorBlock">{errors.employeeId}</span>}
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-2 d-flex align-items-center'>
                                                <input
                                                    type="button"
                                                    value='ADD MEMBER'
                                                    className={`${!isLimitReached ? 'validateBtn' : 'validateBtnDisabled'}`}
                                                    onClick={handleAddMember}
                                                    disabled={isLimitReached}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {memberData.length > 0 && (
                                        <ViewTable
                                            data={memberData}
                                            showCheck={true}
                                            showEdit={true}
                                            handleDeleteDocument={handleDeleteDocument}
                                            handleContactUpdate={handleContactUpdate}
                                            hasAction={true}
                                            memberTable={true}
                                            columns={SuggestionMemberDetails} 
                                            handleThirdAction={handleThirdAction}
                                            hasThirdAction={true}
                                            originalData={originalData}
                                            hasHandlingOfEdit={true}
                                            />
                                    )}
                                    {viewSchemeFilter != '' &&(
                                            <>
                                                <hr className='mt-2' />
                                                <div className='flex-direction-column '>
                                                    <span className='mt-3'>Suggestion Details</span>
                                                    <div className='row mt-2'>
                                                        <div className='col-12 col-md-3 mb-3'>
                                                            <div className='p-sub-heading required'>{viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? 'Present status' : 'Earlier status'}</div>
                                                            <div className='p-placeholder-text'>
                                                                <textarea
                                                                    className="form-control p-placeholder"
                                                                    type="text"
                                                                    value={viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? presentStatus : earlierStatus}
                                                                    onChange={(e) => handleChange(e, viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? 'presentStatus' : 'earlierStatus')}
                                                                    placeholder={viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? ' Enter Present status' : 'Enter Earlier status'}
                                                                    maxLength={350}
                                                                >
                                                                </textarea>
                                                                {(errors.presentStatus) && <span className="p-sub-heading ml-3 errorBlock">{errors.presentStatus}</span>}
                                                                {(errors.earlierStatus) && <span className="p-sub-heading ml-3 errorBlock">{errors.earlierStatus}</span>}
                                                                <div className="justify-content-end time-text">
                                                                    {lengthCalculate(viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? presentStatus : earlierStatus)}/350
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-3'>
                                                            <div className='p-sub-heading required'>{viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? 'Proposed changes' : ' Changes implemented'}</div>
                                                            <div className='p-placeholder-text'>
                                                                <textarea
                                                                    className="form-control p-placeholder"
                                                                    type="text"
                                                                    value={viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? proposedChangesCounts : implementStatus}
                                                                    onChange={(e) => handleChange(e, viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? 'proposedChangesCounts' : 'implementStatus')}
                                                                    placeholder={viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? ' Enter Proposed status' : 'Enter Changes Implemented'}
                                                                    maxLength={350}
                                                                >
                                                                </textarea>
                                                                {errors.proposedChangesCounts && <span className="p-sub-heading ml-3 errorBlock">{errors.proposedChangesCounts}</span>}
                                                                {errors.implementStatus && <span className="p-sub-heading ml-3 errorBlock">{errors.implementStatus}</span>}
                                                                <div className="justify-content-end time-text">
                                                                    {lengthCalculate(viewSchemeFilter?.scheme_type_code === schemeCode?.Normal || viewSchemeFilter?.scheme_type_code === schemeCode?.Special ? proposedChangesCounts : implementStatus)}/350
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-3'>
                                                            <div className='p-sub-heading'>Other areas where this suggestion is applicable</div>
                                                            <div className='p-placeholder-text'>
                                                                <textarea
                                                                    className="form-control p-placeholder"
                                                                    type="text"
                                                                    value={otherAreasCounts}
                                                                    onChange={(e) => handleChange(e, 'otherAreasCounts')}
                                                                    placeholder={'Enter Other areas'}
                                                                    maxLength={350}
                                                                >
                                                                </textarea>
                                                                <div className="justify-content-end time-text">
                                                                    {lengthCalculate(otherAreasCounts)}/350
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-3'>
                                                            <div className='p-sub-heading required'>Benefits</div>
                                                            <div className='p-placeholder-text'>
                                                                <textarea
                                                                    className="form-control p-placeholder"
                                                                    type="text"
                                                                    value={BenefitsCounts}
                                                                    onChange={(e) => handleChange(e, 'BenefitsCounts')}
                                                                    placeholder={'Enter Benefits'}
                                                                    maxLength={350}
                                                                >
                                                                </textarea>
                                                                {errors.BenefitsCounts && <span className="p-sub-heading ml-3 errorBlock">{errors.BenefitsCounts}</span>}
                                                                <div className="justify-content-end time-text">
                                                                    {lengthCalculate(BenefitsCounts)}/350
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-md-3 mb-3'>
                                                            <div className='p-sub-heading'>Annual Saving in ₹(Optional)</div>
                                                            <div className='p-placeholder-text'>
                                                                <input
                                                                    className="form-control p-placeholder"
                                                                    type="text"
                                                                    value={annualSaving}
                                                                    placeholder={'Enter Annual Saving in ₹....'}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        if (/^\d*\.?\d*$/.test(value)) {
                                                                            setAnnualSaving(value);
                                                                        }
                                                                    }}
                                                                    onKeyPress={(e) => {
                                                                        if (!/[0-9.]$/.test(e.key)) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {(viewSchemeFilter.scheme_type_code === schemeCode.Implemented || viewSchemeFilter.scheme_type_code === schemeCode["Special Implemented"]) && (
                                                                <>
                                                                <div className='col-12 col-md-3 mb-3'>
                                                                    <div className='p-sub-heading'>Idea Bank Reference(Optional)</div>
                                                                    <div className='p-placeholder-text flex-direction-row'>
                                                                        <input
                                                                            className="form-control p-placeholder"
                                                                            type="number"
                                                                            value={ideaBankRef}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value.replace(/\D/g, '');
                                                                                if(ideaBankRef==''){
                                                                                    setIdeaBankRef(null)    
                                                                                }else{
                                                                                    setIdeaBankRef( parseInt(value))
                                                                                }
                                                                            }}
                                                                            placeholder={'Enter Idea Bank Reference'}
                                                                            onKeyPress={(e) => {
                                                                                if (!/[0-9]/.test(e.key)) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='align-content-center'>
                                                                    <input
                                                                        type="button"
                                                                        value='VALIDATE'
                                                                        className={`${ideaBankRef ? 'validateBtn' : 'validateBtnDisabled'}`}
                                                                        onClick={()=>getSuggestionDetails(ideaBankRef)}
                                                                        disabled={!ideaBankRef}
                                                                    />
                                                                </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div> </>)}
                                    <hr />
                                    <div className='flex-direction-column '>
                                        <span className='mt-3'>Document to Upload</span>
                                        <div className='row mt-2'>
                                            <div className='col-12 col-md-3'>
                                                <div className='p-sub-heading'>Select Document Type</div>
                                                <div className='p-placeholder-text'>
                                                    <DynamicDropdown
                                                        data={availableDocumentTypes}
                                                        render="title"
                                                        value={viewDocumentTypeFilter?.title}
                                                        selectedValue={(data) => handleDocumentFilter(data)}
                                                        arrow={"true"}
                                                        height={"NotificationHeight"}
                                                    />
                                                    {errors.viewDocumentTypeFilter && <span className="p-sub-heading ml-3 errorBlock">{errors.viewDocumentTypeFilter}</span>}
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-3'>
                                                <div className='p-sub-heading'>Upload Document</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className='form-control p-placeholder'
                                                        type="file"
                                                        accept=".png,.jpg,.jpeg,.pdf"
                                                        ref={fileInputRef}
                                                        style={{ height: '43px' }}
                                                        placeholder={'Select Document'}
                                                        onChange={(e) => fileSelectHandler(e)}
                                                    />
                                                    {errors.attachmentName && <span className="p-sub-heading ml-3 errorBlock">{errors.attachmentName}</span>}
                                                    <div>
                                                    <span className='noteTextMsg'>{'You can upload Attachment upto 2MB size only.(pdf/png/jpg/jpeg)'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-3 mb-4 d-flex  align-items-center'>
                                                <input
                                                    type="button"
                                                    value='UPLOAD'
                                                    className={`${!isUploadButtonDisabled ? 'validateBtn' : 'validateBtnDisabled'}`}
                                                    onClick={handleUpload}
                                                    disabled={isUploadButtonDisabled}
                                                />
                                            </div>
                                        </div>
                                        {documentData.length > 0 && (
                                            <SuggesterPreviewCompoment isUrl={true} data={documentData} download={true} handleAddDocumentDelete={handleAddDocumentDelete} addDocumentKey={true} viewDelete={false} />
                                        )}
                                    </div>
                                    <hr />
                                    <div className='row mt-3'>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Select Division for Evaluation</div>
                                            <div className='p-placeholder-text'>
                                                <LargeDropdown
                                                    data={divisionData}
                                                    value={division?.label}
                                                    selectedValue={(data) => handleDivision(data)}
                                                />
                                                {errors.division && <span className="p-sub-heading ml-3 errorBlock">{errors.division}</span>}
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-3 mb-3'>
                                            <div className='p-sub-heading required'>Select Agency for Evaluation</div>
                                            <div className='p-placeholder-text'>
                                                   <LargeDropdown
                                                    data={agencyData}
                                                    value={agency?.label ? agency?.label : 'Select..' }
                                                    selectedValue={(data) => handleAgency(data)}
                                                />
                                                {errors.agency && <span className="p-sub-heading ml-3 errorBlock">{errors.agency}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row justify-content-end'>
                            <div className="col-4">
                                <input
                                    type="button"
                                    value='SUBMIT'
                                    className={`${!validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                                    onClick={handleSubmit}
                                    disabled={validateBtn} 
                                />
                                <input type='button' onClick={()=>handleBack(documentData, responseData)} value={'BACK'} className=' cancelBtn mr-3' style={{height:'37px'}} />
                            </div>
                        </div> */}
                        {
                            validateBtn ? 
                            (
                                <div className='row justify-content-end'>
                                    <div className="col-4">
                                        <input
                                            type="button"
                                            value='Validate'
                                            // className={`${!validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                                            className='validateBtn'
                                            onClick={validateAllFields}
                                            // disabled={validateBtn} 
                                        />
                                        <input type='button'onClick={()=>handleBack(documentData, responseData)} value={'BACK'} className=' cancelBtn mr-3' style={{height:'37px'}} />
                                    </div>
                                </div>

                            ) : 
                            (
                                <div className='row justify-content-end'>
                                    <div className="col-4">
                                        <input
                                            type="button"
                                            value='SUBMIT'
                                            // className={`${!validateBtn ? 'validateBtn' : 'validateBtnDisabled'}`}
                                            className='validateBtn'
                                            onClick={handleSubmit}
                                            // disabled={validateBtn} 
                                        />
                                        <input type='button' onClick={()=>handleBack(documentData, responseData)} value={'BACK'} className=' cancelBtn mr-3' style={{height:'37px'}} />
                                    </div>
                                </div>

                            )

                        }
                       
                        
                        {/* {validateBtn &&
                            <ConfirmationPopup
                                loading={loading}
                                title={"Confirmation"}
                                message={"Are you sure that you want to Submit Suggestion?"}
                                confirmBtnLbl={"SUBMIT"}
                                onDismiss={() => { setopenConfirmPopUp(false) }}
                                onConfirmAction={onConfirmAction}
                            />
                        } */}
                        {openConfirmPopUp &&
                            <ConfirmationPopup
                                loading={loading}
                                title={"Confirmation"}
                                message={"Are you sure that you want to Submit Suggestion?"}
                                confirmBtnLbl={"SUBMIT"}
                                onDismiss={() => { setopenConfirmPopUp(false) }}
                                onConfirmAction={onConfirmAction}
                            />
                        }
                    </div>
                </>
        )}
        </>
    )
}

export default AddSuggestion