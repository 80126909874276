import React, { useEffect, useState } from 'react'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import radioOn from "../../../../../assets/images/applyLeave/radioOn.png";
import radioOff from "../../../../../assets/images/applyLeave/radioOff.png";
import infoIcon from "../../../../../assets/drishti_images/infoicon.svg";
import ConfirmationPopUpLoan from '../commonComponent/ConfirmationPopUpLoan'
import DynamicDropdown from '../../../../shared/components/DynamicDropdown'
import confirm_popup from '../../../../../assets/drishti_images/confirmIcon.svg'
import { LOAN_TYPE, WARNING, ERROR_MESSAGE, GRADE_GROUP_TYPE, LOAN_NO, UNIT, loanSanctioningColumnFields, loanSanctioningTableData, INTERMEDIATE_SANCTIONING, FINAL_SANCTIONING } from '../constants'
import { toaster } from '../../../../../utils/Utils'
import { useSelector } from 'react-redux';
import { getDropdownList } from '../../../../../constants/api.service';
import LoanTable from '../commonComponent/LoanTable';
import PaginationTable from '../commonComponent/PaginationTable';
import LoanSanctionPopUp from '../commonComponent/LoanSanctionPopUp';

function LoanSanctioning() {
  const [selectedOption, setSelectedOption] = useState('Intermediate Sanctioning for Normal Cases');
  const [loanData, setLoanData] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [checked, setChecked] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [showBudgetDetails, setShowBudgetDetails] = useState(false);
  const [unit, setUnit] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedLocationDataList, setSelectLocationDataList] = useState([]);
  const [locationDataList, setLocationDataList] = useState([])
  const [gradeGroupTypeList, setGradeGroupTypeList] = useState([])
  const [selectedGradeGroupTypeList, setSelectedGradeGroupTypeList] = useState([]);
  const [loanDataNo, setLoanDataNo] = useState([]);
  const [selectedLoanDataNo, setSelectedLoanDataNo] = useState([]);
  const [selectedLoanData, setSelectedLoanData] = useState([]);
  const [loanSanctioningData, setLoanSanctioningData] = useState([]);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(5);
  const AdminLoginData = useSelector((state) => state.adminLoginState);
  const loanSanctioningListStatus = [
    { key: 0, value: INTERMEDIATE_SANCTIONING },
    { key: 1, value: FINAL_SANCTIONING }
  ]

  const handleOptionChange = (key, list) => {
    const selectedOption = key === 0 ? INTERMEDIATE_SANCTIONING : key === 1 ? FINAL_SANCTIONING : '';
    setSelectedOption(selectedOption);
    setChecked(key);
    setLoanSanctioningData([]);
  }



  //==============================================GET UNIT LIST====================================================
  const getUnitList = async (dropDownType) => {
    setLoader(true);
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }

    await getDropdownList(params)
      .then((res) => {
        if ((res.status === 200 || res.status === 201)) {
          const unitList = res?.data?.data?.map((data) => ({
            name: data?.unit_name,
            id: data?.unit_id
          }))
          unitList.unshift({ name: "ALL", id: "" });
          setLocationDataList(unitList);
          setSelectLocationDataList(unitList[0])
        } else {
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(() => {
        setLoader(false);
      })
  }

  //==============================================GET LOAN TYPE LIST====================================================
  const getLoanTypeList = async (dropDownType) => {
    setLoader(true);
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }
    await getDropdownList(params)
      .then((res) => {
        if ((res.status === 200 || res.status === 201)) {
          const loanTypeList = res?.data?.data?.map((data) => ({
            name: data?.loan_type_name,
            id: data?.loan_type_id
          }))
          loanTypeList.unshift({ name: "ALL", id: "" });
          setLoanData(loanTypeList);
          setSelectedLoanData(loanTypeList[0])
        } else {
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(() => {
        setLoader(false);
      })
  }




  //==============================================GET GRADE GROUP TYPE LIST====================================================
  const getGradeGroupTypeList = async (dropDownType) => {
    setLoader(true);
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }

    await getDropdownList(params)
      .then((res) => {
        if ((res.status === 200 || res.status === 201)) {
          const gradeGroupType = res?.data?.data?.map((data) => ({
            name: data?.group_type_name,
            id: data?.group_type_id
          }))
          gradeGroupType.unshift({ name: "ALL", id: "" });
          setGradeGroupTypeList(gradeGroupType);
          setSelectedGradeGroupTypeList(gradeGroupType[0])
        } else {
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(() => {
        setLoader(false);
      })
  }


  //==============================================GET LOAN NO LIST====================================================
  const getLoanNoList = async (dropDownType) => {
    setLoader(true);
    let params = {
      comp_code: AdminLoginData?.company?.company_code,
      drop_down_type: dropDownType
    }

    await getDropdownList(params)
      .then((res) => {
        if ((res.status === 200 || res.status === 201)) {
          const loanDataList = res?.data?.data?.map((data) => ({
            name: data?.loan_no_name,
            id: data?.loan_no_id
          }))
          loanDataList.unshift({ name: "ALL", id: "" });
          setLoanDataNo(loanDataList);
          setSelectedLoanDataNo(loanDataList[0])
        } else {
          toaster(WARNING, res?.data?.message ?? ERROR_MESSAGE);
        }
      }).catch((error) => {
        toaster(WARNING, error?.data?.message ?? ERROR_MESSAGE);
      }).finally(() => {
        setLoader(false);
      })
  }

  const handleSearch = () => {
    setShowTable(true);
    setLoanSanctioningData(loanSanctioningTableData)
  }

  const rejectIconButton = () => {
    setConfirmPopUp(true);
  }

  const approveIconButton = () => {
    setConfirmPopUp(true);
  }

  const closeConfirmPopUp = () => {
    setConfirmPopUp(false);
  }

  const switchPage = (value) => {
    setCurrentPage(value);
  }

  const handleBudgetDetails = () => {
    if(loanSanctioningData.length>0){
      setShowBudgetDetails(true)
    }
  }
  const closeBudgetDetails = () => {
    setShowBudgetDetails(false)
  }
  useEffect(() => {
    getLoanTypeList(LOAN_TYPE)
    getGradeGroupTypeList(GRADE_GROUP_TYPE)
    getLoanNoList(LOAN_NO)
    getUnitList(UNIT)
  }, [])


  return (
    <>
      <div className="ml-10">
        <div className="d-flex flex-direction-column">
          <div className="flex-direction-column filter">
            <div className="row align-item-center justify-content-between ml-2 pb-10px">
              <div>
                {loanSanctioningListStatus.map((list, key) => (
                    <span key={key} className="align-item-center-noflex px-5px">
                      {checked === key ? (
                        <span className="" onClick={() => handleOptionChange(key, list)}>
                          <img alt="radioOn" className="radio" src={radioOn} />
                          <label className="radioTxt ml-2">{list.value}</label>
                        </span>
                      ) : (
                        <span onClick={() => handleOptionChange(key, list)}>
                          <img alt="radioOff" className="radio" src={radioOff} />
                          <label className="radioTxt ml-2">{list.value}</label>
                        </span>
                      )}
                    </span>
                ))}
              </div>
              <img alt="radioOn" style={{width: "25px", height: "25px"}} className="radio" src={infoIcon} onClick={handleBudgetDetails} />
            </div>
            <div className="d-flex flex-direction-row  align-items-center">

              <div
                className="flex-direction-column mb-21px ml-3"
              >
                <div className="p-sub-heading ml-2">
                  {"Select Unit"}
                  <span className='required-field'>*</span>
                </div>
                <DynamicDropdown
                  data={locationDataList}
                  render="name"
                  value={selectedLocationDataList?.name}
                  selectedValue={(data) => setSelectLocationDataList(data)}
                  arrow={"true"}
                  height={"NotificationHeight"}
                  width={"w-20rem"}
                />
              </div>


              <div
                className="flex-direction-column mb-21px ml-3"
              >
                <div className="p-sub-heading ml-2">
                  {"Loan Type"}
                  <span className='required-field'>*</span>
                </div>
                <DynamicDropdown
                  data={loanData}
                  render="name"
                  value={selectedLoanData?.name}
                  selectedValue={(data) => setSelectedLoanData(data)}
                  arrow={"true"}
                  height={"NotificationHeight"}
                  width={"w-20"}
                />
              </div>
              <div
                className="flex-direction-column mb-21px ml-3"
              >
                <div className="p-sub-heading ml-2">
                  {"Loan No."}
                  <span className='required-field'>*</span>
                </div>
                <DynamicDropdown
                  data={loanDataNo}
                  render="name"
                  value={selectedLoanDataNo?.name}
                  selectedValue={(data) => setSelectedLoanDataNo(data)}
                  arrow={"true"}
                  height={"NotificationHeight"}
                  width={"w-20"}
                />
              </div>
              <div
                className="flex-direction-column mb-21px ml-3"
              >
                <div className="p-sub-heading ml-2">
                  {"Grade Group Type"}
                  <span className='required-field'>*</span>
                </div>
                <DynamicDropdown
                  data={gradeGroupTypeList}
                  render="name"
                  value={selectedGradeGroupTypeList?.name}
                  selectedValue={(data) => setSelectedGradeGroupTypeList(data)}
                  arrow={"true"}
                  height={"NotificationHeight"}
                  width={"w-20"}
                />
              </div>

              <div className="flex-direction-column ml-3">
                <button
                  className="h-38px btn buttonStyle px-20px m-10px"
                  onClick={handleSearch}
                >
                  {"Search"}
                </button>
              </div>


            </div>
          </div>

        </div>
        {
          showBudgetDetails && (
            <LoanSanctionPopUp
             title={"Budget Details"}
             crossBtn={closeBudgetDetails}
            />
          )
        }
        {
          confirmPopUp && (
            <ConfirmationPopUpLoan
              title={selectedOption === INTERMEDIATE_SANCTIONING ? "Intermediate Sanctioning" : "Final Sanctioning"}
              crossBtn={closeConfirmPopUp}
              image={confirm_popup}
              note={selectedOption === INTERMEDIATE_SANCTIONING ? "Are you sure you want to sanction application?" : "Are you sure you want to sanction application?"}
            />
          )
        }
        {
          (showTable && loanSanctioningData.length > 0) ? (
            <div>
              <LoanTable
                columns={loanSanctioningColumnFields}
                data={loanSanctioningData}
                hasAction={true}
                rejectIcon={selectedOption === INTERMEDIATE_SANCTIONING ? true : false}
                approveIcon={true}
                rejectIconAction={rejectIconButton}
                approveIconAction={approveIconButton}
              />
              {
                totalPages >= currentPage && (
                  <PaginationTable
                    switchPage={switchPage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                  />
                )
              }
            </div>
          )
            : (
              <>
                {
                  <div className='mt-8'>
                    <DataNotFound />
                  </div>
                }
              </>
            )
        }
      </div>
    </>
  )
}

export default LoanSanctioning