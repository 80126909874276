import React, { Component } from 'react';
import * as Config from '../../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../../constants/Service';
import LoaderIndicator from '../../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import { configStore } from "../../../../../../redux/ConfigStore";
import { gemsMaintenanceModule } from '../../../../../../utils/Utils';
import DataNotFound from '../../../../../shared/notFound/DataNotFound';
import { MaintenanceDashboardConstant } from '../gemsMaintenanceConstant';
import moment from 'moment';
import whitecross from '../../../../../../assets/drishti_images/white-cross.svg'
import view_action from '../../../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../../../assets/drishti_images/transfer/delete_action.svg';
import edit from '../../../../../../assets/images/cfm/edit.svg'
import default_profile from '../../../../../../assets/drishti_images/profile/default_profile.svg';
class MaintenanceDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            radioFilters: gemsMaintenanceModule(),
            dashboardData: [],
            clickImage: null,
            formUpdatePopup: '',

        }
    }

    viewImage = (img) => {
        this.setState({
            clickImage: img
        })
    }


    render() {
        const { loading } = this.state;
        const { tabName, gemsMaintenanceData, onAction } = this.props;
        return (
            <>
                <LoaderIndicator loading={loading} />
                <div className='table-container'>
                    {gemsMaintenanceData.length > 0 ?
                        <div className="main-contain" >
                            <div className="row align-item-center justify-content-start ">
                                <table className="table gemsAdmin">
                                    <thead className='w-100'>
                                        <tr className='w-100'>
                                            {/* area */}
                                            {<th width='5%'><div className='thead'>{MaintenanceDashboardConstant.Sr}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Area && <th width='45%'><div className='thead'>{MaintenanceDashboardConstant.Area}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Area && <th width='40%'><div className='thead'>{MaintenanceDashboardConstant.ColorCode}</div></th>}

                                            {/*Testimonials  */}
                                            {tabName.key == MaintenanceDashboardConstant.Testimonials && <th ><div className='thead'>{MaintenanceDashboardConstant.EmpId}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Testimonials && <th ><div className='thead'>{MaintenanceDashboardConstant.EmpName}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Testimonials && <th ><div className='thead'>{MaintenanceDashboardConstant.OrgUnit}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Testimonials && <th ><div className='thead'>{MaintenanceDashboardConstant.Function}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Testimonials && <th ><div className='thead'>{MaintenanceDashboardConstant.Testimonials}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Testimonials && <th ><div className='thead'>{MaintenanceDashboardConstant.ProfileImage}</div></th>}

                                            {/*gems slider  */}
                                            {tabName.key == MaintenanceDashboardConstant.GemsSliderScreen && <th ><div className='thead'>{MaintenanceDashboardConstant.Image}</div></th>}

                                            {/* duration */}
                                            {tabName.key == MaintenanceDashboardConstant.Duration && <th ><div className='thead'>{MaintenanceDashboardConstant.ProjectCategory}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Duration && <th ><div className='thead'>{MaintenanceDashboardConstant.ProjectDuration}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Duration && <th ><div className='thead'>{MaintenanceDashboardConstant.mtrDuration}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.Duration && <th ><div className='thead'>{MaintenanceDashboardConstant.frDuration}</div></th>}

                                            {/* pip */}
                                            {tabName.key == MaintenanceDashboardConstant.PIPApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.ApplicationNo}</div></th>}


                                            {/* DebarApplicant*/}
                                            {tabName.key == MaintenanceDashboardConstant.DebarApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.ApplicationNo}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.DebarApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.StartDate}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.DebarApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.EndDate}</div></th>}

                                            {/* other project*/}
                                            {tabName.key == MaintenanceDashboardConstant.OtherProjectApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.ApplicationNo}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.OtherProjectApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.StartDate}</div></th>}
                                            {tabName.key == MaintenanceDashboardConstant.OtherProjectApplicant && <th ><div className='thead'>{MaintenanceDashboardConstant.EndDate}</div></th>}

                                            {/* rejected */}
                                            {tabName.key == MaintenanceDashboardConstant.isApplicationRejectScreen && <th ><div className='thead'>{MaintenanceDashboardConstant.Reason}</div></th>}

                                            {<th width='10%'><div className='thead'>Action</div></th>}


                                        </tr>
                                    </thead>
                                    <tbody className='adminScreentbody w-100'>
                                        {gemsMaintenanceData.map((listValue, index) => {
                                            return (
                                                <tr key={index} >
                                                    <td width='5%'> <div className="td-choice-pay d-flex amount-justify-content-start">{index + 1}</div> </td>
                                                    {tabName.key == MaintenanceDashboardConstant.Area && <td ><div className="td-choice-pay d-flex amount-justify-content-start">{listValue.area ? listValue.area : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Area && <td ><div className="td-choice-pay d-flex amount-justify-content-start"><span style={{ backgroundColor: listValue.color_code ? listValue.color_code : 'transperent' }} className='colorBox'>{listValue.color_code ? '' : '-'}</span></div></td>}
                                                    {/*Testimonials  */}
                                                    {tabName.key == MaintenanceDashboardConstant.Testimonials && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.testimonial_id ? listValue.testimonial_id : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Testimonials && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.emp_name ? listValue.emp_name : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Testimonials && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.org_unit ? listValue.org_unit : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Testimonials && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.function ? listValue.function : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Testimonials && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.testimony ? listValue.testimony : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Testimonials && <td >
                                                        <img
                                                            src={listValue.profile_image ? listValue.profile_image : default_profile}
                                                            alt="avatar"
                                                            onClick={() => this.viewImage(listValue?.profile_image)}
                                                            className="profile-header"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalCenter"
                                                        ></img></td>}


                                                    {/*gems slider  */}
                                                    {tabName.key == MaintenanceDashboardConstant.GemsSliderScreen && <td ><img className='sliderImage' src={listValue.image_path} alt="No Image Found" /></td>}

                                                    {/* duration */}
                                                    {tabName.key == MaintenanceDashboardConstant.Duration && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.project_category ? listValue.project_category : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Duration && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.project_duration ? listValue.project_duration : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Duration && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.mtr_duration ? listValue.mtr_duration : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.Duration && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.fr_duration ? listValue.fr_duration : '-'}</div></td>}

                                                    {/* pip */}
                                                    {tabName.key == MaintenanceDashboardConstant.PIPApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.applicant_perno ? listValue.applicant_perno : '-'}</div></td>}

                                                    {/* DebarApplicant*/}
                                                    {tabName.key == MaintenanceDashboardConstant.DebarApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.applicant_perno ? listValue.applicant_perno : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.DebarApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.start_date ? moment(listValue.start_date).format("DD-MM-YYYY") : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.DebarApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.end_date ? moment(listValue.end_date).format("DD-MM-YYYY") : '-'}</div></td>}

                                                    {/* other project*/}
                                                    {tabName.key == MaintenanceDashboardConstant.OtherProjectApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.applicant_perno ? listValue.applicant_perno : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.OtherProjectApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.project_start_date ? moment(listValue.project_start_date).format("DD-MM-YYYY") : '-'}</div></td>}
                                                    {tabName.key == MaintenanceDashboardConstant.OtherProjectApplicant && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.project_end_date ? moment(listValue.project_end_date).format("DD-MM-YYYY") : '-'}</div></td>}


                                                    {/* rejected */}
                                                    {tabName.key == MaintenanceDashboardConstant.isApplicationRejectScreen && <td ><div className='td-choice-pay d-flex amount-justify-content-start'>{listValue.reason ? listValue.reason : '-'}</div></td>}

                                                    <td width='10%'>
                                                        <span className='d-flex'>
                                                            {tabName.key == MaintenanceDashboardConstant.PIPApplicant || tabName.key == MaintenanceDashboardConstant.GemsSliderScreen ?
                                                                null :
                                                                <div
                                                                    onClick={() => onAction(listValue, 'update')}
                                                                >
                                                                    <img alt="update" src={edit} style={{ width: '25px', cursor: 'pointer', marginRight: '10px' }} />
                                                                </div>
                                                            }
                                                            <div
                                                                onClick={() => onAction(listValue, 'delete')}
                                                            >
                                                                <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                            </div>
                                                        </span>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <div className='mt-5'>
                            <DataNotFound />
                        </div>
                    }
                </div>
                {/* </div> */}
                <div className="modal fade" id="exampleModalCenter" tabIndex={"-1"} role="" >
                    <div className="modal-dialog modal-dialog-centered" role="">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='close-btn-styles'>
                                    <img src={whitecross} alt="" className="" data-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <img src={this.state.clickImage} alt="" className="model-img-gems" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(MaintenanceDashboard);