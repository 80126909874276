import React, { useContext, useEffect, useRef, useState } from 'react'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import ClearanceTable from './ClearanceTable'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import CommonButton from '../../../shared/button/GradientButton'
import { masterLabels, masterTabs,exportMaster } from './constants'
import MasterPopup from './MasterPopup'
import ConfirmationPopUp from './ConfirmationPopUp'
import edit from '../../../../assets/images/cfm/edit.svg'
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg'
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import { toaster } from '../../../../utils/Utils'
import { Pagination } from 'react-bootstrap'
import LargeDropdown from '../../../shared/components/LargeDropdown'
import { fetchApiCall } from '../../../../constants/Service'
import * as Config from '../../../../constants/Config';
import filterIconActive from '../../../../assets/drishti_images/admin/filterIconActive.svg';
import filterIconInactive from '../../../../assets/drishti_images/admin/filterIconInactive.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { deleteAgencyConfig, deleteAgencyMaster, deleteEsgGroup, deleteSPOCConfig, deleteSPOCMaster, exportMasterMaintenceReport, getAgencyConfigList, getAgencyFilter, getAgencyMasterList, getEsgGroupList, getGradeGroupFilter, getPAFilter, getPSAFilter, getSPOCConfigList, getSPOCMasterList } from '../../../../constants/api.service'
import { RoleContext } from './Contexts'
import { maskMobile, maskEmail} from '../../../shared/commonMaskFunction';

function MasterMaintenance(props) {
    const filterMaster = useContext(RoleContext)
    const [masterFormFilterData, setMasterFormFilterData] = useState([])
    const [masterFormFilter, setMasterFormFilter] = useState(null)
    const [paFilterData, setPaFilterData] = useState([])
    const [psaFilterData, setPsaFilterData] = useState([])
    const [gradeGrpFilterData, setGradeGrpFilterData] = useState([])
    const [agencyFilterData, setAgencyFilterData] = useState([])
    const [companyList, setCompanyList] = useState([])
    const [companyVal, setCompanyVal] = useState(null)
    const [paFilterVal, setPaFilterVal] = useState(null)
    const [psaFilterVal, setPsaFilterVal] = useState(null)
    const [gradeFilterVal, setGradeFilterVal] = useState(null)
    const [agencyFilterVal, setAgencyFilterVal] = useState(null)
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [addPopUp, setAddPopUp] = useState(false)
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [domainId, setDomainID] = useState(null)
    const [searchText, setSearchText] = useState(null)
    const [originalData, setOriginalData] = useState([])
    const [isAllEmailVisible,setIsAllEmailVisible] = useState(false)
    const [isAllContactVisible,setIsAllContactVisible] = useState(false)
    const initialRenderRef = useRef(true)

    useEffect(() => {
        setTableData([])
        if (initialRenderRef.current == true) {
            fetchPaList()
            fetchCompanyList()
            fetchAgencyList()
            fetchPsaList()
            fetchGradeGrpList()
            initialRenderRef.current = false
        } else {
            setCurrentPage(1)
            setTotalPages(1)
            handleMasterApiCall()
        }
    }, [masterFormFilter, paFilterVal, psaFilterVal, companyVal, gradeFilterVal, agencyFilterVal, domainId])

    // useEffect(() => {
    //     fetchGradeGrpList(); fetchPsaList()
    // }, [companyVal, paFilterVal])

    useEffect(() => {
        const { adminTabList } = filterMaster
        if (adminTabList && adminTabList['Master Maintenance']) {
            let tempMasterMaintenanceList = masterTabs.filter((tab) => {
                return adminTabList['Master Maintenance'].some((subTab) => { return subTab == tab.title })
            })
            setMasterFormFilterData(tempMasterMaintenanceList)
            setMasterFormFilter(tempMasterMaintenanceList[0])
        }
        else {
            setMasterFormFilterData([])
            setMasterFormFilter(null)
        }
    }, [filterMaster])

    const handleViewAction = (index) => {
        setSelectedRecord(index)
        setAddPopUp(true)
        setEditMode(true)
    }
    const handleSecondaryAction = (index) => {
        setSelectedRecord(index)
        setIsConfirmPopupOpen(true)
    }

    const handleThirdAction = (index) => {
        setTableData((prevData) => {
          return prevData.map((val, ind) => {
            if (ind === index) {
              const updatedVal = { ...val, is_unmasked: !val.is_unmasked };
              if (!updatedVal.is_unmasked) {
                updatedVal.email = maskEmail(val.email);
                updatedVal.mobile_no = maskMobile(val.mobile_no);
              } else {
                updatedVal.email = originalData[index].email;
                updatedVal.mobile_no = originalData[index].mobile_no;
              }
              return updatedVal;
            }
            return val;
          });
        });
      };

    const handleMasterFilter = (data) => {
        handleClearFilter()
        setMasterFormFilter(data)
        setIsFilter(false)
    }
    const fetchSpocMasterList = async (page) => {
        setLoading(true);
        let params = { page_number: page };
        if (domainId) { params['domain'] = domainId; }
      
        getSPOCMasterList(params, props)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              const { paginated_data, total_pages } = res.data.data;
              let clonedData = paginated_data.map(data => ({ ...data })); 
              setOriginalData(paginated_data);
              clonedData = clonedData.map(data => {
                // data.is_unmasked = false;
                // if (data.is_unmasked === false) {
                  data.email = maskEmail(data.email);
                  data.mobile_no = maskMobile(data.mobile_no);
                // }
                data.isEmailUnmasked = false;
                data.isMobileUnmasked = false;
                // data.maskEmail(data.email);
                // data.maskMobile(data.email);
                return data;
              });
      
              setTableData(clonedData);
              setTotalPages(total_pages);
            }      
                    else {
                        setTableData([])
                        setTotalPages(0)
                        res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            ).catch((err) => { setTableData([]); setTotalPages(0) }).finally(() => setLoading(false))
    }
    const fetchSpocConfigList = (page) => {
        setLoading(true)
        let params = { page_number: page }
        paFilterVal !== null && (params.pa = paFilterVal.value)
        psaFilterVal !== null && (params.psa = psaFilterVal.value)
        companyVal !== null && (params.comp_code = companyVal.value)
        gradeFilterVal !== null && (params.grade_grp = gradeFilterVal.value)
        agencyFilterVal !== null && (params.agency = agencyFilterVal.value)
        domainId !== null && (params.domain = domainId)
        getSPOCConfigList(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        const { paginated_data, total_pages } = res.data.data
                        setTableData(paginated_data)
                        setTotalPages(total_pages)
                    }
                    else {
                        setTableData([])
                        setTotalPages(0)
                        res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            ).catch((err) => { setTableData([]); setTotalPages(0) }).finally(() => setLoading(false))
    }
    const fetchAgencyMaster = (page) => {
        setLoading(true)
        let params = { page_number: page }
        getAgencyMasterList(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        const { paginated_data, total_pages } = res.data.data
                        setTableData(paginated_data)
                        setTotalPages(total_pages)
                    }
                    else {
                        setTableData([])
                        setTotalPages(0)
                        res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            ).catch((err) => { setTableData([]); setTotalPages(0) }).finally(() => setLoading(false))
    }
    const fetchAgencyConfig = (page) => {
        setLoading(true)
        let params = { page_number: page }
        paFilterVal !== null && (params.pa = paFilterVal.value)
        psaFilterVal !== null && (params.psa = psaFilterVal.value)
        companyVal !== null && (params.comp_code = companyVal.value)
        gradeFilterVal !== null && (params.grade_grp = gradeFilterVal.value)
        agencyFilterVal !== null && (params.agency = agencyFilterVal.value)
        getAgencyConfigList(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        const { paginated_data, total_pages } = res.data.data
                        setTableData(paginated_data)
                        setTotalPages(total_pages)
                    }
                    else {
                        setTableData([])
                        setTotalPages(0)
                        res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            ).catch((err) => { setTableData([]); setTotalPages(0) }).finally(() => setLoading(false))
    }
    const fetchEsgGrpMapping = (page) => {
        setLoading(true)
        let params = { page_number: page }
        paFilterVal !== null && (params.pa = paFilterVal.value)
        companyVal !== null && (params.comp_code = companyVal.value)
        getEsgGroupList(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        const { paginated_data, total_pages } = res.data.data
                        setTableData(paginated_data)
                        setTotalPages(total_pages)
                    }
                    else {
                        setTableData([])
                        setTotalPages(0)
                        res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            ).catch((err) => { setTableData([]); setTotalPages(0) }).finally(() => setLoading(false))
    }
    const onDelete = () => {
        setLoading(true)
        let id = tableData[selectedRecord].id
        if (masterFormFilter.value == "0") {
            deleteSPOCMaster(id, props)
                .then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                        else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                        fetchSpocMasterList(currentPage);
                    }
                ).catch((err) => { toaster("error", err.response.data.message) }).finally(() => setLoading(false))
        }
        else if (masterFormFilter.value == "1") {
            deleteSPOCConfig(id, props)
                .then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                        else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                        fetchSpocConfigList(currentPage);
                    }
                ).catch((err) => { toaster("error", err.response.data.message) }).finally(() => setLoading(false))
        }
        else if (masterFormFilter.value == "2") {
            deleteAgencyMaster(id, props)
                .then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                        else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                        fetchAgencyMaster(currentPage);
                    }
                ).catch((err) => { toaster("error", err.response.data.message) }).finally(() => setLoading(false))
        }
        else if (masterFormFilter.value == "3") {
            deleteAgencyConfig(id, props)
                .then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                        else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                        fetchAgencyConfig(currentPage);
                    }
                ).catch((err) => { toaster("error", err.response.data.message) }).finally(() => setLoading(false))
        }
        else if (masterFormFilter.value == "4") {
            deleteEsgGroup(id, props)
                .then(
                    (res) => {
                        if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                        else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                        fetchEsgGrpMapping(currentPage);
                    }
                ).catch((err) => { toaster("error", err.response.data.message) }).finally(() => setLoading(false))
        }
    }
    const onCloseMasterPopup = () => {
        setTableData([])
        setAddPopUp(false)
        setEditMode(false)
        setSelectedRecord(null)
        if (masterFormFilter.value == "0") { fetchSpocMasterList(1) }
        else if (masterFormFilter.value == "1") { fetchSpocConfigList(1) }
        else if (masterFormFilter.value == "2") { fetchAgencyMaster(1) }
        else if (masterFormFilter.value == "3") { fetchAgencyConfig(1) }
        else if (masterFormFilter.value == "4") { fetchEsgGrpMapping(1) }
    }
    const handlePageCount = (action) => {
        if (action === "decrement" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            if (masterFormFilter.value == "0") { fetchSpocMasterList(currentPage - 1) }
            else if (masterFormFilter.value == "1") { fetchSpocConfigList(currentPage - 1) }
            else if (masterFormFilter.value == "2") { fetchAgencyMaster(currentPage - 1) }
            else if (masterFormFilter.value == "3") { fetchAgencyConfig(currentPage - 1) }
            else if (masterFormFilter.value == "4") { fetchEsgGrpMapping(currentPage - 1) }
        } else if (action === "increment" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            if (masterFormFilter.value == "0") { fetchSpocMasterList(currentPage + 1) }
            else if (masterFormFilter.value == "1") { fetchSpocConfigList(currentPage + 1) }
            else if (masterFormFilter.value == "2") { fetchAgencyMaster(currentPage + 1) }
            else if (masterFormFilter.value == "3") { fetchAgencyConfig(currentPage + 1) }
            else if (masterFormFilter.value == "4") { fetchEsgGrpMapping(currentPage + 1) }
        }
    };
    const fetchPaList = () => {
        setLoading(true)
        getPAFilter(props)
            .then(
                (res) => {
                    let tempPaList = []
                    if (res.status === 200 || res.status === 201) {
                        res?.data?.data && res.data.data.map((paList) => {
                            tempPaList.push({ label: paList.pa, value: paList.pa })
                        })
                    }
                    setPaFilterData(tempPaList)
                }
            ).catch((err) => { setPaFilterData([]) }).finally(() => setLoading(false))
    }
    const fetchPsaList = () => {
        setPsaFilterData([])
        setPsaFilterVal(null)
        setLoading(true)
        let params = {}
        paFilterVal !== null && (params.pa = paFilterVal.value)
        getPSAFilter(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        let tempPsaList = [{ label: "ALL", value: "ALL" }]
                        res?.data?.data && res.data.data.map((psaList) => { tempPsaList.push({ label: psaList.psa, value: psaList.psa }) })
                        setPsaFilterData(tempPsaList)
                    }
                    else {
                        setPsaFilterData([])
                    }
                }
            ).catch((err) => { setPsaFilterData([]) }).finally(() => setLoading(false))
    }
    const fetchGradeGrpList = () => {
        setGradeGrpFilterData([])
        setGradeFilterVal(null)
        setLoading(true)
        let params = {}
        paFilterVal !== null && (params.pa = paFilterVal.value)
        companyVal !== null && (params.comp_code = companyVal.value)
        getGradeGroupFilter(params, props)
            .then(
                (res) => {
                    let tempPsaList = []
                    if (res.status === 200 || res.status === 201) {
                        res?.data?.data && res.data.data.map((gradeGrp) => { tempPsaList.push({ label: gradeGrp.grp_name, value: gradeGrp.grp_name }) })
                    }
                    setGradeGrpFilterData(tempPsaList)
                }
            ).catch((err) => { setGradeGrpFilterData([]) }).finally(() => setLoading(false))
    }
    const fetchAgencyList = () => {
        setLoading(true)
        getAgencyFilter(props)
            .then(
                (res) => {
                    let tempPsaList = []
                    if (res.status === 200 || res.status === 201) {
                        res?.data?.data && res.data.data.map((agency) => { tempPsaList.push({ label: agency.name, value: agency.id }) })
                    }
                    setAgencyFilterData(tempPsaList)
                }
            ).catch((err) => { setAgencyFilterData([]) }).finally(() => setLoading(false))
    }
    const fetchCompanyList = () => {
        setLoading(true)
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service').then((res) => {
            if (res.data.status_code === "200") {
                let TempCompList = []
                res.data.company_list.map((company) => { TempCompList.push({ label: company.company_sht_txt, value: company.company_code }) })
                setCompanyList(TempCompList)
            }
        }).catch((err) => { setCompanyList([]) }).finally(() => setLoading(false))
    }
    const handleClearFilter = () => {
        setCompanyVal(null)
        setPaFilterVal(null)
        setPsaFilterVal(null)
        setGradeFilterVal(null)
        setAgencyFilterVal(null)
        setDomainID(null)
        setSearchText(null)
        // handleMasterApiCall()
        // setGradeGrpFilterData([])
        // setPsaFilterData([])
        // setIsFilter(false)
    }
    const handlePaFilter = (data) => {
        if (!(masterFormFilter.value == "2" && masterFormFilter.value == "4")) {
            setPaFilterVal(data);
        }
    }
    const handlePsaFilter = (data) => { setPsaFilterVal(data) }
    const handleCompanyFilter = (data) => { setCompanyVal(data) }
    const handleAgencyFilter = (data) => { setAgencyFilterVal(data) }
    const handleGradeFilter = (data) => { setGradeFilterVal(data) }
    const searchFilterFunction = () => {
        setDomainID(searchText)
        // fetchSpocConfigList(1, searchText)
    }
    const onClickFilter = () => { setIsFilter(!isFilter) }
    const handleMasterApiCall = () => {
        if (masterFormFilter?.value == "0") { fetchSpocMasterList(1) }
        else if (masterFormFilter?.value == "1") { fetchSpocConfigList(1) }
        else if (masterFormFilter?.value == "2") { fetchAgencyMaster(1) }
        else if (masterFormFilter?.value == "3") { fetchAgencyConfig(1) }
        else if (masterFormFilter?.value == "4") { fetchEsgGrpMapping(1) }
        else { setTableData([]) }
    }

    const exportMasterReport = () => {
        setLoading(true);
        exportMasterMaintenceReport()
          .then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                toaster("success", res?.data?.message ? res.data.message : "")
            } else {
                toaster("warning", res?.data?.message ? res.data.message : "");
            }
          })
          .catch((error) => {
            console.log("error in post export Master Report API", error);
            error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
          })
          .finally(() => setLoading(false));
    };

    const handleAllContact = (val) => {
        setIsAllContactVisible(!val);
    };

    const handleAllEmail = (val) => {
        setIsAllEmailVisible(!val);
    }

    useEffect(() => {
        setTableData((prevData) =>
            prevData.map((val, index) => {
                const updatedVal = { 
                    ...val, 
                    isMobileUnmasked: isAllContactVisible,
                    isEmailUnmasked: isAllEmailVisible,
                    mobile_no: isAllContactVisible ? originalData[index].mobile_no : maskMobile(val.mobile_no),
                    email: isAllEmailVisible ? originalData[index].email : maskEmail(val.email)
                };
                return updatedVal;
            })
        );
    }, [isAllContactVisible, isAllEmailVisible]);
    

    return (
        <div>
            {
                masterFormFilter ?
                    <div className='flex-direction-column'>
                        <LoaderIndicator loading={loading} />
                        {/* ------------------------------------------------------------ Filters ------------------------------------------------------------------------ */}
                        <div className='row align-item-center justify-content-end background-highlighter gap-10px'>
                            <div className='flex-direction-row gap-10px align-item-center'>
                                <div className='p-sub-heading ml-2'>{"Select Master Form"}</div>
                                <div className='d-flex align-item-center'></div>
                                <DynamicDropdown
                                    data={masterFormFilterData}
                                    render="title"
                                    value={masterFormFilter.title}
                                    selectedValue={(data) => handleMasterFilter(data)}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                    width={"w-14rem"}
                                />
                            </div>
                            {masterFormFilter?.value =='1' && 
                                <div className='row align-items-center gap-10px'>
                                    <CommonButton
                                        label={exportMaster}
                                        onClick={() =>  exportMasterReport()}
                                        isGradientBtn={true}
                                    />
                                </div>
                            }
                            {
                                <div className='row align-items-center gap-10px'>
                                    <CommonButton
                                        label={masterLabels[masterFormFilter.value].addBtn}
                                        onClick={() => setAddPopUp(true)}
                                        isGradientBtn={true}
                                    />
                                </div>
                            }
                            {
                                masterFormFilter.value != 2 &&
                                <div data-tooltip2={'Filters'} className="filterIconLabel infoIconStyle ml-1 mt-5px" >
                                    <div className='row mt-1' onClick={onClickFilter}>
                                        <div className='mr-4'><img alt="radioOn" className='filterIcon' src={isFilter ? filterIconActive : filterIconInactive} ></img></div>
                                    </div>
                                </div>
                            }
                            {
                                isFilter &&
                                <div className='flex-row-reverse justify-content-between flex-basis-100 align-items-center filter-top-border'>
                                    {/* <div className='row align-items-center purple-color performance-timeline-text-bold pointer' onClick={() => { handleClearFilter() }}>Clear Filters</div> */}
                                    <input type='button' onClick={handleClearFilter} value='CLEAR ALL' className='cancelBtn' />
                                    <div className='row justify-content-start gap-10px'>
                                        {
                                            (masterFormFilter.value == 1 || masterFormFilter.value == 0) &&
                                            <div className='flex-direction-row gap-10px align-item-center'>
                                                <div className='row'>
                                                    <div className='search-box-clearance'>
                                                        <div className='p-placeholder-text'>
                                                            <input
                                                                className="form-control search-box-input"
                                                                type="text"
                                                                placeholder='Enter domain id'
                                                                onChange={(e) => setSearchText(e.target.value)}
                                                                value={searchText ?? ""}
                                                            />
                                                        </div>
                                                        <div type='button' className='search-box-icon' onClick={() => searchFilterFunction()}
                                                        ><div className='mr-2'><img alt="radioOn" className='filterIcon' src={searchIcon} ></img></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (masterFormFilter.value == 1 || masterFormFilter.value == 3) &&
                                            <div className='flex-direction-row gap-10px align-item-center'>
                                                <div className='p-sub-heading ml-2'>{"Select Agency"}</div>
                                                <div className='d-flex align-item-center'></div>
                                                <LargeDropdown
                                                    data={agencyFilterData}
                                                    value={agencyFilterVal ?? "Select Agency"}
                                                    selectedValue={handleAgencyFilter}
                                                    noOptionFound={"No Data Found"}
                                                />
                                            </div>}
                                        {
                                            (masterFormFilter.value != 0 && masterFormFilter.value != 2) &&
                                            <div className='flex-direction-row gap-10px align-item-center'>
                                                <div className='p-sub-heading ml-2'>{"Select Company"}</div>
                                                <div className='d-flex align-item-center'></div>
                                                <LargeDropdown
                                                    data={companyList}
                                                    value={companyVal ?? "Select Company"}
                                                    selectedValue={handleCompanyFilter}
                                                    noOptionFound={"No Data Found"}
                                                />
                                            </div>}
                                        {
                                            (masterFormFilter.value != 0 && masterFormFilter.value != 2) &&
                                            <div className='flex-direction-row gap-10px align-item-center'>
                                                <div className='p-sub-heading ml-2'>{"Select PA"}</div>
                                                <div className='d-flex align-item-center'></div>
                                                <LargeDropdown
                                                    data={paFilterData}
                                                    value={paFilterVal ?? "Select PA"}
                                                    selectedValue={handlePaFilter}
                                                    noOptionFound={"No Data Found"}
                                                />
                                            </div>}
                                        {
                                            (masterFormFilter.value == 1 || masterFormFilter.value == 3) &&
                                            <div className='flex-direction-row gap-10px align-item-center'>
                                                <div className='p-sub-heading ml-2'>{"Select PSA"}</div>
                                                <div className='d-flex align-item-center'></div>
                                                <LargeDropdown
                                                    data={psaFilterData}
                                                    value={psaFilterVal ?? "Select PSA"}
                                                    selectedValue={handlePsaFilter}
                                                    noOptionFound={"No Data Found"}
                                                />
                                            </div>}
                                        {
                                            (masterFormFilter.value == 1 || masterFormFilter.value == 3) &&
                                            <div className='flex-direction-row gap-10px align-item-center'>
                                                <div className='p-sub-heading ml-2'>{"Select Grade Group"}</div>
                                                <div className='d-flex align-item-center'></div>
                                                <LargeDropdown
                                                    data={gradeGrpFilterData}
                                                    value={gradeFilterVal ?? "Select Grade Group"}
                                                    selectedValue={handleGradeFilter}
                                                    noOptionFound={"No Data Found"}
                                                />
                                            </div>}

                                    </div>
                                </div>
                            }
                        </div>
                        {/* ------------------------------------------------------------ Table -------------------------------------------------------------------------- */}
                        { 
                            tableData && tableData.length > 0 ?
                                <div className={'flex-direction-column' + (isFilter ? ' h-40vh' : ' h-57vh') + ' scroll-auto mt-10px'}>
                                    <ClearanceTable
                                        data={tableData}
                                        columns={masterLabels[masterFormFilter.value].fields}
                                        handleAction={handleViewAction} //set is edit true
                                        hasPrimaryAction={!(masterFormFilter.value == 3 || masterFormFilter.value == 4 || masterFormFilter.value == 5)}
                                        hasSecondaryAction={true}
                                        hasThirdAction={(masterFormFilter.title === "SPOC Master") ? true : false} 
                                        hasAction={true}
                                        handleSecondaryAction={handleSecondaryAction} //for popup open
                                        handleThirdAction={handleThirdAction}
                                        currentPage={currentPage}
                                        pageSize={10}
                                        primaryActionImg={edit}
                                        secondaryActionImg={delete_action}
                                        isFilterOpen={isFilter}
                                        screenType={"Master Maintenance"}
                                        isAllEmailVisible = {isAllEmailVisible}
                                        isAllContactVisible = {isAllContactVisible}
                                        handleAllContact = {handleAllContact}
                                        handleAllEmail = {handleAllEmail}
                                    />
                                </div>
                                :
                                <div className='mt-5 h-90-percent'>
                                    <DataNotFound />
                                </div>
                        }
                        {/* ------------------------------------------------------------ Popups ------------------------------------------------------------------------- */}
                        {
                            addPopUp &&
                            <MasterPopup
                                closePopUp={onCloseMasterPopup}
                                masterOption={masterFormFilter.value}
                                loading={loading}
                                title={editMode ? "Edit master data" : "Add new master data"}
                                totalData={tableData.length}
                                editMode={editMode}
                                selectedRecord={tableData.length > 0 ? originalData[selectedRecord] : null}
                            />
                        }
                        {/* ------------------------------------------------------------- Delete Confirmation ----------------------------------------------------------- */}
                        {
                            isConfirmPopupOpen &&
                            <ConfirmationPopUp
                                onDismiss={() => { setIsConfirmPopupOpen(false) }}
                                title={"Confirmation"}
                                loading={false}
                                onConfirmAction={() => { onDelete(); setIsConfirmPopupOpen(false); setEditMode(false); setSelectedRecord(null); }}
                                message={masterLabels[masterFormFilter.value].deleteMsg} />
                        }
                        {tableData.length > 0 &&
                            <div className="col-md-12 w-100 d-flex justify-content-center paginator">
                                <Pagination>
                                    {currentPage != 1 &&
                                        <>
                                            <Pagination.Prev
                                                onClick={() => { handlePageCount("decrement") }} />
                                            <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                                            <Pagination.Ellipsis disabled />
                                        </>
                                    }
                                    <Pagination.Item active>{currentPage}</Pagination.Item>
                                    {currentPage != totalPages &&
                                        <>
                                            <Pagination.Ellipsis disabled />
                                            <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                                            <Pagination.Next
                                                onClick={() => { handlePageCount("increment") }} />
                                        </>
                                    }
                                </Pagination>
                            </div>
                        }
                    </div>
                    :
                    <div className='flex-direction-column h-70vh justify-content-center align-item-center'>
                        You don't have access for master maintenance.
                    </div>
            }
        </div>
    )
}

export default MasterMaintenance