import React, { Component } from 'react';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, fetchApiCallWithService, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import { configStore } from "../../../../../redux/ConfigStore";
import { gemsMaintenanceModule, toaster } from '../../../../../utils/Utils';
import radioOff from '../../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../../assets/drishti_images/radioOn.png';
import plus from '../../../../../assets/drishti_images/manager/landing/plus.svg';
import MaintenanceDashboard from './dashboard/MaintenanceDashboard';
import Dropdown from '../../../../shared/components/Dropdown';
import AddPopups from './popupModel/AddPopups';
import ConfirmFormPopup from './popupModel/confirmFormPopup';
import { toast } from 'react-toastify';
import { GEMSMaintenanceConstant } from './gemsMaintenanceConstant';
import './gemsMaintainance.css'
import { deleteGemsMaintenance, fetchGemsMaintenanceList } from '../../../employeeScreens/gems/Gems.api.service';
import UpdateFormPopUp from './popupModel/UpdateFormPopUp';
import NotificationConfirmPopup from '../../adminNotificationHome/NotificationConfirmPopup';
class GEMSMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,


            radioFilters: gemsMaintenanceModule(),
            filteredValue: { key: 'Area', value: 'Area', endPoint: 'Area' },
            addFormDropDownValue: { key: '', value: '', endpoint: '' },
            isOpenFirstPopUp: false,
            isOpenNextPopUp: '',
            gemsMaintenanceData: "",
            updateFormData: {},
            isFormUpdatePopup: false,
            isDeletePopup: false
        }
    }
    componentDidMount() {
        this.getFilterValueData()
    }

    changeFilterValue = (data) => {
        this.setState({
            filteredValue: data,
        },
            () => this.getFilterValueData()
        )
    }

    getFilterValueData = () => {
        this.setState({
            loading: true
        })
        fetchGemsMaintenanceList(this.state.filteredValue.endPoint)
            .then(res => {
                sessionOut(this.props, res);
                if (res.status == '200') {
                    this.setState({
                        gemsMaintenanceData: res.data.data,
                        loading: false,
                        message: '',
                        total_page: res.data.total_page
                    })
                } else {
                    this.setState({
                        loading: false,
                        gemsMaintenanceData: [],
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }
    addForm = (data) => {
        this.setState({
            isOpenFirstPopUp: true
        },)
    }

    deletAdmineData = () => {
        this.setState({
            loading: true
        })
        deleteGemsMaintenance(this.state.filteredValue.endPoint, this.state.updateFormData.id)
            .then(res => {
                if (res.status == '200' || res.status == '201') {
                    this.setState({
                        loading: false,
                        message: '',
                    })
                    this.closeDeletePopUp()
                    toaster("success", res.data.message ? res.data.message : "deleted successfully")

                } else {
                    this.setState({
                        loading: false,
                        message: res.data.message
                    });
                    toaster("warning", res.data.message ? res.data.message : "Something went wrong")

                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toaster("", err.data.message ? err.data.message : "Something went wrong")
            })
    }
    addForm = (data) => {
        this.setState({
            isOpenFirstPopUp: true
        },)
    }

    openFirstPopUp = () => {
        this.setState({
            isOpenNextPopUp: false,
            isOpenFirstPopUp: true,
            addFormDropDownValue: { key: '', value: '', endPoint: '' }
        })
    }

    closeFirstPopup = () => {
        this.setState({
            isOpenNextPopUp: false,
            isOpenFirstPopUp: false,
            addFormDropDownValue: { key: '', value: '', endPoint: '' }

        })
    }

    setDropdown = (data) => {
        this.setState({
            addFormDropDownValue: data
        })
    }

    validate = () => {
        if (this.state.addFormDropDownValue.key === '') {
            toast.warning('Please select notification type', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.setState({
                isOpenNextPopUp: true,
                isOpenFirstPopUp: false
            })
        }
    }

    AddValues = () => {
        this.changeFilterValue(this.state.addFormDropDownValue)
        this.closeFirstPopup()
    }

    closeUpdatePopUp = () => {
        this.changeFilterValue(this.state.filteredValue)
        this.setState({
            isFormUpdatePopup: false
        })
    }

    closeDeletePopUp = () => {
        this.changeFilterValue(this.state.filteredValue)
        this.setState({
            isDeletePopup: false
        })
    }

    onAction = (listData, type) => {
        if (type === 'update') {
            this.setState({
                isFormUpdatePopup: true,
                updateFormData: listData
            })
        }
        else if (type === 'delete') {
            this.setState({
                isDeletePopup: true,
                updateFormData: listData
            })
        }
        else {
            this.setState({
                doReject: true,
                // updateFormData: listData
            })
        }
    }

    dismisUpdateForm = () => {
        this.setState({
            isFormUpdatePopup: false,
        })
    }

    render() {
        const { loading, isOpenFirstPopUp, isOpenNextPopUp, filteredValue, isFormUpdatePopup, isDeletePopup } = this.state;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                <div className="px-0 pt-0">
                    <div className='justify-content-between align-item-center mt-1'>
                        <div className='route-common-heading ml-3'>
                            {GEMSMaintenanceConstant.GEMSMaintenance}
                        </div>
                        <div className='justify-content-end align-item-center'>
                            <div className='ml-2 dropdown-Text'>
                                <Dropdown
                                    data={this.state.radioFilters}
                                    selectedValue={(data) => this.changeFilterValue(data)}
                                    value={filteredValue.value}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'dependOnContent'}
                                />
                            </div>
                            <a
                                type="button"
                                className=' btn-primary common-btn common-button-text text-uppercase d-flex justify-content-center w-13rem'
                                onClick={() => this.addForm(filteredValue)}
                            >
                                <img alt="plus" src={plus} className=" mx-1"></img>
                                {GEMSMaintenanceConstant.AddForm}
                            </a>
                        </div>
                    </div>

                    <div className='row mt-2'>
                        {/* <div className='card-new bg-white mt-1 ml-1 mr-1 w-100 h-100'> */}

                        {/* <div className='hz-lines mr-4 ml-4'></div> */}

                        <div className='profile-inner-card w-100'>
                            {filteredValue &&
                                <MaintenanceDashboard
                                    tabName={filteredValue}
                                    gemsMaintenanceData={this.state.gemsMaintenanceData}
                                    onAction={this.onAction}
                                />
                            }
                        </div>
                        {/* </div> */}
                    </div>
                    {isOpenFirstPopUp &&
                        <AddPopups
                            loading={this.state.loading}
                            closeFirstPopup={this.closeFirstPopup}
                            dropdownValue={(data) => this.setDropdown(data)}
                            validate={this.validate}
                            addFormDropDownValue={this.state.addFormDropDownValue}
                        />
                    }

                    {isOpenNextPopUp &&
                        <ConfirmFormPopup
                            loading={this.state.loading}
                            onDismiss={this.closeFirstPopup}
                            openFirstPopUp={this.openFirstPopUp}
                            AddValues={this.AddValues}
                            addFormDropDownValue={this.state.addFormDropDownValue}

                        />}

                    {isFormUpdatePopup &&
                        <UpdateFormPopUp
                            loading={this.state.loading}
                            updateFormData={this.state.updateFormData}
                            filteredValue={this.state.filteredValue}
                            dismisUpdateForm={this.dismisUpdateForm}
                            closeUpdatePopUp={this.closeUpdatePopUp}
                        />
                    }
                    {isDeletePopup &&
                        <NotificationConfirmPopup
                            title={`Delete ${filteredValue.value}`}
                            action={'deleteGemsAdminData'}
                            loader={this.state.loading}
                            content={`Are you sure you want to delete ${filteredValue.value}?`}
                            deleteGemsAdminData={() => this.deletAdmineData()}
                            onDismiss={() => this.setState({ isDeletePopup: false })}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(GEMSMaintenance);