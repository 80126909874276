import React, { Component } from 'react';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import edit from '../../../../../assets/images/cfm/edit.svg';
import { validatePassportnumber } from '../../../../../utils/Utils';
import DeclarationPopup from '../../profile/DeclarationPopup'
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import { maskMobile, maskEmail, maskBank,maskUAN,maskPassport } from '../../../../shared/commonMaskFunction';
import eye from '../../../../../assets/drishti_images/eye.svg';
import eyeslash from '../../../../../assets/drishti_images/eye-slash.svg';

const requestFailed = 'Unable to contact server\nPlease try again later'

class Passport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            loading: false,
            popupVisibility: false,
            emp_id: '',
            begining_date: '',
            end_date: '',
            issue_date: '',
            id_expiry_date: '',
            country: '',
            country_code: '',
            countryText: '',
            countryData: [],
            account_number: '',
            isPassportUnmasked: false,

        };
    }
    componentDidMount() {
        this.getCountryData()
        this.getPassportDetails(this.props.loginData.userData.Perno);
    }
    getCountryData() {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCountrydata, {}, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        countryData: res.data.data,
                        loading: false
                    });
                    const countryArray = this.state.countryData.map((item, idx) => ({
                        ...item,
                        label: item.country_text
                    }))
                    this.setState({ countryData: countryArray })
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    getPassportDetails(empID) {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: empID
        }
        fetchApiCall(Config.getPassportDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        emp_id: res.data.passport_details.emp_id ? res.data.passport_details.emp_id : '',
                        begining_date: res.data.passport_details.begin_date ? res.data.passport_details.begin_date : '',
                        end_date: res.data.passport_details.end_date ? res.data.passport_details.end_date : '',
                        issue_date: res.data.passport_details.issue_date ? res.data.passport_details.issue_date : '',
                        id_expiry_date: res.data.passport_details.issue_expiry_date ? res.data.passport_details.issue_expiry_date : '',
                        country: res.data.country.country_text ? res.data.country.country_text : '',
                        countryText: res.data.country.country_text ? res.data.country.country_text : '',
                        country_code: res.data.country.country_code ? res.data.country.country_code : '',
                        account_number: res.data.passport_details.account_number ? res.data.passport_details.account_number : '',
                        isPassportUnmasked: false,
                    });

                } else {
                    this.setState({
                        loading: false,
                        isPassportUnmasked: false,
                    });
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    validateData = () => {
        var IssueDate = new Date(this.state.issue_date).setHours(0, 0, 0, 0);
        var ExpiryDate = new Date(this.state.id_expiry_date).setHours(0, 0, 0, 0);
        var currentDate = new Date().setHours(0, 0, 0, 0);

        if (this.state.account_number === '' || this.state.account_number.length < 9) {
            toast.error('Please Enter Valid Passport Number', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.account_number === '' || this.state.country_code === '' || this.state.issue_date === '' || this.state.id_expiry_date === '') {
            toast.error('All details are required to update', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (IssueDate > currentDate) {
            toast.error('Issue Date can not exceed current Date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }

        else if (ExpiryDate < IssueDate) {
            toast.error('Issue Date can not exceed ID Expiry Date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else { this.setState({ popupVisibility: true }) }
    }
    updatePassportDetails = () => {
        this.setState({ loading: true, popupVisibility: false })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            begin_date: this.state.begining_date ? this.state.begining_date : '',
            end_date: this.state.end_date ? this.state.end_date : '',
            account_number: this.state.account_number,
            issue_date: this.state.issue_date,
            issue_expiry_date: this.state.id_expiry_date,
            country: this.state.country_code
        }
        fetchApiCall(Config.updatePassportDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        isEdit: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getPassportDetails(this.props.loginData.userData.Perno);
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    setCountry = (data) => {
        this.setState({
            countryText: data.country_text,
            country_code: data.country_code
        })
    }
    render() {
        const { isEdit } = this.state
        return (
            <div>
                <div className='row justify-content-between'>
                    <div className='p-heading mx-4 mt-3 mb-2'>Passport Details </div>
                    {!isEdit ?
                        <div className='mt-2 mr-3 pointer'>
                            <img
                                alt="edit"
                                src={edit}
                                className="plus-img"
                                onClick={() => this.setState({ isEdit: true })}
                            ></img>
                        </div> : null
                    }
                </div>
                <div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Country</div>
                            <div className='p-placeholder-text'>
                                <LargeDropdown
                                    data={this.state.countryData}
                                    value={this.state.countryText}
                                    selectedValue={(data) => this.setCountry(data)}
                                    disabled={!isEdit}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Passport Number</div>
                            <div className='p-placeholder-text p-placeholder contact-field'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='address'
                                    maxLength={9}
                                    value={isEdit ? this.state.account_number : this.state.isPassportUnmasked ? (this.state.account_number ?? '') :  maskPassport(this.state.account_number)}
                                    readOnly={!isEdit}
                                    onChange={(e) => {
                                        this.setState({ account_number: e.target.value ? validatePassportnumber(e.target.value) : '' });
                                    }}
                                    />
                                                                                                    
                                    {
                                        !isEdit &&
                                        <div 
                                        onClick={() => this.setState({ isPassportUnmasked : !this.state.isPassportUnmasked })} // Toggle between true/false for mask/unmask
                                            className='email-feild'>
                                            <img
                                                alt="eyeimage"
                                                src={ this.state.isPassportUnmasked ? eye : eyeslash }
                                            />
                                        </div>
                                    }
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Issue Date</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="date"
                                    name='country'
                                    readOnly={!isEdit}
                                    value={this.state.issue_date}
                                    onChange={(e) => this.setState({ issue_date: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>ID Expiry Date</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="date"
                                    name='date of expiry'
                                    value={this.state.id_expiry_date}
                                    readOnly={!isEdit}
                                    onChange={(e) => this.setState({ id_expiry_date: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    {isEdit &&
                        <div className='row justify-content-end mb-3'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() =>{ 
                                        this.getPassportDetails(this.props.loginData.userData.Perno);
                                        this.setState({ isEdit: false })
                                }}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={this.validateData}
                                >Save</div>
                            </div>
                        </div>}
                    <div className='hz-lines mt-4 mb-2 mr-2 ml-2'></div>
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.updatePassportDetails}
                    />
                }
            </div>
        );
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
export default connect(mapStatesToProps)(Passport);
