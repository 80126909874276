import React, { Component } from 'react';
import radioOn from '../../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../../assets/images/applyLeave/radioOff.png';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import TransferRequestPopups from '../TransferRequestPopups';

toast.configure();
class ApplyPhase1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            screenwidth: 0,
            screenheight: 0,
            swipeKey: 0,
            swapData: [{ value: 'No' }, { value: 'Yes' }],
            isSearch: true,
            isEmployeeInformation: false,
            showEmployeeInformation: false,
            isSwap: 'No',
            // checked: 0,
            transfer_emp_name: '',

            fillPosition: false,
            checked: "No",
            emp_id: '',
            employee_name: '',
            pa: '',
            ou: '',
            ou_code: '',
            ou1: '',
            ou1_code: '',
            ou2: '',
            ou2_code: '',
            ou3: '',
            ou3_code: '',
            function: '',
            band: '',
            psa: '',
            sub_function_1: '',
            position_level: '',
            position_code: '',
            dem: '',
            level: '',
            current_bhr: '',
            current_location: '',

            position_bhr: '',
            position_bhr_name: '',
            transfer_date: '',
            reporting_manager: '',
            reporting_manager_name: '',
            ujr: '',         //unique job role
            level_for_new_position: '',
            job_code: '',
            position_code_for_new_position: '',
            job_post: '',
            sales_incentive: '',
            vacant: '',
            fem: '',
            fem_name: '',
            ou_for_new_position: '',
            ou1_for_new_position: '',
            ou2_for_new_position: '',
            ou3_for_new_position: '',
            istrue: false,
        }
    }

    loadData = (empID) => {
        if (empID === '') {
            toast.warn('Please enter employee ID', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else {
            this.getEmployeeTransferData(empID)
        }
    }

    validateTransfer = () => {
        var transferDate = new Date(this.state.transfer_date).setHours(0, 0, 0, 0);
        var currentDate = new Date().setHours(0, 0, 0, 0);
        if (this.state.position_code_updated === '') {
            toast.error("Please enter position code",
                {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
        }
        else if (this.state.transfer_date === '') {
            toast.error("Please enter transfer date",
                {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
        }
        else if (transferDate < currentDate) {
            toast.error("Transfer could not be in past date",
                {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
        }
        else {
            this.getPositionDataTransfer()
        }
    }

    onDismiss = () => {
        this.setState({ isSwap: 'No' })
    }

    getEmployeeTransferData = (empID) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: empID,
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : ''
        }
        fetchApiCall(Config.getCetDataTransfer, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        isSearch: false,
                        isEmployeeInformation: true,
                        showEmployeeInformation: true,
                        fillPosition: true,
                        transferRequestData: res.data.user_details ? res.data.user_details : [],
                        current_bhr: res.data.user_details.approving_bhr ? res.data.user_details.approving_bhr : '',
                        transfer_emp_name: res.data.user_details.CompName,
                    },
                        () => this.updateData(this.state.transferRequestData)
                    );
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                        transferRequestData: []
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    updateData = (empData) => {
        let data = empData
        this.setState({
            emp_id: data.Perno ? data.Perno : '',
            employee_name: data.Firstname ? data.Firstname : '',
            pa: data.Patxt ? data.Patxt : '',
            psa: data.Psatxt ? data.Psatxt : '',
            ou: data.Orgtx ? data.Orgtx : '',
            ou_code: data.Orgeh ? data.Orgeh : '',
            ou1: data.OuLevel1LongTxt ? data.OuLevel1LongTxt : '',
            ou1_code: data.OuLevel1 ? data.OuLevel1 : '',
            ou2: data.OuLevel2LongTxt ? data.OuLevel2LongTxt : '',
            ou2_code: data.OuLevel1 ? data.OuLevel2 : '',
            ou3: data.OuLevel3LongTxt ? data.OuLevel3LongTxt : '',
            ou3_code: data.OuLevel3 ? data.OuLevel3 : '',
            function: data.FunctionText ? data.FunctionText : '',
            band: data.Band ? data.Band : '',
            sub_function_1: data.Subfunction1Tex ? data.Subfunction1Tex : '',
            position_level: data.Postxt ? data.Postxt : '',
            position_code: data.Position1 ? data.Position1 : '',
            dem: data.Nameofreporting ? data.Nameofreporting : '',
            current_bhr: data.approving_bhr ? data.approving_bhr : '',
            current_location: data.LocDesc ? data.LocDesc : '',
            level: data.Esgtxt ? data.Esgtxt : ''
        })
    }

    confirmPositionData = () => {
        this.setState({ checked: !this.state.checked })
    }

    getPositionDataTransfer = () => {
        let body = {
            position_code: this.state.position_code_updated,
            transfer_date: this.state.transfer_date,
            transfer_emp_id: this.state.emp_id,
            logged_in_emp_id: this.props.loginData.userData.Perno,
            swap: this.state.isSwap
        }
        this.props.getPositionDataTransfer(
            body,
            this.state.emp_id,
            this.state.transfer_emp_name,
            this.state.current_bhr
        )
    }
    defaultNo = () => {
        this.setState({
            istrue: false
        })
    }

    handleChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
            this.setState({ emp_id: e.target.value })
        } 
    }

    render() {
        return (
            <div className="justify-content-between">
                <LoaderIndicator loading={this.props.loading} />
                <div className="col-md-12 px-0" >
                    <div className='row'>
                        <div className='col-4 mb-3 mt-3'>
                            <div className='p-sub-heading ml-3'>Enter the employee ID here</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    placeholder="Enter Employee ID"
                                    type="text"
                                    maxLength={8}
                                    onChange={(e) => this.handleChange(e)}
                                    readOnly={!this.state.isSearch}
                                    value={this.state.emp_id}
                                />
                            </div>
                        </div>
                        {this.state.isSearch &&
                        <div className='mb-3 mt-3 d-flex align-items-flex-end'>
                            <button
                                type='button'
                                className={this.state.emp_id.length >= 6 ? 'common-save-btn save-txt btn ' : 'common-disable-btn save-txt btn'}
                                onClick={() => this.loadData(this.state.emp_id)}
                                disabled={this.state.emp_id.length < 6} 
                            >SEARCH
                            </button>
                        </div>
                        }
                    </div>
                    {this.state.showEmployeeInformation &&
                        <div>
                            <hr />
                            <div>
                                <div className='row justify-content-between'>
                                    <div className='p-heading mx-4 mt-2 mb-2'>Check employee details</div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Employee Ticket No.</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.emp_id}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Employee Name</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.employee_name}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Position Code</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.position_code}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Position Level</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.position_level}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Personnel Area</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.pa}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Personnel Subarea</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.psa}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Immediate Org Unit Text</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.ou + ' (' + this.state.ou_code + ')'}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>OUI1 Text</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.ou1 + ' (' + this.state.ou1_code + ')'}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>OUI2 Text</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.ou2 + ' (' + this.state.ou2_code + ')'}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>OUI3 Text</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.ou3 + ' (' + this.state.ou3_code + ')'}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Current Location</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.current_location}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>DEM</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.dem}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Current BHR</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.current_bhr}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Level</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.level}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Band</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.band}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Function</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.function}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Sub function</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.sub_function_1}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.fillPosition &&
                        <div>
                            <hr />
                            <div>
                                <div className='row'>
                                    <div className='p-heading mx-4 mt-2 mb-2'>Enter employee transfer details</div>
                                </div>
                                <div className='row'>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Position Code</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                value={this.state.position_code_updated}
                                                onChange={(e) => this.setState({ position_code_updated: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-4 mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Transfer Date</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="date"
                                                onChange={(e) => this.setState({ transfer_date: e.target.value })}
                                                value={this.state.transfer_date}
                                            />
                                        </div>
                                    </div>
                                    <div className='box col-4 mb-3 mt-2'>
                                        <div className=''>
                                            <label className='p-sub-heading ml-3'>Swap</label>
                                            <div className='d-flex mt-1 row'>
                                                {this.state.swapData.map((list, key) => {
                                                    return (
                                                        <div className='row' key={key}>
                                                            <div onClick={() => this.setState({ swipeKey: key, isSwap: list.value, })} className='mx-2'>
                                                                <img alt="radioOn" className='radio' src={this.state.swipeKey === key ? radioOn : radioOff} />
                                                                <label className="radioTxt">{list.value}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row justify-content-end'>
                                    <div className='justify-content-between'>
                                        <div
                                            type='button'
                                            className='cancl-btn cancel-txt'
                                            onClick={() => this.setState({ showEmployeeInformation: false, fillPosition: false, isSearch: true })}
                                        >Cancel</div>
                                        <div
                                            type='button'
                                            className='save-btn save-txt'
                                            onClick={() => this.validateTransfer()}
                                        >Validate</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.isSwap === 'Yes' &&
                        <TransferRequestPopups
                            loader={this.props.loading}
                            onDismiss={this.onDismiss}
                            submit={this.validateTransfer}
                            oncancel={this.defaultNo}
                        />
                    }
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(ApplyPhase1);