import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import Dropdown from '../../../shared/components/Dropdown';
import { exportTOExcel } from './constants';
import * as Config from '../../../../constants/Config';
import { fetchApiCall } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import ViewIcon from '../../../../assets/drishti_images/view.svg'
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { bdcHistoryColumns as columns, tdStyle } from './constants';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import { getClaimBDC, getClaimBDCGenerate } from '../../../../constants/api.service';
import { toaster } from '../../../../utils/Utils';
import moment from 'moment/moment';
import ExpenseDetailsPopUp from './ExpenseDetailsPopUp';

function BDCHistory() {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [BDCGenrationDate, setBDCGenrationDate] = useState('')
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [companyValue, setCompanyValue] = useState(['TML', '0100']);
    const [isBDCPopup, setBDCPopup] = useState(false);
    const [generateBDC, setGenerateBDC] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSelectIndex, setPaginateDataIndex] = useState(0)
    const [searchButtonClicked, setSearchButtonClicked] = useState(true);
    const [isPaginatedClicked, setIsPaginatedClicked] = useState(false);

    useEffect(() => {
        getCompanyNames();
    }, []);

    useEffect(() => {
        if (searchButtonClicked) {
            fetchClaimDetails();
        }
    }, [searchButtonClicked]);

    const handleSearchButtonClick = (isGenerateBDC) => {
        setGenerateBDC(isGenerateBDC);
        setSearchButtonClicked(true);
    };

    const handlePageCount = (action) => {
        if (action === "decrement" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            handleSearchButtonClick()
        } else if (action === "increment" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            handleSearchButtonClick()
        }
    };
    const getCompanyNames = () => {
        setLoading(true)
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service')
            .then(res => {
                if (res?.data?.status_code == "200") {
                    let comp_codes = []
                    res.data?.company_list && res.data.company_list.map((comp) => {
                        comp_codes.push({ code: comp.company_code, value: comp.company_sht_txt })
                    })
                    setCompanyData(comp_codes)
                    setLoading(false)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const onGenerateBDCFile = (data) => {
        let params = {
            bdc_generated_date: data?.created_date,
            bdc_document_name: data?.bdc_document_name
        };

        getClaimBDCGenerate(params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    let exportBDCData = res?.data?.data.map((data, idx) => {
                        return {
                            "Claim No": data["claim_no"],
                            "Purpose Of Travel": data["purpose"],
                            "Start Date": data["claim_start_date"].replaceAll("-", "."),
                            "Report Download Date": data["report_download_date"].replaceAll("-", "."),
                            "Posting Code": data["posting_code"],
                            "GL / Emp ID": data["gl_emp_id"],
                            "Cost center": data["costcenter"],
                            "Amount": data["amount"],
                            "Blank": data["blank1"],
                            "Order No": data["order_no"],
                            "Unique No (700 & Claim ID)": data["unique_no"],
                            "Text (LOC/CLAIM id/Emp Id/Destination City)": data["text_1"],
                            "Payment Term": data["payment_term"],
                            "Blank 1": data["blank2"],
                            "Blank 2": data["blank3"],
                            "Employee Id": data["emp_id"]
                        }
                    })
                    let fileName = data.bdc_document_name

                    exportTOExcel(fileName, exportBDCData, '.csv');
                    setLoading(false);
                } else {
                    setLoading(false);
                    toaster("warning", res.data.message ? res.data.message : "");
                }
            })
            .catch((err) => {
                setLoading(false);
                toaster("warning", err.data.message ? err.data.message : "");
            });
        setSearchButtonClicked(false);
        setGenerateBDC(false)
    }

    const fetchClaimDetails = () => {
        setLoading(true);
        let params = {
            from_date: fromDate,
            to_date: toDate,
            comp_code: companyValue[1] ? companyValue[1] : [],
        };

        if (generateBDC !== true) {
            isPaginatedClicked == true ? (params["page_number"] = currentPage) : (params["page_number"] = 1);
        }

        getClaimBDC(params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    if (res.data.status === "401") {
                        setTotalPages(1);
                        setCurrentPage(1);
                        setPaginatedData([]);
                        setLoading(false);
                    } else {
                        setTotalPages(res?.data?.data?.total_pages);
                        setCurrentPage(res?.data?.data?.page_number);
                        setLoading(false)
                        if (generateBDC !== true) {
                            let newData = res?.data?.data?.paginated_data ? res?.data?.data?.paginated_data : [];
                            setPaginatedData(newData);
                            setLoading(false)
                        }
                    }
                } else {
                    setTotalPages(1);
                    setCurrentPage(1);
                    setPaginatedData([]);
                    setLoading(false);
                    toaster("warning", res.data.message ? res.data.message : "");
                }
            })
            .catch((err) => {
                setTotalPages(1);
                setCurrentPage(1);
                setPaginatedData([]);
                setLoading(false);
                toaster("warning", err.data.message ? err.data.message : "");
            });
        setSearchButtonClicked(false);
        setGenerateBDC(false)
    };

    return (
        <div>
            <LoaderIndicator loading={loading} />
            <div className='d-flex row justify-content-between background-highlighter'>
                <div className='row'>
                    <div className='ml-3'>
                        <div className='p-sub-heading ml-2 mb-1'>{'From Date'}</div>
                        <div className='row align-item-center'>
                            <div className='p-placeholder-text mb-1'>
                                <input
                                    className="form-control p-placeholder px-2 p-placeholder-admin"
                                    type="date"
                                    placeholder='Select the date'
                                    onChange={(e) => setFromDate(e.target.value)}
                                    value={fromDate}
                                    onKeyDown={(e) => e.preventDefault()}
                                // max={toDate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='ml-3'>
                        <div className='p-sub-heading mb-1 ml-2'>{'To Date'}</div>
                        <div className='row align-item-center'>
                            <div className='p-placeholder-text mb-1'>
                                <input
                                    className="form-control p-placeholder px-2 p-placeholder-admin"
                                    type="date"
                                    placeholder='Select the date'
                                    onChange={(e) => setToDate(e.target.value)}
                                    value={toDate}
                                    onKeyDown={(e) => e.preventDefault()}
                                // min={fromDate}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className='ml-3'>
                        <div className='p-sub-heading ml-2 mb-1'>{'BDC Generation Date'}</div>
                        <div className='row align-item-center'>
                            <div className='p-placeholder-text mb-1'>
                                <input
                                    className="form-control p-placeholder px-2 p-placeholder-admin"
                                    type="date"
                                    placeholder='Select the date'
                                    onChange={(e) => setBDCGenrationDate(e.target.value)}
                                    value={BDCGenrationDate}
                                // max={toDate}
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className='ml-3'>
                        <div className='p-sub-heading mb-1 ml-2'>{'Select Company'}</div>
                        <div className='column align-item-center'>
                            <div className='p-placeholder-text mb-1'>
                                <Dropdown
                                    data={companyData}
                                    selectedValue={(selectedItem) => { setCompanyValue([selectedItem.value, selectedItem.code]) }}
                                    value={companyValue[0]}
                                    render={'value'}
                                    arrow={true}
                                    height={'innerCard'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row align-item-center mt-4'>
                        <input
                            type="button"
                            value='SEARCH'
                            className=' validateBtn mb-1 ml-3'
                            onClick={() => { handleSearchButtonClick(); setIsPaginatedClicked(false) }} />
                    </div>
                </div>

                {/* <div className='row align-item-center'>
                    <input
                        type="button"
                        value='GENERATE BDC HISTORY'
                        className=' validateBtn mb-1'
                        onClick={() => handleSearchButtonClick(true)} />
                </div> */}

            </div>

            <div className='table-container ml-3 mt-10px summary-table'>
                {paginatedData.length > 0 ?
                    <div className="main-contain">
                        <div className="row align-item-center justify-content-start ">
                            <table className="table gemsAdmin">
                                <thead className='w-100'>
                                    <tr className='w-100'>
                                        {columns.map((col) => {
                                            return (
                                                <th width={col.width}><div className='thead'>{col.title}</div></th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody className='w-100'>
                                    {paginatedData.length > 0 && paginatedData.map((row, index) => {
                                        return (
                                            <tr>
                                                <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                <td width='10%'><div className={tdStyle}>{row.admin_name ? row.admin_name : '-'}</div></td>
                                                <td width='10%'><div className={tdStyle}>{row.bdc_document_name ? row.bdc_document_name : '-'}</div></td>
                                                <td width='10%'><div className={tdStyle}>{row.created_date ? moment(row.created_date).format('DD-MM-YYYY') : '-'}</div></td>
                                                <td width='10%'><div className={tdStyle}>{row.total_claims ? row.total_claims : '-'}</div></td>
                                                <td width='10%'><div className={tdStyle}>{row.total_claim_amount ? row.total_claim_amount : '-'}</div></td>
                                                <td width='5%'>
                                                    <span className='d-flex'>
                                                        <div onClick={() => onGenerateBDCFile(row)}>
                                                            <img alt="view" src={downloadLatestImg} style={{ width: '20px', cursor: 'pointer ' }} />
                                                        </div>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className='mt-5 h-90-percent'>
                        <DataNotFound />
                    </div>
                }
                {paginatedData.length > 0 &&
                    <div className="col-md-12 w-100 d-flex justify-content-center">
                        <Pagination>
                            <Pagination.Prev
                                onClick={() => { handlePageCount("decrement"); setIsPaginatedClicked(true) }} />
                            {currentPage != 1 &&
                                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                            }
                            <Pagination.Ellipsis disabled />
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            <Pagination.Ellipsis disabled />
                            {currentPage != totalPages &&
                                <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                            }
                            <Pagination.Next
                                onClick={() => { handlePageCount("increment"); setIsPaginatedClicked(true) }} />
                        </Pagination>
                    </div>
                }
            </div>

            {/* {isBDCPopup &&
                <ExpenseDetailsPopUp
                    action={'bdcPopupDetails'}
                    paginatedData={paginatedData[isSelectIndex]}
                    setBDCPopup={(bdcPopupDetails) => setBDCPopup(bdcPopupDetails)}
                />
            } */}
        </div>
    )
}

export default BDCHistory