import React from 'react'
import { useState } from 'react'
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import './LocalConveyanceAdmin.css';
import SummaryTable from './SummaryTable';
import { Pagination } from 'react-bootstrap';
import { applicantStatus, claimSummaryStatus } from './constants';
import Dropdown from '../../../shared/components/Dropdown'
import DynamicDropdown from '../../../shared/components/DynamicDropdown';

function LocalConveyanceSummary() {
  const [approvedChecked, setApprovedChecked] = useState({ id: "PENDING_BY_ADMIN", title: 'Pending for DEM approval' })
  const [empId, setEmpId] = useState("")
  const [claimId, setClaimId] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [exportSummary, setExportSummary] = useState(false)

  const handlePageCount = (action) => {
    if (action == "decrement" && selectedPage > 1) {
      setSelectedPage(selectedPage - 1)
    }
    else if (action == "increment" && selectedPage < totalPage) {
      setSelectedPage(selectedPage + 1)
    }
  }

  const onChangedApprovalStatus = (data) => {
    setSelectedPage(1)
    setApprovedChecked(data)
  }

  return (
    <div >

      {/* -------------------------------------------------- Filter View ------------------------------------------------------- */}
      <div className='d-flex row justify-content-between background-highlighter'>
        <div className='row'>
          <div className='ml-3'>
            <div className='p-sub-heading ml-2 mb-1'>{'From Date'}</div>
            <div className='row align-item-center'>
              <div className='p-placeholder-text mb-1'>
                <input
                  className="form-control p-placeholder px-2 p-placeholder-admin"
                  type="date"
                  placeholder='Select the date'
                  onChange={(e) => { setFromDate(e.target.value); setSelectedPage(1) }}
                  value={fromDate}
                  // max={toDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>

          <div className='ml-3'>
            <div className='p-sub-heading mb-1 ml-2'>{'To Date'}</div>
            <div className='row align-item-center'>
              <div className='p-placeholder-text mb-1'>
                <input
                  className="form-control p-placeholder px-2 p-placeholder-admin"
                  type="date"
                  placeholder='Select the date'
                  onChange={(e) => { setToDate(e.target.value); setSelectedPage(1) }}
                  value={toDate}
                  // min={fromDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>

          <div className='ml-3'>
            <div className='p-sub-heading mb-1 ml-2'>{'Applicant ID'}</div>
            <div className='row align-item-center'>
              <div className='p-placeholder-text mb-1'>
                <input
                  className="form-control p-placeholder px-2 p-placeholder-admin"
                  type="text"
                  placeholder='Enter applicant id'
                  onChange={(e) => { setEmpId(e.target.value); setSelectedPage(1) }}
                  value={empId}
                />
              </div>
            </div>
          </div>

          <div className='ml-3'>
            <div className='p-sub-heading mb-1 ml-2'>{'Claim ID'}</div>
            <div className='row align-item-center'>
              <div className='p-placeholder-text mb-1'>
                <input
                  className="form-control p-placeholder px-2 p-placeholder-admin"
                  type="text"
                  placeholder='Enter claim id'
                  onChange={(e) => { setClaimId(e.target.value); setSelectedPage(1) }}
                  value={claimId}
                />
              </div>
            </div>
          </div>

          <div className='ml-3'>
            <div className='p-sub-heading mb-1 ml-2'>{'Status :'}</div>
            <div className='row align-item-center'>
              <div className='p-placeholder-text mb-1'>
                <DynamicDropdown
                  data={claimSummaryStatus}
                  selectedValue={(data) => onChangedApprovalStatus(data)}
                  value={approvedChecked.title ? approvedChecked.title : 'Select status'}
                  render={'title'}
                  arrow={'true'}
                  height={'NotificationHeight'}
                  width={"w-35rem"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row align-item-center'>
          <input
            type="button"
            value='EXPORT SUMMARY'
            className=' validateBtn mb-1'
            onClick={() => setExportSummary(true)}
          />
        </div>
      </div>

      {/* ------------------------------------------------- Table View -------------------------------------------------- */}
      <div className='table-container ml-3 mt-10px summary-table'>

        <SummaryTable fromDate={fromDate} toDate={toDate} empId={empId} claimId={claimId} selectedPage={selectedPage} setTotalPage={setTotalPage} exportSummary={exportSummary} setExportSummary={setExportSummary} approvedChecked={approvedChecked} />


        {/* -------------------------------------------------- Paginator --------------------------------------------------- */}

        <div className="col-md-12 w-100 d-flex justify-content-center">
          <Pagination>
            <Pagination.Prev
              onClick={() => handlePageCount("decrement")} />
            {
              selectedPage != 1 &&
              <Pagination.Item disabled onClick={() => setSelectedPage(1)}>{1}</Pagination.Item>
            }
            <Pagination.Ellipsis disabled />
            <Pagination.Item active>{totalPage > 1 ? selectedPage : 1}</Pagination.Item>
            <Pagination.Ellipsis disabled />
            {
              selectedPage != totalPage &&
              <Pagination.Item disabled onClick={() => setSelectedPage(totalPage)} >{totalPage}</Pagination.Item>
            }
            <Pagination.Next
              onClick={() => handlePageCount("increment")} />
          </Pagination>
        </div>
      </div>
    </div>
  )
}
export default LocalConveyanceSummary