import React, { Component } from 'react';
import './LoginScreen.css';
import { RiArrowDropDownLine } from 'react-icons/ri';
import LoginMainHead from './LoginMainHead';
import WarnIcon from '../../../../assets/drishti_images/login/WarnIcon.svg';
import Cookies from 'universal-cookie'
import * as Config from '../../../../constants/Config';
import { fetchApiCall } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as serviceActionToken from '../../employeeScreens/login/ServiceTokenAction';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import loginHead from '../../../../assets/drishti_images/login/tml_logo.png';
import { Carousel } from 'react-bootstrap';
import slider_image_default from '../../../../assets/drishti_images/login/drishti_login_default_img.png';
import { toast } from 'react-toastify';
import ResetPassword from './ResetPassword';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import * as AdminLoginAction from './LoginAction';
import * as KeycloakActions from '../../employeeScreens/login/KeyCloakAction';
import * as userActions from '../../employeeScreens/login/LoginAction';
import jwt_decode from "jwt-decode";
import { isAdmin } from './LoginAction'
import { toggleLoginStatus } from '../../employeeScreens/login/LoginAction'
import { configStore } from "../../../../redux/ConfigStore";
import leftarrow from "../../../../assets/drishti_images/backArrow.svg";
const cookies = new Cookies();
const CryptoJS = require("crypto-js");
const default_text = 'Engage and connect with new UI & Omnichannel experience'
// const default_label = 'Welcome To Drishti'
const default_company = { id: 1, company_code: '0100', company_sht_txt: 'TML', company_lng_txt: 'Tata Motors Limited', kong_path: '/cv' }
class AdminLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            empID: '',
            visited: cookies.get('visited'),
            access_token: '',
            sliderImgData: [],
            companyList: [],
            companyName: 'Select',
            companyCode: '',
            companyPath: '',
            company: '',
            loading: false,
            showPass: false,
            emailIdWarn: false,
            isWhiteCollar: true,
            rememberMe: false,
            requestPath: '',
            token: '',
            PassWarn: false,
            emailId: '',
            password: '',
            selectedCompanyValue: {},
            isEmployeeReporting: false,
            resetPassword: false,
            loginError: '',
            scheduleJobs: { payslipJobs: true, ItWorksheetJobs: true, errorInPosting: true },
            employee_Id: '',
            OTPWarn:false,
            OTP:'',
            showOTP:false,
            timeLeft: 600,
            OTPWarnMsg:''
        }
        this.timerInterval = null;
        this.passInputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.adminLoginData.isAdminLoggedIn == true) {
            // Reset the all state
            // window.localStorage.clear();
            configStore.dispatch(isAdmin(false));
            configStore.dispatch(toggleLoginStatus(false));
        }
        // else if (this.props.adminLoginData.isAdminLoggedIn == false) {
        //     this.getServiceAccountToken()
        //     this.getCookieValue()
        // }
        this.getServiceAccountToken()
        this.getCookieValue()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showOTP && !prevState.showOTP) {
            this.startTimer();
        }
    }

    startTimer = () => {
        if (this.timerInterval) {
            clearTimeout(this.timerInterval); // Clear any existing interval
        }
        this.countdown();
    };

    countdown = () => {
        if (this.state.timeLeft > 0) {
            this.timerInterval = setTimeout(() => {
                this.setState(
                    (prevState) => {
                        if (prevState.timeLeft <= 0) {
                            clearTimeout(this.timerInterval);
                            return { timeLeft: 0 };
                        }
                        return { timeLeft: prevState.timeLeft - 1 };
                    },
                    this.countdown // Call recursively
                );
            }, 1000);
        }
    };

    resetTimer = (event) => {
        clearTimeout(this.timerInterval);
        this.setState({ timeLeft: 600 }, () => {
            this.startTimer(); 
            this.sendOTP(event);
        });
    };

    formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    getCookieValue = () => {
        const isRemember = cookies.get('admin_rememberMe') != undefined
        if (isRemember) {
            this.setState({ emailId: cookies.get('admin_emailId') })
            let tempCompanyPath = cookies.get('admin_company_path')
            this.setState({ selectedCompanyValue: cookies.get('admin_company') },
                () => this.setState({
                    companyName: this.state.selectedCompanyValue.company_sht_txt,
                    companyCode: this.state.selectedCompanyValue.company_code,
                    companyPath: tempCompanyPath,
                    rememberMe: true
                }))
            this.props.adminAction.setCompany(cookies.get('admin_company'))
            this.decryptPass()
        }
    }

    decryptPass = () => {
        let password = cookies.get('admin_pass')
        if (password !== undefined) {
            let skey = "edp@$3#drishti"
            let salt = "dRishtI";
            let iterations = 128;
            let bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
            let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
            let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
            let decryptedpassword = CryptoJS.AES.decrypt(password, key, { iv: iv });
            this.setState({ password: decryptedpassword.toString(CryptoJS.enc.Utf8) })
            this.passInputRef.current.value = decryptedpassword.toString(CryptoJS.enc.Utf8)
        }
    }

    getServiceAccountToken = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getServiceAccountToken, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.props.serviceActionToken.getServiceToken(res.data.access_token)
                    this.setState({ access_token: res.data.access_token, loading: false })
                    this.getCompanyNames()
                    this.getSliderImage()
                }
                else {
                    this.setState({ loading: false })
                    console.log('Login error')
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err);
            })
    }

    getCompanyNames = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({ companyList: res.data.company_list ? res.data.company_list : [], loading: false })
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err);
            })
    }

    getSliderImage = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const company_path = '/cv'          //common slider image for all companies
        fetchApiCall(company_path + Config.getSliderImages, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        sliderImgData: res.data.data,
                        loading: false
                    })
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err);
            })
    }

    setCompanyName(e, listValue) {
        this.setState({
            companyName: listValue.company_sht_txt,
            companyCode: listValue.company_code, selectedCompanyValue: listValue,
            companyPath: listValue.kong_path
        })
    }

    handelPassword = () => {
        this.setState({ showPass: !this.state.showPass })
    }

    getEmployeeIdByMail = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            mail_id: this.state.emailId
        }
        fetchApiCall(Config.getEmployeeIdByMail, body, headers, true, 'admin')
            .then(res => {
                if (res.data.message === '200') {
                    this.props.adminAction.adminEmpID(res.data.emp_id === '00000000' ? this.state.employee_Id : res.data.emp_id)
                    this.setState({
                        loading: false,
                        empID: res.data.emp_id,
                    },
                        () => this.whiteUserLogin(res.data.emp_id)
                    )
                    this.getProfile(res.data.emp_id)
                    this.handleRoute(res.data.user_role)
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    getProfile = (emp_id) => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: emp_id,
        }
        fetchApiCall(Config.getProfilePhotoDetails, body, headers, true, 'admin')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({ loading: false })
                    this.props.adminAction.getProfileImage(res.data.data);
                } else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('error login')
            })
    }

    loginError = (data) => {
        this.setState({
            loginError: data
        })
    }

    loginAPI = (event) => {
        event.preventDefault()
        this.setState({ loading: true })
        const password = this.state.password
        let skey = "edp@$3#drishti"
        let salt = "dRishtI";
        let iterations = 128;
        let bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
        let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
        let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

        let cipherpassword = CryptoJS.AES.encrypt(password, key, { iv: iv });
        var encryptPass = cipherpassword.toString()

        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            password: cipherpassword.toString(),
            email: this.state.emailId,
            platform: 'admin-portal',
            comp_code: this.state.companyCode
        }
        const { companyPath } = this.state
        fetchApiCall(companyPath + Config.adminLogin, body, headers, true, '')
            .then(res => {
                if (res.data.status == "200" || res.data.status === "200") {
                    // console.log("res.data.access_token:",res.data.access_token.access_token);
                    const decodeAccessToken = jwt_decode(res.data.access_token.access_token)
                    this.props.adminAction.adminOperativeRole(decodeAccessToken.resource_access['tml-edp-operative'] !== undefined ? decodeAccessToken.resource_access['tml-edp-operative'] : "")
                    this.props.adminAction.getToken(res.data)
                    this.rememberMe(encryptPass)
                    this.props.actions.toggleLoginStatus(false);
                    this.props.adminAction.isAdmin(true)
                    this.props.adminAction.adminData(res.data)
                    this.props.adminAction.adminRole(res.data.user_role)
                    this.props.adminAction.setCompany(this.state.selectedCompanyValue)
                    this.props.adminAction.setScheduleJobs(this.state.scheduleJobs)
                    this.getEmployeeIdByMail()
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false, employee_Id: res.data.employeeId })
                }
                else {
                    this.loginError(res.data.message)
                    this.setState({ loading: false, PassWarn: true })
                }
            }).catch(err => {
                console.log('ggg', err);
                this.setState({ loading: false })
            })
    }

    sendOTP = (event) => {
        // event.preventDefault()
        this.setState({ loading: true })
        const password = this.state.password
        let skey = "edp@$3#drishti"
        let salt = "dRishtI";
        let iterations = 128;
        let bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
        let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
        let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

        let cipherpassword = CryptoJS.AES.encrypt(password, key, { iv: iv });
        var encryptPass = cipherpassword.toString()

        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            password: cipherpassword.toString(),
            email: this.state.emailId,
            platform: 'admin-portal',
            comp_code: this.state.companyCode
        }
        const { companyPath } = this.state  
        fetchApiCall(companyPath + Config.sendOTPAdmin, body, headers, true, '')
            .then(res => {
                if (res.data.status_code == "200") {
                    // this.rememberMe(encryptPass)
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.actions.toggleLoginStatus(false);
                    this.setState({ loading: false,showOTP:true})
                }
                else {
                    this.loginError(res.data.message)
                    this.setState({ loading: false, PassWarn: true,showOTP:false })
                }
            }).catch(err => {
                console.log('sendOTP err---> ', err);
                this.setState({ loading: false })
            })
    }

    validateOTP = (event) => {
        event.preventDefault()
        this.setState({ loading: true })
        const password = this.state.password
        const OTP = this.state.OTP
        let skey = "edp@$3#drishti"
        let salt = "dRishtI";
        let iterations = 128;
        let bytes = CryptoJS.PBKDF2(skey, salt, { keySize: 48, iterations: iterations });
        let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
        let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));

        let cipherpassword = CryptoJS.AES.encrypt(password, key, { iv: iv });
        let cipherOTP = CryptoJS.AES.encrypt(OTP, key, { iv: iv });
        var encryptPass = cipherpassword.toString()
        var encryptOTP = cipherOTP.toString()

        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            password: cipherpassword.toString(),
            email: this.state.emailId,
            platform: 'admin-portal',
            comp_code: this.state.companyCode,
            otp:cipherOTP.toString()
        }
        const { companyPath } = this.state
        fetchApiCall(companyPath + Config.validateOTPAdmin, body, headers, true, '')
            .then(res => {
                if (res.data.status == "200" || res.data.status === "200") {
                    // console.log("res.data.access_token:",res.data.access_token.access_token);
                    const decodeAccessToken = jwt_decode(res.data.access_token.access_token)
                    this.props.adminAction.adminOperativeRole(decodeAccessToken.resource_access['tml-edp-operative'] !== undefined ? decodeAccessToken.resource_access['tml-edp-operative'] : "")
                    this.props.adminAction.getToken(res.data)
                    this.rememberMe(encryptPass)
                    this.props.actions.toggleLoginStatus(false);
                    this.props.adminAction.isAdmin(true)
                    this.props.adminAction.adminData(res.data)
                    this.props.adminAction.adminRole(res.data.user_role)
                    this.props.adminAction.setCompany(this.state.selectedCompanyValue)
                    this.props.adminAction.setScheduleJobs(this.state.scheduleJobs)
                    this.getEmployeeIdByMail()
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false, employee_Id: res.data.employeeId,OTPWarnMsg:'',OTPWarn:false})
                }
                else if (res.data.status_code === '423') {
                    toast.warn(res?.data?.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false, OTPWarn: false, OTPWarnMsg: '', loginError:'',emailIdWarn:false, PassWarn: false}, () => {
                        this.toggleLoginScreen();
                    })
                }                
                else {
                    // this.loginError(res.data.message)
                    this.setState({ loading: false, OTPWarn:true,OTPWarnMsg:res?.data?.message})
                }
            }).catch(err => {
                console.log('validateOTP err-->', err);
                this.setState({ loading: false })
            })
    }

    whiteUserLogin = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            empId: emp_id
        }
        // console.log('empId white caller---->', body);
        fetchApiCall(Config.getUserAndManagerDetail, body, headers, true, 'admin')
            .then(res => {
                if (res.data.status_code == '200') {
                    this.props.adminAction.authUser(res.data.user_details[0]);
                } else {
                    console.log('invalid credentials')
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log('error login')
            })
    }

    //to check role string present in role array from keycloak
    contains(roleArray, list) {
        // array matches
        if (Array.isArray(list)) {
            return list.some(roles => roleArray.indexOf(roles) > -1);
        }
        // string match
        return roleArray.indexOf(list) > -1;
    }
    // after login successful redirection is based on roles comming from keycloak

    handleRoute = (userRole) => {
        this.props.history.push('/admin/home')
    }

    rememberMe(encryptPass) {
        this.getEmployeeIdByMail()
        if (this.state.rememberMe) {
            cookies.set('admin_rememberMe', true)
            cookies.set('admin_pass', encryptPass)
            cookies.set('admin_emailId', this.state.emailId)
            if (this.state.companyName === 'TML') {
                cookies.set('admin_company', default_company)
                cookies.set('admin_company_path', '/cv')
            }
            else {
                cookies.set('admin_company', this.state.selectedCompanyValue)
                cookies.set('admin_company_path', this.state.selectedCompanyValue.kong_path)
            }
        }
    }

    handleChangeEvents(type, event) {
        if (type === 'emailId') {
            this.setState({
                emailId: event,
            })
        }
        else {
            this.setState({
                password: event,
            })
        }
    }

    adminLogin = (event) => {
        event.preventDefault()
        if (this.state.companyName === 'Select') {
        }
        else if (this.state.emailId === '') {
        }
        else if (this.state.password === '') {
        }
        else {
            // this.loginAPI(event)
            this.sendOTP(event)
        }
    }

    onDismiss = () => {
        this.setState({ resetPassword: false })
    }

    resetPasswordOpen = () => {
        this.setState({ resetPassword: true })
    }

    handelOTP = () => {
        this.setState({ showOTPValue: !this.state.showOTPValue })
    }
    toggleLoginScreen = () => {
        clearTimeout(this.timerInterval); 
        this.setState({showOTP: false,timeLeft: 600,OTP:'',OTPWarn:false,PassWarn:false,emailIdWarn:false});
    };
    handleResendOTP = (event) => {
        this.resetTimer(event); // Reset the timer when resending OTP
    };
    componentWillUnmount() {
        clearInterval(this.timerInterval);
    }

    render() {
        // console.log("configStore.getState() admin:",configStore.getState())
        const isResendDisabled = this.state.timeLeft > 0; 
        return (
            <div>
                <div className='sec-col row d-flex'>
                    <LoaderIndicator loading={this.state.loading} />
                    <div className='column-1 col-md'>
                        <div className='round-border d-flex align-items-center justify-content-center'>
                            <div className='main-box mt-5'>
                                <LoginMainHead
                                    loginHead={'DRISHTI 2.0'}
                                    loginSubhead={'Support & Admin Login'}
                                    loginSubPara={'Drishti makes it easy for everyone to find and manage everything at one place'}
                                />
                                <form className='form-group login-form'>
                                    {this.state.showOTP == false ?
                                        <>
                                            <label className='login-form-label card-content-title1 mt-3 ml-2'>Company</label>
                                            <div className='dropdown'>
                                                <button
                                                    className='btn inpt dropDown d-flex justify-content-between'
                                                    type='button' id="dropdownMenuButton"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    {this.state.company === '' ? this.state.companyName : this.state.company}
                                                    <RiArrowDropDownLine size={20} color={'rgb(185 188 197)'} />
                                                </button>
                                                <div className='dropdown-menu' aria-labelledby="dropdownMenuButton">
                                                    {this.state.companyList.map((listValue, i) => {
                                                        return (
                                                            <label key={i} className='dropdown-item' onClick={(e) => this.setCompanyName(e, listValue)}>{listValue.company_sht_txt}</label>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <label className='login-form-label card-content-title1 mt-3 ml-2'>Domain ID</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    id="emailID"
                                                    onChange={(e) => this.setState({ emailId: e.target.value, emailIdWarn: false })}
                                                    className='inpt'
                                                    placeholder='Ex: ABC123456 or abcd.tcs etc.'
                                                    value={this.state.emailId}
                                                ></input>
                                                {this.state.emailIdWarn ? <>
                                                    <img src={WarnIcon} alt='' className='ml-1' /><label className='ml-2 PassWarning'>Please enter valid Email ID</label></> : null}
                                            </div>

                                            <label className='login-form-label card-content-title1 mt-3 ml-2'>Password</label>
                                            <div className='d-flex justify-content-between'>
                                                <input
                                                    ref={this.passInputRef}
                                                    type={this.state.showPass ? "text" : "password"}
                                                    className={this.state.PassWarn ? 'invalid' : 'inpt'}
                                                    onChange={(e) => this.setState({ password: e.target.value, PassWarn: false })}
                                                    placeholder='Enter Your Password'
                                                    value={this.state.password}
                                                    autoComplete="new-password"
                                                    name="password" 
                                                />
                                                {this.state.password != '' && this.state.password !== undefined ?
                                                    <span
                                                        className='showPassBtn'
                                                        onClick={this.handelPassword}
                                                    > {this.state.showPass ? 'hide' : 'show'}</span>
                                                    :
                                                    <span className='showDisabledPassBtn'>show</span>
                                                }
                                            </div>
                                            {this.state.PassWarn ? <>
                                                <img src={WarnIcon} alt='' className='ml-1' /><label className='ml-2 PassWarning'>{this.state.loginError}</label></> : null}
                                            <div className='justify-content-between d-flex align-item-center mt-3'>
                                                <div className='d-flex ml-3'>
                                                    <label className="checkBoxData pl-3">
                                                        <input type="checkbox" className='hidecheckBox'
                                                            checked={this.state.rememberMe}
                                                            onChange={e => this.setState({ rememberMe: !this.state.rememberMe })}
                                                        />
                                                        <span className="markk"></span>
                                                    </label>
                                                    <div className='rememberMe'>Remember Me  </div>
                                                </div>
                                                <div className='resetPassText' onClick={this.resetPasswordOpen}>Reset password </div>
                                            </div>
                                            <button
                                                type="submit"
                                                className='btn btn-primary w-100 mt-3 login-btn py-2'
                                                value="LOGIN"
                                                disabled={(this.state.password === '' && this.state.emailId === '') ? true : false}
                                                onClick={this.adminLogin}
                                            >LOGIN</button>
                                        </>
                                        :
                                        <>
                                            <div className='row'>
                                                <img
                                                    alt="hide"
                                                    src={leftarrow}
                                                    className="up_arrow pointer"
                                                    onClick={() => this.toggleLoginScreen()}
                                                />
                                                <div className='toggle-card-heading ml-1 pointer' onClick={() => this.toggleLoginScreen()}>
                                                    {'Back'}
                                                </div>
                                            </div>
                                            <label className='login-form-label card-content-title1 mt-3 ml-2'>Enter OTP</label>
                                            <div className='d-flex justify-content-between'>
                                                <input
                                                    type={this.state.showOTPValue ? "text" : "password"}
                                                    className={this.state.OTPWarn ? 'invalid' : 'inpt'}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            this.setState({ OTP: value, OTPWarn: false });
                                                        }
                                                    }}
                                                    placeholder='Enter Your OTP'
                                                    value={this.state.OTP}
                                                    maxLength={6}
                                                    name="otp"
                                                    autoComplete="one-time-code"
                                                    inputMode="numeric" 
                                                />
                                                {this.state.OTP != '' & this.state.OTP !== undefined ?
                                                    <span
                                                        className='showPassBtn'
                                                        onClick={this.handelOTP}
                                                    > {this.state.showOTPValue ? 'hide' : 'show'}</span>
                                                    :
                                                    <span className='showDisabledPassBtn'>show</span>
                                                }
                                            </div>
                                            <div className='justify-content-between d-flex align-item-center mt-3'>
                                                <div className='d-flex'>
                                                    <div className='rememberMe align-item-center'>
                                                        {this.formatTime(this.state.timeLeft)}
                                                    </div>
                                                </div>
                                                <div
                                                    className='resetPassText mr-2'
                                                    onClick={!isResendDisabled ? this.handleResendOTP : undefined}
                                                    style={{ cursor: isResendDisabled ? 'not-allowed' : 'pointer', opacity: isResendDisabled ? 0.5 : 1 }}
                                                >
                                                    RESEND OTP
                                                </div>
                                            </div>

                                            <button
                                                type="submit"
                                                className='btn btn-primary w-100 mt-3 login-btn py-2'
                                                value="LOGIN"
                                                disabled={(this.state.OTP.length !== 6) ? true : false}
                                                onClick={this.validateOTP}
                                            >
                                                VALIDATE OTP
                                            </button>
                                            {this.state.OTPWarn ?
                                                <span className='d-flex mt-2'>
                                                    <img src={WarnIcon} alt='' className='ml-1 align-items-start' />
                                                    <div className='ml-2 PassWarning'>{this.state.OTPWarnMsg}</div>
                                                </span>
                                                : null
                                            }
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='column-2 col-md'>
                        <div className='row mt-4 ml-5'>
                            <img src={loginHead} alt='' className='login-img-head' />
                        </div>
                        {this.state.sliderImgData.length != 0 ?
                            <div className='my__carousel'>
                                <Carousel
                                    nextIcon={false}
                                    prevIcon={false}
                                    indicators={true}
                                    controls={false}
                                    pause={false}
                                >
                                    {this.state.sliderImgData.map((list, index) =>
                                        <Carousel.Item
                                            key={index}
                                            activeindex={index - 1}
                                            interval={3000}
                                        >
                                            <div className='row mt-1 ml-5 pl-5'>
                                                <img src={list.image_link} alt='' className='login-3d-img' />
                                            </div>
                                            <div className='row  ml-4'>
                                                <div className='login-stay-touch'>{list.image_title}</div>
                                                {/* <div className='login-stay'>{list.image_title.split(' ')[0]}</div>
                                                <div className='login-stay-touch'>{list.image_title.split(' ')[1]}</div> */}
                                            </div>
                                            <div className='login-stay-touch-sub mt-2 ml-1'>
                                                {list.image_hyperlink}
                                            </div>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </div> :
                            <div className='my__carousel'>
                                <Carousel
                                    nextIcon={false}
                                    prevIcon={false}
                                    indicators={true}
                                    controls={false}
                                    pause={false}
                                >
                                    <Carousel.Item
                                        activeindex={1}
                                        interval={3000}
                                    >
                                        <div className='row mt-4 ml-5 pl-5'>
                                            <img src={slider_image_default} alt='' className='login-3d-img' />
                                        </div>
                                        <div className='row mt-1 ml-4'>
                                            <span className='login-stay'>Stay</span>
                                            <span className='login-stay-touch'>in Touch</span>
                                        </div>
                                        <div className='login-stay-touch-sub mt-2 ml-1'>
                                            {default_text}
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        }
                    </div>
                </div>
                {this.state.resetPassword &&
                    <ResetPassword
                        onDismiss={this.onDismiss}
                    />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        serviceActionToken: bindActionCreators(serviceActionToken, dispatch),
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        actions: bindActionCreators(userActions, dispatch)
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(AdminLogin));