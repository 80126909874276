export const budgetColumnFields = [
    { title: 'From Year', key: 'from_year', width: '20%', type: 'text' },
    { title: 'To Year', key: 'to_year', width: '20%', type: 'text' },
    { title: 'Company', key: 'comp_code', width: '20%', type: 'text' },
    { title: 'Unit', key: 'unit_name', width: '30%', type: 'text' },
    { title: 'Loan Type', key: 'loan_type_name', width: '20%', type: 'text' },
    { title: 'Loan No.', key: 'loan_no_name', width: '20%', type: 'text' },
    { title: 'Grade Group Type', key: 'group_type_name',width: '20%', type: 'text' },
    { title: 'Grade Group', key: 'grade_group_name',width: '20%', type: 'text' },
    { title: 'Budget Amount', key: 'budget_year', width: '20%', type: 'text' },
    { title: 'Utilized Amount', key: 'amt_utilized', width: '20%', type: 'text' },
    { title: 'Balance Amount', key: 'balance', width: '20%', type: 'text' },
]

export const installmentColumnFields = [
    { title: 'Company', key: 'comp_code', width: '20%', type: 'text' },
    { title: 'Loan Type', key: 'loan_type_name', width: '20%', type: 'text' },
    { title: 'Grade Group Type', key: 'group_type_name',width: '20%', type: 'text' },
    { title: 'Grade Group', key: 'grade_group_name',width: '20%', type: 'text' },
    { title: 'Loan Amount', key: 'amount', width: '20%', type: 'text' },
    { title: 'Interest Rate', key: 'interest_rate', width: '20%', type: 'text' },
    { title: 'Total Month Recovery', key: 'total_monthly_recovery', width: '20%', type: 'text' },
    { title: 'Start Date', key: 'start_date', width: '20%', type: 'text' },
]

export const gradeGroupColumnFields = [
    { title: 'Company', key: 'comp_code', width: '20%', type: 'text' },
    { title: 'Loan Type', key: 'loan_type_name', width: '20%', type: 'text' },
    { title: 'Grade Group Type', key: 'group_type_name',width: '20%', type: 'text' },
    { title: 'Start Date', key: 'start_date', width: '20%', type: 'text' },
]

export const loanAllowedColumnFields = [
    { title: 'Company', key: 'comp_code', width: '20%', type: 'text' },
    { title: 'Loan Type', key: 'loan_type_name', width: '20%', type: 'text' },
    { title: 'Grade Group Type', key: 'group_type_name',width: '20%', type: 'text' },
    { title: 'No of Loans Allowed', key: 'no_of_loan', width: '20%', type: 'text' },
    { title: 'Start Date', key: 'start_date', width: '20%', type: 'text' },
]

export const nextLoanStartColumnFields = [
    { title: 'Company', key: 'comp_code', width: '20%', type: 'text' },
    { title: 'Loan Type', key: 'loan_type_name', width: '20%', type: 'text' },
    { title: 'Grade Group Type', key: 'group_type_name',width: '20%', type: 'text' },
    { title: 'Start Date', key: 'start_date', width: '20%', type: 'text' },
    { title: 'Months In Number', key: 'no_of_month', width: '20%', type: 'text' },
    { title: 'Loan No.', key: 'loan_no_name', width: '20%', type: 'text' },
]

export const loanRegistration = [
    {title: 'Application No.', key: 'application_no', width: '20%', type: 'text'},
    {title: 'Application Name', key: 'applicant_name', width: '20%', type: 'text'},
    {title: 'Application Date', key: 'application_date', width: '20%', type: 'text'},
    {title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
    {title: 'Department', key: 'department', width: '20%', type: 'text'},
]

export const loanSanctioningColumnFields = [
  { title: 'Employee Id', key: 'employee_id', width: '20%', type: 'text' },
  { title: 'Registration No.', key: 'registration_no', width: '20%', type: 'text' },
  { title: 'Application No.', key: 'application_no', width: '20%', type: 'text' },
  { title: 'Application Name', key: 'application_name', width: '20%', type: 'text' },
  { title: 'Application Date', key: 'application_date', width: '20%', type: 'text' },
  { title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
  { title: 'CC NO.', key: 'cc_no', width: '20%', type: 'text'},
  { title: 'Person Area', key: 'person_area', width: '20%', type: 'text'},
  { title: 'Status', key: 'status', width: '20%', type: 'text'},
]

export const holdApplicationColumnFields = [
  { title: 'Employee Id', key: 'employee_id', width: '20%', type: 'text' },
  { title: 'Application No.', key: 'application_no', width: '20%', type: 'text' },
  { title: 'Application Name', key: 'application_name', width: '20%', type: 'text' },
  { title: 'Application Date', key: 'application_date', width: '20%', type: 'text' },
  { title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
  { title: 'Cost Center No.', key: 'cc_no', width: '20%', type: 'text'},
  { title: 'Person Area', key: 'person_area', width: '20%', type: 'text'},
  { title: 'Status', key: 'status', width: '20%', type: 'text'},
]

export const loanLetterColumnFields = [
  { title: 'Employee Id', key: 'employee_id', width: '20%', type: 'text' },
  { title: 'Application No.', key: 'application_no', width: '20%', type: 'text' },
  { title: 'Application Name', key: 'application_name', width: '20%', type: 'text' },
  { title: 'Application Date', key: 'application_date', width: '20%', type: 'text' },
  { title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
  { title: 'CC NO.', key: 'cc_no', width: '20%', type: 'text'},
  { title: 'Person Area', key: 'person_area', width: '20%', type: 'text'},
  { title: 'Status', key: 'status', width: '20%', type: 'text'},
]

export const employeeDetailsKeyList = [
    { title: "Application Date", key: "application_date" },
    { title: "Applicant Name", key: "applicant_name" },
    { title: "Joining Date", key: "joining_date" },
    { title: "Cost Center No.", key: "cc_no" },
    { title: "Department", key: "department" },
    { title: "Grade Group", key: "grade_group" },
    { title: "Grade", key: "grade" },
    { title: "Phone No.", key: "phone_no" },
    { title: "Ownership", key: "ownership" }
];

export const employeeDetailsKeyListRegistration = [
  { title: "Application No", key: "application_no" },
  { title: "Applicant Name", key: "applicant_name" },
  { title: "Loan Type", key: "loan_type" },
  { title: "Joining Date", key: "joining_date" },
  { title: "Department", key: "department" },
  { title: "Grade", key: "grade" },
  { title: "Application Date", key: "application_date" },
  { title: "Ownership", key: "ownership" }, 
  { title: "CC No.", key: "cc_no" },
  { title: "Phone No", key: "phone_no" }
];


export const applicationStatusPersonalDetailsKeyList = [
  {title: "Employee Id", key: "employee_id"},
  {title: "Phone No", key: "phone_no"},
  {title: "Name", key: "applicant_name"},
  {title: "CC No.", key: "cc_no"},
  {title: "Grade", key: "grade"},
  {title: "Address", key: "address"},
  {title: "Merit", key: "merit"},
  {title: "Reason Of Cancellation", key: "cancellation_reason"},
]

export const applicationStatusAdditionalDetailsKeyList = [
  { title: "Application No", key: "application_no" },
  { title: "Application Date", key: "application_date" },
  { title: "Loan Type", key: "loan_type" },
  { title: "Applied Amount", key: "applied_amount" },
  { title: "Eligible Amount", key: "eligible_amount" },
  { title: "Sanction Amount", key: "sanction_amount" },
  { title: "Disburse Amount", key: "disburse_amount" },
  { title: "Status", key:"status"}
]

export const applicationStatusFinancialDetailsKeyList = [
  { title: "INST", key: "inst" },
  { title: "Saving", key: "saving" },
  { title: "Bank", key: "bank" },
  { title: "PF", key: "pf" },
  { title: "TMKSP", key: "tmksp" },
  { title: "Other", key: "other" },
]

export const applicationStatusPropertyDetailsKeyList = [
  { title: "Agree Cost", key: "agree_cost" },
  { title: "Appr Inst", key: "appr_inst" },
  { title: "Purchase From", key: "purchase_from" },
]


export const registrationFormFields = [
  {
    name: "Agreement Cost",
    id: "RF01",
    code: 'agreementCost',
    isRequired: true
  },
  {
    name: "Compl Cert",
    id: "RF03",
    code: 'complCert',
    isRequired: true
  },
  {
    name: "NA Certificate",
    id: "RF10",
    code: 'naCertificate',
    isRequired: true
  }, 
  {
    name: "Property Tax",
    id: "RF12",
    code: 'propertyTax',
    isRequired: true
  }, 
  {
    name: "Share Cert",
    id: "RF14",
    code: 'shareCert',
    isRequired: true
  },
  {
    name: "Society Charges Receipt",
    id: "RF19",
    code: 'societyChargesReceipt',
    isRequired: true
  }, 
  {
    name: "Cancel",
    id: "RF02",
    code: 'cancel',
    isRequired: false
  },
  {
    name: "Finance Inst Letter",
    id: "RF04",
    code: 'financeInstLetter',
    isRequired: false
  },
  {
    name: "Plan Approved",
    id: "RF05",
    code: 'planApproved',
    isRequired: false
  },
  {
    name: "Agreement Date",
    id: "RF06",
    code: 'agreementDate',
    isRequired: false
  },
  {
    name: "Appr Fin List",
    id: "RF07",
    code: 'apprFinList',
    isRequired: false
  },
  {
    name: "Booking Rcpt",
    id: "RF08",
    code: 'bookingRcpt',
    isRequired: false
  },
  {
    name: "Commence Cert",
    id: "RF08",
    code: 'commenceCert',
    isRequired: false
  },
  {
    name: "Dev Agreement",
    id: "RF09",
    code: 'devAgreement',
    isRequired: false
  }, 
  {
    name: "Posses Letter",
    id: "RF11",
    code: 'poseesLetter',
    isRequired: false
  }, 
  {
    name: "Register Flat",
    id: "RF13",
    code: 'registerFlat',
    isRequired: false
  },  
  {
    name: "Fin From Fin Ins",
    id: "RF15",
    code: 'finFromFinIns',
    isRequired: false
  }, 
  {
    name: "Fin From Own Sav",
    id: "RF16",
    code: 'finFromOwnSav',
    isRequired: false
  }, 
  {
    name: "Fin From TKSP-KSP",
    id: "RF17",
    code: 'finFromTkspKsp',
    isRequired: false
  }, 
  {
    name: "Register Land",
    id: "RF18",
    code: 'registerLand',
    isRequired: false
  },  
  {
    name: "Soc Reg Cert",
    id: "RF20",
    code: 'socRegCert',
    isRequired: false
  }, 
  {
    name: "Fin From Bank",
    id: "RF21",
    code: 'finFromBank',
    isRequired: false
  }, 
  {
    name: "Fin From Others",
    id: "RF22",
    code: 'finFromOthers',
    isRequired: false
  }, 
  {
    name: "Fin From PF",
    id: "RF23",
    code: 'finFromPf',
    isRequired: false
  }, 

]

export const employeeStatusFields = [
  {title: 'Employee Id', key: 'employee_id', width: '20%', type: 'text'},
  {title: 'Applicant Name',key:'applicant_name',width: '20%',type: 'text'},
  {title: 'Application No.', key: 'application_no', width: '20%', type: 'text'},
  {title: 'Application Date', key: 'application_date', width: '20%', type: 'text'},
  {title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
  {title: 'Applied Amount', key: 'applied_amount',width: '20%',type:'text'},
  {title: 'Status', key: 'status', width: '20%', type: 'text'}
]

export const meritStamping = [
  {title: 'Employee Id', key: 'employee_id', width: '20%', type: 'text'},
  {title: 'Registration No.', key: 'registration_no', width: '20%', type: 'text'},
  {title: 'Application No.', key: 'application_no', width: '20%', type: 'text'},
  {title: 'Applicant Name', key: 'applicant_name', width: '20%', type: 'text'},
  {title: 'Application Date', key: 'application_date', width: '20%', type: 'text'},
  {title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
  {title: 'CC No.', key: 'cc_no', width: '20%', type: 'text'},
  {title: 'Person Area', key: 'person_area', width: '20%', type: 'text'},
  {title: 'Status', key: 'status', width: '20%', type: 'text'}
]

export const rejectStamping = [
  {title: 'Employee Id', key: 'employee_id', width: '20%', type: 'text'},
  {title: 'Application No.', key: 'application_no', width: '20%', type: 'text'},
  {title: 'Applicant Name', key: 'applicant_name', width: '20%', type: 'text'},
  {title: 'Application Date', key: 'application_date', width: '20%', type: 'text'},
  {title: 'Loan Type', key: 'loan_type', width: '20%', type: 'text'},
  {title: 'Status', key: 'status', width: '20%', type: 'text'}
]
export const applicationStatusPreviousLoanDetailsColumnFields = [
  { title: 'Heading', key: 'heading', width: '20%', type: 'text' },
  { title: 'Disburse Date', key: 'disburse_date', width: '20%', type: 'text' },
  { title: 'Disburse Amount', key: 'disburse_amount', width: '20%', type: 'text' },
]


export const LOAN_MASTER = 'loan_master'
export const LOAN_TYPE = 'loan_type'
export const LOAN_NO = 'loan_no'
export const UNIT = 'unit'
export const GRADE_GROUP_TYPE = 'grade_group_type'
export const GRADE_GROUP = 'grade_group'
export const ERROR_MESSAGE = "Something Went Wrong!"
export const NO_DATA_FOUND = "No data found"
export const SUCCESS_MESSAGE = "Data Fetched Successfully"
export const DELETE_MESSAGE = "Data Deleted Successfully"
export const ADD_MESSAGE = "Data Created Successfully"
export const UPDATE_MESSAGE = "Data Updated Successfully"
export const SUCCESS = 'success'
export const WARNING = 'warning'
export const DECREMENT = "decrement"
export const INCREMENT = "increment"
export const MASTER01 = 'M0001'
export const MASTER02 = 'M0002'
export const MASTER03 = 'M0003'
export const MASTER04 = 'M0004'
export const MASTER05 = 'M0005'
export const ADDBUDGET = "Add Budget"
export const ADDINSTALLMENT = "Add Installment"
export const ADDGGTYPE = "Add Grade Group Type"
export const ADDLOANALLOWED = "Add Loan Allowed"
export const ADDNEXTLOANSTART = "Add Next Loan Start"
export const ADD = "Add"
export const UPDATE = "Update"
export const DELETE = "Delete"
export const UPDATEBUDGET = "Update Budget"
export const UPDATEINSTALLMENT = "Update Installment"
export const UPDATEGGTYPE = "Update Grade Group Type"
export const UPDATELOANALLOWED = "Update Loan Allowed"
export const UPDATENEXTLOANSTART = "Update Next Loan Start"
export const DELETEBUDGET = "Delete Budget"
export const DELETEINSTALLMENT = "Delete Installment"
export const DELETEGGTYPE = "Delete Grade Group Type"
export const DELETELOANALLOWED = "Delete Loan Allowed"
export const DELETENEXTLOANSTART = "Delete Next Loan Start"
export const DELETECONFIRM = "Delete Confirmation"
export const DELETEMSGCONFIRM = 'Are you sure you want to delete this data?'
export const NO = 'NO'
export const YES = 'YES'
export const APPLY = "APPLY"
export const EMP_DETAILS = "Employee Details"
export const INTERMEDIATE_SANCTIONING = "Intermediate Sanctioning for Normal Cases"
export const FINAL_SANCTIONING = "Final Sanctioning for Normal Cases"
export const MERIT_STAMPING = "Merit Stamping"
export const REJECT_STAMPING = "Reject Stamping"

// ==========================================================================================
// Constants Values Temporary
// ==========================================================================================

export const processData = [
    {
      name: "Loan Application",
      id: "PT001",
    },
    {
      name: "Loan Registration",
      id: "PT002",
    },
    {
      name: "Emp Application Status Check",
      id: "PT003",
    },
    {
      name: "Cancel Application",
      id: "PT004",
    },
    {
      name: "Hold Application",
      id: "PT005",
    },
  ];


export const loanRegistrationData = [
  {
    application_no: "TU4F20257",
    applicant_name: "Amit Verma",
    joining_date: "01-03-2022",
    department: "Finance",
    loan_type:"Home Loan",
    grade: "A",
    application_date: "10-02-2024",
    ownership: "Self",
    cc_no: "12345",
    phone_no: "9876543210",
  },
  {
    application_no: "TU4F20258",
    applicant_name: "Priya Singh",
    joining_date: "11-05-2021",
    department: "Loans",
    loan_type:"Car Loan",
    grade: "B",
    application_date: "11-02-2024",
    ownership: "Self",
    cc_no: "67890",
    phone_no: "9876543220",
  },
  {
    application_no: "TU4F20259",
    applicant_name: "Vikram Patel",
    joining_date: "02-08-2023",
    department: "Student Loans",
    loan_type:"Educational Loan",
    grade: "C",
    application_date: "12-02-2024",
    ownership: "Self",
    cc_no: "11223",
    phone_no: "9876543230",
  },
  {
    application_no: "TU4F20260",
    applicant_name: "Neha Gupta",
    joining_date: "03-09-2020",
    department: "Corporate Finance",
    loan_type:"Home Loan",
    grade: "A",
    application_date: "13-02-2024",
    ownership: "Corporate",
    cc_no: "44556",
    phone_no: "9876543240",
  },
  {
    application_no: "TU4F20261",
    applicant_name: "Raju Sharma",
    joining_date: "14-07-2021",
    department: "Finance",
    loan_type:"Home Loan",
    grade: "B",
    application_date: "14-02-2024",
    ownership: "Self",
    cc_no: "78901",
    phone_no: "9876543250",
  }
  ];


export const employeeData = 
    {application_date:"19-02-2025" ,
    applicant_name:"Suresh Rane" ,
    joining_date:"21-02-2025" ,
    cc_no:"32122" ,
    department:"Automobile" ,
    grade_group:"1" ,
    grade:"A" ,
    phone_no:"8204373923" ,
    ownership:"Own"}


  export const houseTypeData = [
    {
        name: "FE - Flat Extension",
        id: "HT001",
        Desc: "FE - Flat Extension"
    },
    {
        name: "BC - Bungalow Construction",
        id: "HT002",
        Desc: "BC - Bungalow Construction"
    },
    {
        name: "FP - Flat New Purchase",
        id: "HT003",
        Desc: "FP - Flat New Purchase"
    },
    {
        name: "FR - Flat Resale",
        id: "HT004",
        Desc: "FR - Flat Resale"
    },
    {
        name: "BR - Bungalow Extension",
        id: "HT005",
        Desc: "BR - Bungalow Extension"
    },
    {
        name: "EB - Balcony Extension",
        id: "HT006",
        Desc: "EB - Balcony Extension"
    },
    {
        name: "BE - Bungalow Extension",
        id: "HT007",
        Desc: "BE - Bungalow Extension"
    },
  ]

  export const ownerShipData = [
    {
        name: "Joint",
        id: "OW001"
    },
    {
        name: "Single",
        id: "OW002"
    },
    {
        name: "Other",
        id: "OW003"
    },
  ]

 export const sourceLandData= [
    {
        name: "GENERAL",
        id: "SL001"
    },
    {
        name: "MAHADA",
        id: "SL002"
    },
    {
        name: "MIDC",
        id: "SL003"
    },
    {
        name: "PCNTDA",
        id: "SL004"
    },
 ]

 export const unitData = [
  {
    name: "PNCV CVBU",
    id: "UM001"
  },
  {
    name: "PNCV CVBU Other",
    id: "UM002"
  },
 ]

 export const meritStampingData = [
  {
    employee_id: "123456",
    registration_no: "RG000388",
    application_no: "A000388",
    applicant_name: "Raju Paisewala",
    application_date: "14-02-2024",
    loan_type: "Home Loan",
    cc_no: "12345",
    person_area: "PNCV",
    status: "Loan Registered"
  },
  {
    employee_id: "018382",
    registration_no: "RG000399",
    application_no: "A000399",
    applicant_name: "Garib Babu",
    application_date: "16-02-2024",
    loan_type: "Home Loan",
    cc_no: "58342",
    person_area: "PNCV",
    status: "Loan Registered"
  },
]

export const rejectStampingData = [
  {
    employee_id: "12345",
    application_no: "A000399",
    applicant_name: "Paresh Rawal",
    application_date: "16-02-2024",
    loan_type: "Home Loan",
    status: "Loan Registered"
  },
  {
    employee_id: "12346",
    application_no: "A000400",
    applicant_name: "Aman Yadav",
    application_date: "17-02-2024",
    loan_type: "Personal Loan",
    status: "Rejected"
  },
  {
    employee_id: "12347",
    application_no: "A000401",
    applicant_name: "Ankit Singh",
    application_date: "18-02-2024",
    loan_type: "Car Loan",
    status: "Under Review"
  },
  {
    employee_id: "12348",
    application_no: "A000402",
    applicant_name: "Ravi Vishwakarma",
    application_date: "19-02-2024",
    loan_type: "Education Loan",
    status: "Approved"
  },
  {
    employee_id: "12349",
    application_no: "A000403",
    applicant_name: "Rajau Sharma",
    application_date: "20-02-2024",
    loan_type: "Business Loan",
    status: "Loan Registered"
  },
  {
    employee_id: "12350",
    application_no: "A000404",
    applicant_name: "Sushant Sinha",
    application_date: "21-02-2024",
    loan_type: "Gold Loan",
    status: "Rejected"
  }
 ]


 export const employeeStatusData = [
  {
    employee_id: "12350",
    application_no: "A000404",
    applicant_name: "Sushant Sinha",
    application_date: "12-09-2024",
    loan_type: "Gold Loan",
    cc_no: "342",
    person_area: "PNCV",
    status: "Rejected"
  },
  {
    employee_id: "12351",
    application_no: "A000405",
    applicant_name: "Rahul Sharma",
    application_date: "13-09-2024",
    loan_type: "Personal Loan",
    cc_no: "343",
    person_area: "PNCV",
    status: "Approved"
  },
  {
    employee_id: "12352",
    application_no: "A000406",
    applicant_name: "Priya Patel",
    application_date: "14-09-2024",
    loan_type: "Home Loan",
    cc_no: "344",
    person_area: "PNCV",
    status: "Pending"
  },
  {
    employee_id: "12353",
    application_no: "A000407",
    applicant_name: "Amit Kumar",
    application_date: "15-09-2024",
    loan_type: "Car Loan",
    cc_no: "345",
    person_area: "PNCV",
    status: "Rejected"
  },
  {
    employee_id: "12354",
    application_no: "A000408",
    applicant_name: "Neha Gupta",
    application_date: "16-09-2024",
    loan_type: "Education Loan",
    cc_no: "346",
    person_area: "PNCV",
    status: "Approved"
  },
  {
    employee_id: "12355",
    application_no: "A000409",
    applicant_name: "Vikram Singh",
    application_date: "17-09-2024",
    loan_type: "Business Loan",
    cc_no: "347",
    person_area: "PNCV",
    status: "Pending"
  },
  {
    employee_id: "12356",
    application_no: "A000410",
    applicant_name: "Anjali Mehta",
    application_date: "18-09-2024",
    loan_type: "Gold Loan",
    cc_no: "348",
    person_area: "PNCV",
    status: "Rejected"
  },
  {
    employee_id: "12357",
    application_no: "A000411",
    applicant_name: "Rajesh Verma",
    application_date: "19-09-2024",
    loan_type: "Personal Loan",
    cc_no: "349",
    person_area: "PNCV",
    status: "Approved"
  },
  {
    employee_id: "12358",
    application_no: "A000412",
    applicant_name: "Sneha Reddy",
    application_date: "20-09-2024",
    loan_type: "Home Loan",
    cc_no: "350",
    person_area: "PNCV",
    status: "Pending"
  },
  {
    employee_id: "12359",
    application_no: "A000413",
    applicant_name: "Karan Malhotra",
    application_date: "21-09-2024",
    loan_type: "Car Loan",
    cc_no: "351",
    person_area: "PNCV",
    status: "Rejected"
  }
];

export const applicationStatusData = [
  {
    personal_details: {
      employee_id: "12354",
      phone_no: "7508976534",
      applicant_name: "Kevin Patil",
      cc_no: "6443",
      grade: "A",
      address: "Mumbai",
      merit: "XYZ",
      cancellation_reason: "Documents Incompleteness"
    },
    additional_details: {
      application_no: "32412",
      application_date: "12-03-2025",
      loan_type: "Home Loan",
      applied_amount: "345",
      eligible_amount: "234",
      sanction_amount: "532",
      disburse_amount: "5325",
      status: "Rejected"
    },
    financial_details: {
      inst: "3200",
      saving: "340",
      bank: "TMKL",
      pf: "ABC123",
      tmksp: "532",
      other: "54435"
    },
    property_details: {
      agree_cost: "4314",
      appr_inst: "757576",
      purchase_from: "12-09-2023"
    },
    previous_loan_details: [
      {
        heading: "Home Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Educational Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Two Wheeler Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Four Wheeler Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Home Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
    ]
  },
  {
    personal_details: {
      employee_id: "67890",
      phone_no: "9876543210",
      applicant_name: "Amit Sharma",
      cc_no: "9876",
      grade: "B",
      address: "Delhi",
      merit: "ABC",
      cancellation_reason: "Hi"
    },
    additional_details: {
      application_no: "56789",
      application_date: "25-04-2025",
      loan_type: "Car Loan",
      applied_amount: "500",
      eligible_amount: "450",
      sanction_amount: "400",
      disburse_amount: "3500",
      status: "Approved"
    },
    financial_details: {
      inst: "5000",
      saving: "700",
      bank: "HDFC",
      pf: "XYZ987",
      tmksp: "600",
      other: "30000"
    },
    property_details: {
      agree_cost: "7000",
      appr_inst: "850000",
      purchase_from: "15-10-2024"
    },
    previous_loan_details: [
      {
        heading: "Home Loan",
        disburse_date: "12-04-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Educational Loan",
        disburse_date: "14-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Two Wheeler Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Four Wheeler Loan",
        disburse_date: "24-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Home Loan",
        disburse_date: "17-04-2025",
        disburse_amount: "34643"
      },
    ]
  },
  {
    personal_details: {
      employee_id: "11223",
      phone_no: "9123456789",
      applicant_name: "Priya Verma",
      cc_no: "2233",
      grade: "C",
      address: "Bangalore",
      merit: "LMN",
      cancellation_reason: "Hello"
    },
    additional_details: {
      application_no: "99876",
      application_date: "05-06-2025",
      loan_type: "Education Loan",
      applied_amount: "700",
      eligible_amount: "650",
      sanction_amount: "600",
      disburse_amount: "5000",
      status: "Pending"
    },
    financial_details: {
      inst: "2000",
      saving: "500",
      bank: "SBI",
      pf: "DEF456",
      tmksp: "700",
      other: "25000"
    },
    property_details: {
      agree_cost: "9000",
      appr_inst: "900000",
      purchase_from: "20-11-2024"
    },
    previous_loan_details: [
      {
        heading: "Home Loan",
        disburse_date: "12-04-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Educational Loan",
        disburse_date: "14-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Two Wheeler Loan",
        disburse_date: "12-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Four Wheeler Loan",
        disburse_date: "24-03-2025",
        disburse_amount: "34643"
      },
      {
        heading: "Home Loan",
        disburse_date: "17-04-2025",
        disburse_amount: "34643"
      },
    ]
  }
]


export const loanSanctioningTableData = [
  {
    employee_id: "13141",
    registration_no: "434234",
    application_no: "TC132",
    application_name: "ABC",
    application_date: "12-03-2025",
    loan_type: "Home Loan",
    cc_no: "2412",
    person_area: "PNCV",
    status: "Merit Stamped"
  },
  {
    employee_id: "13142",
    registration_no: "434235",
    application_no: "TC133",
    application_name: "John Doe",
    application_date: "15-04-2025",
    loan_type: "Car Loan",
    cc_no: "2413",
    person_area: "PNCV",
    status: "Merit Stamped"
  },
  {
    employee_id: "13143",
    registration_no: "434236",
    application_no: "TC134",
    application_name: "Jane Smith",
    application_date: "20-05-2025",
    loan_type: "Education Loan",
    cc_no: "2414",
    person_area: "PNCV",
    status: "Merit Stamped"
  },
  {
    employee_id: "13144",
    registration_no: "434237",
    application_no: "TC135",
    application_name: "Mike Johnson",
    application_date: "10-06-2025",
    loan_type: "Business Loan",
    cc_no: "2415",
    person_area: "PNCV",
    status: "Merit Stamped"
  },
  {
    employee_id: "13145",
    registration_no: "434238",
    application_no: "TC136",
    application_name: "Sarah Williams",
    application_date: "25-07-2025",
    loan_type: "Personal Loan",
    cc_no: "2416",
    person_area: "PNCV",
    status: "Merit Stamped"
  },
  {
    employee_id: "13146",
    registration_no: "434239",
    application_no: "TC137",
    application_name: "David Brown",
    application_date: "05-08-2025",
    loan_type: "Gold Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Merit Stamped"
  }
];


export const holdApplicationTableData = [
  {
    employee_id: "13146",
    application_no: "TC137",
    application_name: "David Brown",
    application_date: "05-08-2025",
    loan_type: "Gold Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Hold"
  },
  {
    employee_id: "13256",
    application_no: "TC147",
    application_name: "Kevin Brown",
    application_date: "15-04-2025",
    loan_type: "Home Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Hold"
  },
  {
    employee_id: "13346",
    application_no: "TC137",
    application_name: "David Brown",
    application_date: "15-08-2025",
    loan_type: "Education Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Hold"
  },
  {
    employee_id: "13256",
    application_no: "TC167",
    application_name: "David Brown",
    application_date: "08-08-2025",
    loan_type: "Two Wheelar Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Hold"
  },
]


export const cancelApplicationTableData = [
  {
    employee_id: "13146",
    application_no: "TC137",
    application_name: "David Brown",
    application_date: "05-08-2025",
    loan_type: "Gold Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Final Sanctioning"
  },
  {
    employee_id: "13256",
    application_no: "TC147",
    application_name: "Kevin Brown",
    application_date: "15-04-2025",
    loan_type: "Home Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Final Sanctioning"
  },
  {
    employee_id: "13346",
    application_no: "TC137",
    application_name: "David Brown",
    application_date: "15-08-2025",
    loan_type: "Education Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Final Sanctioning"
  },
  {
    employee_id: "13256",
    application_no: "TC167",
    application_name: "David Brown",
    application_date: "08-08-2025",
    loan_type: "Two Wheelar Loan",
    cc_no: "2417",
    person_area: "PNCV",
    status: "Final Sanctioning"
  },
]

