import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import DeclarationPopup from '../profile/DeclarationPopup';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import LargeDropdown from '../../../shared/components/LargeDropdown';

const chooseItem = 'Choose an item'
class DependentCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisibility: false,
            loading: false,
            isEdit: false,
            openDetail: false,
            delete: false,
            countryData: [],
            familyMemberData: [],
            genderData: [],
            family_member: chooseItem,
            family_member_code: '',
            first_name: '',
            last_name: '',
            birth_name: '',
            gender: chooseItem,
            gender_code: '',
            country: '',
            country_code: '',
            date_of_birth: '',
            birth_place: '',
            nationality: chooseItem,
            nationality_code: '',
        }
    }
    componentDidMount() {
        if (!this.props.isEdit)
            return;
        const countryArray = this.props.countryData.map((item, idx) => ({
            ...item,
            label: item.country_text
        }))
        this.setState(
            {
                countryData: countryArray ? countryArray : [],
                familyMemberData: this.props.familyMemberData ? this.props.familyMemberData : [],
                genderData: this.props.genderData ? this.props.genderData : []
            })
    }

    toggleOpenDetail = () => {
        this.setState({
            openDetail: !this.state.openDetail
        })
    }

    validateEmployeeData = () => {
        if (this.state.family_member == '' || this.state.family_member == chooseItem) {
            toast.warn("Please select family member", { hideProgressBar: true });
        } else if (this.state.first_name == '') {
            toast.warn("Please enter first name", { hideProgressBar: true });
        } else if (this.state.date_of_birth == '') {
            toast.warn("Please select date of birth", { hideProgressBar: true });
        } else if (this.state.first_name.length > 40) {
            toast.warn("Please enter first name less than 40 characters only", { hideProgressBar: true });
        } else if (this.state.last_name == '') {
            toast.warn("Please enter last name", { hideProgressBar: true });
        } else if (this.state.last_name.length > 40) {
            toast.warn("Please enter last name less than 40 characters only", { hideProgressBar: true });
        } else if (this.state.birth_name == '') {
            toast.warn("Please enter birth name", { hideProgressBar: true });
        } else if (this.state.birth_name.length > 40) {
            toast.warn("Please enter birth name less than 40 characters only", { hideProgressBar: true });
        } else if (this.state.birth_place == '') {
            toast.warn("Please enter birth place", { hideProgressBar: true });
        } else if (this.state.birth_place.length > 40) {
            toast.warn("Please enter birth place less than 40 characters only", { hideProgressBar: true });
        } else if (this.state.gender == '' || this.state.gender == 'Select gender') {
            toast.warn("Please select gender", { hideProgressBar: true });
        } else if (this.state.nationality == '' || this.state.nationality === chooseItem) {
            toast.warn("Please select nationality", { hideProgressBar: true });
        } else {
            this.showModal();
        }
    }

    showModal = () => {
        this.setState({ popupVisibility: true });
    }

    handleChangeLastName = (value) => {
        this.setState({
            last_name: value
        });
    }

    handleChangeBirthPlace = (value) => {
        this.setState({
            birth_place: value
        });
    }

    setDateOfBirth = (fulldate) => {
        this.setState({
            date_of_birth: fulldate
        });
    }

    changeFamilyMember = (data) => {
        this.setState({
            family_member: data.value,
            family_member_code: data.code
        })
    }
    changeGender = (data) => {
        this.setState({
            gender: data.value,
            gender_code: data.code
        })
    }

    setNationality = (data) => {
        this.setState({
            nationality: data.country_text,
            nationality_code: data.country_code
        })
    }

    saveEmploymentDetails = () => {
        this.setState({
            popupVisibility: false
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            family_member: this.state.family_member ? this.state.family_member : '',
            family_member_code: this.state.family_member_code ? this.state.family_member_code : '',
            first_name: this.state.first_name ? this.state.first_name : '',
            last_name: this.state.last_name ? this.state.last_name : '',
            birth_name: this.state.birth_name ? this.state.birth_name : '',
            gender: this.state.gender ? this.state.gender : '',
            gender_code: this.state.gender_code ? this.state.gender_code : '',
            date_of_birth: this.state.date_of_birth ? this.state.date_of_birth : '',
            birth_place: this.state.birth_place ? this.state.birth_place : '',
            // cityText: this.props.item.city ? this.props.item.city : '',
            // city: this.props.item.city ? this.props.item.city : '',
            nationality: this.state.nationality ? this.state.nationality : '',
            nationality_code: this.state.nationality_code ? this.state.nationality_code : '',
        }
        this.props.saveEmployeeData(body);
    }

    render() {
        const { isEdit } = this.props
        return (
            <div>
                <div>
                    {isEdit ?
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='document-common-heading common-text-color'>Add New Dependent Details
                                </div>
                                {!this.state.openDetail &&
                                    <div
                                        className='justify-content-end'
                                        onClick={() => this.toggleOpenDetail()}>
                                        <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                    </div>
                                }
                            </div>
                            {!this.state.openDetail &&
                                <div>
                                    <div className='hz-lines mt-2 mb-2'></div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-4 mt-2 mb-2'>Dependent Details</div>
                                    </div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Family Member</div>
                                                <div className='dropdown'>
                                                    <button
                                                        className='btn p-placeholder d-flex justify-content-between align-item-center'
                                                        type='button' id="dropdownMenuButton"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {this.state.family_member}
                                                        <RiArrowDropDownLine
                                                            size={25}
                                                            color={'rgb(185 188 197)'}
                                                        />
                                                    </button>
                                                    <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                                        {this.state.familyMemberData.map((listValue, i) => {
                                                            return (
                                                                <label key={i} className='dropdown-item' onClick={() => this.changeFamilyMember(listValue)}>
                                                                    {listValue.value}
                                                                </label>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>First Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={40}
                                                        onChange={(e) => this.setState({ first_name: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Last Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={40}
                                                        onChange={(e) => this.setState({ last_name: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Birth Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        maxLength={40}
                                                        type="text"
                                                        onChange={(e) => this.setState({ birth_name: e.target.value })}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Gender</div>
                                                <div className='dropdown'>
                                                    <button
                                                        className='btn p-placeholder d-flex justify-content-between'
                                                        type='button' id="dropdownMenuButton"
                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {this.state.gender}
                                                        <RiArrowDropDownLine
                                                            size={25}
                                                            color={'rgb(185 188 197)'}
                                                        />
                                                    </button>
                                                    <div className='dropdown-menu w-100' aria-labelledby="dropdownMenuButton">
                                                        {this.state.genderData.map((listValue, i) => {
                                                            return (
                                                                <label key={i} className='dropdown-item' onClick={() => this.changeGender(listValue)}>
                                                                    {listValue.value}
                                                                </label>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Date of Birth</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3 w-100"
                                                        type="date"
                                                        onChange={(e) => this.setDateOfBirth(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Birth Place</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={40}
                                                        onChange={(e) => this.setState({ birth_place: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Nationality</div>

                                                <LargeDropdown
                                                    data={this.state.countryData}
                                                    value={this.state.nationality}
                                                    selectedValue={(data) => this.setNationality(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                        </div>
                                        <div className='row justify-content-end'>
                                            <div className='justify-content-between'>
                                                <div
                                                    type='button'
                                                    className='cancl-btn cancel-txt'
                                                    onClick={() => this.props.cancel()}
                                                >Cancel</div>
                                                <div
                                                    type='button'
                                                    className='save-btn save-txt'
                                                    onClick={() => this.validateEmployeeData()}
                                                >Save</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='justify-content-end mt-3'
                                        onClick={() => this.toggleOpenDetail()}>
                                        <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                    </div>
                                </div>}
                        </div>
                        :
                        <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <div className='toggle-card-heading ml-2'>
                                    {this.props.item.first_name}
                                </div>
                                {!this.state.openDetail &&
                                    <div
                                        className='justify-content-end'
                                        onClick={() => this.toggleOpenDetail()}>
                                        <img
                                            alt="hide"
                                            src={down_arrow}
                                            className="up_arrow"
                                        ></img>
                                    </div>
                                }
                            </div>
                            {this.state.openDetail &&
                                <div>
                                    <div className='hz-lines mt-2 mb-2'></div>
                                    <div className='row justify-content-between'>
                                        <div className='p-heading mx-2 mt-2 mb-2'>Dependent Details</div>
                                    </div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Family Member</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.family_member}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>First Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.first_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Last Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.last_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Birth Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.birth_name}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Gender</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.gender}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Date of Birth</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={moment(this.props.item.date_of_birth).format("DD-MM-YYYY")}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Birth Place</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.birth_place}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Nationality</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        value={this.props.item.nationality}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='justify-content-end mt-3'
                                        onClick={() => this.toggleOpenDetail()}>
                                        <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveEmploymentDetails}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(DependentCards);