import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import Nodata from '../../../../assets/drishti_images/nodatafound/Nodatafound.svg';
import moment from 'moment';
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import { downloadPDFExit } from '../../employeeScreens/gems/Gems.api.service';
class ExitForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            screenwidth: 0,
            screenheight: 0,
            isSearch: true,
            emp_id: '',
            admin: true,
            empID: '',
            page: 1,
            showEmpData: false,
            html: '',
        }
    }

    loadData = (emp_id) => {
        if (emp_id === '') {
            toast.warn('Please enter employee id', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (emp_id.length > 8) {
            toast.error("Please enter valid employee id", {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (emp_id.length < 6) {
            toast.error("Please enter valid employee id", {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.getEmployeeExitForm(emp_id)
        }
    }

    getEmployeeExitForm = (empId) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: empId
        }
        fetchApiCall(Config.getEmployeeExitForm, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState(
                        {
                            loading: false,
                            exitEmployeeData: res.data.employee_exit_form ? res.data.employee_exit_form[0] : null,
                            showEmpData: true,
                            // html: res.data.employee_exit_form_template
                        })
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false
                    })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    download = (esepID) => {
        this.setState({
            loading: true,
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            employee_seperation_id: esepID,
            emp_id: this.state.emp_id,
        }
        downloadPDFExit(body, "admin")
        .then(res => {
            sessionOut(this.props, res, this.state.admin);
            if (res.status == 200 || res.status == 201) {
                let data = res.data;
                let fileNametest = res.headers["content-disposition"].split("filename=")[1];
                let fileName = (fileNametest ? fileNametest : esepID) + ".pdf";
                let toasterMsg = "Your document has been downloaded.";
                commonDownloadFile(data, toasterMsg, fileName);
                this.setState({ loading: false })
            } else {
                toast.error(res.data.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({ loading: false })
            }
        }).catch(err => {
            this.setState({ loading: false })
            toast.error(err, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        })
        // fetchApiCall(Config.downloadEmployeeExitForm, body, headers, false, 'admin','', '', 'blob')
        //     .then(res => {
        //         sessionOut(this.props, res, this.state.admin);
        //         if (res.status == 200 || res.status == 201) {
        //             let data = res.data;
        //             let fileNametest = res.headers["content-disposition"].split("filename=")[1];
        //             let fileName = (fileNametest ? fileNametest : esepID) + ".pdf";
        //             let toasterMsg = "Your document has been downloaded.";
        //             commonDownloadFile(data, toasterMsg, fileName);//////////////////////////////
        //             this.setState({ loading: false })
        //         } else {
        //             toast.error(res.data.message, {
        //                 position: "bottom-center",
        //                 hideProgressBar: true,
        //                 className: 'toastError'
        //             })
        //             this.setState({ loading: false })
        //         }
        //     }).catch(err => {
        //         this.setState({ loading: false })
        //         toast.error(err, {
        //             position: "bottom-center",
        //             hideProgressBar: true,
        //             className: 'toastError'
        //         })
        //     })
    }

    generateExitForm = async (element, esepID) => {
        const html2pdf = (await import("html2pdf.js")).default;
        const options = {
            margin: [10, 0, 10, 0],
            filename: `${esepID}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                dpi: 192,
                scale: 2,
                letterRendering: true,
                useCORS: true,
            },
            jsPDF: {
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
                putOnlyUsedFonts: true,
                compress: true
            },
            pagebreak: {
                mode: ['avoid-all', 'css'],
                before: '.page-break',
                after: '.page-break',
                avoid: '.no-break'
            }
        };
        html2pdf().set(options).from(element).save();
    };

    handleEmpChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
                this.setState({ emp_id: e.target.value })
            }
    }

    render() {
        return (
            <div>
                <div className="justify-content-between">
                    <LoaderIndicator loading={this.state.loading} />
                    <div className="col-md-12 px-0" >
                        <div className='row'>
                            <div className='col-12 col-sm-6 col-md-4 mb-3 mt-3'>
                                <div className='p-sub-heading ml-3'>Enter the employee Details</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        placeholder="Employee ID"
                                        type="text"
                                        maxLength={8}
                                        onChange={(e) => this.handleEmpChange(e)}
                                        readOnly={!this.state.isSearch}
                                        value={this.state.emp_id ? this.state.emp_id : ''}
                                    />
                                </div>
                            </div>
                            {this.state.isSearch &&
                                <div
                                    type='button'
                                    className='col-12 save-btn save-txt mt-38px'
                                    onClick={() => this.loadData(this.state.emp_id)}
                                >SEARCH
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.state.showEmpData === true &&
                    <>
                        {this.state.exitEmployeeData !== null ?
                            <>
                                <div className='hz-lines mr-4 ml-4'></div>
                                <div className="col-md-12 px-0" >
                                    <div className='p-heading ml-4 mt-3'>Employee details :</div>
                                </div>
                                <div className="justify-content-between">
                                    <LoaderIndicator loading={this.state.loading} />

                                    <div className="col-md-12 px-0" >
                                        <div className='row'>
                                            <div className='col-4 mb-3 mt-3'>
                                                <div className='p-sub-heading ml-3'>Employee Name</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        // placeholder="Giftson Jeyakumar"
                                                        value={this.state.exitEmployeeData.employee_name}
                                                        type="text"
                                                        // onChange={(e) => this.setState({ emp_id: e.target.value })}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3 mt-3'>
                                                <div className='p-sub-heading ml-3'>Created Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        value={moment(this.state.exitEmployeeData.created_datetime).format("DD-MM-YYYY")}
                                                        type="text"
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3 mt-3'>
                                                <div className='p-sub-heading ml-3'>Date os joining</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        value={moment(this.state.exitEmployeeData.date_of_joining).format("DD-MM-YYYY")}
                                                        type="text"
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3 mt-3'>
                                                <div className='p-sub-heading ml-3'>Dissatisfaction Feature</div>
                                                <div className='p-placeholder-text'>
                                                    <textarea
                                                        className="form-control p-placeholder px-3"
                                                        placeholder="If you are leaving the organisationfor better career prospects,
                                                        would you like to specify which other organisation are you joining. 
                                                        If this company is a partner, please specify."
                                                        value={this.state.exitEmployeeData.dissatisfaction_feature}
                                                        readOnly={true}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3 mt-3'>
                                                <div className='p-sub-heading ml-3'>Function</div>
                                                <div className='p-placeholder-text'>
                                                    <textarea
                                                        className="form-control p-placeholder px-3"
                                                        placeholder="Information Technology"
                                                        value={this.state.exitEmployeeData.function}
                                                        readOnly={true}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3 mt-3'>
                                                <div className='p-sub-heading ml-3'>Like To Work Later</div>
                                                <div className='p-placeholder-text'>
                                                    <textarea
                                                        className="form-control p-placeholder px-3"
                                                        value={this.state.exitEmployeeData.like_to_work_later}
                                                        readOnly={true}
                                                        rows={4}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='p-placeholder-text justify-content-end'>
                                                    <div justify-content-end
                                                        type='button'
                                                        className='save-btn save-txt mt-38px'
                                                        onClick={() => this.download(this.state.exitEmployeeData.employee_seperation_id)}
                                                    >DOWNLOAD
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className='d-flex justify-content-center flex-column' style={{ height: '33vh' }}>
                                <div className='align-self-center'>
                                    <img src={Nodata} alt="Nodata" />
                                </div>
                                <div className='align-self-center'>
                                    <label className='card-content-title grey-color'>No Data found!</label>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
        );
    }
}

export default ExitForm;