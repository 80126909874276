import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import GoToPage from '../../blueCollarRegistration/goToPage';

const PaginationTable = ({ switchPage, totalPages, currentPage, totalRecords }) => {

  let theme = createTheme({
    palette: {
      custom: {
        main: '#623AAA',
        darker: '#623AAA',
        contrastText: '#FFFFFF'
      }
    },
  });

  const handleSwitchPage = (value) => {
    switchPage(value);
  }
  return (
    <>
      <div className='w-100 text-align-center btm-pagination'>
        <div className='flex-style-for-view-pagination'>
          <div className='total-records'>
            {'Total records: '}
            <span className='font-weight-light'>{totalRecords}</span>
          </div>
          <div className='dflex align-item-center-noflex'>
            <ThemeProvider theme={theme}>
              <Pagination count={totalPages} color="custom" page={parseInt(currentPage)} onChange={(event, value) => handleSwitchPage(value)} showFirstButton showLastButton />
            </ThemeProvider>
            <GoToPage totalPage={parseInt(totalPages)} currentPage={currentPage}
              onChange={handleSwitchPage}
            />
          </div>
          <div></div>
        </div>
      </div>

    </>
  )
}

export default PaginationTable
