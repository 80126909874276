export const maskEmail = (value) => {
    //Regex ^(.).+(.)(@.+)$ captures the first and last character of the username and the domain, replacing the middle part with "XXXX" using '$1XXXX$2$3'

    if (!value) return '';
    const [user, domain] = value.split('@');
    return user[0] + 'XXXX' + user.slice(-1) + '@' + domain;
}

export const maskPAN = (value) => {
    //Replaces all characters except the last four with 'X', ensuring only the last four remain visible.
    if (!value) return '';
    return value ? value.replace(/.(?=.{4})/g, 'X') : '';
}

export const maskGSTIN = (value) => {
    //Captures the first two and last three characters, replacing the middle ten characters with 'XXXXXXXXXX'

    if (!value) return '';
    return value ? value.replace(/^(.{2}).{10}(.{3})$/, '$1XXXXXXXXXX$2') : '';
}

export const maskAadhar = (value) => {
    //Replaces everything except the last four characters with 'XXXXXXXX'

    if (!value) return '';
    return value ? value.replace(/^(.{4}).*(.{4})$/, 'XXXXXXXX$2') : '';
}

export const maskSocialID = () => {
    return 'XXXXXXXXX';
}

export const maskMobile = (value) => {
    // Replaces all digits except the last four with 'X'
    if (!value) return '';
    if (typeof value === 'number' || Number.isFinite(value)) {
        value = String(value); // Convert to string if it is a number
    }
    let cleaned = value.replace(/\D/g, '');
    // Check length and apply masking
    if (cleaned.length <= 4) {
        return cleaned; // If too short, return as is
    } else if (cleaned.length <= 6) {
        return cleaned.replace(/\d(?=\d{2})/g, 'X'); // Mask all but last 2 digits
    } else {
        return cleaned.replace(/\d(?=\d{4})/g, 'X'); // Mask all but last 4 digits
    }
}

export const maskBank = (value) => {
    // Replaces the middle characters with 'XXXXXXXX' while keeping the first four and last four characters intact.

    if (!value) return '';
    return value ? value.replace(/^(.{4}).*(.{4})$/, 'XXXXXXXX$2') : '';
}

export const maskPassport = (value) => {
    //  Replaces the middle characters with 'XXXXX' while keeping the first two and last three characters intact.

    if (!value) return '';
    return value ? value.replace(/^(.{2}).*(.{3})$/, '$1XXXXX$2') : '';
}

export const maskUAN = (value) => {
    //Replaces all characters except the last four with 'XXXXXXXX'
    
    if (!value) return '';
    return 'X'.repeat(8) + value.slice(-4); // Mask first 8 digits
}