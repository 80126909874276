import React, { useEffect, useState } from 'react'
import { agencyDetailsPrimaryCol, constantVals } from '../../../adminScreens/clearance/constants'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import ClearanceTable from '../../../adminScreens/clearance/ClearanceTable'
import CleEmpDetailsPopup from './CleEmpDetailsPopup'
import { toaster } from '../../../../../utils/Utils'
import { downloadExitHandbook, fetchEmployeeClearance } from '../../../../../constants/api.service'
import rightArrow from '../../../../../assets/drishti_images/header/right-arrow-gif-unscreen.gif';
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction'
import ViewIcon from '../../../../../assets/drishti_images/view.svg';
import { maskEmail, maskMobile } from '../../../../shared/commonMaskFunction'

function ClearanceRequests(props) {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedEmployee, setSelectedEmployee] = useState([])
  const [netAmount, setNetAmount] = useState("NA")
  const [totalPayableAmt, setTotalPayableAmt] = useState("NA")
  const [totalRecoveryAmt, setTotalRecoveryAmt] = useState("NA")

  useEffect(() => {
    setCurrentPage(1)
    setTotalPages(1)
    fetchAgencyWiseClearanceDetails(currentPage)
  }, [])

  const fetchAgencyWiseClearanceDetails = (page) => {
    setLoading(true)
    let params = {
      // page_number: page
    }
    fetchEmployeeClearance(props, params)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          let { employee_clearance_list, total_pages } = res?.data?.data
          
          employee_clearance_list = employee_clearance_list.map((req) => {
            if (req.contact && Array.isArray(req.contact)) {
                req.contact.forEach(contact => {
                    if (contact.agent) {
                        contact.agent.email = maskEmail(contact.agent.email ?? '');
                        contact.agent.mobile_no = maskMobile(contact.agent.mobile_no ?? '');
                    }
                });
            }
            return req;
        });

          employee_clearance_list = employee_clearance_list.map((data) => {
            return {
              ...data,
              "contact_details": data?.contact.reduce((accumulator, val, index) =>
                accumulator + "" + String(index + 1) + ' . ' + val.agent.domain_id + "\n" + (val.agent.email != '' ? val.agent.email : '--') + "\n" + val.agent.mobile_no + '\n', '')
            }
          })
          setTableData(employee_clearance_list)
          setTotalPages(total_pages)
          calcNetAmount(employee_clearance_list)
        }
        else {
          setTableData([])
          toaster("warning", res.data.message)
        }
      })
      .catch((error) => {
        setTableData([])
        toaster("warning", error.data.message)
      })
      .finally(() => { setLoading(false) })
  }
  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchAgencyWiseClearanceDetails(currentPage - 1)
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      fetchAgencyWiseClearanceDetails(currentPage + 1)
    }
  };
  const handleViewAction = (index) => {
    setSelectedEmployee(tableData[index])
  }
  const backAction = () => {
    setSelectedEmployee([])
  }
  const downloadFile = () => {
    setLoading(true)
    downloadExitHandbook(props).then((res) => {
      if (res.status === 200 || res.status === 201) {
        let toasterMsg = "Your document has been downloaded.";
        commonDownloadFile(res?.data?.data?.attachment_presigned_url, toasterMsg, "Employee Exit handbook.pdf");
      }
      else {
        toaster("warning", res.data.message)
      }
    }).catch((error) => {
      toaster("warning", error.data.message)
    })
      .finally(() => { setLoading(false) })
  }
  const calcNetAmount = (dataList) => {
    if (dataList && dataList.length > 0) {
      let totalPayableAmount = dataList.reduce((accumulator, currentValue) => {
        if (currentValue?.agency_name != 'F & F Settlement' && currentValue?.pay_amount) { return accumulator + currentValue.pay_amount }
        else { return accumulator + 0 }
      }, 0)
      let totalRecoveryAmount = dataList.reduce((accumulator, currentValue) => {
        if (currentValue?.agency_name != 'F & F Settlement' && currentValue?.recovery_amount) { return accumulator + currentValue.recovery_amount }
        else { return accumulator + 0 }
      }, 0)
      setTotalPayableAmt("Rs. " + totalPayableAmount)
      setTotalRecoveryAmt("Rs. " + totalRecoveryAmount)
      if (totalPayableAmount > totalRecoveryAmount) { setNetAmount(`Rs. ${totalPayableAmount - totalRecoveryAmount} payable`) }
      else if (totalPayableAmount < totalRecoveryAmount) { setNetAmount(`Rs. ${totalRecoveryAmount - totalPayableAmount} recoverable`) }
      else { setNetAmount(0) }
    }
  }
  return (
    <div className='flex-direction-column h-60vh'>
      <div className='row w-100 justify-content-between mt-2 ml-2'>
        <div className='toggle-card-heading mb-10 ml-2'>{constantVals.timeline}</div>
        <div className='d-flex justify-content-end'>
          <img
            src={rightArrow}
            alt="rightArrow"
            className="right-arrow-gif"
          />
          <div className=' ml-2'>
            <div onClick={downloadFile}><label className='tata-policy-link mr-2' type='button'>{'Employee Exit Handbook'}</label></div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-direction-row ml-3 justify-content-start w-100 gap-10'>
        <div className='w-16'>
          <div className='appcenter-app-name1 mt-0px mr-10'>{"Total Recovery Amount"} </div>
          <div className='p-placeholder-text'>
            <input
              className="form-control p-placeholder px-3"
              type='text'
              readOnly={true}
              placeholder='NA'
              value={totalRecoveryAmt}
            />
          </div>
        </div>
        <div className='w-16'>
          <div className='appcenter-app-name1 mt-0px mr-10'>{"Total Payable Amount"} </div>
          <div className='p-placeholder-text'>
            <input
              className="form-control p-placeholder px-3"
              type='text'
              readOnly={true}
              placeholder='NA'
              value={totalPayableAmt}
            />
          </div>
        </div>
        <div className='w-16'>
          <div className='appcenter-app-name1 mt-0px mr-10'>{"Net Amount"} </div>
          <div className='p-placeholder-text'>
            <input
              className="form-control p-placeholder px-3"
              type='text'
              readOnly={true}
              placeholder='NA'
              value={netAmount}
            />
          </div>
        </div>
      </div>
      <div className='flex-direction-column h-48vh scroll-auto mt-10px'>
        {
          tableData && tableData.length > 0 ?
            <ClearanceTable
              action={'empDashboardTable'}
              data={tableData}
              columns={agencyDetailsPrimaryCol}
              handleAction={handleViewAction}
              primaryActionImg={ViewIcon}
              // currentPage={currentPage}
              isFilterOpen={true}
              pageSize={tableData.length}
            />
            :
            <div className='mt-5 h-90-percent'>
              <DataNotFound />
            </div>
        }
        {/* {tableData.length > 0 &&
          <div className="col-md-12 w-100 d-flex justify-content-center paginator">
            <Pagination>
              {currentPage != 1 &&
                <>
                  <Pagination.Prev
                    onClick={() => { handlePageCount("decrement") }} />
                  <Pagination.Item disabled>{1}</Pagination.Item>
                  <Pagination.Ellipsis disabled />
                </>
              }
              <Pagination.Item active>{currentPage}</Pagination.Item>
              {currentPage != totalPages &&
                <>
                  <Pagination.Ellipsis disabled />
                  <Pagination.Item disabled >{totalPages}</Pagination.Item>
                  <Pagination.Next
                    onClick={() => { handlePageCount("increment") }} />
                </>
              }
            </Pagination>
          </div>
        } */}
      </div>
      {selectedEmployee.length != 0 &&
        <CleEmpDetailsPopup
          onDismiss={backAction}
          title={selectedEmployee["agency_name"]}
          selectedEmployee={selectedEmployee}
        />
      }
    </div>
  )
}

export default ClearanceRequests