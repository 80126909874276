import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { requestFailed } from '../../../../constants/Messages';
import moment from 'moment';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class SpecificEmployeeReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            coiData: [],
            showMyDigitalSigningRequests: false,
            emp_id: ''
        }
    }


    componentDidMount() {

    }

    getData(empid) {
        console.log("empid ", empid)
        if (empid === '') {
            toast.warning("Please enter employee ID", {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.getCOIData(empid)
        }
    }
    getCOIData = (empid) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: empid
        }
        console.log('getCOIEmpReport body', body);
        fetchApiCall(Config.getCOIEmpReport, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                console.log('getCOIEmpReport response', res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        coiData: res.data.data,
                        showMyDigitalSigningRequests: true
                    })
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    getEmpDetail = (e) => {
        if (e.key === 'Enter') {
            this.getData(this.state.emp_id)
        }
    }

    handleEmpChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value)) {
            this.setState({ emp_id: e.target.value })
        }
    }

    render() {
        return (
            <div >
                <div className="justify-content-between">
                    <LoaderIndicator loading={this.state.loading} />
                    <div className="col-md-12 px-0" >
                        <div className='row'>
                            <div className='col-4 mb-3 mt-3'>
                                <div className='p-sub-heading ml-3'>Enter the employee Details</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        placeholder="Employee ID"
                                        type="text"
                                        maxLength={8}
                                        onKeyDown={this.getEmpDetail}
                                        onChange={(e) => this.handleEmpChange(e)}
                                        value={this.state.emp_id}

                                    />
                                </div>
                            </div>
                            <div className='mb-3 mt-3 d-flex align-items-flex-end'>
                            <button
                                type='button'
                                className={this.state.emp_id.length >= 6 ? 'common-save-btn save-txt btn ' : 'common-disable-btn save-txt btn'}
                                onClick={() => this.getData(this.state.emp_id)}
                                disabled={this.state.emp_id.length < 6} 
                            >SEARCH
                            </button>
                        </div>
                        </div>
                    </div>
                </div>


                {this.state.showMyDigitalSigningRequests === true &&
                    <>
                        <div className='hz-lines mr-4 ml-4'></div>
                        <div className="row justify-content-between align-item-center px-3">
                            <div>
                                <label className="edit-cardtitle ml-2">Employee Specific Report</label>
                            </div>
                            <div className=' justify-content-end'>
                                <div>
                                    {this.state.coiData.length > 0 ?
                                        <ExcelFile filename="EmployeeSpecificReport"
                                            element={
                                                <div
                                                    type='button'
                                                    className="save-btn save-txt mt-38px"
                                                >Export
                                                </div>
                                            }>
                                            <ExcelSheet
                                                data={this.state.coiData}
                                                name="Employees"
                                            >
                                                <ExcelColumn label="Form Type" value="form_type" />
                                                <ExcelColumn label="Employee ID" value="emp_id" />
                                                <ExcelColumn label="Employee Name" value="emp_name" />
                                                <ExcelColumn label="PA" value="emp_pa" />
                                                <ExcelColumn label="PSA" value="emp_psa" />
                                                <ExcelColumn label="Region" value="emp_region" />
                                                <ExcelColumn label="Employee Subgroup" value="emp_esubgroup" />
                                                <ExcelColumn label="Company Code" value="company_code" />
                                                <ExcelColumn label="Organization Unit" value="organization_unit" />
                                                <ExcelColumn label="Relative ID" value="relative_perno" />
                                                <ExcelColumn label="Relative Name" value="relative_full_name" />
                                                <ExcelColumn label="Relative Business Unit" value="relative_business_unit" />
                                                <ExcelColumn label="Relationship" value="relationship" />
                                                <ExcelColumn label="Deleted" value="is_deleted" />
                                                <ExcelColumn label="Created Date Time" value="created_date_time" />
                                                <ExcelColumn label="Modified Date Time" value="modified_date_time" />
                                            </ExcelSheet>
                                        </ExcelFile> : null
                                    }
                                </div>
                            </div>
                        </div>

                        {this.state.coiData.length != 0 ?
                            <>
                                <div className='col-md-12 flex-direction-column coiAdminDashboard'>
                                    <div className='mb-2 col-md-12 mt-3'>
                                        <table className="table">
                                            <thead className=''>
                                                <tr>
                                                    <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                    <th scope="col"><div className='thead'>Form Type</div></th>
                                                    <th scope="col"><div className='thead'>Employee ID</div></th>
                                                    <th scope="col"><div className='thead'>Employee Name</div></th>
                                                    <th scope="col"><div className='thead'>PA</div></th>
                                                    <th scope="col"><div className='thead'>PSA</div></th>
                                                    <th scope="col"><div className='thead'>Region</div></th>
                                                    <th scope="col"><div className='thead'>Company Code</div></th>
                                                    <th scope="col"><div className='thead'>Organization Unit</div></th>
                                                    <th scope="col"><div className='thead'>Relative ID</div></th>
                                                    <th scope="col"><div className='thead'>Relative Name</div></th>
                                                    <th scope="col"><div className='thead'>Relative Business Unit</div></th>
                                                    <th scope="col"><div className='thead'>Relationship</div></th>
                                                    <th scope="col"><div className='thead'>Deleted</div></th>
                                                    <th scope="col"><div className='thead'>Created Date</div></th>
                                                    <th scope="col"><div className='thead'>Modified Date</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.coiData.map((listValue, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                            <td><div className="td">{listValue.form_type ? listValue.form_type : '-'}</div></td>
                                                            <td> <div className="td d-flex">{listValue.emp_id ? listValue.emp_id : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.emp_name ? listValue.emp_name : '-'}</div> </td>
                                                            <td><div className="td">{listValue.emp_pa ? listValue.emp_pa : '-'}</div></td>
                                                            <td> <div className="td d-flex">{listValue.emp_psa ? listValue.emp_psa : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.emp_region ? listValue.emp_region : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.company_code ? listValue.company_code : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.organization_unit ? listValue.organization_unit : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.relative_perno ? listValue.relative_perno : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.relative_full_name ? listValue.relative_full_name : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.relative_business_unit ? listValue.relative_business_unit : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.relationship ? listValue.relationship : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.is_deleted ? "YES" : "NO"}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.created_date_time ? moment(listValue.created_date_time).format("DD-MM-YYYY") : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.modified_date_time ? moment(listValue.modified_date_time).format("DD-MM-YYYY") : '-'}</div> </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                            :
                            <div className='col-md-12 justify-content-center align-item-center h-60vh'>
                                <DataNotFound />
                            </div>
                        }
                    </>
                }
            </div>
        );
    }
}

// export default ExitScreen;
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(SpecificEmployeeReport);